<template>
    <div class="popup" v-if="isOpen" style="z-index: 10001;">
        <div class="popup-content">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <b-button id="btn_sendEmailForgotPIN_Back" v-if="showBackButton" class="btn-back" @click="back">
                    <b-icon icon="chevron-left" class="pr-1 pt-1" scale="1"></b-icon>
                    {{ $t('common.BACK') }}
                </b-button>
                <div v-else></div>
                <div v-if="showCloseButton && !isBanned" class="btn-close" @click="close()">
                    <b-icon icon="x" style="color: gray;" scale="1.6"></b-icon>
                </div>
            </div>
            <div v-if="isForgotPin" class="d-flex flex-column align-items-center">
                <img v-if="sentEmail" class="lock-img ml-4" src="@/assets/img/sent-email.png" alt="" />
                <img v-else class="lock-img" src="@/assets/img/pin-lock-3.png" alt="" />
                <p v-if="sentEmail" class="font-weight-bold font-24 mt-4">{{ $t("PIN_SECURITY.EMAIL_SENT") }}</p>
                <p v-else="sentEmail" class="font-weight-bold font-24 mt-4">{{ $t("PIN_SECURITY.FORGOT_PIN") }}</p>
                <p v-if="sentEmail" class="mt-1">{{ $t("PIN_SECURITY.PLEASE_CHECK_THE_EMAIL_WE_SENT_TO") }}<br><span
                        class="font-weight-bold">{{ userEmail
                        }}</span> {{ $t("PIN_SECURITY.TO_CHANGE_YOUR_PIN") }}
                </p>
                <p v-if="!sentEmail" class="mt-2">{{ $t("PIN_SECURITY.WE_WILL_SEND_EMAIL_TO") }} <span
                        class="font-weight-bold">{{ userEmail
                        }}</span>
                    <br>{{ $t("PIN_SECURITY.CLICK_THE_LINK_TO_CHANGE_YOUR_PIN") }}
                </p>
                <b-button id="btn_sendEmailForgotPIN_Submit" class="btn-main col-12 button mt-5" variant="none" @click="sendEmailForgotPIN()">
                    {{ sentEmail ? $t("PIN_SECURITY.OKAY") : $t("PIN_SECURITY.SEND_EMAIL") }}
                </b-button>
            </div>
            <div v-else>
                <div v-if="isBanned != null && isBanned"
                    class="d-flex flex-column align-items-center">
                    <img class="lock-img mt-4" src="@/assets/img/pin-blocked.png" alt="" />
                    <p class="font-weight-bold font-20 mt-3">{{ $t('PIN_SECURITY.ACCOUNT_BLOCKED') }}</p>
                    <p class="pre-formatted font-14 mt-2">{{ $t('PIN_SECURITY.ACCOUNT_BLOCKED_MESSAGE') }}</p>
                    <p class="mt-5 mb-5 contact-message">{{ contactMessage }}</p>
                    <b-button id="btn_closePopupBanned" class="btn-main col-12 mt-2 button" variant="none" @click="close(true)">
                        {{ $t("PIN_SECURITY.OKAY") }}
                    </b-button>
                </div>
                <div v-else-if="isBanned != null && !pinCreatedSuccess" class="d-flex flex-column align-items-center">
                    <img class="lock-img" src="@/assets/img/pin-lock-1.png" alt="" />
                    <p class="font-weight-bold font-20 mt-2 mb-1">{{ title }}</p>
                    <p class="pre-formatted font-14" :class="{ 'mb-4': isReEnter }">{{ message }}</p>
                    <div class="d-flex flex-row justity-content-center mt-3" :class="{ shake: pinDoesNotMatchShake }">
                        <div class="pin" :class="{ 'filled': pinLength >= 1 }"></div>
                        <div class="pin ml-4" :class="{ 'filled': pinLength >= 2 }"></div>
                        <div class="pin ml-4" :class="{ 'filled': pinLength >= 3 }"></div>
                        <div class="pin ml-4" :class="{ 'filled': pinLength >= 4 }"></div>
                        <div class="pin ml-4" :class="{ 'filled': pinLength >= 5 }"></div>
                        <div class="pin ml-4" :class="{ 'filled': pinLength >= 6 }"></div>
                    </div>
                    <p v-if="error" class="not-match mt-3">{{ error }}</p>
                    <p v-if="isValidatePin" class="mt-5 font-14 forgot-pin mb-4" @click="forgotPin">{{
                        $t('PIN_SECURITY.FORGOT_PIN')
                    }}
                    </p>
                    <div class="d-flex flex-column mb-5" :class="{ 'mt-5': !isValidatePin }">
                        <div class="d-flex flex-row">
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(1)">1</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(2)">2</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(3)">3</div>
                        </div>
                        <div class="h-line"></div>
                        <div class="d-flex flex-row">
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(4)">4</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(5)">5</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(6)">6</div>
                        </div>
                        <div class="h-line"></div>
                        <div class="d-flex flex-row">
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(7)">7</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(8)">8</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(9)">9</div>
                        </div>
                        <div class="h-line"></div>
                        <div class="d-flex flex-row">
                            <div class="key"></div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center" @click="clickPin(0)">0</div>
                            <div class="v-line"></div>
                            <div class="key d-flex flex-column justify-content-center align-items-center" @click="clearPin">
                                <img class="clear-img" src="@/assets/img/ic-clear.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="isBanned != null"
                    class="d-flex flex-column align-items-center">
                    <div class="mt-5 mb-5">
                        <img class="lock-img" src="@/assets/img/pin-lock-2.png" alt="" />
                        <p class="font-18 font-weight-bold mt-3">{{ $t('PIN_SECURITY.PIN_CREATED_SUCCESSFULLY') }}</p>
                    </div>
                    <b-button id="btn_sendEmailForgotPIN_Success" class="btn-main col-12 button" variant="none" @click="close()">
                        {{ $t("PIN_SECURITY.OKAY") }}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import pinService from "../services/pin.service"
import { isLoggedIn } from "../constants/userStatus"
import { isUser } from "../constants/roles"
import externalSites from "../constants/externalSites"

export default {
    props: {
        shouldCheckPin: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isOpen: false,
            pin: '',
            confirmingPin: '',
            isReEnter: false,
            error: null,
            pinCreatedSuccess: false,
            isValidatePin: false,
            pinDoesNotMatchShake: false,
            attemptsLeft: null,
            isBanned: null,
            isForgotPin: false,
            sentEmail: false,
            operatingSystem: "",
            browserName: "",
            showBackButton: false,
        }
    },
    emits: ['on-success'],
    async mounted() {
        if (this.shouldCheckPin) {
            await this.checkPin()
        }
        this.browserName = this.$browserDetect.meta.name
        let userAgent = this.$browserDetect.meta.ua.toLocaleLowerCase()
        if (userAgent.includes("win")) this.operatingSystem = "Windows"
        else if (userAgent.includes("mac") && !this.$browserDetect.isIOS) this.operatingSystem = "MacOS"
        else if (userAgent.includes("ios") || userAgent.includes("iphone") || this.$browserDetect.isIOS) this.operatingSystem = "iOS"
        else if (userAgent.includes("android")) this.operatingSystem = "Android"
        else if (userAgent.includes("linux")) this.operatingSystem = "Linux"
        else this.operatingSystem = "Unknown os"

        const self = this
        window.addEventListener('keyup', function(ev) {
            if (self.isOpen) {
                const key = ev.key
                if (key == 'Backspace') {
                    self.clearPin()
                } else {
                    const reg = /^\d+$/;
                    if (reg.test(key)) {
                        self.clickPin(key)
                    }
                }
            }
        });
    },

    watch: {
        async '$store.getters.userProfile'(value) {
            if (!this.isOpen && this.shouldCheckPin) {
                await this.checkPin()
            }
        },
        async pin(value) {
            if (value.length === 6) {
                if (this.isValidatePin) {
                    try {
                        const res = await pinService.validatePin({ pin: value })
                        if (res && res.data) {
                            this.$emit('on-success', value)
                            this.isOpen = false
                        }
                    } catch (e) {
                        this.attemptsLeft = e.extraData.attempts_left
                        this.isBanned = e.extraData.is_banned
                        if (!this.isBanned && this.attemptsLeft != null) {
                            const wrongAttempts = 5 - this.attemptsLeft
                            this.error = this.$t('PIN_SECURITY.WRONG_PIN_ATTEMPTS', { value: wrongAttempts })
                        } else {
                            this.error = e.extraData.error
                        }
                        this.startShaking()
                        setTimeout(() => { this.pin = '' }, 1500)
                    }
                } else {
                    setTimeout(() => { this.isReEnter = true }, 200)
                }
            }
        },
        confirmingPin(value) {
            if (value.length === 6) {
                if (value !== this.pin) {
                    this.confirmingPin = ''
                    this.error = this.$t('PIN_SECURITY.PIN_DOES_NOT_MATCH')
                    this.startShaking()
                    setTimeout(() => { this.confirmingPin = '' }, 1500)
                } else {
                    this.setPin()
                }
            }
        },
    },
    methods: {
        async checkPin(checkAgain = false) {
            if (isLoggedIn() && isUser()) {
                const res = await pinService.checkPin()
                if (res) {
                    if (!res.data) {
                        if (checkAgain) {
                            this.isValidatePin = false
                        } else {
                            this.openPopup(false, false, false)
                        }
                    }
                }
            }
        },
        async setPin() {
            const res = await pinService.setPin({ pin: this.pin })
            if (res && res.message) {
                this.pinCreatedSuccess = true
            }
        },
        async getAttemptsLeft() {
            if (isLoggedIn()) {
                const res = await pinService.attemptsLeft()
                if (res && res.attempts_left >= 0) {
                    this.attemptsLeft = res.attempts_left
                    this.isBanned = res.is_banned
                }
            }
        },
        async openPopup(validate = false, forgotPin = false, showCloseButton = true) {
            this.pin = ''
            this.confirmingPin = ''
            this.isOpen = true
            this.isValidatePin = validate
            this.isForgotPin = forgotPin
            this.showCloseButton = showCloseButton
            this.pinCreatedSuccess = false
            this.sentEmail = false
            this.isBanned = null
            await this.getAttemptsLeft()
            if (validate) {
                await this.checkPin(true)
            }
        },

        clickPin(num) {
            if (this.pinLength < 6) {
                this.error = null
                if (this.isReEnter) {
                    this.confirmingPin = `${this.confirmingPin}${num}`
                } else {
                    this.pin = `${this.pin}${num}`
                }
            }
        },

        clearPin() {
            if (this.pinLength > 0) {
                this.error = null
                if (this.isReEnter) {
                    this.confirmingPin = this.confirmingPin.substring(0, this.pinLength - 1)
                } else {
                    this.pin = this.pin.substring(0, this.pinLength - 1)
                }
            }
        },

        forgotPin() {
            this.isForgotPin = true
            this.showBackButton = true
        },

        async sendEmailForgotPIN() {
            if (this.sentEmail) {
                this.close()
            } else {
                await this.$recaptchaLoaded()
                const recaptchaToken = await this.$recaptcha("forgotPin")
                const res = await pinService.forgotPin({
                    browser_name: this.browserName,
                    operating_system: this.operatingSystem,
                    recaptcha_token: recaptchaToken,
                })
                if (res && res.message) {
                    this.sentEmail = true
                    this.showBackButton = false
                    this.showCloseButton = false
                }
            }
        },

        async close(logout = false) {
            this.isOpen = false
            if (logout) {
                await this.$store.dispatch("doLogout")
                await this.$router.push({ path: "/login", })
            }
        },

        back() {
            if (this.showBackButton) {
                this.isForgotPin = false
                this.showBackButton = false
            } else {
                this.close()
            }
        },

        startShaking() {
            this.pinDoesNotMatchShake = true
            setTimeout(() => {
                this.pinDoesNotMatchShake = false
            }, 1500)
        },
    },

    computed: {
        pinLength() {
            return this.isReEnter ? this.confirmingPin.length : this.pin.length
        },

        title() {
            return this.isValidatePin ? this.$t('PIN_SECURITY.ENTER_YOUR_CURRENT_PIN') :
                this.isReEnter ?
                    this.$t('PIN_SECURITY.RE_ENTER_PIN') :
                    this.$t('PIN_SECURITY.CREATE_NEW_PIN')
        },

        message() {
            return this.isValidatePin ?
                this.$t('PIN_SECURITY.ENTER_6_DIGITS') : this.isReEnter ?
                    this.$t('PIN_SECURITY.RE_ENTER_6_DIGITS') :
                    this.$t('PIN_SECURITY.AVOID_USING')
        },

        contactMessage() {
          return this.$t("PIN_SECURITY.PLEASE_CONTACT", {
            email: externalSites.CUSTOMER_EMAIL,
            phone: externalSites.PHONE_SUPPORTS[this.$i18n.locale]
          })
        },

        userEmail() {
            return this.$store.getters.userProfile && this.$store.getters.userProfile.email;
        },
    },
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

    .popup-content {
        position: relative;
        background-color: #fff;
        padding: 30px;
        border-radius: 20px;
        text-align: center;
        min-width: 30%;
        height: fit-content;
        margin: auto;

        @media (max-width: 767.98px) {
            min-width: 90%;
        }

        .lock-img {
            width: 150px;
            height: 150px;
            object-fit: contain;
        }

        .pre-formatted {
            white-space: pre;
        }

        .pin {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: var(--primary-darker-color) solid 1px;

            &.filled {
                background-color: var(--primary-darker-color);
            }
        }

        .key {
            width: 100px;
            height: 50px;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
        }

        .v-line {
            height: 50px;
            width: 0.5px;
            background-color: #0f9a9a;
        }

        .h-line {
            width: 300px;
            height: 0.5px;
            background-color: #0f9a9a;
        }

        .clear-img {
            width: 25px;
            height: 25px;
            object-fit: contain;
        }

        .not-match {
            padding: 4px 16px 4px 16px;
            background-color: #9C1E1F;
            border-radius: 16px;
            font-size: 15px;
            color: white;
            white-space: pre;
        }

        .shake {
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
        }

        @keyframes shake {

            10%,
            90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%,
            80% {
                transform: translate3d(2px, 0, 0);
            }

            30%,
            50%,
            70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%,
            60% {
                transform: translate3d(4px, 0, 0);
            }
        }

        .contact-message {
            font-size: 15px;
            color: white;
            background-color: #EA983E;
            padding: 4px 20px 4px 20px;
            border-radius: 8px;
            white-space: pre;
        }

        .btn-back {
            color: var(--primary-color);
            border: none;
            background-color: transparent;
        }

        .btn-close {
            background-color: rgb(221, 221, 221);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .button {
            height: 40px;
            max-height: 40px;

        }

        .forgot-pin {
            background-color: var(--primary-color);
            color: white;
            padding: 4px 12px 4px 12px;
            margin-bottom: 4px;
            border-radius: 4px;
            cursor: pointer;
            font-weight: bold;

            &:hover {
                background-color: var(--primary-darker-color);
            }
        }
    }
}
</style>
