<!-- Popup.vue -->
<template>
    <div class="popup" v-if="isOpen" style="z-index: 9999;">
      <div class="popup-content">
        <p v-if="title" class="font-24 font-bolder">{{ title }}</p>
        <p v-if="message" v-html="message" :class="`font-18 font-normal mt-3 ${messageClass}`"></p>
        <b-row class="justify-content-center mt-4">
          <b-button id="btn_popup_onPositiveButtonClicked" v-if="positiveButton" class="btn-main ml-1 mr-1" :class="btnClass"
                    variant="none" @click="onPositiveButtonClicked">
            {{ positiveButton }}
          </b-button>
          <b-button id="btn_popup_onNegativeButtonClicked" class="btn-outline-main ml-1 mr-1" :class="btnClass" variant="none" @click="onNegativeButtonClicked">
            {{ negativeButton ? negativeButton : $t("common.CANCEL") }}
          </b-button>
        </b-row>
      </div>
    </div>
  </template>

  <script>
  export default {
    data () {
      return {
        isOpen: false,
        title: "",
        message: "",
        positiveButton: "",
        negativeButton: "",
        messageClass: "",
        btnClass: ""
      }
    },
    methods: {
      openPopup (title, message, positiveButton, negativeButton, messageClass = "", btnClass = "col-5") {
        this.title = title
        this.message = message
        this.positiveButton = positiveButton
        this.negativeButton = negativeButton
        this.isOpen = true
        this.messageClass = messageClass
        this.btnClass = btnClass
      },
      onPositiveButtonClicked () {
        this.$emit("on-positive-clicked")
        this.isOpen = false
      },
      onNegativeButtonClicked () {
        this.$emit("on-negative-clicked")
        this.isOpen = false
      },
    }
  }
  </script>

  <style scoped>
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    text-align: center;
  }
  </style>
