<template>
    <b-modal v-model="show" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light" @hidden="onClose()">
        <template #modal-header>
            <h5 class="modal-header-title">Verify Selfie</h5>
            <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose()">×</button>
        </template>
        <div class="modal-verify-selfie d-flex flex-column align-items-center">
            <p class="font-20 font-weight-bold">{{ fullyActive ? 'User is Fully Active' : ktpUrl && selfieUrl ? 'Verify if KTP matches with the selfie and set user to Fully Active' : ktpUrl ? '' : 'User have not uploaded the KTP' }}</p>
            <img v-if="ktpUrl" :src="ktpUrl" alt="">
            <img class="mt-2" v-if="selfieUrl" :src="selfieUrl" alt="">
            <label v-if="canUpload" class="mt-3">Upload Selfie</label>
            <VueFileAgent v-if="canUpload" :model-value="images" v-model:rawModelValue="images" ref="vueFileAgent" :theme="'grid'"
                :multiple="false" :meta="false" :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1"
                :helpText="'Choose image file'" :errorText="{
                    type: 'Invalid file type. Only images are allowed',
                    size: 'Files should not exceed 3MB in size',
                }" @beforedelete="onBeforeDelete($event)" @select="filesSelected($event)" required>
            </VueFileAgent>
            <p v-if="selfieError" class="error">Please select a selfie to upload</p>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button v-if="canVerify || canUpload" size="sm" variant="secondary" @click="onClose({ show: false })">
                Cancel
            </b-button>
            <b-button size="sm" variant="primary" @click="onVerify()">
                {{ canVerify ? 'Verify' : canUpload ? 'Upload and Verify' : 'Close' }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import { urlImage, getBase64, userHasPermission } from '../../../helpers/common'
import userStatus from '../../../constants/userStatus'
import userService from '../../../services/admin/user.service'
import { USER_PERMISSIONS } from '../../../constants/permissions';

export default {
    data() {
        return {
            show: false,
            user: {},
            images: [],
            selfieError: false,
        }
    },

    emits: ['on-success'],

    methods: {
        showModal(user) {
            this.user = user
            this.images = []
            this.selfieError = false
            this.show = true
        },

        onClose() {
            this.show = false
        },

        async onVerify() {
            if (this.canVerify) {
                const res = await userService.verifySelfie(this.user.id)
                if (res.data) {
                    this.show = false
                    this.$emit('on-success', res.data)
                }
            } else if (this.canUpload) {
                if (!this.images.length) {
                    this.selfieError = true
                    return;
                }
                const res = await userService.uploadSelfie(this.user.id, { image: this.images[0].base64 })
                if (res.data) {
                    this.show = false
                    this.$emit('on-success', res.data)
                }
            } else {
                this.show = false
            }
        },

        onBeforeDelete(fileRecord) {
            const index = this.images.indexOf(fileRecord)
            if (index !== -1) {
                this.images.splice(index, 1)
            }
        },

        async filesSelected(fileRecords) {
            if (this.images.length) {
                this.selfieError = false
                for (let i = 0; i < this.images.length; i++) {
                    if (!this.images[i].id && !this.images[i].path) {
                        this.images[i].base64 = await getBase64(this.images[i].file)
                    }
                }
            }
        },
    },

    computed: {
        ktpUrl() {
            if (!this.user.id_card) {
                return ''
            }
            return urlImage({ image: this.user.id_card.card_image })
        },

        selfieUrl() {
            if (!this.user.selfie) {
                return ''
            }
            return urlImage({ image: this.user.selfie.selfie_image })
        },

        fullyActive() {
            if (!this.user) {
                return false
            }
            return this.user.status === userStatus.FullyActive
        },

        canVerify() {
            return !this.fullyActive && this.ktpUrl && this.selfieUrl && this.hasUpdatePermission
        },

        canUpload() {
            return !this.fullyActive && this.ktpUrl && !this.selfieUrl && this.hasUpdatePermission
        },

        hasUpdatePermission() {
            return userHasPermission(USER_PERMISSIONS.USER.UPDATE_KTP_PASSPORT_SELFIE)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-verify-selfie {

    img {
        width: 600px;
        object-fit: contain;
        border-radius: 10px;
    }

    .error {
        color: red;
        font-size: 15px;
    }
}
</style>