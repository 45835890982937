<template>
    <b-modal
      v-model="showModal"
      id="full-screen-modal"
      header-class="modal-header"
      footer-class="modal-footer"
      body-class="modal-body"
      title="Property Milestone"
      @show="updateModal"
      @hidden="$emit('on-close')"
      size="huge"
    >
      <Form ref="milestoneForm">
        <div class="d-flex flex-column align-items-center">
          <div v-for="(item, index) in milestones" class="milestone-item">
            <div class="text-right">
              <button
                size="sm"
                type="button"
                class="btn-outline-main pl-3 pr-3"
                @click="removeMilestone(index)"
              >
                x
              </button>
            </div>
            <label>Headline:</label>
            <Field
              :id="`headline_${index}`"
              :name="`milestones[${index}].headline`"
              :model-value="item.headline"
              @update:modelValue="item.headline = $event"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-input
                v-bind="validationContext.field"
                :id="`headline-${index}`"
                :state="validateMilestone(validationContext.meta)"
                placeholder="Headline"
              >
              </b-form-input>
            </Field>
            <label class="mt-3">Headline ID Locale:</label>
            <Field
              :id="`headline-id-locale-${index}`"
              :name="`milestones[${index}].headline_id_locale`"
              :model-value="item.headline_id_locale"
              @update:modelValue="item.headline_id_locale = $event"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-input
                v-bind="validationContext.field"
                :id="`headline-id-locale-${index}`"
                :state="validateMilestone(validationContext.meta)"
                placeholder="Headline ID Locale"
              >
              </b-form-input>
            </Field>
            <label class="mt-3">Description:</label>
            <b-form-textarea
              :id="`description-${index}`"
              v-model="item.description"
              rows="3"
              max-rows="6"
              placeholder="Description"
            >
            </b-form-textarea>
            <label class="mt-3">Description ID Locale:</label>
            <b-form-textarea
              :id="`description-id-locale-${index}`"
              v-model="item.description_id_locale"
              rows="3"
              max-rows="6"
              placeholder="Description ID Locale"
            >
            </b-form-textarea>
            <label>Date:</label><br />
            <Field
              :id="`date-${index}`"
              :name="`milestones[${index}].date`"
              :model-value="item.date"
              @update:modelValue="item.date = $event"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-datepicker
              v-bind="validationContext.field"
                class="mb-2"
                :id="`date-${index}`"
                :class="[
                  {
                    'input-valid':
                      validateMilestone(validationContext.meta) == true,
                  },
                  {
                    'input-normal':
                      validateMilestone(validationContext.meta) == null,
                  },
                  {
                    'input-invalid':
                      validateMilestone(validationContext.meta) == false,
                  },
                ]"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </Field>
            <div class="d-flex flex-row mt-2">
              <b-form-checkbox
                :id="`key-hidden-${index}`"
                v-model="item.is_hidden"
                name="checkbox-hidden"
                :unchecked-value="false"
              >
                Hidden
              </b-form-checkbox>
              <b-form-checkbox
                class="ml-3"
                :id="`key-done-${index}`"
                v-model="item.is_done"
                name="checkbox-done"
                :unchecked-value="false"
              >
                Done
              </b-form-checkbox>
            </div>
          </div>
          <p v-if="!milestones.length" class="mt-3 mb-4">
            There are no milestones
          </p>
          <button
            size="sm"
            type="button"
            class="btn-outline-main pl-4 pr-4"
            @click="addMilestone"
          >
            + Add
          </button>
        </div>
      </Form>
      <template #modal-footer>
        <b-button size="sm" variant="success" @click="handleSubmit">
          Update
        </b-button>
        <b-button size="sm" variant="primary" @click="$emit('on-close')">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </template>

  <script>
  import propertiesService from "../../../services/admin/properties.service";
  import {
    defineRule,
    Form,
    Field,
    FieldArray,
    ErrorMessage,
  } from "vee-validate";
  import {required } from "@vee-validate/rules";

  defineRule("required", required);
  export default {
    components: {
      Form,
      Field,
      FieldArray,
      ErrorMessage,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      propertyId: {
        type: Number,
        default: null,
      },
    },
    emits: ["on-close"],
    data() {
      return {
        showModal: false,
        milestones: [],
        deletedIds: [],
      };
    },

    watch: {
      show(value) {
        this.showModal = value;
      },
    },

    methods: {
      validateMilestone(meta) {
        const { dirty, validated, valid = null } = meta;
        return dirty || validated ? valid : null;
      },
      async getMilestones() {
        const res = await propertiesService.getMilesStones({
          property_id: this.propertyId,
        });
        if (res && res.data) {
          this.milestones = res.data;
        }
      },
      async updateModal() {
        this.resetModal();
        if (this.propertyId) {
          await this.getMilestones();
        }
      },

      resetModal() {
        this.milestones = [];
        this.deletedIds = [];
      },

      addMilestone() {
        this.milestones = [
          ...this.milestones,
          {
            is_hidden: false,
            is_done: false,
          },
        ];
      },

      removeMilestone(index) {
        const deletedItems = this.milestones.splice(index, 1);
        deletedItems.forEach((e) => {
          if (e.id) {
            this.deletedIds = [...this.deletedIds, e.id];
          }
        });
      },

      async handleSubmit() {
        const isFormValid = await this.$refs.milestoneForm.validate();
        if (!isFormValid.valid) {
          return;
        }
        const res = await propertiesService.updateMilesStones({
          property_id: this.propertyId,
          milestones: this.milestones,
          deleted_ids: this.deletedIds,
        });
        if (res) {
          this.$emit("on-close");
        }
      },
    },

    computed: {},
  };
  </script>

  <style scoped>
  :deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
  }

  #full-screen-modal :deep(.modal-dialog) {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
  }

  :deep() #full-screen-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
  }

  :deep() #full-screen-modal .modal-header {
    border-radius: 0;
  }

  :deep() .modal-header {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 100;
    width: 100%;
  }

  :deep() .modal-body {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: white;
  }

  :deep() .modal-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 100;
  }

  .milestone-item {
    width: 50%;
    min-height: 50px;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--primary-color);
    padding: 10px;
  }

  @media (max-width: 991px) {
    .milestone-item {
      width: 100%;
    }
  }

  .input-invalid {
    border: 1px solid #dc3545;
    border-radius: 5px;
  }

  .input-valid {
    border: 1px solid #28a745;
    border-radius: 5px;
  }

  .input-normal {
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  </style>
