import { useGtm } from '@gtm-support/vue-gtm'
import CryptoJS from 'crypto-js'

/**
 * gtmTrackEvent function
 * 
 * @param {*} options 
 */
function gtmTrackEvent(options, hash = true) {
    const gtm = useGtm();
    if (gtm && gtm.enabled()) {
        let dataOptions = options
        if (hash) {
            dataOptions = encryptDataBeforePush(dataOptions)
        }
        gtm.trackEvent(dataOptions)
    }
}

/**
 * encryptDataBeforePush function
 * 
 * @param {*} options 
 * @returns 
 */
function encryptDataBeforePush(options = {}) {
    let encryptedOptions = { ...options }
    const keysAllowedToEncrypt = [
        'email',
        'phone',
        'customerEmail',
        'customerPhoneNumber',
    ]

    // Encrypt specific keys
    for (const key in encryptedOptions) {
        if (keysAllowedToEncrypt.includes(key)) {
            encryptedOptions[key] = encryptData(encryptedOptions[key])
        }
    }
    return encryptedOptions
}

/**
 * encryptData function
 * 
 * @param {*} data 
 * @returns 
 */
function encryptData(data) {
    try {
        const encrypted = CryptoJS.SHA256(data)
        return encrypted.toString(CryptoJS.enc.Hex)
    } catch (e) {
        console.log('Error encrypt the data:', e)
    }
}

export { gtmTrackEvent, encryptData, encryptDataBeforePush }