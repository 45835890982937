<template>
  <b-modal v-model="show" size="lg" no-close-on-backdrop no-close-on-esc
           body-class="modal-body-full" hide-footer="true"
           :title="$t('CONTRACT.YOUR_CONTRACT_DOCUMENTS_OF_PROPERTY', { value: property ? property.name : ''})">

    <div>
      <div v-for="document in contractDocuments.data" class="d-flex flex-row align-items-center mt-3 mb-3 pl-3 pr-3">
        <button id="btn_openContractDocument" type="button" class="font-22 download-btn"
                @click="openContractDocument(document)">
          <img class="mb-1" height="26" src="../assets/img/ic_document.svg" alt=""/>
        </button>
        <p class="font-weight-bold font-20 ml-3"
           style="cursor: pointer; margin: auto 0;" @click="openContractDocument(document)">
          {{ getDocumentName(document) }}
        </p>
      </div>

      <b-pagination v-if="contractDocuments.total" align="right" v-model="contractDocuments.current_page"
                    :total-rows="contractDocuments.total" :per-page="contractDocuments.per_page"
                    @change="onChangePage" aria-controls="my-table"></b-pagination>

    </div>
  </b-modal>
</template>

<script>

import contractsService from "../services/contracts.service"

export default {
  components: {},
  data () {
    return {
      show: false,
      property: null,
      contractDocuments: {},
    }
  },

  methods: {
    async showModal (property) {
      this.show = true
      this.property = property
      await this.getContractDocuments(1)
    },

    async getContractDocuments (page) {
      const response = await contractsService.getContractDocuments({ property_uuid: this.property.uuid, page })
      if (response && response.data) {
        this.contractDocuments = response.data
      }
    },

    async onChangePage (page) {
      await this.getContractDocuments(page)
    },

    getDocumentName (documentPath) {
      try {
        if (documentPath) {
          let segments = documentPath.split("?")[0].split("/")
          const fileName = segments[segments.length - 1]
          return fileName.charAt(0).toUpperCase() + fileName.slice(1)
        }
      } catch (e) {
        console.log(e)
      }
      return this.$t("CONTRACT.DOCUMENT_IS_BEING_PROCESSED")
    },

    openContractDocument (documentUrl) {
      if (documentUrl) {
        window.open(documentUrl, "_blank")
      }
    },
  },
}
</script>
<!--suppress CssUnusedSymbol -->
<style scoped>

.modal-class {
  border-radius: 8px;
}

.responsive-iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.download-btn {
  background-color: rgba(170, 170, 170, 0.2);
  width: 70px;
  min-width: 70px;
  height: 40px;
  border: none;
  border-radius: 10px;
  transition: 0.3s;
}

.download-btn:hover {
  background-color: rgba(170, 170, 170, 0.4);
}

</style>
