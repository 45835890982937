<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-form-group label="Template Alias" label-for="key-input" :state="validateState('alias')">
                <b-form-input id="key-input" v-model="form.alias" :state="validateState('alias')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="Template Model Fields (Separate by comma)" label-for="value-input" :state="validateState('fields')">
                <b-form-textarea id="value-input" v-model="form.fields" rows="3" max-rows="6"
                    placeholder="e.g. name_field, action_link_field" :state="validateState('fields')">
                </b-form-textarea>
            </b-form-group>

        </form>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { notify } from "../../../helpers/common";
import emailTemplateService from "../../../services/admin/emailTemplate.service";

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                alias: {
                    required,
                },
                fields: {
                    required,
                },
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {
                alias: '',
                fields: '',
            },
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            if (this.template != null) {
                this.form = {
                    alias: this.template.alias,
                    fields: this.template.fields,
                    id: this.template.id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                alias: '',
                fields: '',
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            try {
                if (this.form.id) {
                    await emailTemplateService.update(this.form.id, {
                        alias: this.form.alias,
                        fields: this.form.fields,
                    });
                    this.$nextTick(() => {
                        this.resetModal();
                        this.$emit('on-close', true);
                    })
                } else {
                    await emailTemplateService.create(this.form);
                    this.$nextTick(() => {
                        this.resetModal();
                        this.$emit('on-close', true);
                    })
                }
            } catch (err) {
                notify({
                    text: err.data.fields && err.data.fields.join('|') || err.data,
                    type: 'error',
                });
            }
        }
    },

    computed: {
        title() {
            return this.template != null ? 'Update template' : 'Add new template';
        },
    },
}
</script>

<style lang="scss" scoped></style>