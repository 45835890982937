import serverApi from "../../utils/serverApi";

const bannerService = {
  getList: (data) => serverApi({ uri: "admin/banners", method: "GET", data }),

  createBanner: (data) =>
    serverApi({ uri: "admin/banners", method: "POST", data }),

  updateBanner: (id, data) =>
    serverApi({ uri: `admin/banners/${id}`, method: "PUT", data }),

  deleteBanner: (id) =>
    serverApi({ uri: `admin/banners/${id}`, method: "DELETE" }),
};

export default bannerService;
