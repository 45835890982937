<template>
  <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
           @hidden="$emit('on-close')" @ok="handleOk">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Slug" label-for="key-input" :state="validateState('slug')">
        <b-form-input id="key-input" v-model="form.slug" :state="validateState('slug')" required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Company Name" label-for="key-input" :state="validateState('company_name')">
        <b-form-input id="key-input" v-model="form.company_name" :state="validateState('company_name')" required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Director Name" label-for="key-input" :state="validateState('director_name')">
        <b-form-input id="key-input" v-model="form.director_name" :state="validateState('director_name')" required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Company Address" label-for="key-input" :state="validateState('company_address')">
        <b-form-input id="key-input" v-model="form.company_address" :state="validateState('company_address')" required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Company Tax No" label-for="key-input" :state="validateState('company_tax_no')">
        <b-form-input id="key-input" v-model="form.company_tax_no" :state="validateState('company_tax_no')" required>
        </b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import spcsService from "../../../services/spcs.service"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    spc: {
      type: Object,
      default: null,
    },
  },
  emits: ["on-close"],
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      form: {
        slug: { required },
        company_name: { required },
        director_name: { required },
        company_address: { required },
        company_tax_no: { required },
      },
    }
  },
  data () {
    return {
      form: {
        slug: "",
        company_name: "",
        director_name: "",
        company_address: "",
        company_tax_no: "",
      },
      showModal: false,
    }
  },

  watch: {
    show (value) {
      this.showModal = value
    }
  },

  methods: {
    validateState (name) {
      const { $dirty, $error } = this.v$.form[name]
      return $dirty ? !$error : null
    },
    updateModal () {
      if (this.spc != null) {
        this.form = {
          id: this.spc.id,
          uuid: this.spc.uuid,
          slug: this.spc.slug,
          company_name: this.spc.company_name,
          director_name: this.spc.director_name,
          company_address: this.spc.company_address,
          company_tax_no: this.spc.company_tax_no,
        }
      } else {
        this.resetModal()
      }
    },
    resetModal () {
      this.form = {
        id: "",
        uuid: "",
        slug: "",
        company_name: "",
        director_name: "",
        company_address: "",
        company_tax_no: "",
      }
    },
    handleOk (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit () {
      this.v$.form.$touch()
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }
      if (this.spc != null) {
        await spcsService.updateSpc(this.form)
      } else {
        await spcsService.createSpc(this.form)
      }
      this.$nextTick(() => {
        this.resetModal()
        this.$emit("on-close", true)
      })
    }
  },

  computed: {
    title () {
      return this.spc != null ? "Update Spc" : "Create Spc"
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
