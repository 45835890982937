import serverApi from "../utils/serverApi"

const paymentsService = {

  createPayment: (body) => serverApi({ uri: "payments/create-payment", method: "POST", data: body }),

  getPayments: data => serverApi({ uri: "payments", data, method: "GET" }),

  getPaymentDetail: (body) => serverApi({ uri: "payments/get-payment-detail", method: "POST", data: body }),

  buyTokensUsingVirtualBalance: (body) => serverApi({ uri: "payments/buy-tokens-virtual-balance", method: "POST", data: body }),

  checkKycToken: (body) => serverApi({
    uri: "payments/check-kyc-token",
    method: "POST",
    data: body,
    isManualErrorHandling: true
  }),
}

export default paymentsService
