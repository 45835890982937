import serverApi from "../../utils/serverApi";

const partnerService = {
  getPartnerRole: (data) => serverApi({ uri: "admin/roles/partner-roles", method: "GET", data }),

  getList: (data) => serverApi({ uri: "admin/partners", method: "GET", data }),

  create: (data) => serverApi({ uri: "admin/partners", method: "POST", data }),

  update: (id, data) => serverApi({ uri: `admin/partners/${id}`, method: "PUT", data }),

}

export default partnerService;
