<template>
  <div class="map-content">
    <div class="map-toolbar-c" v-if="searchForm || geo">
      <b-form @submit.prevent="search" v-if="searchForm">
        <b-input-group class="mb-2">
          <b-form-input placeholder="Search location" v-model="query" :disabled="disabled"></b-form-input>
          <b-input-group-append is-text>
            <b-icon class="search" icon="search" @click="search" />
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <b-button ib="btn_requestLocation" icon v-if="geo" @click="requestLocation">
        <b-icon icon="pin-map"/>
      </b-button>
    </div>
    <div :id="containerID" :style="containerCSS"></div>
  </div>
</template>

<script>
  const mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
  export default {
    model: {
      prop: "datetime",
      event: "input",
    },
    props: {
      value: {
        type: Object,
      },
      geo: {
        type: Boolean,
        default: true,
      },
      searchForm: {
        type: Boolean,
        default: true,
      },
      height: {
        type: String,
        default: "500px",
      },
      initialLocation: {
        type: Array,
        default: () => [-0.496934, 51.437032],
      },
      color: {
        type: String,
        default: "#66615b",
      },
      apiKey: {
        type: String,
        required: true,
      },
      mapStyle: {
        type: String,
        default: "mapbox://styles/mapbox/outdoors-v11",
      },
      containerID: {
        type: String,
        default: "map",
      },
      initDelay: {
        type: Number,
        default: 0
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        map: null,
        query: "",
        location: [],
      };
    },
    computed: {
      containerCSS() {
        return {
          width: "100%",
          height: this.height,
          overflow: "hidden",
        };
      },
    },
    methods: {
      initMap() {
        mapboxgl.accessToken = this.apiKey;
        // Create map object
        this.map = new mapboxgl.Map({
          container: this.containerID,
          style: this.mapStyle,
          center: this.initialLocation,
          zoom: 5,
        });
        // Adds basic zoom and rotate control
        this.map.addControl(new mapboxgl.NavigationControl());
        // Add Click Listener
        this.map.on("click", (e) => {
          this.setLocation(e.lngLat);
        });
      },
      removeMapMarkers() {
        const oldMarker = document.querySelector(".mapboxgl-marker");
        if (oldMarker) {
          oldMarker.parentElement.removeChild(oldMarker);
        }
      },
      addMapMarker(lngLat) {
        new mapboxgl.Marker({ color: this.color })
          .setLngLat(lngLat)
          .addTo(this.map);
      },
      setLocationCoordinates(lngLat) {
        this.location = [lngLat.lng, lngLat.lat];
      },
      requestLocation() {
        // Request to get the user's current location
        window.navigator.geolocation.getCurrentPosition((position) => {
          // get the latitude and longitude returned
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          // set location data
          this.setLocation({ lng, lat });
          this.map.flyTo({ center: [lng, lat], zoom: 15 });
        });
      },
      setLocation(lngLat) {
        this.removeMapMarkers();
        this.addMapMarker(lngLat);
        this.setLocationCoordinates(lngLat);
        this.$emit("input", this.location);
      },
      async search() {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.query}.json?access_token=${process.env.VUE_APP_MAP_KEY}`
        );
        // this.query = "";
        const responseBody = await response.json();
        // Check we have at least 1 result
        if (responseBody.features.length === 0) {
          alert("no results found");
          return null;
        }
        const [lng, lat] = responseBody.features[0].center;
        this.setLocation({ lng, lat });
        this.map.flyTo({ center: [lng, lat], zoom: 10 });
      },
    },
    mounted() {
      if (this.initDelay !== 0) {
        setTimeout(() => {
          this.initMap();
        }, this.initDelay)
      } else {
        this.initMap();
      }

    },
  };
</script>

<style>
  .mapboxgl-marker {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
  }
  .map-content {
    position: relative;
    overflow: hidden;
  }
  .map-toolbar-c {
    width: 360px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100000000;
  }

  .search {
    cursor: pointer;
  }
</style>
