<!--suppress HtmlUnknownTag -->
<template>
  <b-modal v-model="showModal" id="modal-prevent-closing" :no-close-on-backdrop="true" header-bg-variant="dark"
           header-text-variant="light" @hidden="onClose(false)">
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose(false)">×
      </button>
    </template>
    <div class="container">
      <b-form class="form-content">
        <b-row class="mb-4">
          <label>Property Id:</label>
          <b-form-input v-model.trim="form.property_id" type="text" placeholder="Property Id" :disabled="true">
          </b-form-input>
        </b-row>
        <b-row class="mb-4">
          <label>Property Name:</label>
          <b-form-input v-model.trim="form.property_name" type="text" placeholder="Property Name" :disabled="true">
          </b-form-input>
        </b-row>
        <b-row class="mb-4">
          <b-col class="pl-0 pr-0">
            <label>Date:</label>
            <b-form-input v-model.trim="form.date" type="text" :disabled="true"/>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <label>Rental Income (IDR):</label>
          <b-form-input v-model.trim="form.rental_income" type="text" placeholder="Rental Income" :disabled="true">
          </b-form-input>
        </b-row>
        <b-row class="mb-4">
          <label>Occupancy Rate (%):</label>
          <b-form-input v-model.trim="form.occupancy_rate" type="text" placeholder="Occupancy Rate"
                        :disabled="true">
          </b-form-input>
        </b-row>
        <b-row class="mb-4">
          <label>Description (optional):</label>
          <b-form-input v-model.trim="form.description" type="text" placeholder="Description" :disabled="true">
          </b-form-input>
        </b-row>
        <b-row class="mb-4">
          <label>Status:</label>
          <b-form-select v-model.trim="form.status" :options="statuses" :disabled="true">
          </b-form-select>
        </b-row>
      </b-form>
    </div>
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button size="sm" class="btn-outline-main" variant="none" @click="onClose(false)">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { STATUS } from "@/constants/constants"

export default {
  components: {
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rentalDistributionDetail: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      showModal: false,
      form: this.rentalDistributionDetail != null ? { ...this.rentalDistributionDetail } : { status: STATUS.PENDING },
      statuses: [STATUS.WAITING, STATUS.PENDING, STATUS.APPROVED],
    }
  },

  methods: {
    onClose: function (hasChanged) {
      this.form = { status: STATUS.PENDING }
      this.$emit("on-close", hasChanged)
    },
  },

  computed: {
    title () {
      return "Rental Distribution Detail"
    },
  },

  watch: {
    async rentalDistributionDetail (value) {
      if (value) {
        this.form = { ...value }
      }
    },
    show (value) {
      this.showModal = value
    },
  }
}
</script>

<style scoped>

.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: .8;
  padding-bottom: 20px !important;
}

.form-content label {
  font-weight: bold;
  font-size: 16px;
}
</style>
