<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="emails.data" :columns="tableColumns" :showEditAction="true"
                        @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="3%"></th>
                                <th width="30%">
                                    <b-input v-model="filters.title"></b-input>
                                </th>
                                <th width="15%">
                                    <b-input v-model="filters.alias"></b-input>
                                </th>
                                <th width="20%">
                                    <b-input v-model="filters.to"></b-input>
                                </th>
                                <th width="10%">
                                    <b-input v-model="filters.tag"></b-input>
                                </th>
                                <th width="15%">
                                    <b-form-select v-model="filters.status" text-field="name" value-field="name">
                                        <template #first>
                                            <b-form-select-option :value="null">Select status</b-form-select-option>
                                        </template>
                                        <template v-for="option in options">
                                            <b-form-select-option :value="option.name">
                                                {{ option.name }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th width="7%"></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="emails.total" align="right" v-model="emails.current_page"
                        :total-rows="emails.total" :per-page="emails.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-broadcast-detail title="Broadcast Detail" :show="showModalDetail" :email="email"
            @onClose="hideModalDetail"></modal-broadcast-detail>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import emailBroadcastService from "../../services/admin/emailBroadcast.service";
import ModalBroadcastDetail from "./modals/ModalBroadcastDetail.vue"

const tableColumns = ["Id", "Title", "Alias", "To", "Tag", "Status"];

export default {
    components: {
        PaperTable,
        ModalBroadcastDetail,
    },
    data() {
        return {
            emails: {},
            tableColumns: [...tableColumns],
            filters: {
                title: null,
                alias: null,
                to: null,
                tag: null,
                status: null,
            },
            email: {},
            showModalDetail: false,
            options: [
                {
                    name: 'PENDING',
                },
                {
                    name: 'COMPLETED',
                },
                {
                    name: 'FAILED',
                },
            ],
        };
    },
    async mounted() {
        await this.getEmails(1);
    },
    watch: {
        'filters.title'() {
            this.searchTimeOut(1);
        },
        'filters.alias'() {
            this.searchTimeOut(1);
        },
        'filters.to'() {
            this.searchTimeOut(1);
        },
        'filters.tag'() {
            this.searchTimeOut(1);
        },
        'filters.status'() {
            this.searchTimeOut(1);
        }
    },
    methods: {

        async getEmails(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await emailBroadcastService.getList(filters);
            if (result && result.data) {
                this.emails = result;
            }
        },

        async onChangePage(page) {
            await this.getEmails(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getEmails(page);
            }, 400);
        },

        onModalEdit(data) {
            if (data) {
                this.email = this.emails.data.find((e) => e.id === data);
                this.showModalDetail = true;
            }
        },

        async hideModalDetail(data) {
            this.showModalDetail = data.show
            if (data.show === false) {
                this.email = {}
            }
        },
    }
};
</script>
<style scoped>

</style>
  