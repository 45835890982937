<template>
    <div class="popup" v-if="isOpen" style="z-index: 10001;">
        <div class="popup-content d-flex flex-column">
            <div class="d-flex justify-content-end">
                <div class="btn-close" @click="close()">
                    <b-icon icon="x" style="color: gray;" scale="1.6"></b-icon>
                </div>
            </div>
            <div v-if="!successEnabled" class="p-3 text-left flex-grow-1 d-flex flex-column">
                <p class="font-weight-bold font-18">{{ $t('TWO_FA.GET_YOUR_CODE') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.ENTER_THE_6_DIGITS') }}</p>
                <input class="otp-input mt-5" v-model="otp" :placeholder="$t('TWO_FA.ENTER_CODE')" />
                <div class="mt-5"></div>
                <b-button id="btn_enable2FAOTP_Next" class="btn-main mt-5 col-12 btn-submit" :disabled="!enabledSubmit" variant="none"
                    @click="submit()">
                    {{ $t("common.NEXT") }}
                </b-button>
            </div>
            <div v-if="successEnabled" class="p-3 text-left flex-grow-1 d-flex flex-column">
                <p class="font-weight-bold font-18">{{ $t('TWO_FA.TWO_FA_IS_ON') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.TWO_FA_IS_ON_DESC') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.RECOVERY_CODES_DESC') }}</p>
                <p class="text-copy mt-4 p-0"><span class="text-btn" @click="downloadtxt">{{
        $t('TWO_FA.DOWNLOAD_AS_TXT') }}</span> - <span class="text-btn" @click="copyCodes">{{
        $t('TWO_FA.COPY_CODES') }}</span></p>
                <div class="d-flex mt-3">
                    <div class="d-flex flex-row flex-wrap col-12 p-0">
                        <p v-for="(code, index) in recoveryCodes" class="col-6 font-weight-bold font-22">{{ getNumber(index) }}. {{ code }}</p>
                    </div>
                </div>
                <p class="text-copy text-btn mt-4" @click="generateNewCodes">{{ $t('TWO_FA.GET_NEW_CODES') }}</p>
                <div class="flex-grow-1">
                </div>
                <b-button id="btn_enable2FAOTP_Done" class="btn-main mt-5 col-12 btn-submit" variant="none" @click="close()">
                    {{ $t("common.DONE") }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>

import twofaService from '../services/twofa.service'
import { notify } from "@/helpers/common"
import fileSaver from 'file-saver'

export default {
    data() {
        return {
            isOpen: false,
            otp: '',
            enabled: false,
            successEnabled: false,
            recoveryCodes: [],
            operatingSystem: "",
            browserName: "",
        }
    },

    emits: ["on-success"],

    mounted() {
        this.browserName = this.$browserDetect.meta.name
        let userAgent = this.$browserDetect.meta.ua.toLocaleLowerCase()
        if (userAgent.includes("win")) this.operatingSystem = "Windows"
        else if (userAgent.includes("mac") && !this.$browserDetect.isIOS) this.operatingSystem = "MacOS"
        else if (userAgent.includes("ios") || userAgent.includes("iphone") || this.$browserDetect.isIOS) this.operatingSystem = "iOS"
        else if (userAgent.includes("android")) this.operatingSystem = "Android"
        else if (userAgent.includes("linux")) this.operatingSystem = "Linux"
        else this.operatingSystem = "Unknown os"

    },

    watch: {

    },

    methods: {
        openPopup() {
            this.isOpen = true
        },

        close() {
            this.isOpen = false
            this.$emit("on-success")
        },

        async submit() {
            await this.$recaptchaLoaded()
            const recaptchaToken = await this.$recaptcha("enable2FA")
            const res = await twofaService.enabled2FA({
                otp: this.otp,
                browser_name: this.browserName,
                operating_system: this.operatingSystem,
                recaptcha_token: recaptchaToken,
            })
            if (res && res.data) {
                this.successEnabled = true
                this.recoveryCodes = res.data
            }
        },

        async generateNewCodes() {
            const res = await twofaService.generateRecoveryCodes()
            if (res && res.data) {
                this.recoveryCodes = res.data
            }
        },

        copyCodes() {
            if (this.recoveryCodes.length) {
                navigator.clipboard.writeText(this.recoveryCodes.join(' '));
                notify({ text: this.$t('common.COPIED') });
            }
        },

        downloadtxt() {
            if (this.recoveryCodes.length) {
                const content = this.recoveryCodes.map((e, i) => `${this.getNumber(i)}. ${e}`).reduce(function (result, value, index, array) {
                    if (index % 2 === 0) {
                        result.push(array.slice(index, index + 2).join('\t'));
                    }
                    return result;
                }, []).join('\n')
                const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
                fileSaver.saveAs(blob, "GORO Two-Factor Authentication Recovery Codes.txt");
            }
        },

        getNumber(index) {
            const numbers = [1, 6, 2, 7, 3, 8, 4, 9, 5, 10]
            return numbers[index]
        },
    },

    computed: {
        enabledSubmit() {
            return this.otp && this.otp.length >= 6
        }
    },

}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    overflow: auto;

    .popup-content {
        position: relative;
        background-color: #fff;
        padding: 30px;
        border-radius: 20px;
        text-align: center;
        min-width: 30%;
        max-width: 95%;
        height: fit-content;
        margin: auto;

        @media screen and (min-width: 600px) {
            max-width: 70%;
        }

        @media screen and (min-width: 1100px) {
            max-width: 50%;
        }

        .btn-close {
            background-color: rgb(221, 221, 221);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .otp-input {
            width: 100%;
            background-color: #ffffff;
            border: solid 2px var(--primary-color);
            border-radius: 4px;
            padding-left: 1rem;
            padding-right: 1rem;
            color: black;
            min-height: 60px;
            font-size: 28px;
            font-weight: 700;
            text-align: start;

            &::placeholder {
                color: rgb(202, 202, 202);
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                color: rgb(202, 202, 202);
            }

            &::-ms-input-placeholder {
                color: rgb(202, 202, 202);
            }

            &.confirming {
                background-color: #f7f7f8b6;
            }
        }

        .btn-submit {
            max-height: 40px;
        }

        .text-copy {
            color: blue;
        }

        .text-btn {
            cursor: pointer;
        }
    }
}
</style>