import serverApi from "../utils/serverApi"

const authService = {
  requestOtp: (body, isManualErrorHandling = false) => serverApi({
    uri: "auth/request-otp",
    method: "POST",
    data: body,
    isManualErrorHandling: isManualErrorHandling
  }),

  getOtpStatus: (body) => serverApi({ uri: "auth/get-otp-status", method: "POST", data: body, isLoading: false }),

  register: (body) => serverApi({ uri: "auth/register", method: "POST", data: body }),

  registerWithGoogle: (body) => serverApi({
    uri: "auth/register-with-google",
    method: "POST",
    data: body,
  }),

  registerWithFacebook: (body) => serverApi({
    uri: "auth/register-with-facebook",
    method: "POST",
    data: body
  }),

  verifyEmail: (params) => serverApi({ uri: "auth/verify-email", method: "GET", data: params }),

  login: (body) => serverApi({ uri: "auth/login", method: "POST", data: body }),

  loginWithGoogle: (body, isNotify = true, isManualErrorHandling = false) => serverApi({
    uri: "auth/login-with-google",
    method: "POST",
    data: body,
    isNotify: isNotify,
    isManualErrorHandling: isManualErrorHandling,
  }),

  loginWithFacebook: (body, isNotify = true, isManualErrorHandling = false) => serverApi({
    uri: "auth/login-with-facebook",
    method: "POST",
    data: body,
    isNotify: isNotify,
    isManualErrorHandling: isManualErrorHandling,
  }),

  logout: () => serverApi({ uri: "auth/logout", method: "POST" }),

  forgotPassword: (body) => serverApi({ uri: "auth/forgot-password", method: "POST", data: body }),

  resetPassword: (body) => serverApi({ uri: "auth/reset-password", method: "PUT", data: body }),

  getUserProfile: (isLoading = true) => serverApi({ uri: "auth/user-profile", method: "GET", isLoading }),

  resendVerifyEmail: () => serverApi({ uri: "auth/resend-verify-email", method: "PUT" }),

  updateLocale: (body) => serverApi({
    uri: "auth/update-locale",
    method: "PUT",
    data: body,
    isLoading: false,
  }),

  requestVerificationCode: (body) => serverApi({
    uri: "auth/verification-code/request",
    method: "POST",
    data: body,
    isLoading: true,
    isNotify: true,
    isManualErrorHandling: true
   }),

  validVerificationCode: (body) => serverApi({
    uri: "auth/verification-code/valid",
    method: "POST",
    data: body,
    isLoading: true,
    isNotify: true,
    isManualErrorHandling: true
  }),
}

export default authService
