<template>
  <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light" @hidden="onHidden()">
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose({show: false})">×</button>
    </template>
    <div class="container">
      <b-form class="form-content">
        <b-row class="mb-4">
          <b-col cols="6">
            <label>User Id:</label>
            <b-form-input v-model.trim="paymentData.user_id" type="text" placeholder="User Id" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>User Email:</label>
            <b-form-input v-model.trim="paymentData.user_email" type="text" placeholder="User Email" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Property Id:</label>
            <b-form-input v-model.trim="paymentData.property_id" type="text" placeholder="Property Id" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Property Name:</label>
            <b-form-input v-model.trim="paymentData.property_name" type="text" placeholder="Property Name" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>External Id:</label>
            <b-form-input v-model.trim="paymentData.external_id" type="text" placeholder="External Id" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Partner Id:</label>
            <b-form-input v-model.trim="paymentData.partner_id" type="text" placeholder="Partner Id" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Partner User Id:</label>
            <b-form-input v-model.trim="paymentData.partner_user_id" type="text" placeholder="Partner User Id" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Partner Invoice Url:</label>
            <b-form-input v-model.trim="paymentData.partner_invoice_url" type="text" placeholder="Partner Invoice Url" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="3">
            <label>Number Of Tokens:</label>
            <b-form-input v-model.trim="paymentData.num_of_tokens" type="text" placeholder="Number Of Tokens" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="3">
            <label>Amount:</label>
            <b-form-input v-model.trim="paymentData.amount" type="text" placeholder="Amount" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="3">
            <label>Currency:</label>
            <b-form-input v-model.trim="paymentData.currency" type="text" placeholder="Currency" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="3">
            <label>Status:</label>
            <b-form-input v-model.trim="paymentData.status" type="text" placeholder="Status" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Description:</label>
            <b-form-input v-model.trim="paymentData.description" type="text" placeholder="Description" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Payment Method:</label>
            <b-form-input v-model.trim="paymentData.payment_method" type="text" placeholder="Payment Method" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Payment Channel:</label>
            <b-form-input v-model.trim="paymentData.payment_channel" type="text" placeholder="Payment Channel" disabled="true">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Paid At:</label>
            <b-form-input v-model.trim="paymentData.paid_at" type="text" placeholder="Paid At" disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="primary" @click="onClose({show: false})">
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Object,
      default: {},
    },
  },

  data () {
    return {
      showModal: false,
      paymentData: {},
    }
  },

  methods: {
    onReset () {
      this.paymentData = {}
    },

    onHidden: function (v) {
      this.onReset()
      this.$emit("onClose", {show: false})
    },

    onClose: function (v) {
      this.showModal = false
    },
  },

  computed: {
    title () {
      return "Payment Detail"
    },
  },

  watch: {
    show (v) {
      this.showModal = v
    },
    payment (v) {
      this.paymentData = v
    },
  },
}
</script>

<style scoped>
:deep() #full-screen-modal {
  padding: 0 !important;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  margin: 0;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
  border-radius: 0;
}

.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: .8;
  padding-bottom: 20px !important;
  /*!* opacity: .5; */
}

.images-picker {
  width: 60%;
}

.document-content {
  margin: 20px 0;
  padding-bottom: 20px;
}

.document-content li {
  list-style: none;
  color: #3a283d;
  margin: 3px 0;
  font-size: 16px;
}

.form-content label {
  font-weight: bold;
  font-size: 16px;
}

.capitalize:first-letter {
  text-transform: uppercase;
}

/*:deep() .mapboxgl-canvas {*/
/*  width: 100% !important;*/
/*  height: 100% !important;*/
/*}*/
</style>
