
import serverApi from '../../utils/serverApi';

const locationsService = {
    getLocations: (data) => serverApi({ uri: 'admin/locations', method: 'GET', data }),

    createOrUpdate: (data) => serverApi({ uri: 'admin/locations', method: 'POST', data }),

    delete: (id) => serverApi({ uri: `admin/locations/${id}`, method: 'DELETE' }),
};

export default locationsService;
