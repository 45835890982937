<template>
    <b-modal v-model="showModal" id="cls-modal-show-admin-logs-changes" ref="modal" title="Show Changes"
        @hidden="$emit('on-close')">
        <b-container class="p-0">
            <b-row class="m-0">
                <b-col cols="12" sm="6" class="p-0 p-sm-2">
                    <h2 class="font-20 font-weight-bold mt-3 mb-2">Value Original</h2>
                    <div class="cls-form-block">
                        <vue-json-pretty v-if="valueOriginal" :data="valueOriginal" :showLineNumber="true" :showIcon="true" :showDoubleQuotes="false" :virtual="true"></vue-json-pretty>
                        <p class="font-18 mt-4 mb-4 text-center" v-if="!valueOriginal">Empty value original!</p>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" class="p-0 p-sm-2">
                    <h2 class="font-20 font-weight-bold mt-3 mb-2">Value Changes</h2>
                    <div class="cls-form-block">
                        <vue-json-pretty v-if="valueChanges" :data="valueChanges" :showLineNumber="true" :showIcon="true" :showDoubleQuotes="false" :virtual="true"></vue-json-pretty>
                        <p class="font-18 mt-4 mb-4 text-center" v-if="!valueChanges">Empty value changes!</p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <template #modal-footer>
            <b-button size="sm" variant="primary" @click="onClose({ show: false })">
                Close
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
    components: {
        VueJsonPretty
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        showChangesData: {
            type: Object,
            default: null,
        },
    },
    
    emits: ['on-close'],
    data() {
        return {
            showModal: false,
        }
    },
    watch: {
        show(value) {
            this.showModal = value
        }
    },
    methods: {
        onClose: function (v) {
            this.$emit("onClose", v)
        },
    },

    computed: {
        valueOriginal() {
            if (this.showChangesData && this.showChangesData.value_original) {
                return this.showChangesData.value_original
            }
            return null
        },
        valueChanges() {
            if (this.showChangesData && this.showChangesData.value_changes) {
                return this.showChangesData.value_changes
            }
            return null
        }
    },
}
</script>

<style lang="scss">
#cls-modal-show-admin-logs-changes___BV_modal_outer_{
    .modal-dialog{
        width: 100% !important;
        max-width: 1200px !important;
        margin: 0.5rem auto !important;
        @media(max-width: 992px) {
            margin: 0 auto !important;
        }
        .cls-form-block{
            box-shadow: 0 8px 35px rgba(7, 55, 99, 0.16);
            // padding: 10px;
            background-color: white;
            border-radius: 16px;
            overflow: hidden;
            margin-top: 15px;
            border: 1px solid #E9E9E9;
            .vjs-tree{
                .vjs-tree-list{
                    margin: 15px !important;
                }
            }
        }
    }
}
</style>
