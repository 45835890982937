<template>
  <div class="row">
    <div class="col-12">
      <div class="pb-2">
        <b-button size="sm" id="show-btn" @click="createContractTemplate">
          <b-icon icon="plus-lg"></b-icon>
          Add new
        </b-button>
      </div>
      <div slot="raw-content" class="table-responsive">
        <paper-table
          :data="contractTemplates.data" :columns="tableColumns" :show-action="true" :show-edit-action="true" :show-delete-action="true"
          @onModalEdit="editContractTemplate" @onModalDelete="onModalDeleteContractTemplate"
        >
          <template #theadSearch>
            <thead class="search">
            <th></th>
            <th>
              <b-input v-model="filters.uuid"></b-input>
            </th>
            <th>
              <b-input v-model="filters.version"></b-input>
            </th>
            <th>
              <b-input v-model="filters.contract_preview_path"></b-input>
            </th>
            <th>
              <b-input v-model="filters.contract_path"></b-input>
            </th>
            <th>
              <b-input v-model="filters.annex_a_path"></b-input>
            </th>
            <th>
              <b-input v-model="filters.annex_b_path"></b-input>
            </th>
            <th></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination
          v-if="contractTemplates.total"
          align="right"
          v-model="contractTemplates.current_page"
          :total-rows="contractTemplates.total"
          :per-page="contractTemplates.per_page"
          @change="onChangePage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
      <modal-contract-template :show="showModalContractTemplate"
                               :contract-template="contractTemplate" @on-close="onModalContractTemplateClose"/>

      <b-modal v-model="modalDeleteContractTemplate.show" header-bg-variant="dark" header-text-variant="light">
        <template #modal-header>
          <h5 class="modal-header-title mb-0">Delete confirm</h5>
          <button id="btn_closeModalDeleteContractTemplate" type="button" class="close font-weight-bold" aria-label="Close"
                  @click="modalDeleteContractTemplate.show = false">×
          </button>
        </template>
        <p>Are you sure you wanna delete this <strong style="color: red">contract template</strong>?</p>
        <template #modal-footer>
          <b-button variant="danger" size="sm" @click="deleteContractTemplate">
            Delete
          </b-button>
          <b-button variant="primary" size="sm" @click="modalDeleteContractTemplate.show = false">
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import ModalContractTemplate from "./modals/ModalContractTemplate"
import contractsService from "../../services/contracts.service"

const tableColumns = ["Id", "Uuid", "Version", "Contract_Preview_Path", "Contract_Path", "Annex_A_Path", "Annex_B_Path"]

export default {
  components: {
    PaperTable,
    ModalContractTemplate,
  },
  data () {
    return {
      contractTemplates: {},
      tableColumns: [...tableColumns],
      filters: {
        uuid: "",
        version: "",
        contract_preview_path: "",
        contract_path: "",
        annex_a_path: "",
        annex_b_path: "",
      },
      contractTemplate: null,
      showModalContractTemplate: false,
      modalDeleteContractTemplate: {
        show: false,
        content: "",
        id: null,
      },
    }
  },
  async mounted () {
    await Promise.all([
      this.getContractTemplates(1),
    ])
  },
  watch: {
    async "filters.uuid" () {
      this.searchTimeOut(1)
    },
    async "filters.version" () {
      this.searchTimeOut(1)
    },
    async "filters.contract_preview_path" () {
      this.searchTimeOut(1)
    },
    async "filters.contract_path" () {
      this.searchTimeOut(1)
    },
    async "filters.annex_a_path" () {
      this.searchTimeOut(1)
    },
    async "filters.annex_b_path" () {
      this.searchTimeOut(1)
    },
  },
  methods: {
    async getContractTemplates (page) {
      const filters = { ...this.filters, page }
      const result = await contractsService.getContractTemplates(filters)
      if (result && result.data) {
        this.contractTemplates = result
        //console.log(this.contractTemplates)
      }
    },
    async onChangePage (page) {
      await this.getContractTemplates(page)
    },
    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getContractTemplates(page)
      }, 800)
    },
    createContractTemplate () {
      this.contractTemplate = null
      this.showModalContractTemplate = true
    },
    editContractTemplate (data) {
      if (data) {
        this.contractTemplate = this.contractTemplates.data.find((e) => e.id === data)
        this.showModalContractTemplate = true
      }
    },
    onModalContractTemplateClose (success) {
      this.showModalContractTemplate = false
      if (success) {
        this.getContractTemplates(this.contractTemplates.current_page)
      }
    },
    onModalDeleteContractTemplate (data) {
      this.modalDeleteContractTemplate = data
    },
    async deleteContractTemplate () {
      if (this.modalDeleteContractTemplate.id) {
        await contractsService.deleteContractTemplate(this.modalDeleteContractTemplate.id)
        this.modalDeleteContractTemplate = {
          show: false,
          content: "",
          id: null,
        }
        await this.getContractTemplates(1)
      }
    },
  }
}
</script>
<style scoped>
</style>
