<template>
  <div class="content">
    <div class="container-fluid">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" class="view" />
        </transition>
      </router-view>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter-from,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

  {
  opacity: 0;
}
</style>
