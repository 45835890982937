<template>
  <div class="container">
    <p class="font-28 font-weight-bold">{{ $t("account.ASSETS_OVERVIEW") }}</p>
    <b-row align-h="between">
      <b-col class="mt-3" cols="12" lg="12" xl="5">
        <b-row class="rent-balance" align-h="between">
          <b-col cols="12" lg="7" xl="7" align-self="center">
            <b-row>
              <b-col cols="12" xl="12" lg="6" align-self="center">
                <router-link :to="{ name: 'balanceHistory' }">
                  <div class="d-flex">
                    <div class="main-color text-clickable balance-bg d-flex align-items-center">
                      <div class="flex-grow-0">
                        <p class="font-14">{{ $t("ASSETS.MY_BALANCE") }}</p>
                        <div class="d-flex flex-row align-items-center mt-1">
                          <p class="font-24 font-weight-bold fit-content" id="my-balance">{{ myBalance }}</p>
                          <b-icon class="ml-3 mr-2" icon="chevron-down" style="color: var(--primary-color);"></b-icon>
                        </div>
                        <CurrencyTooltip v-if="data" tooltipId="my-balance" :value="data.user_balance || 0"></CurrencyTooltip>
                      </div>
                    </div>
                 </div>
                </router-link>
              </b-col>
              <b-col v-if="isEnableVirtualBalance" cols="12" xl="12" lg="6" align-self="center"
                class="mt-2 mt-lg-0 mt-xl-2">
                <p class="font-14">{{ $t("account.VIRTUAL_BALANCE") }}<span style="padding-left:5px"><img width="20"
                      height="20" id="tooltip-virtual-balance" src="../../assets/img/info-circle-fill.png"
                      alt=""></span>
                </p>
                <b-tooltip variant="secondary" target="tooltip-virtual-balance" triggers="hover" placement="top">
                  {{ $t("VIRTUAL_BALANCE.TOOLTIP_MESSAGE") }}
                </b-tooltip>
                <p class="font-24 font-weight-bold mt-1 fit-content" id="virtual-balance">{{ virtualBalance }}</p>
                <CurrencyTooltip v-if="data" tooltipId="virtual-balance" :value="data.user_virtual_balance || 0">
                </CurrencyTooltip>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="12" xl="4" align-self="center" class="mr-xl-0 mr-3 ml-xl-3 ml-0">
            <span class="d-inline-block mt-2 mb-1" tabindex="0">
              <b-button id="btn_assetsOverview_createWithdrawal" @click="createWithdrawal" class="bg-main-color color-white mr-1" type="submit"
                style="padding: 5px 10px;">
                {{ $t("ASSETS.WITHDRAW") }}
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mt-3" cols="12" lg="12" xl="7">
        <b-row class="assets-info" align-h="around">
          <b-col cols="6" lg="4" xl="4">
            <p class="font-14">{{ $t("ASSETS.CURRENT_ACCOUNT_VALUE") }}</p>
            <p class="font-20 font-weight-bold fit-content" id="current-account-value">{{ currentAccountValue }}</p>
            <CurrencyTooltip v-if="data" tooltipId="current-account-value"
              :value="data.user_balance + data.total_property_value"></CurrencyTooltip>

            <p class="font-14 mt-3">{{ $t("ASSETS.TOTAL_PROPERTY_VALUE") }}</p>
            <p class="font-20 font-weight-bold fit-content" id="total-property-value">{{ totalPropertyValue }}</p>
            <CurrencyTooltip v-if="data" tooltipId="total-property-value" :value="data.total_property_value">
            </CurrencyTooltip>
          </b-col>
          <b-col cols="6" lg="4" xl="4">
            <p class="font-14">{{ $t("ASSETS.TOTAL_RENT_EARNED") }}</p>
            <p class="font-20 font-weight-bold fit-content" id="total-rent-earn">{{ totalRentEarned }}</p>
            <p class="font-14 mt-3">{{ $t("ASSETS.PROPERTIES_OWNED") }}</p>
            <p class="font-20 font-weight-bold">{{ filteredPropertiesCount }}</p>
            <CurrencyTooltip v-if="data" tooltipId="total-rent-earn" :value="data.total_rent_earned">
            </CurrencyTooltip>
          </b-col>
          <b-col cols="12" lg="4" xl="4" class="d-flex align-items-center justify-content-center">
            <Popper v-if="rows > 0" class="light" hover placement="bottom" arrow>
              <b-button id="btn_assetsOverview_share" class="btn-outline-white pt-1 pb-1 pl-3 pr-4 mt-3 mt-lg-0" type="submit" variant="none">
                <b-icon icon="share" scale="0.7"></b-icon>
                {{ $t("account.share") }}
              </b-button>
              <template #content>
                <div class="popper-content">
                  <b-img class="click-able" :src="require('@/assets/img/socials/facebook_sharing.png')" width="45"
                    height="45" @click="sharePortfolioOverview('facebook')" />
                  <b-img class="click-able" :src="require('@/assets/img/socials/wa_sharing.png')" width="45" height="45"
                    @click="sharePortfolioOverview('whatsapp')" />
                  <b-img class="click-able" :src="require('@/assets/img/socials/x_sharing.png')" width="45" height="45"
                    @click="sharePortfolioOverview('twitter')" />
                  <b-img v-if="canCopyToClipboard" class="click-able" width="45" height="45"
                    :src="require('@/assets/img/socials/manual_sharing.png')"
                    @click="sharePortfolioOverview('manual')" />
                </div>
              </template>
            </Popper>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div id="portfolio-overview" class="portfolio-overview off-screen">
      <b-row align-v="center">
        <b-col cols="6" class="text-left px-0">
          <img class="logo-img" style="width: 155px" src="../../assets/img/logo_with_text.png" alt="">
        </b-col>
        <b-col cols="6" class="text-right px-0">
          <p class="portfolio-overview-id">{{ $t("SHARING.GORO_ID", { value: userUuid }) }}</p>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mt-4">
        <b-col cols="6" align="center" class="pl-0" style="padding-right: 6px">
          <div class="portfolio-overview-info">
            <p class="portfolio-overview-title">{{ $t("SHARING.MEMBER_SINCE") }}</p>
            <p class="portfolio-overview-value">{{ userCreatedAt }}</p>
          </div>
        </b-col>
        <b-col cols="6" align="center" class="pr-0" style="padding-left: 6px">
          <div class="portfolio-overview-info">
            <p class="portfolio-overview-title">{{ $t("SHARING.TOTAL_PROPERTIES") }}</p>
            <p class="portfolio-overview-value">{{ rows }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" class="portfolio-overview-info" style="margin-top: 12px">
        <b-col cols="6" align="left">
          <p class="portfolio-overview-title">{{ $t("SHARING.TOTAL_EARNINGS") }}</p>
        </b-col>
        <b-col cols="6" align="right">
          <p class="portfolio-overview-value">{{ totalRentEarned }} ({{ avgEarningAnnualizedPercent }}%)</p>
        </b-col>
      </b-row>
      <p class="portfolio-overview-title text-center mt-4 mb-3">{{ $t("SHARING.FAVORITE_PROPERTIES") }}</p>
      <b-row class="justify-content-center">
        <b-col cols="4" class="pl-0 pr-0" v-for="(ownedProperty, index) in sharingOwnedProperties"
          :key="ownedProperty.id" :class="{ 'pr-2': index = 0, 'pl-1 pr-1': index = 1, 'pl-2': index = 2 }">
          <div style="position: relative">
            <b-img class="portfolio-overview-image" crossorigin="anonymous" :src="getAvatar(ownedProperty.images)"
              blank-src="property image" />
            <p class="portfolio-overview-name single-line">{{ ownedProperty.name }}</p>
          </div>
        </b-col>
      </b-row>
      <p class="portfolio-overview-title mt-5 mb-3">{{ $t("SHARING.JOIN_WITH_REFERRAL_CODE") }}</p>
      <p class="portfolio-overview-referral-code mt-3 mb-3">{{ userReferralCode }}</p>
    </div>

    <div class="mt-4 d-flex flex-row justify-content-between">
      <p class="font-weight-bold font-20">{{ $t('account.LIST_PROPERTIES') }}</p>
      <div class="d-flex flex-row align-items-center click-able" @click="onChangeHideZeroAssets">
        <b-icon v-if="!hideZeroAssets" icon="eye" style="color: var(--primary-color);"></b-icon>
        <b-icon v-if="hideZeroAssets" icon="eye-slash" style="color: var(--primary-color);"></b-icon>
        <p class="ml-2">{{ $t("common.HIDE_ZERO_ASSETS") }}</p>
      </div>
    </div>

    <b-list-group v-if="ownedPropertiesFiltered.length" class="mt-1">
      <b-list-group-item v-for="ownedProperty in ownedPropertiesFiltered">
        <div class="property-item goro-access-listing">
          <div class="d-flex flex-column flex-lg-row">
            <b-col cols="12" lg="5" xl="4" class="image-container" style="width: 100%">
              <b-img :src="getAvatar(ownedProperty.images)" blank-src="property image" class="click-able img-property"
                @click="openPropertyDetail(ownedProperty)" />
              <div class="bottom-info">{{ ownedProperty.name }}
                <span>
                  <b-icon v-if="ownedProperty.owning_tokens > 0" icon="file-earmark-text" font-scale="1"
                    class="click-able ml-1" :id="'tooltip-contract' + ownedProperty.id"
                    @click="openAssetContract(ownedProperty, ownedProperty.signed_contract)"
                    :color="ownedProperty.signed_contract ? '#FFFFFF' : '#EAB44F'"></b-icon>
                </span>
                <b-tooltip :target="'tooltip-contract' + ownedProperty.id" :delay="{ show: 0, hide: 0 }" no-fade="true"
                  variant="secondary" triggers="hover" placement="top">
                  {{ $t("CONTRACT.YOUR_CONTRACT_ON_THIS_ASSET") }}
                </b-tooltip>
              </div>
              <div class="address-container" @click="openGoogleMap(ownedProperty)">
                <img src="@/assets/img/location.svg" alt="" />
                <span>{{ ownedProperty.metadata.address }}</span>
              </div>
            </b-col>
            <b-col cols="12" lg="7" xl="8" align-self="center"
              class="info-container d-flex flex-row align-items-center">
              <div class="flex-grow-1">
                <b-row>
                  <b-col cols="6" xl="4" class="mb-2">
                    <p class="font-14 capitalize">{{ $t("RENTAL_INCOME.TOTAL_TOKENS") }}</p>
                    <p class="font-16 font-weight-bold">
                      {{ ownedProperty.owning_tokens }} ({{ ownedProperty.owningPercent }})
                    </p>
                  </b-col>
                  <b-col cols="6" xl="4" class="mb-2">
                    <p class="font-14 capitalize">{{ $t("ASSETS.LOCKED_TOKENS") }}<span>
                        <b-icon :id="`tooltip-locked-token-${ownedProperty.id}`" class="click-able ml-1"
                          @click="viewLockedTokens(ownedProperty.uuid, ownedProperty.name)" icon="info-circle-fill"
                          scale="1" style="color: var(--primary-color)"></b-icon>
                      </span>
                    </p>
                    <b-tooltip :target="`tooltip-locked-token-${ownedProperty.id}`" triggers="hover" placement="top"
                      variant="secondary">
                      {{ $t("ASSETS.CLICK_TO_SEE_DETAILS") }}
                    </b-tooltip>
                    <p class="font-16 font-weight-bold">
                      <span>
                        <img src="@/assets/img/locked.svg" class="img-locked" alt="" />
                      </span>
                      {{ ownedProperty.locked_tokens }}
                    </p>
                  </b-col>
                  <b-col cols="6" xl="4" class="mb-2">
                    <p class="font-14 capitalize">
                      {{ $t("ASSETS.TOKEN_AVAILABLE") }}
                    </p>
                    <p class="font-16 font-weight-bold">
                      {{ ownedProperty.sellable_tokens }}
                    </p>
                  </b-col>
                </b-row>
                <div class="divider d-block d-xl-none"></div>
                <div class="d-flex flex-row align-items-center">
                  <b-row align-h="between mt-0 mt-md-3 flex-grow-1">
                    <b-col cols="12" lg="6" xl="4" class="mb-2">
                      <p class="font-14 capitalize">{{ $t("ASSETS.CURRENT_VALUE") }}</p>
                      <p class="font-16 font-weight-bold fit-content" :id="`${ownedProperty.id}-current-value`">
                        {{ exchangeValue(ownedProperty.owning_tokens * ownedProperty.price_per_token) }}
                      </p>
                      <CurrencyTooltip :tooltipId="`${ownedProperty.id}-current-value`"
                        :value="ownedProperty.owning_tokens * ownedProperty.price_per_token">
                      </CurrencyTooltip>
                    </b-col>
                    <b-col cols="12" lg="6" xl="4" class="mb-2">
                      <p class="font-14 capitalize">
                        {{ $t("ASSETS.LAST_RENT_EARNED") }}
                        <small v-if="ownedProperty.last_rent_annualized_percent > 0" class="font-12">
                          {{ $t("ASSETS.ANNUALIZED_PERCENT") }}
                        </small>
                      </p>
                      <p class="font-16 font-weight-bold fit-content" :id="`${ownedProperty.id}-last-rent-value`">{{
      exchangeValue(ownedProperty.last_rent_earned)
    }}
                        <small v-if="ownedProperty.last_rent_annualized_percent > 0" class="font-weight-bold">
                          ({{ ownedProperty.last_rent_annualized_percent }}%)
                        </small>
                      </p>
                      <CurrencyTooltip :tooltipId="`${ownedProperty.id}-last-rent-value`"
                        :value="ownedProperty.last_rent_earned">
                      </CurrencyTooltip>
                    </b-col>
                    <b-col cols="12" lg="6" xl="4" class="mb-2">
                      <p class="font-14 capitalize">
                        {{ $t("ASSETS.TOTAL_RENT_EARNED") }}
                        <small v-if="ownedProperty.last_rent_annualized_percent > 0" class="font-12">
                          {{ $t("ASSETS.ANNUALIZED_PERCENT") }}
                        </small>
                      </p>
                      <p class="font-16 font-weight-bold fit-content" :id="`${ownedProperty.id}-total-rent-value`">{{
      exchangeValue(ownedProperty.total_rent_earned)
    }}
                        <small v-if="ownedProperty.last_rent_annualized_percent > 0" class="font-weight-bold">
                          ({{ ownedProperty.total_rent_annualized_percent }}%)
                        </small>
                      </p>
                      <CurrencyTooltip :tooltipId="`${ownedProperty.id}-total-rent-value`"
                        :value="ownedProperty.total_rent_earned">
                      </CurrencyTooltip>
                    </b-col>
                  </b-row>
                  <div class="d-xl-none d-flex flex-column justify-content-center ml-2">
                    <b-button id="btn_assetsOverview_swapToken" class="btn-main action-btn d-flex flex-row align-items-center justify-content-center" :disabled="!canSwapSell(ownedProperty)"
                      @click="swapToken(ownedProperty.uuid)">
                      <img :src="swapImage(ownedProperty)" alt="" />{{ $t("ASSETS.SWAP") }}
                    </b-button>
                    <b-button id="btn_assetsOverview_sellToken"
                      class="btn-outline-white action-btn d-flex flex-row align-items-center justify-content-center" :disabled="!canSwapSell(ownedProperty)"
                      @click="sellToken(ownedProperty.uuid)">
                      <img :src="sellImage(ownedProperty)" alt="" />{{ $t("ASSETS.SELL") }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="d-none d-xl-flex flex-column justify-content-center ml-2">
                <b-button id="btn_mobile_assetsOverview_swapToken" class="btn-main action-btn d-flex flex-row align-items-center justify-content-center" :disabled="!canSwapSell(ownedProperty)"
                  @click="swapToken(ownedProperty.uuid)">
                  <img :src="swapImage(ownedProperty)" alt="" />{{ $t("ASSETS.SWAP") }}
                </b-button>
                <b-button id="btn_mobile_assetsOverview_sellToken" class="btn-outline-white action-btn d-flex flex-row align-items-center justify-content-center" :disabled="!canSwapSell(ownedProperty)"
                  @click="sellToken(ownedProperty.uuid)">
                  <img :src="sellImage(ownedProperty)" alt="" />{{ $t("ASSETS.SELL") }}
                </b-button>
              </div>
            </b-col>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-if="!rows && isDataLoaded" class="text-center mt-4">
      <p class="no-properties">{{ $t("ASSETS.NO_PROPERTY") }}</p>
      <router-link :to="{ name: 'marketplace' }">
        <b-button id="btn_assetsOverview_viewProperties" class="btn-main mt-5 pl-4 pr-4" type="submit" variant="none" style="padding: 10px">
          {{ $t("ASSETS.VIEW_PROPERTIES") }}
        </b-button>
      </router-link>
    </div>

    <modal-locked-tokens :property-uuid="lockedPropertyUuid" :property-name="lockedPropertyName"
      :show="showLockedTokens" @on-close="showLockedTokens = false"></modal-locked-tokens>

    <modal-contract-documents ref="contractDocuments" />
    <modal-contract-agreement ref="contractAgreement" @on-agreed-to-contract="onAgreedToContract" />
    <popup ref="popupPendingTask" @on-positive-clicked="openContractAgreement(this.agreeToContractPropertyUuid)"></popup>
    <modal-sell-sold-out :show="showPopupSellSoldOut" @on-close="showPopupSellSoldOut = false" @on-sell="continuteSellSoldOut()"></modal-sell-sold-out>
  </div>
</template>

<script>

import accountService from "../../services/account.service"
import { appendTimestampToUrl, exchange, notify, urlImage } from "../../helpers/common"
import moment from "moment"
import { ref } from "vue"
import contractsService from "../../services/contracts.service"
import CurrencyTooltip from "../../components/CurrencyTooltip.vue"
import ModalLockedTokens from "../../modals/ModalLockedTokens.vue"
import store from "../../store/store"
import Popup from "../../components/Popup"
import ModalContractAgreement from "../../modals/ModalContractAgreement"
import ModalContractDocuments from "../../modals/ModalContractDocuments"
import html2canvas from "html2canvas"
import Popper from "vue3-popper"
import "../../assets/css/popper.theme.css"
import propertiesService from '../../services/properties.service'
import { PROPERTY_STATUSES } from "../../constants/constants"
import ModalSellSoldOut from "../../modals/ModalSellSoldOut"

export default {
  components: {
    CurrencyTooltip,
    ModalLockedTokens,
    ModalContractAgreement,
    ModalContractDocuments,
    ModalSellSoldOut,
    Popup,
    Popper
  },
  data() {
    return {
      innerWidth: 0,
      data: null,
      ownedPropertiesFiltered: [],
      ownedProperties: [],
      sharingOwnedProperties: [],
      virtualData: null,
      virtualAssets: [],
      isDataLoaded: false,
      showLockedTokens: false,
      lockedPropertyUuid: null,
      lockedPropertyName: null,
      hideZeroAssets: false,
      agreeToContractPropertyUuid: null,
      agreeToContractPendingTask: null,
      latestContractTransaction: null,
      showPopupSellSoldOut: false,
      propertyUUIDSellSoldOut: null
    }
  },

  async mounted() {
    this.handleWindowResize()
    window.addEventListener("resize", this.handleWindowResize)

    this.hideZeroAssets = this.isHideZeroAssets

    await this.getAssets()
  },

  methods: {
    async onChangeHideZeroAssets() {
      this.hideZeroAssets = !this.hideZeroAssets
      const res = await accountService.updateAssetsSettings({
        hide_zero_assets: this.hideZeroAssets,
      })
      if (res) {
        await this.$store.dispatch('refreshUserProfile');
      }
    },
    handleWindowResize() {
      this.innerWidth = window.innerWidth
    },
    async beforeDestroy() {
      window.removeEventListener("resize", this.handleWindowResize)
    },
    async getAssets() {
      const data = await accountService.getAssets()
      if (data) {
        this.data = data
        this.ownedProperties = this.data.owned_properties
        this.sharingOwnedProperties = this.ownedProperties.length > 3
          ? this.ownedProperties.slice(0, 3) : this.ownedProperties
        this.virtualData = this.data.virtual_data
        this.virtualAssets = this.data.virtual_data.assets.sort((a, b) => {
          const expiredA = (a.total_tokens - a.expired_tokens) === 0
          const expiredB = (b.total_tokens - b.expired_tokens) === 0
          if (expiredA === expiredB) {
            return 0
          }
          if (expiredA) {
            return 1
          }
          return -1
        })
        this.ownedProperties.forEach(ownedProperty => {
          let owningPercent = ownedProperty.owning_tokens / ownedProperty.total_tokens * 100
          if (owningPercent < 1) {
            ownedProperty.owningPercent = owningPercent.toFixed(5) + "%"
          } else {
            ownedProperty.owningPercent = owningPercent.toFixed(2) + "%"
          }
        })
      }

      this.filterOwnedProperties()
      this.isDataLoaded = true
    },

    filterOwnedProperties() {
      if (this.hideZeroAssets) {
        this.ownedPropertiesFiltered = this.ownedProperties.filter(item => {
          if (item && item.owning_tokens && (parseInt(item.owning_tokens) > 0)) return true
        })
      } else {
        this.ownedPropertiesFiltered = this.ownedProperties
      }
    },

    getAvatar(images) {
      if (images && images.length) {
        return urlImage(images[0])
      }
      return ""
    },

    async openPropertyDetail(property) {
      if (property) {
        await this.$router.push({ name: "propertyDetail", params: { uuid: property.uuid } })
      }
    },

    async viewLockedTokens(propertyUuid, propertyName) {
      this.lockedPropertyUuid = propertyUuid
      this.lockedPropertyName = propertyName
      this.showLockedTokens = true
    },

    async onAgreedToContract(data) {
      if (data && data.propertyUuid) {
        for (let i = 0; i < this.ownedProperties.length; i++) {
          const ownedProperty = this.ownedProperties[i]
          if (ownedProperty.uuid === data.propertyUuid) {
            ownedProperty.signed_contract = ref(true)
            break
          }
        }
      }
    },

    async openAssetContract(property, signedContract = false) {
      if (property) {
        this.$root.$emit("bv::hide::tooltip")
        if (signedContract) {
          //view existing contract
          await this.$refs.contractDocuments.showModal(property)
        } else {
          //ask user agree to contract
          let contractUrl = await contractsService.getContractPreviewForExisting({ property_uuid: property.uuid })
          this.$refs.contractAgreement.showModal(contractUrl, property.uuid)
        }
      }
    },
    async createWithdrawal() {
      if (this.$store.getters.userProfile && this.$store.getters.userProfile.email_verified_at !== null) {
        await this.$store.dispatch('refreshUserProfile');
        await this.$router.push({ name: "createWithdrawal" }).catch(() => { })
      } else {
        await this.$router.push({ path: "/account/my-account/basic-info?status=email_not_verified" })
      }
    },
    nextExpiredTokenMessage(items) {
      if (items) {
        const item = items.find((e) => moment() <= moment(e.expiration_time))
        if (item) {
          return this.$t("VIRTUAL_BALANCE.TOKEN_EXPIRED_ON", {
            token: item.tokens,
            date: moment(item.expiration_time).format("DD/MM/YYYY HH:mm"),
            value: item.tokens === 1 ? "" : "s",
          })
        }
      }
      return null
    },

    virtualTextClass(virtualAsset) {
      return virtualAsset.total_tokens - virtualAsset.expired_tokens === 0 ? "property-item-disabled" : "property-item-enabled"
    },

    exchangeValue(value) {
      return exchange(value)
    },

    async getBase64String(element, maxSizeInKB, initialScale = 1, initialQuality = 1) {
      let scale = initialScale
      let quality = initialQuality
      const maxBytes = maxSizeInKB * 1024 // Convert KB to Bytes
      let base64String, sizeInBytes

      do {
        let canvas = await html2canvas(element, {
          scale: scale,
          allowTaint: true,
          useCORS: true
        })

        base64String = canvas.toDataURL("image/jpeg", quality)
        sizeInBytes = Math.ceil((base64String.length * 3) / 4)

        // Adjust scale and quality
        if (sizeInBytes > maxBytes) {
          if (quality > 0.8) {
            quality -= 0.1 // Reduce quality first
          } else {
            scale -= 0.1 // Then reduce scale
            quality = 1 // Reset quality for new scale
          }
        }
      } while (sizeInBytes > maxBytes && scale > 0.1)

      return base64String
    },

    async sharePortfolioOverview(method) {
      let baseUrl = "https://goro.id"
      if (process.env.VUE_APP_ENV === "local") {
        baseUrl = "http://localhost:8080"
      } else if (process.env.VUE_APP_ENV === "staging") {
        baseUrl = "https://staging.goro.id"
      }
      const shareLink = appendTimestampToUrl(`${baseUrl}/image-share/portfolio-overview?code=${this.userReferralCode}`)
      const encodedShareLink = encodeURIComponent(shareLink)
      if (shareLink) {
        const title = this.$t("SHARING.INVITE_MESSAGE")
        const encodedTitle = encodeURIComponent(title)
        if (method === "facebook") {
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}&title=${encodedTitle}`, "_blank")
        } else if (method === "whatsapp") {
          window.open(`https://wa.me?text=${encodedTitle} ${encodedShareLink}`, "_blank")
        } else if (method === "twitter") {
          window.open(`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedShareLink}`, "_blank")
        } else if (method === "manual") {
          if (this.canCopyToClipboard) {
            await navigator.clipboard.writeText(`${title} ${shareLink}`)
            notify({text: this.$t("common.COPIED")})
          }
        }
      }
      // Avoid calling window.open() after asynchronous operations or delays
      // (like after fetching data from an API or a setTimeout), as the browser may treat these as non-user-initiated.
      let portfolioOverviewElement = document.getElementById("portfolio-overview")
      let initialScale = 800 / portfolioOverviewElement.offsetWidth
      let base64String = await this.getBase64String(portfolioOverviewElement, 290, initialScale)
      let res = await accountService.uploadPortfolioOverview({
        portfolio_overview_file_base_64: base64String
      })
    },

    async swapToken(uuid) {
      await this.getContractStatus(uuid)
      if (this.agreeToContractPendingTask) {
        this.$refs.popupPendingTask.openPopup(this.$t("PENDING_TASKS.COMPLETE_PENDING_TASK"),
          this.$t("PENDING_TASKS.PLEASE_READ_AND_AGREE_TO_AGREEMENT"), this.$t("common.OK"))
      } else {
        await this.$router.push({ name: "swapToken", query: { uuid: uuid } })
      }
    },

    async sellToken(uuid) {
      await this.getContractStatus(uuid)
      if (this.agreeToContractPendingTask) {
        this.$refs.popupPendingTask.openPopup(this.$t("PENDING_TASKS.COMPLETE_PENDING_TASK"),
          this.$t("PENDING_TASKS.PLEASE_READ_AND_AGREE_TO_AGREEMENT"), this.$t("common.OK"))
      } else {
        const property = await this.getPropertyByUuid(uuid)
        if (property && property.status === PROPERTY_STATUSES.SOLD) {
          // Show popup sell sold out
          this.showPopupSellSoldOut = true
          this.propertyUUIDSellSoldOut = uuid
        } else {
          await this.$router.push({ name: "sellTokenRequest", query: { uuid: uuid } })
        }
      }
    },

    async continuteSellSoldOut() {
      if (this.propertyUUIDSellSoldOut) {
        await this.$router.push({ name: "sellTokenRequest", query: { uuid: this.propertyUUIDSellSoldOut } })
      }
    },

    async getPropertyByUuid (uuid) {
      const property = await propertiesService.getByUuid(uuid)
      if (property && property.data) {
        return property.data
      } else {
        return null
      }
    },

    async getContractStatus(propertyUuid) {
      this.agreeToContractPropertyUuid = propertyUuid
      this.agreeToContractPendingTask = null
      this.latestContractTransaction = null
      const response = await contractsService.getContractStatus(propertyUuid)
      if (response) {
        this.agreeToContractPendingTask = response.agree_to_contract_pending_task
        this.latestContractTransaction = response.latest_contract_transaction
      }
    },

    async openContractAgreement(uuid) {
      const contractPreviewUrl = await contractsService.getContractPreviewForExisting({ property_uuid: uuid })
      this.$refs.contractAgreement.showModal(contractPreviewUrl, uuid)
    },

    openGoogleMap(property) {
      if (property.map_link) {
        window.open(property.map_link, "_blank")
      }
    },

    canSwapSell(property) {
      return property.owning_tokens > 0
    },

    swapImage(property) {
      return this.canSwapSell(property) ? require("@/assets/img/swap.png") : require("@/assets/img/swap-disabled.png")
    },

    sellImage(property) {
      return this.canSwapSell(property) ? require("@/assets/img/sell.png") : require("@/assets/img/sell-disabled.png")
    }
  },
  computed: {
    rows() {
      return this.ownedProperties.length || this.virtualAssets.length
    },

    currency() {
      return this.$t("PAYMENT.CURRENCY")
    },

    userUuid() {
      return this.$store.getters.userProfile ? this.$store.getters.userProfile.uuid : "0"
    },

    isHideZeroAssets() {
      return this.$store.getters.userProfile ? this.$store.getters.userProfile.hide_zero_assets : false
    },

    myBalance() {
      return this.data && exchange(this.data.user_balance) || "0"
    },

    userReferralCode() {
      return this.$store.getters.referralCode ? this.$store.getters.referralCode.code : ""
    },

    userCreatedAt() {
      if (this.$store.getters.userProfile) {
        return moment(this.$store.getters.userProfile.created_at).format("DD-MM-YYYY")
      }
      return ""
    },

    isEnableVirtualBalance() {
      if (store.state.configs && store.state.configs.enabled_virtual_balance) {
        return store.state.configs.enabled_virtual_balance
      }
      return false
    },

    virtualBalance() {
      return this.data && exchange(this.data.user_virtual_balance)
    },

    totalPropertyValue() {
      return this.data && exchange(this.data.total_property_value)
    },

    totalRentEarned() {
      return this.data && exchange(this.data.total_rent_earned)
    },

    currentAccountValue() {
      return this.data && exchange(this.data.user_balance + this.data.total_property_value)
    },

    avgEarningAnnualizedPercent() {
      if (this.ownedProperties && this.ownedProperties.length > 0) {
        let totalEarningAnnualizedPercent = 0
        this.ownedProperties.forEach(ownedProperty => {
          totalEarningAnnualizedPercent = totalEarningAnnualizedPercent + ownedProperty.total_rent_annualized_percent
        })
        return (totalEarningAnnualizedPercent / this.ownedProperties.length).toFixed(2)
      }
      return 0
    },

    virtualAssetsTitle() {
      return this.$t("VIRTUAL_BALANCE.VIRTUAL_ASSETS", {
        value1: this.virtualData.properties_owned,
        value2:
          this.virtualData.properties_owned === 1 ? "y" : "ies",
        value3: this.virtualData.current_tokens,
        value4:
          this.virtualData.current_tokens === 1 ? "" : "s",
        value5: this.exchangeValue(this.virtualData.current_owned),
      })
    },

    canCopyToClipboard() {
      return window.isSecureContext && navigator.clipboard
    },

    filteredPropertiesCount() {
      return this.ownedPropertiesFiltered.length
    }
  },
  watch: {
    hideZeroAssets(value) {
      this.filterOwnedProperties()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  .rent-balance {
    height: 100%;
    margin-right: 1px;
    margin-left: 1px;
    padding: 20px 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(7, 55, 99, 0.16);
    border-radius: 8px;

    .balance-bg:hover {
      background-color: rgb(246, 246, 246);
    }
  }

  .assets-info {
    height: 100%;
    margin-right: 1px;
    margin-left: 1px;
    padding: 20px 15px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(7, 55, 99, 0.16);
    border-radius: 8px;
  }

  .list-group-item {
    border: none;
    padding: 0;
  }

  .property-item {
    margin: 12px 3px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(7, 55, 99, 0.16);
    border-radius: 16px;
    overflow: hidden;

    .info-container {
      padding: 24px;
    }

    .img-locked {
      width: 22px;
      height: 22px;
    }

    .image-container {
      aspect-ratio: 1.7;
      width: 100%;
      position: relative;
      padding: 0px;
      border-radius: 16px;
      overflow: hidden;

      .img-property {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .bottom-info {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 10px 30px 10px 30px;
        font-weight: bold;
        background-image: linear-gradient(to right, #003E3E, #003e3e71);
        color: #fff;
      }

      .address-container {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 4px 8px 4px 8px;
        background-color: #fff;
        color: var(--primary-color);
        font-size: 12px;
        border-radius: 16px;
        font-weight: 600;
        cursor: pointer;

        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          object-fit: contain;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: rgb(230, 230, 230);
      margin-top: 14px;
      margin-bottom: 14px;
    }

    .action-btn {
      padding: 6px 24px;
      margin: 4px 0px 4px 0px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
  }

  .property-item-enabled {
    color: var(--primary-color);
  }

  .property-item-disabled {
    color: gray;
  }

  .no-properties {
    font-size: 18px;
    font-weight: 400;
  }

  .next-expired-token {
    color: red;
    font-size: 10px;
  }

  .portfolio-overview {
    background-image: url('../../assets/img/sharing/general_bg.png');
    background-size: cover;
    width: 920px;
    height: 920px;
    padding: 70px 90px;

    .portfolio-overview-info {
      background: #D3EEF0;
      border-radius: 20px;
      padding: 20px 20px;
    }

    .portfolio-overview-id {
      font-family: "Figtree-Bold", serif;
      font-weight: 500;
      font-size: 18px;
      color: #267375;
    }

    .portfolio-overview-title {
      font-family: "Figtree", serif;
      font-weight: 900;
      font-size: 18px;
      color: #267375;
    }

    .portfolio-overview-value {
      font-family: "Figtree-Bold", serif;
      font-weight: 500;
      font-size: 36px;
    }

    .portfolio-overview-image {
      background: #D3EEF0;
      border-radius: 20px;
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
    }

    .portfolio-overview-name {
      background-image: url('../../assets/img/sharing/property_name_bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 99%;
      position: absolute;
      padding: 5px 15px;
      bottom: 20px;
      font-family: "Figtree", serif;
      font-weight: 900;
      font-size: 14px;
      color: white;
    }

    .portfolio-overview-referral-code {
      background-image: url('../../assets/img/sharing/referral_code_bg.png');
      background-size: cover;
      margin: 0px -90px;
      padding: 5px 90px;
      font-family: "Figtree-Bold", serif;
      font-weight: 500;
      font-size: 36px;
      color: white;
    }
  }

  .popper-content {
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .capitalize {
    text-transform: capitalize;
  }
}
</style>
