<template>
    <div class="popup" :class="popupClass" v-if="isOpen" style="z-index: 10001;">
        <div class="d-flex flex-column w-100 align-items-center">
            <img class="logo" src="@/assets/img/logo.png" alt="">
            <p class="font-24 font-weight-bold mt-3 mb-4">{{ $t("TWO_FA.TWO_FACTOR_AUTHENTICATION") }}</p>
            <b-form @submit.stop.prevent="submit" class="w-100">
                <div class="popup-content d-flex flex-column">
                    <p class="mt-2">{{ isOtp ? $t('TWO_FA.ENTER_CODE_AUTHENTICATION_APP') :
        $t('TWO_FA.ENTER_RECOVERY_CODE')
                        }}
                    </p>
                    <input class="otp-input mt-2" v-model="value" />
                    <!-- <b-form-checkbox class="mt-1 align-self-start ml-1" name="remember_device" v-model="remember">
                <p class="font-14">{{ $t("TWO_FA.REMEMBER_THIS_DEVICE") }}</p>
            </b-form-checkbox> -->
                    <p class="mt-3">{{ $t('TWO_FA.HAVING_PROBLEMS') }} <span class="btn-use-code"
                            @click="switchUsingCode">{{
        isOtp ?
            $t('TWO_FA.USE_RECOVERY_CODE') : $t('TWO_FA.USE_AUTHENTICATION_CODE') }}</span></p>
                    <div class="mt-3"></div>
                    <div class="d-flex flex-row mt-5 justify-content-center">
                        <b-button id="btn_popupVerify2FA_Verify" class="btn-main col-4 btn-submit" type="submit" :disabled="!enabledSubmit"
                            variant="none">
                            {{ $t("AUTH.2FA_VERIFY") }}
                        </b-button>
                        <b-button id="btn_popupVerify2FA_Cancel" class="btn-outline-main col-4 btn-submit ml-3" variant="none" @click="logout()">
                            {{ $t("common.CANCEL") }}
                        </b-button>
                    </div>
                </div>
            </b-form>

            <p class="mt-3">
                <span class="btn-use-code" @click="openContact">{{
        $t('TWO_FA.CONTACT_US') }}</span>
                <span class="color-black">{{ ` ${$t('TWO_FA.IF_YOU_ARE_LOCKED_OUT')}` }}</span>
            </p>
            <a ref="mailto" :href="contactMailTo" target="_blank" class="hidden"></a>
        </div>
    </div>
</template>

<script>

import twofaService from '../services/twofa.service'
import externalSites from '../constants/externalSites';
import { isAdmin } from "../constants/roles"

export default {
    data() {
        return {
            isOpen: false,
            isOtp: true,
            value: '',
            remember: false,
            contactMailTo: externalSites.MAIL_TO.CUSTOMER_SUPPORT,
        }
    },

    emits: ["on-success"],

    mounted() {

    },

    watch: {
        async '$store.getters.required2FA'(value) {
            if (!this.isOpen && value) {
                this.openPopup()
            }
        },
    },

    methods: {
        openPopup() {
            this.isOpen = true
        },

        close() {
            this.isOpen = false
        },

        async submit() {
            await this.$recaptchaLoaded()
            const recaptchaToken = await this.$recaptcha("verify2FA")
            const data = this.isOtp ? { 'otp': this.value } : { 'recovery_code': this.value }
            const res = await twofaService.verify2FA({
                recaptcha_token: recaptchaToken,
                // remember: this.remember,
                ...data,
            })
            if (res) {
                this.close()
                setTimeout(() => {
                    window.location.reload()
                }, 200)
            }
        },

        switchUsingCode() {
            this.isOtp = !this.isOtp
        },

        async logout() {
            await this.$store.dispatch('doLogout');
            await this.$router.push({ name: 'home' });
            this.close()
        },

        openContact() {
            this.$refs.mailto.click()
        }
    },

    computed: {
        enabledSubmit() {
            return this.value && this.value.length >= 6
        },

        popupClass() {
            if (isAdmin()) {
                return 'admin'
            }
            return ''
        }
    },

}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E7F6F6;
    display: flex;
    justify-content: center;
    align-items: center;

    &.admin {
        background-color: #d9d7d7;
    }

    .logo {
        width: 90px;
        height: 90px;
    }

    .popup-content {
        position: relative;
        background-color: #fff;
        padding: 30px;
        border-radius: 20px;
        text-align: center;
        width: 95%;
        height: fit-content;
        margin: auto;
        box-shadow: 0 5px 20px rgba(7, 55, 99, 0.16);
        color: black;

        @media screen and (min-width: 800px) {
            width: 50%;
        }

        @media screen and (min-width: 1200px) {
            width: 30%;
        }

        .btn-close {
            background-color: rgb(221, 221, 221);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .otp-input {
            width: 100%;
            background-color: #ffffff;
            border: solid 2px var(--primary-color);
            border-radius: 4px;
            padding-left: 1rem;
            padding-right: 1rem;
            color: black;
            min-height: 60px;
            font-size: 28px;
            font-weight: 700;
            text-align: start;

            &::placeholder {
                color: rgb(202, 202, 202);
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                color: rgb(202, 202, 202);
            }

            &::-ms-input-placeholder {
                color: rgb(202, 202, 202);
            }

            &.confirming {
                background-color: #f7f7f8b6;
            }
        }

        .btn-submit {
            max-height: 40px;
        }
    }

    a {
        &:hover {
            color: var(--primary-darker-color);
            text-decoration: underline;
        }
    }

    .btn-use-code {
        color: var(--primary-color);
        font-weight: bold;
        cursor: pointer;
    }
}
</style>