<template>
    <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light" @hidden="$emit('on-close')">
        <template #modal-header>
            <h5 class="modal-header-title">Property Holders</h5>
            <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose()">×</button>
        </template>
        <template #default="{ hide }">
            <div class="container">
                <b-row class="mb-3" align-v="center" v-if="property">
                    <b-col cols="3">
                        <img style="width: 100%" :src="getImageUrl(property.images[0])" alt="" />
                    </b-col>
                    <b-col class="ml-3" cols="8">
                        <p class="font-28 font-weight-bold">{{ property.name }}<b-icon class="link-icon"
                                @click="openPropertyDetails" icon="arrow-up-right-square">
                            </b-icon></p>
                        <p class="mb-1">{{ property.metadata.address }}</p>
                        <p>Total tokens: {{ property.total_tokens }}</p>
                        <p>Sold tokens: {{ property.sold_tokens }} ({{ getPercent(property.sold_tokens) }}%)</p>
                        <p>Tokens left: {{ property.total_tokens - property.sold_tokens }}
                            ({{ getPercent(property.total_tokens - property.sold_tokens) }}%)</p>
                        <p>Price per token: IDR{{ getFormattedCurrency(property.price_per_token) }}</p>
                    </b-col>
                </b-row>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="items" :columns="tableColumns">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th width="30%">
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th width="30%">
                                    <b-input v-model="filters.email"></b-input>
                                </th>
                                <th width="20%"></th>
                                <th width="15%"></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="holders.total" align="right" v-model="holders.current_page"
                        :total-rows="holders.total" :per-page="holders.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </template>

        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="primary" @click="onClose()">
                Close
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import { PaperTable } from "../../../components";
import propertiesService from '../../../services/admin/properties.service';
import { urlImage, numberWithCommas, formatCurrency } from "@/helpers/common";

export default {
    components: {
        PaperTable,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        property: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            showModal: false,
            holders: [],
            items: [],
            tableColumns: ["Id", "Name", "Email", "Owned", "Avg_Price_Per_Token"],
            filters: {
                name: '',
                email: '',
            },
        };
    },

    emits: ['on-close'],

    watch: {
        async property(value) {
            if (value) {
                await this.getHolders(value.id, 1);
            }
        },

        show(value) {
            this.showModal = value;
        },

        'filters.name'() {
            this.searchTimeOut(1);
        },
        'filters.email'() {
            this.searchTimeOut(1);
        },
    },

    methods: {

        async getHolders(id, page) {
            const filters = {
                ...this.filters,
                page,
            };
            const res = await propertiesService.getHolders(id, filters);
            if (res) {
                this.holders = res;
                this.items = res.data.map(e => {
                    return {
                        'id': e.id,
                        'name': e.name,
                        'email': e.email,
                        'owned': `${e.owned_tokens} tokens`,
                        'avg_price_per_token': `IDR${numberWithCommas(e.avg_price_per_token)}`,
                    };
                });
            }
        },

        onClose: function () {
            this.$emit("on-close")
        },

        getImageUrl(image) {
            return urlImage(image);
        },

        getPercent(tokens) {
            return Math.round((tokens * 100 / this.property.total_tokens) * 10) / 10;
        },

        async openPropertyDetails() {
            const route = this.$router.resolve({ name: 'propertyDetail', params: { uuid: this.property.uuid } });
            window.open(route.href, '_blank');
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getHolders(this.property.id, page);
            }, 400);
        },

        async onChangePage(page) {
            await this.getHolders(this.property.id, page);
        },

        getFormattedCurrency(value) {
            return formatCurrency(value)
        },
    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        },
    },
}
</script>

<style scoped>
:deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
    border-radius: 0;
}

.modal-header-title {
    margin: 0;
    font-weight: bold;
}

.close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
}

.item {
    background-color: white;
    padding: 20px;
}

.link-icon {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-bottom: 5px;
    cursor: pointer;
}
</style>
