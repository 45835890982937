import serverApi from "../../utils/serverApi"

const withdrawalsService = {
    getWithdrawals: (data) => serverApi({ uri: 'admin/withdrawals', method: 'GET', data }),

    approveWithdrawal: (body) => serverApi({ uri: 'admin/withdrawals/approve', method: 'PUT', data: body }),

    rejectWithdrawal: (body) => serverApi({ uri: 'admin/withdrawals/reject', method: 'PUT', data: body }),
};

export default withdrawalsService;
