import serverApi from "../utils/serverApi"

const notificationsService = {
    getNotifications: (data) => serverApi({ uri: 'notifications', method: 'GET', data }),

    read: (body) => serverApi({ uri: 'notifications/read', method: 'PUT', data: body, isLoading: false }),

    unreadCount: () => serverApi({ uri: 'notifications/unread-count', method: 'GET', isLoading: false }),

    markAllAsRead: () => serverApi({ uri: 'notifications/mark-all-read', method: 'PATCH' }),

    claimNotification: (body) => serverApi({ uri: 'notifications/claim', method: 'PUT', data: body }),
};

export default notificationsService;
