<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-form-group label="Config Key" label-for="key-input" :state="validateState('c_key')">
                <b-form-input id="key-input" v-model="form.c_key" :state="validateState('c_key')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="Config Value" label-for="value-input" :state="validateState('c_value')">
                <b-form-input id="value-input" v-model="form.c_value" :state="validateState('c_value')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="Config Description" label-for="description-input">
                <b-form-input id="description-input" v-model="form.description"></b-form-input>
            </b-form-group>

        </form>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import configService from "../../../services/admin/configs.service";

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                c_key: {
                    required,
                },
                c_value: {
                    required,
                },
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {
                c_key: '',
                v_value: '',
                description: '',
            },
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            if (this.config != null) {
                this.form = {
                    c_key: this.config.c_key,
                    c_value: this.config.c_value,
                    description: this.config.description,
                    id: this.config.id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                c_key: '',
                c_value: '',
                description: '',
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            await configService.createOrUpdate(this.form);
            this.$nextTick(() => {
                this.resetModal();
                this.$emit('on-close', true);
            })
        }
    },

    computed: {
        title() {
            return this.config != null ? 'Update config' : 'Add new config';
        },
    },
}
</script>

<style lang="scss" scoped></style>