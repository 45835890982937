import { notify } from "../helpers/common"
import messErrors from "../constants/errors"
import { STATUS_CODE } from "../constants/constants"
import router from "../router";

let notifyTimeout;
let notifyMessage;

export default async function (err, vm, info) {
  if (err != null && err.source === "API") {
    let mess = '';
    if (err.data) {
      if (typeof err.data === 'object') {
        let i = 0;
        for (const p in err.data) {
          if (err.data.hasOwnProperty(p)) {
            mess += `${err.data[p]} <br>`;
            i++;
          }
          if (i === 3) { break }
        }
      } else {
        mess = err.data
      }
    } else if (err.message) {
      mess = err.message
    }
    if (mess === '') {
      mess = messErrors.INTERNAL
    }
    showError(mess)
    if (err.statusCode === STATUS_CODE.HTTP_UNAUTHORIZED) {
      await store.dispatch("doLogout")
      router.push({ path: "/login", })
    }
  } else {
    showError((err != null && err.message) || info || messErrors.INTERNAL)
  }
}

function showError(error) {
  if (!notifyMessage) {
    notifyMessage = error
    notify({ text: notifyMessage, type: 'error' });
    notifyTimeout = setTimeout(() => {
      notifyMessage = null
    }, 2000)
  } else {
    if (notifyMessage !== error) {
      notify({ text: error, type: 'error' });
    }
  }
}
