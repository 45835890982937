<template>
  <div class="row">
    <div class="col-12">
      <div class="mb-3">
        <b-button
          class="btn btn-none btn-main"
          style="padding: 5px 20px"
          size="sm"
          id="property-pick-date"
          @click="onShowModalPickDate()"
        >
          {{ $t("PARTNER.PICK_DATE") }}
        </b-button>
      </div>
      <div class="mb-3">
        <div class="cls-custom-filters-applied d-flex justify-content-start align-items-center">
          <p class="cls-applied-label">Filters: </p>
          <p v-if="!hasFiltered" class="cls-applied-label no-filtered">No filters</p>
          <div v-if="filters.from_live_date || filters.to_live_date" class="cls-applied-item cls-pick-date">
            <span v-if="filters.from_live_date">{{ filters.from_live_date }}</span>
            <span v-if="filters.to_live_date"> - {{ filters.to_live_date }}</span>
            <button class="btn-remove-filter" @click="filters.first_live_on=null">
                <b-icon icon="x-lg" style="color: gray;" scale="1.6"></b-icon>
            </button>
          </div>
        </div>
      </div>
      <div slot="raw-content" class="table-responsive">
        <paper-table
          :data="properties.data"
          :columns="tableColumns"
          :show-edit-action="false"
          :show-delete-action="false"
          :customActionTitles="customActions"
          @onCustomAction="onClickedCustomAction"
          :tr-class-field="'status'"
          :tdCustomActionStyle="{ width: '150px' }"
        >
          <template #theadSearch>
            <thead class="search">
              <th width="30px" />
              <th width="10%">
                <b-input v-model="filters.uuid"></b-input>
              </th>
              <th>
                <b-input v-model="filters.name"></b-input>
              </th>
              <th width="10%">
                <b-select
                  :options="statusList"
                  v-model="filters.status"
                ></b-select>
              </th>
              <th>
                <b-form-select
                  v-model="filters.spc_id"
                  text-field="name"
                  value-field="id"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >Select spc</b-form-select-option
                    >
                  </template>
                  <template v-for="spc in spcs">
                    <b-form-select-option :value="spc.id">
                      {{ spc.company_name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th>
                <b-input v-model="filters.location"></b-input>
              </th>
              <th>
                <b-input v-model="filters.address"></b-input>
              </th>
              <th>
                <b-form-select
                  v-model="filters.category_id"
                  text-field="name"
                  value-field="id"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >Select type</b-form-select-option
                    >
                  </template>
                  <template v-for="category in categories">
                    <b-form-select-option :value="category.id">
                      {{ category.name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination
          v-if="properties.total"
          align="lelf"
          class="mt-3"
          v-model="properties.current_page"
          :total-rows="properties.total"
          :per-page="properties.per_page"
          @change="onChangePage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <modal-property-holders
      :show="modalPropertyHolders"
      :property="property"
      @on-close="modalPropertyHolders = false"
    />
    <modal-pick-date
      :show="showModalPickDate"
      @on-close="showModalPickDate = false"
      @on-apply="onApply"
    ></modal-pick-date>
  </div>
</template>
<script>
import moment from 'moment';
import { PaperTable } from "../../components";
import ModalPropertyHolders from "@/pages/partner/modals/ModalPropertyHolders";
import propertiesService from "../../services/properties.service";
import spcsService from "../../services/spcs.service";
import categoryService from "../../services/categories.service";
import { USER_PERMISSIONS } from "../../constants/permissions";
import {
  PROPERTY_STATUSES_LIST,
  PROPERTY_CUSTOM_ACTIONS,
  PROPERTY_SHOW_MODE_STATUS,
} from "../../constants/constants";
import { partnerHasPermission } from "../../helpers/partner";
import ModalPickDate from "./modals/ModalPickDate.vue";

const tableColumns = [
  "Id",
  "Uuid",
  "Name",
  "Status",
  "Spc",
  "Location",
  "Address",
  "Type",
  "Sold_Tokens",
  "Date time"
];

export default {
  components: {
    PaperTable,
    ModalPropertyHolders,
    ModalPickDate,
  },
  data() {
    return {
      propertyId: null,
      property: null,
      properties: {},
      tableColumns: [...tableColumns],
      filters: {
        uuid: "",
        name: "",
        status: "",
        spc_id: null,
        location: "",
        address: "",
        category_id: null,
        first_live_on: null,
        from_live_date: null,
        to_live_date: null,
      },
      statusList: PROPERTY_STATUSES_LIST,
      spcs: [],
      categories: [],
      modalPropertyHolders: false,
      viewPropertyId: null,
      showModalPickDate: false,
    };
  },
  async mounted() {
    await Promise.all([
      this.getProperties(),
      this.getSpcs(),
      this.getCategories(),
    ]);
  },
  watch: {
    async "filters.uuid"() {
      this.searchTimeOut();
    },
    async "filters.name"() {
      this.searchTimeOut();
    },
    async "filters.spc_id"() {
      this.searchTimeOut();
    },
    async "filters.location"() {
      this.searchTimeOut();
    },
    async "filters.address"() {
      this.searchTimeOut();
    },
    async "filters.status"() {
      this.searchTimeOut();
    },
    async "filters.category_id"() {
      this.searchTimeOut();
    },
    async "filters.first_live_on"(value) {
      this.filters.from_live_date = null;
      this.filters.to_live_date = null;

      if (value && value[0]) {
        this.filters.from_live_date = value[0];
      }
      if (value && value[1]) {
        this.filters.to_live_date = value[1];
      }
      this.searchTimeOut();
    },
  },
  computed: {
    hasFiltered() {
      if (this.filters.first_live_on) {
        return true
      }
      return false
      // return Object.values(this.filters).some(value => 
      //   value !== "" && value !== null
      // )
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    customActions() {
      let actions = [];
      // 'Holders'
      if (
        partnerHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_HOLDER)
      ) {
        actions.push({
          label: this.$t("PARTNER.PROPERTY.TOKEN_HOLDERS"),
          key: PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_HOLDER.key,
          btnClass: "btn btn-none btn-main",
        });
      }

      return actions;
    },
  },
  methods: {
    onShowModalPickDate() {
      this.showModalPickDate = true;
    },

    onApply(dates) {
      this.filters.first_live_on = dates;
    },

    async onClickedCustomAction(data, index) {
      if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_HOLDER.key) {
        this.property = this.properties.data.find((e) => e.id == data);
        this.modalPropertyHolders = true;
      }
    },

    async getSpcs() {
      const response = await spcsService.getSpcs();
      if (response && response.data) {
        this.spcs = response.data;
      }
    },
    async getCategories() {
      this.categories = await categoryService.getCategories();
    },
    async getProperties(page = 1) {
      const filters = {
        ...this.filters,
        page,
      };

      const result = await propertiesService.getList(filters);
      if (result) {
        const { data } = result;
        data.map((property) => {
          property.type = property.category.name;
          property.bed = property.num_bed;
          property.bath = property.num_bath;
          property.spc = property.spc ? property.spc.company_name : "";
          property.location = property.location.name;
          property.address = property.metadata.address;
          property.date_time = moment(property.first_live_on).format('DD/MM/YYYY');
        });
        this.properties = result;
      }
    },
    async onChangePage(page) {
      await this.getProperties(page);
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getProperties();
      }, 200);
    },
  },
};
</script>
<style scoped>
</style>
