<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="roles.data" :columns="tableColumns" :show-edit-action="true" :show-delete-action="true"
                        @onModalDelete="onModalDelete" @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th width="100px">
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th width="10%">
                                    <b-input v-model="filters.guard_name"></b-input>
                                </th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="roles.total" align="right" v-model="roles.current_page" :total-rows="roles.total"
                        :per-page="roles.per_page" @change="onChangePage" aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-create-role :show="showModalCreate" :role="role" @on-close="onModalClose" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeleteRole" type="button" class="close font-weight-bold" aria-label="Close"
                    @click="modalDelete.show = false">×</button>
            </template>
            <p>Are you sure you want to delete this role?<strong style="color: red">{{ modalDelete.content }}</strong>
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeleteRole">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show = false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import rolesService from "../../services/admin/roles.service";
import ModalCreateRole from "./modals/ModalCreateRole.vue";

const tableColumns = ["Id", "Name", "Guard_Name"];

export default {
    components: {
        PaperTable,
        ModalCreateRole,
    },
    data() {
        return {
            roles: {},
            tableColumns: [...tableColumns],
            filters: {
                name: '',
                guard_name: '',
            },
            showModalCreate: false,
            role: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
        };
    },
    async mounted() {
        await this.getRoles(1);
    },
    watch: {
        'filters.name'() {
            this.searchTimeOut(1);
        },
        'filters.guard_name'() {
            this.searchTimeOut(1);
        }
    },
    methods: {

        async getRoles(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await rolesService.getList(filters);
            if (result && result.data) {
                this.roles = result;
            }
        },

        async onChangePage(page) {
            await this.getRoles(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getRoles(page);
            }, 400);
        },

        showAddModal() {
            this.role = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalCreate = false;
            if (success) {
                this.getRoles(this.roles.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.role = this.roles.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },

        async onDeleteRole() {
            if (this.modalDelete.id) {
                await rolesService.delete(this.modalDelete.id);
                this.modalDelete = {
                    show: false,
                    content: '',
                    id: null,
                };
                await this.getRoles(this.roles.current_page);
            }
        },
    }
};
</script>
<style scoped></style>
  