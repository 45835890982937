<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Role Name" label-for="key-input" :state="validateState('name')">
                <b-form-input id="key-input" v-model="form.name" :state="validateState('name')" required>
                </b-form-input>
            </b-form-group>
            <b-form-group label="Permissions">
                <div v-for="permission in permissions" :key="permission.id" aria-describedby="input-name-feedback">
                    <b-form-checkbox :id="permission.id.toString()" :value="permission.id" v-model="form.permission_ids">
                        {{ permission.name }}
                    </b-form-checkbox>
                </div>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import rolesService from "../../../services/admin/roles.service";
import permissionService from "../../../services/admin/permission.service";

export default {
    components: {
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        role: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                name: { required }
            }
        }
    },
    emits: ["on-close"],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {
                name: "",
                permission_ids: [],
            },
            showModal: false,
            permissions: [],
        };
    },

    async mounted() {
        await this.getPermissions();
    },

    watch: {
        show(value) {
            this.showModal = value;
        },
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        async getPermissions() {
            const result = await permissionService.getAll();
            if (result) {
                this.permissions = result;
            }
        },

        updateModal() {
            if (this.role != null) {
                this.form = {
                    name: this.role.name,
                    permission_ids: this.role.permissions.map((item) => item.id),
                    id: this.role.id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                name: "",
                permission_ids: [],
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            await this.handleSubmit();
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            await rolesService.createOrUpdate(this.form);
            this.$nextTick(() => {
                this.resetModal();
                this.$emit("on-close", true);
            });
        },
    },

    computed: {
        title() {
            return this.role != null ? "Update role" : "Add new role";
        },
    },
};
</script>

<style lang="scss" scoped></style>
