<template>
    <div v-if="isOpen">
        <div class="modal-video" @click="$emit('update:isOpen', false)" @keydown.esc="$emit('update:isOpen', false)">
            <div class="modal-video-body">
                <div class="modal-video-inner">
                    <div class="modal-video-movie-wrap" @click.stop="" :style="{ 'padding-bottom': paddingBottom }">
                        <iframe v-if="videoId" frameborder="0" height="100%" width="100%"
                            :src="fullVideoUrl" frameBorder='0' allowFullScreen tabIndex='-1' />
                        <video v-else :src="videoUrl" autoplay></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isOpen: Boolean,
        videoUrl: String,
        videoId: String,
        ratio: {
            type: String,
            default: '16:9'
        },
        youtube: {
            type: Object,
            default() {
                return {
                    autoplay: 1,
                    cc_load_policy: 1,
                    color: null,
                    controls: 0,
                    disablekb: 0,
                    enablejsapi: 0,
                    end: null,
                    fs: 1,
                    h1: null,
                    iv_load_policy: 1,
                    list: null,
                    listType: null,
                    loop: 0,
                    modestbranding: null,
                    origin: null,
                    playlist: null,
                    playsinline: null,
                    rel: 0,
                    showinfo: 1,
                    start: 0,
                    wmode: 'transparent',
                    theme: 'light',
                    mute: 0,
                }
            }
        },
    },
    data() {
        return {

        }
    },
    methods: {
        getPadding(ratio) {
            const arr = ratio.split(':')
            const width = Number(arr[0])
            const height = Number(arr[1])
            const padding = height * 100 / width
            return padding + '%'
        },
        getQueryString(obj) {
            let url = ''
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] !== null) {
                        url += key + '=' + obj[key] + '&'
                    }
                }
            }
            return url.substring(0, url.length - 1)
        },
    },
    computed: {
        paddingBottom() {
            return this.getPadding(this.ratio)
        },
        fullVideoUrl() {
            const query = this.getQueryString(this.youtube)
            return '//www.youtube.com/embed/' + this.videoId + '?' + query
        },
    },
}
</script>
<style lang="scss" scoped>
$animation-speed: 0.3s;
$animation-function: ease-out;
$backdrop-color: rgba(0, 0, 0, 0.7);
$modal-bg-color: #333;

@keyframes modal-video {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal-video-inner {
    from {
        transform: translate(0, 100px);
    }

    to {
        transform: translate(0, 0);
    }
}

.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop-color;
    z-index: 1000000;
    opacity: 1;
    animation-timing-function: $animation-function;
    animation-duration: $animation-speed;
    animation-name: modal-video;
    transition: opacity $animation-speed $animation-function;
}

.modal-video-body {
    max-width: 1100px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
}

.modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: $modal-bg-color;
    animation-timing-function: $animation-function;
    animation-duration: $animation-speed;
    animation-name: modal-video-inner;
    transform: translate(0, 0);
    transition: transform $animation-speed $animation-function;
    border: 2px solid white;
    border-radius: 20px;
    overflow: hidden;

    & video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>