<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="pb-2">
          <b-button size="sm" id="show-btn" @click="showAddModal()">
            <b-icon icon="plus-lg"></b-icon>
            Add new
          </b-button>
        </div>
        <div slot="raw-content" class="table-responsive">
          <paper-table :data="rentalDeductionTypes.data" :columns="tableColumns" :show-edit-action="true" :show-delete-action="true"
                       @onModalDelete="onModalDelete" @onModalEdit="onModalEdit">
            <template #theadSearch>
              <thead class="search">
              <th width="30px"></th>
              <th>
                <b-input v-model="filters.deduction_type"></b-input>
              </th>
              <th></th>
              <th></th>
              </thead>
            </template>
          </paper-table>
          <b-pagination v-if="rentalDeductionTypes.total" align="right" v-model="rentalDeductionTypes.current_page"
                        :total-rows="rentalDeductionTypes.total" :per-page="rentalDeductionTypes.per_page"
                        @change="onChangePage" aria-controls="my-table"></b-pagination>
        </div>
      </div>
    </div>
    <modal-create-rental-deduction-type :show="showModalCreate"
                                        :rentalDeductionType="rentalDeductionType" @on-close="onModalClose"/>
    <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
      <template #modal-header>
        <h5 class="modal-header-title mb-0">Delete confirm</h5>
        <button id="btn_closeModelDeleteDeductionType" type="button" class="close font-weight-bold" aria-label="Close"
                @click="modalDelete.show=false">×</button>
      </template>
      <p>Are you sure you want to delete this deduction type?</p>
      <template #modal-footer>
        <b-button variant="danger" size="sm" @click="onDeleteConfig">
          Delete
        </b-button>
        <b-button variant="primary" size="sm" @click="modalDelete.show=false">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import rentalDistributionService from "../../services/admin/rentalDistribution.service"
import ModalCreateRentalDeductionType from "./modals/ModalCreateRentalDeductionType.vue"

const tableColumns = ["Id", "Deduction_Type", "Description"]

export default {
  components: {
    PaperTable,
    ModalCreateRentalDeductionType,
  },
  data () {
    return {
      rentalDeductionTypes: {},
      tableColumns: [...tableColumns],
      filters: {
        deduction_type: "",
      },
      showModalCreate: false,
      rentalDeductionType: null,
      modalDelete: {
        show: false,
        content: '',
        id: null,
      },
    }
  },
  async mounted () {
    await this.getRentalDeductionTypes(1)
  },
  watch: {
    "filters.deduction_type" () {
      this.searchTimeOut(1)
    },
  },
  methods: {

    async getRentalDeductionTypes (page) {
      const filters = {
        ...this.filters,
        page,
      }
      const result = await rentalDistributionService.getRentalDeductionTypes(filters)
      if (result && result.data) {
        this.rentalDeductionTypes = result
      }
    },

    async onChangePage (page) {
      await this.getRentalDeductionTypes(page)
    },

    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getRentalDeductionTypes(page)
      }, 400)
    },

    showAddModal () {
      this.rentalDeductionType = null
      this.showModalCreate = true
    },

    onModalClose (success) {
      this.showModalCreate = false
      if (success) {
        this.getRentalDeductionTypes(this.rentalDeductionTypes.current_page)
      }
    },

    onModalDelete (data) {
      this.modalDelete = data
    },

    onModalEdit (data) {
      if (data) {
        this.rentalDeductionType = this.rentalDeductionTypes.data.find((e) => e.id === data)
        this.showModalCreate = true
      }
    },

    async onDeleteConfig () {
      if (this.modalDelete.id) {
        await rentalDistributionService.deleteRentalDeductionType(this.modalDelete.id)
        this.modalDelete = {
          show: false,
          content: '',
          id: null,
        }
        await this.getRentalDeductionTypes(this.rentalDeductionTypes.current_page)
      }
    }
  }
}
</script>
<style scoped>

</style>
