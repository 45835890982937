<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div slot="raw-content" class="table-responsive">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div class="d-flex flex-row">
                            <VueDatePicker class="mb-2" v-model="filters.date" model-type="dd/MM/yyyy" format="dd/MM/yyyy"
                                :range="true" style="width: 300px;" :enable-time-picker="false"
                                placeholder="Search date range" />
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            Export:
                            <download-csv ref="downloadCsv" class="ml-2" :data="dataToExport" :fields="downloadFields"
                                :labels="downloadLabels" :name="csvFileName">
                                <div></div>
                            </download-csv>
                            <div class="download-btn" @click="exportCsv">csv
                                <b-icon icon="download" scale="0.8" />
                            </div>
                            <export-excel ref="downloadExcel" class="ml-2" :data="dataToExport" :fields="excelDownloadFields" :name="excelFileName">
                                <div></div>
                            </export-excel>
                            <div class="download-btn" @click="exportExcel">xls
                                <b-icon icon="download" scale="0.8" />
                            </div>
                        </div>
                    </div>
                    <paper-table :data="withdrawals.data" :columns="tableColumns" :showEditAction="true"
                        @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th>
                                    <!-- <VueDatePicker v-model="filters.date" model-type="dd-MM-yyyy" format="dd-MM-yyyy" style="width: 150px;" /> -->
                                </th>
                                <th></th>
                                <th width="15%">
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th width="20%">
                                    <b-input v-model="filters.email"></b-input>
                                </th>
                                <th width="10%">
                                    <b-input v-model="filters.amount"></b-input>
                                </th>
                                <th></th>
                                <th>
                                    <b-form-select v-model="filters.status" text-field="name" value-field="id">
                                        <template #first>
                                            <b-form-select-option :value="null">Select status</b-form-select-option>
                                        </template>
                                        <template v-for="status in statuses">
                                            <b-form-select-option :value="status">
                                                {{ status }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="withdrawals.total" align="right" v-model="withdrawals.current_page"
                        :total-rows="withdrawals.total" :per-page="withdrawals.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <b-modal v-model="showModal" size="md" title="Withdrawal details" centered hide-header-close
            @hide="onModalClose">
            <div class="d-flex flex-row justify-content-between">
                <p class="title">Date</p>
                <p class="value">{{ date }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">Transaction ID</p>
                <p class="value">{{ transactionId }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">Name</p>
                <p class="value">{{ name }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">Email</p>
                <p class="value">{{ email }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">User Balance</p>
                <p class="value">IDR<strong>{{ balance }}</strong></p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.WITHDRAWAL_AMOUNT') }}</p>
                <p class="value">IDR<strong>{{ amount }}</strong></p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.WITHDRAWAL_FEE') }}</p>
                <p class="value">IDR<strong>{{ fee }}</strong></p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.TOTAL') }}</p>
                <p class="value">IDR<strong>{{ total }}</strong></p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.BANK_NAME') }}</p>
                <p class="value">{{ bankName }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.ACCOUNT_HOLDER') }}</p>
                <p class="value">{{ accountHolder }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.ACCOUNT_NUMBER') }}</p>
                <p class="value">{{ accountNumber }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title">{{ $t('WITHDRAWALS.NOTE') }}</p>
                <p class="value">{{ note }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="title mr-5">{{ $t('WITHDRAWALS.STATUS') }}</p>
                <b-form-select v-model="selectedStatus" :options="isPending ? allowedChangestatuses : statuses"
                    :disabled="!isPending">
                </b-form-select>
            </div>
            <div class="d-flex flex-row justify-content-between mt-2">
                <p class="title mr-5">{{ $t('WITHDRAWALS.REASON') }}</p>
                <p class="value">{{ failedReason }}</p>
            </div>
            <div v-if="selectedStatus === 'REJECTED' && !failedReason" class="d-flex flex-row justify-content-between mt-2">
                <label class="title">{{ $t('WITHDRAWALS.REASON') }}</label>
                <textarea v-model="reason" placeholder="Input reason" style="width:78%"
                    :disabled="!isPending"></textarea>
            </div>
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button :disabled="selectedStatus === 'REJECTED' && reason === ''"
                    v-if="isPending && selectedStatus !== 'PENDING'" size="sm" variant="success" @click="onUpdate">
                    Update
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import moment from 'moment';
import { PaperTable } from "../../components";
import withdrawalsService from "../../services/admin/withdrawals.service";
import { numberWithCommas } from '../../helpers/common';

const tableColumns = ["Id", "Date", "Transaction ID", "Name", "Email", "Amount", "Description", "Status"];

export default {
    components: {
        PaperTable,
    },
    data() {
        return {
            withdrawals: {},
            tableColumns: [...tableColumns],
            filters: {
                date: "",
                name: '',
                email: '',
                amount: '',
                status: '',
            },
            showModal: false,
            withdrawal: null,
            statuses: ['PENDING', 'APPROVED', 'CANCELLED', 'REJECTED', 'COMPLETED', 'FAILED'],
            selectedStatus: '',
            allowedChangestatuses: ['PENDING', 'APPROVED', 'REJECTED'],
            reason: '',
            needUpdate: false,
            downloadFields: ['id', 'date', 'external_id', 'name', 'email', 'amount', 'description', 'status'],
            downloadLabels: {
                id: 'ID',
                date: 'Date',
                external_id: 'Transaction ID',
                name: 'Name',
                email: 'Email',
                amount: 'Amount',
                description: 'Description',
                status: 'Status'
            },
            excelDownloadFields: {
                'ID': 'id',
                'Date': 'date',
                'Transaction ID': 'external_id',
                'Name': 'name',
                'Email': 'email',
                'amount': 'amount',
                'Description': 'description',
                'Status': 'status'
            },
            dataToExport: [],
        };
    },
    async mounted() {
        await this.getWithdrawals(1);
    },
    watch: {
        'filters.name'() {
            this.searchTimeOut(1);
        },
        'filters.email'() {
            this.searchTimeOut(1);
        },
        'filters.amount'() {
            this.searchTimeOut(1);
        },
        'filters.status'() {
            this.searchTimeOut(1);
        },
        'filters.date'(value) {
            if (value && value.length === 2) {
                this.filters.start_date = value[0]
                this.filters.end_date = value[1]
                this.searchTimeOut(1);
            } else {
                this.filters.start_date = ''
                this.filters.end_date = ''
                this.searchTimeOut(1);
            }
            this.dataToExport = []
        },
    },
    methods: {
        async getWithdrawals(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await withdrawalsService.getWithdrawals(filters);

            if (result && result.data) {
                result.data.map(item => {
                    item.date = moment(item.created_at).format("DD/MM/YYYY");
                    item.transaction_id = item.external_id
                })
                this.withdrawals = result;
            }
        },

        async onChangePage(page) {
            await this.getWithdrawals(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getWithdrawals(page);
            }, 400);
        },

        onModalClose() {
            this.showModal = false;
            if (this.needUpdate) {
                this.getWithdrawals(this.withdrawals.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.withdrawal = this.withdrawals.data.find((e) => e.id === data);
                this.selectedStatus = this.withdrawal.status;
                this.showModal = true;
                this.needUpdate = false;
            }
        },

        async onUpdate() {
            if (this.selectedStatus === 'APPROVED') {
                const res = await withdrawalsService.approveWithdrawal({
                    request_id: this.withdrawal.id,
                });
                if (res && res.data) {
                    this.withdrawal = res.data;
                    this.needUpdate = true;

                }
            } else if (this.selectedStatus === 'REJECTED' && this.reason !== '') {
                const res = await withdrawalsService.rejectWithdrawal({
                    request_id: this.withdrawal.id,
                    reason: this.reason,
                });
                if (res && res.data) {
                    this.withdrawal = res.data;
                    this.needUpdate = true;
                }
            }
        },

        async exportCsv() {
            this.dataToExport = []
            await this.getExportData(this.withdrawals?.current_page || 1)
            this.$refs.downloadCsv.generate()
        },

        async exportExcel() {
            this.dataToExport = []
            await this.getExportData(this.withdrawals?.current_page || 1)
            this.$refs.downloadExcel.generate()
        },

        async getExportData(page = 1) {
            const filters = {
                ...this.filters,
                page,
                per_page: this.withdrawals?.total || 1000,
            };
            const result = await withdrawalsService.getWithdrawals(filters);

            if (result && result.data) {
                result.data.map(item => {
                    item.date = moment(item.created_at).format("DD/MM/YYYY");
                })
                
                this.dataToExport = result.data
                await this.$nextTick()
            }
        },
    },

    computed: {
        isPending() {
            return this.withdrawal && this.withdrawal.status === 'PENDING';
        },

        date() {
            return this.withdrawal && this.withdrawal.created_at ? moment(this.withdrawal.created_at).format("DD/MM/YYYY") : '';
        },

        transactionId() {
            return this.withdrawal && this.withdrawal.external_id;
        },

        name() {
            return this.withdrawal && this.withdrawal.name;
        },

        email() {
            return this.withdrawal && this.withdrawal.email;
        },

        balance() {
            return this.withdrawal && numberWithCommas(this.withdrawal.balance);
        },

        amount() {
            return this.withdrawal && numberWithCommas(this.withdrawal.amount);
        },

        fee() {
            return this.withdrawal && numberWithCommas(this.withdrawal.fee);
        },

        total() {
            return this.withdrawal && numberWithCommas(this.withdrawal.amount + this.withdrawal.fee);
        },

        bankName() {
            return this.withdrawal && this.withdrawal.bank_name;
        },

        accountHolder() {
            return this.withdrawal && this.withdrawal.account_holder_name;
        },

        accountNumber() {
            return this.withdrawal && this.withdrawal.account_number_masked;
        },

        note() {
            return this.withdrawal && this.withdrawal.description;
        },

        failedReason() {
            return this.withdrawal && this.withdrawal.reason;
        },

        fileName() {
            const name = this.filters.name ? `_${this.filters.name}` : '_withdraw_history'
            let date = this.filters.start_date ? `_${this.filters.start_date}` : '';
            if (this.filters.end_date) {
                date = `${date}-${this.filters.end_date}`
            }
            return `export${name}${date}_${moment().format('YYYYMMDDHHmmss')}`;
        },

        csvFileName() {
            return `${this.fileName}.csv`;
        },

        excelFileName() {
            return `${this.fileName}.xls`;
        },
    },
};
</script>
<style lang="scss" scoped>
.download-btn {
    cursor: pointer;
    color: white;
    background-color: black;
    padding: 4px 10px 6px 10px;
    border-radius: 6px;
    &:hover{
        opacity: .7;
    }
}
</style>

