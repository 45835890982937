import serverApi from "../../utils/serverApi";

const rolesService = {
  getList: (data) => serverApi({ uri: "admin/roles", method: "GET", data }),

  getAll: (data) => serverApi({ uri: "admin/roles/all", method: "GET", data }),

  create: (data) => serverApi({ uri: "admin/roles", method: "POST", data }),

  update: (id, data) => serverApi({ uri: `admin/roles/${id}`, method: "PUT", data }),

  delete: (id) => serverApi({ uri: `admin/roles/${id}`, method: "DELETE" }),

  createOrUpdate: (data) =>
    serverApi({ uri: "admin/roles/create-or-update", method: "POST", data }),
};

export default rolesService;
