import serverApi from "../utils/serverApi"

const contractsService = {

  getContractStatus: (uuid) => serverApi({ uri: `contracts/get-status/${uuid}`, method: "GET" }),

  getContractPreview: data => serverApi({ uri: "contracts/transactions/get-preview", data, method: "POST" }),

  getContractPreviewForExisting: data => serverApi({
    uri: "contracts/transactions/get-preview-for-existing",
    data,
    method: "POST"
  }),

  getContractDocuments: data => serverApi({ uri: "contracts/transactions/get-documents", data, method: "POST" }),

  createContractForExisting: data => serverApi({
    uri: "contracts/transactions/create-for-existing",
    data,
    method: "POST"
  }),

  getContractTemplates: data => serverApi({ uri: "contracts/templates", data, method: "GET" }),

  createContractTemplate: async data => {
    return await serverApi({ uri: "contracts/templates/create", data, method: "POST" })
  },

  updateContractTemplate: async data => {
    return await serverApi({ uri: `contracts/templates/update`, data, method: "PUT" })
  },

  deleteContractTemplate: async id => serverApi({ uri: `contracts/templates/delete/${id}`, method: "DELETE" }),
}

export default contractsService
