<template>
  <div v-if="isShowLocationReminder" id="locationReminder" class="localReminderWrapper">
    <div class="localReminderContainer">
      <div class="localReminderLabelBox">
        <p class="font-semibold">
          {{ $t("HEADER.LOCATION_SUGGEST", {country: geoLocation.country}) }}
        </p>
      </div>
      <div class="localReminderButtonBox">
        <div class="btn stayHereButton font-wide-bold ml-1 mr-1" @click="stayHere">
          {{ $t("HEADER.LOCATION_STAY_HERE") }}
        </div>
        <div class="btn switchButton font-wide-bold ml-1 mr-1" @click="switchLocation">
          {{ $t("HEADER.LOCATION_SWITCH") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { FOREIGNER, INDO } from "../../constants/constants"

export default {
  components: {},
  data () {
    return {}
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    geoLocation () {
      return this.$store.getters.geoLocation
    },
    isShowLocationReminder () {
      if (!this.$store.getters.stayAtCurrentLocation
        && (!this.$route.path.includes("admin"))) {
        if (this.userProfile && this.userProfile.phone) {
          return this.userProfile.phone.startsWith(INDO.CALLING_CODE)
            ? this.$route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)
            : !this.$route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)
        } else if (this.geoLocation) {
          let browserLanguage = window.navigator.language
          return (!browserLanguage || browserLanguage.toLowerCase() === "id" || browserLanguage.toLowerCase().startsWith("id-"))
          && (this.geoLocation.country_code === INDO.COUNTRY_CODE || this.geoLocation.iso_country_code === INDO.ISO_COUNTRY_CODE)
            ? this.$route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)
            : !this.$route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)
        }
      }
      return false
    },
  },
  watch: {
    isShowLocationReminder (value) {
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"))
      })
    },
  },
  methods: {
    async stayHere () {
      await store.dispatch("setStayAtCurrentLocation", true)
    },
    async switchLocation () {
      const currentPath = this.$route.path
      const currentQuery = this.$route.query
      const currentParams = this.$route.params
      if (this.userProfile && this.userProfile.phone) {
        if (this.userProfile.phone.startsWith(INDO.CALLING_CODE)) {
          if (currentPath.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
            await store.dispatch("setSwitchToNewLocation", true)
            this.$router.push({
              path: currentPath.substring(FOREIGNER.ROUTE_PATH_PREFIX.length) || "/",
              query: currentQuery,
              params: currentParams
            })
          }
        } else {
          if (!currentPath.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
            await store.dispatch("setSwitchToNewLocation", true)
            this.$router.push({
              path: `${FOREIGNER.ROUTE_PATH_PREFIX}${currentPath}`,
              query: currentQuery,
              params: currentParams
            })
          }
        }
      } else if (this.geoLocation) {
        let browserLanguage = window.navigator.language
        if ((!browserLanguage || browserLanguage.toLowerCase() === "id" || browserLanguage.toLowerCase().startsWith("id-"))
          && (this.geoLocation.country_code === INDO.COUNTRY_CODE || this.geoLocation.iso_country_code === INDO.ISO_COUNTRY_CODE)) {
          if (currentPath.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
            await store.dispatch("setSwitchToNewLocation", true)
            this.$router.push({
              path: currentPath.substring(FOREIGNER.ROUTE_PATH_PREFIX.length) || "/",
              query: currentQuery,
              params: currentParams
            })
          }
        } else {
          if (!currentPath.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
            await store.dispatch("setSwitchToNewLocation", true)
            this.$router.push({
              path: `${FOREIGNER.ROUTE_PATH_PREFIX}${currentPath}`,
              query: currentQuery,
              params: currentParams
            })
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.localReminderWrapper {
  width: 100%;
  padding: 5px 0;
  background: var(--primary-background-color);
}

.localReminderContainer {
  display: flex;
  width: 100%;
  max-width: 1236px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.localReminderContainer .localReminderLabelBox {
  width: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: -20px;
  margin-right: 24px;
}

.localReminderContainer .localReminderLabelBox p {
  font-size: 15px;
}

.localReminderContainer .localReminderButtonBox {
  display: flex;
  align-items: center
}

.localReminderContainer .localReminderButtonBox .btn {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
}

.localReminderContainer .localReminderButtonBox .stayHereButton {
  border: 1px solid var(--primary-darker-color);
  color: var(--primary-darker-color);
  background: white;
  margin-inline-end: 8px;
}

.localReminderContainer .localReminderButtonBox .stayHereButton:hover {
  background-color: var(--primary-background-hover-color);
  color: var(--primary-darker-color)
}

.localReminderContainer .localReminderButtonBox .switchButton {
  color: #ffffff;
  background: #006767;
}

.localReminderContainer .localReminderButtonBox .switchButton:hover {
  background: var(--primary-hover-color)
}

@media screen and (max-width: 1310px) {
  .localReminderContainer .localReminderLabelBox {
    margin-left: 15px;
    white-space: normal;
  }
}

@media screen and (max-width: 960px) {
  .localReminderWrapper {
    padding: 2px 16px 16px 16px
  }

  .localReminderContainer {
    flex-direction: column
  }

  .localReminderContainer .localReminderLabelBox {
    margin-left: 2px;
    white-space: normal;
  }

  .localReminderContainer .localReminderButtonBox {
    margin-top: 5px
  }

  .localReminderContainer .localReminderButtonBox .btn {
    font-size: 15px
  }
}

@media screen and (max-width: 730px) {
  .localReminderContainer .localReminderLabelBox {
    margin-left: 2px;
    white-space: normal;
  }

  .localReminderContainer .localReminderButtonBox {
    flex-direction: column
  }

  .localReminderContainer .localReminderButtonBox .btn:first-child {
    margin-bottom: 8px
  }

  .localReminderContainer .localReminderButtonBox .stayHereButton {
    margin-inline-end: 0;
    width: 100%
  }

  .localReminderContainer .localReminderButtonBox .switchButton {
    width: 100%
  }
}
</style>
