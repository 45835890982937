import serverApi from "../utils/serverApi"

const spcsService = {

  getSpcs: data => serverApi({ uri: "spcs", data, method: "GET" }),

  createSpc: async data => {
    return await serverApi({ uri: "spcs/create", data, method: "POST" })
  },

  updateSpc: async data => {
    return await serverApi({ uri: `spcs/update`, data, method: "PUT" })
  },

  deleteSpc: async id => serverApi({ uri: `spcs/delete/${id}`, method: "DELETE" }),
}

export default spcsService
