<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <b-button class="btn btn-none btn-main" style="padding: 5px 20px;" size="sm" id="property-pick-date" @click="onShowModalPickDate()">
                    {{ $t('PARTNER.PICK_DATE') }}
                    </b-button>
                </div>
                <div class="mb-3">
                    <div class="cls-custom-filters-applied d-flex justify-content-start align-items-center">
                    <p class="cls-applied-label">Filters: </p>
                    <p v-if="!hasFiltered" class="cls-applied-label no-filtered">No filters</p>
                    <div v-if="filters.from_created_at || filters.to_created_at" class="cls-applied-item cls-pick-date">
                        <span v-if="filters.from_created_at">{{ filters.from_created_at }}</span>
                        <span v-if="filters.to_created_at"> - {{ filters.to_created_at }}</span>
                        <button class="btn-remove-filter" @click="filters.created_at=null">
                            <b-icon icon="x-lg" style="color: gray;" scale="1.6"></b-icon>
                        </button>
                    </div>
                    </div>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="items" :columns="tableColumns" :showEditAction="false"
                        :customActionTitles="customActions"
                        @onModalEdit="onModalEdit" @onCustomAction="onClickedCustomAction">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th>
                                    <b-input v-model="filters.uuid"></b-input>
                                </th>
                                <th>
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th>
                                    <b-input v-model="filters.email"></b-input>
                                </th>
                                <th>
                                    <b-input v-model="filters.phone"></b-input>
                                </th>
                                <th width="30px"></th>
                                <th>
                                    <b-input v-model="filters.status"></b-input>
                                </th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="users.total" align="left" class="mt-3" v-model="users.current_page" :total-rows="users.total"
                        :per-page="users.per_page" @change="onChangePage" aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-user-assets :show="modalUserAssets" :user="user" @on-close="onCloseUserAssets" />
        <modal-pick-date :show="showModalPickDate" @on-close="showModalPickDate = false" @on-apply="onApply"></modal-pick-date>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import userPartnerService from "../../services/partner/user.service";
import ModalUserAssets from "./modals/ModalUserAssets.vue";
import { numberWithCommas, userHasPermission } from "../../helpers/common";
import { USER_CUSTOM_ACTIONS } from "../../constants/constants";
import { USER_PERMISSIONS } from "../../constants/permissions";
import { partnerHasPermission } from '../../helpers/partner';
import ModalPickDate from "./modals/ModalPickDate.vue"

const tableColumns = ["Id", "Uuid", "Name", "Email", "Phone", "Balance", "Status", "Created_at"];

export default {
    components: {
        PaperTable,
        ModalUserAssets,
        ModalPickDate
    },
    data() {
        return {
            users: {},
            items: [],
            tableColumns: [...tableColumns],
            filters: {
                uuid: '',
                name: '',
                email: '',
                phone: '',
                status: '',
                created_at: null,
                from_created_at: '',
                to_created_at: ''
            },
            showModal: false,
            user: null,
            modalUserAssets: false,
            showModalPickDate: false,
        };
    },
    async mounted() {
        await this.getUsers(1);
    },
    watch: {
        'filters.uuid'() {
            this.searchTimeOut();
        },
        'filters.name'() {
            this.searchTimeOut();
        },
        'filters.email'() {
            this.searchTimeOut();
        },
        'filters.phone'() {
            this.searchTimeOut();
        },
        'filters.status'() {
            this.searchTimeOut();
        },
        async "filters.created_at"(value) {
            this.filters.from_created_at = null
            this.filters.to_created_at = null

            if (value && value[0]) {
                this.filters.from_created_at = value[0]
            }
            if (value && value[1]) {
                this.filters.to_created_at = value[1]
            }
            this.searchTimeOut()
        },
    },
    computed: {
        hasFiltered() {
            if (this.filters.created_at) {
                return true
            }
            return false
        },
        customActions() {
            let actions = []
            if (partnerHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_OWN_BY_USER)) {
                actions.push({
                    ...USER_CUSTOM_ACTIONS.VIEW_USER_ASSETS,
                    label: this.$t('PARTNER.USER.VIEW_USER_ASSETS'),
                    btnClass: 'btn btn-none btn-main'
                })
            }

            return actions
        },
    },
    methods: {
        async getUsers(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await userPartnerService.getList(filters);
            if (result && result.data) {
                this.users = result;
                this.items = result.data.map(e => {
                    return {
                        'id': e.id,
                        'uuid': e.uuid,
                        'name': e.name,
                        'email': e.email,
                        'phone': e.phone,
                        'balance': `IDR${numberWithCommas(e.balance)}`,
                        'status': e.status,
                        'created_at': e.created_at,
                        // 'notification_status': this.getSubscriptionStatus(e.notification_sub_status),
                    };
                });
            }
        },

        onShowModalPickDate() {
            this.showModalPickDate=true
        },

        onCloseUserAssets() {
            this.modalUserAssets = false
            this.user = null
        },

        onApply(dates) {
            this.filters.created_at = dates
        },

        async onChangePage(page) {
            await this.getUsers(page);
        },

        searchTimeOut(page = 1) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getUsers(page);
            }, 400);
        },

        onClickedCustomAction(data, key) {
            const user = this.users.data.find((e) => e.id === data);
            if (key === USER_CUSTOM_ACTIONS.VIEW_USER_ASSETS.key) {
                this.user = user;
                this.modalUserAssets = true;
            }
        },
    }
};
</script>
<style scoped></style>
