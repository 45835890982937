import serverApi from "../utils/serverApi";

const pinService = {
  checkPin: () =>
    serverApi({ uri: "pin/check-pin", method: "GET", isLoading: false }),

  attemptsLeft: () =>
    serverApi({ uri: "pin/attempts-left", method: "GET", isLoading: false }),

  setPin: (data) => serverApi({ uri: "pin/set-pin", method: "PUT", data }),

  changePin: (data) =>
    serverApi({ uri: "pin/change-pin", method: "PUT", data }),

  validatePin: (data) =>
    serverApi({
      uri: "pin/validate",
      method: "POST",
      data,
      isManualErrorHandling: true,
    }),

  forgotPin: (data) =>
    serverApi({
      uri: "pin/forgot-pin",
      method: "POST",
      data,
    }),

  resetPin: (data) =>
    serverApi({
      uri: "pin/reset-pin",
      method: "PUT",
      data,
    }),
};

export default pinService;
