<template>
  <b-modal v-model="showModal" id="modal-prevent-closing"
           ref="modal" @hidden="$emit('on-close')" hide-footer>
    <template #modal-header>
      <p class="font-22 font-weight-bold m-0">{{ $t("ASSETS.LOCKED_TOKENS_OF", { propertyName: propertyName }) }}</p>
      <button id="btn_closeModalLockedToken" type="button" class="close font-weight-bold" aria-label="Close" @click="$emit('on-close')">×</button>
    </template>
    <div v-for="(item, index) in rows" class="item d-flex flex-row align-items-center">
      <p class="m-0 mr-2 font-weight-bold font-18 text-right" style="width: 20px">{{ index + 1 }}.</p>
      <div class="ml-3">
        <p class="font-weight-bold m-0 font-18">
          {{ item.num_of_tokens }} {{ item.num_of_tokens > 1 ? $t("common.TOKENS") : $t("common.TOKEN") }}
        </p>
        <p class="m-0 font-14">
          {{ $t("ASSETS.LOCKED_UNTIL") }}: <span class="font-12 font-weight-bold">{{ item.lock_until }}</span>
        </p>
      </div>
    </div>
    <p class="text-center" v-if="items && !items.length">{{ $t("ASSETS.THIS_PROPERTY_HAS_NO_LOCKED_TOKENS") }}</p>
  </b-modal>
</template>

<script>

import accountService from "../services/account.service"

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    propertyUuid: {
      type: String,
    },
    propertyName: {
      type: String,
    }
  },
  emits: ["on-close"],
  data () {
    return {
      showModal: false,
      cachedPropertyUuid: null,
      items: null,
    }
  },

  watch: {
    show (value) {
      this.showModal = value
    },
    propertyUuid (value) {
      if (this.cachedPropertyUuid !== value) {
        this.items = null
        this.getLockedTokens()
        this.cachedPropertyUuid = value
      }
    }
  },

  methods: {
    async getLockedTokens () {
      const response = await accountService.getLockedTokens(this.propertyUuid)
      console.log(response)
      if (response && response.data) {
        this.items = response.data || []
      }
    }
  },

  computed: {
    rows () {
      return this.items || []
    }
  },
}
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  padding: 10px 20px 10px 20px;
  border: 1px solid rgb(230, 230, 230);
  background-color: white;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 5px 15px rgba(7, 55, 99, 0.16);
  box-shadow: 0 5px 15px rgba(7, 55, 99, 0.16);
}
</style>
