<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div slot="raw-content" class="table-responsive">
          <paper-table :data="sellTokenRequests.data" :columns="tableColumns" :showEditAction="true"
                       @onModalEdit="onModalEdit">
            <template #theadSearch>
              <thead class="search">
              <th width="30px"></th>
              <th>
                <b-input v-model="filters.email"></b-input>
              </th>
              <th>
                <b-input v-model="filters.property_name"></b-input>
              </th>
              <th>
                <b-input v-model="filters.num_of_tokens"></b-input>
              </th>
              <th>
                <b-input v-model="filters.received_amount"></b-input>
              </th>
              <th>
                <b-input v-model="filters.fee"></b-input>
              </th>
              <th>
                <b-form-select v-model="filters.status" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select status</b-form-select-option>
                  </template>
                  <template v-for="status in statuses">
                    <b-form-select-option :value="status">
                      {{ status }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th></th>
              </thead>
            </template>
          </paper-table>
          <b-pagination v-if="sellTokenRequests.total" align="right" v-model="sellTokenRequests.current_page"
                        :total-rows="sellTokenRequests.total" :per-page="sellTokenRequests.per_page"
                        @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" size="md" title="Sell token request details" centered hide-header-close
             @hide="onModalClose">

      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.UUID") }}</p>
        <p class="value">{{ uuid }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.ADMIN_REQUEST_ID") }}</p>
        <p class="value">{{ adminRequestId }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.NAME") }}</p>
        <p class="value">{{ name }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.EMAIL") }}</p>
        <p class="value">{{ email }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.PROPERTY_NAME") }}</p>
        <p class="value">{{ propertyName }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.NUM_OF_TOKENS") }}</p>
        <p class="value">{{ numOfTokens }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.AMOUNT") }}</p>
        <p class="value">{{ amount }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.RECEIVED_AMOUNT") }}</p>
        <p class="value">{{ receivedAmount }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.FEE") }}</p>
        <p class="value">{{ fee }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.FEE_PERCENT") }}</p>
        <p class="value">{{ feePercent }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.DESCRIPTION") }}</p>
        <p class="value">{{ description }}</p>
      </div>

      <div class="d-flex flex-row justify-content-between">
        <p class="title mr-5">{{ $t("SELL_TOKEN.STATUS") }}</p>
        <b-form-select v-model="selectedStatus" :options="isPending ? allowedChangeStatuses : statuses"
                       :disabled="!isPending">
        </b-form-select>
      </div>
      <div v-if="!isPending" class="d-flex flex-row justify-content-between mt-2">
        <p class="title mr-5">{{ $t("SELL_TOKEN.REASON") }}</p>
        <p class="value">{{ failedReason }}</p>
      </div>
      <div v-if="selectedStatus === 'REJECTED' && !failedReason" class="d-flex flex-row justify-content-between mt-2">
        <label class="title">{{ $t("SELL_TOKEN.REASON") }}</label>
        <textarea v-model="reason" placeholder="Input reason" style="width:78%"
                  :disabled="!isPending"></textarea>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button :disabled="selectedStatus === statuses.REJECTED && reason === ''"
                  v-if="isPending && selectedStatus !== statuses.PENDING"
                  size="sm" variant="success" @click="onUpdate">
          Update
        </b-button>
        <b-button size="sm" variant="primary" @click="ok()">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import sellTokensService from "../../services/sellTokens.service"
import { STATUS } from "@/constants/constants"
import { numberWithCommas } from "@/helpers/common"

const tableColumns = ["Id", "Email", "Property_Name", "Num_Of_Tokens", "Received_Amount", "Fee", "Status", "created_at"]

export default {
  components: {
    PaperTable,
    STATUS,
  },
  data () {
    return {
      sellTokenRequests: {},
      tableColumns: [...tableColumns],
      filters: {
        email: "",
        property_name: "",
        num_of_tokens: "",
        received_amount: "",
        fee: "",
        status: "",
      },
      showModal: false,
      sellTokenRequest: null,
      selectedStatus: "",
      statuses: [STATUS.PENDING, STATUS.APPROVED, STATUS.CANCELLED, STATUS.REJECTED],
      allowedChangeStatuses: [STATUS.PENDING, STATUS.APPROVED, STATUS.REJECTED],
      reason: "",
      needUpdate: false,
    }
  },
  async mounted () {
    await this.getSellTokenRequests(1)
  },
  watch: {
    "filters.email" () {
      this.searchTimeOut(1)
    },
    "filters.property_name" () {
      this.searchTimeOut(1)
    },
    "filters.num_of_tokens" () {
      this.searchTimeOut(1)
    },
    "filters.received_amount" () {
      this.searchTimeOut(1)
    },
    "filters.fee" () {
      this.searchTimeOut(1)
    },
    "filters.status" () {
      this.searchTimeOut(1)
    }
  },
  methods: {

    async getSellTokenRequests (page) {
      const filters = {
        ...this.filters,
        page,
      }
      const result = await sellTokensService.getSellTokenRequests(filters)
      if (result && result.data) {
        this.sellTokenRequests = result
      }
    },

    async onChangePage (page) {
      await this.getSellTokenRequests(page)
    },

    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getSellTokenRequests(page)
      }, 400)
    },

    onModalClose () {
      this.showModal = false
      if (this.needUpdate) {
        this.getSellTokenRequests(this.sellTokenRequests.current_page)
      }
    },

    onModalDelete (data) {
      this.modalDelete = data
    },

    onModalEdit (data) {
      if (data) {
        this.sellTokenRequest = this.sellTokenRequests.data.find((e) => e.id === data)
        this.selectedStatus = this.sellTokenRequest.status
        this.showModal = true
        this.needUpdate = false
      }
    },

    async onUpdate () {
      if (this.selectedStatus === "APPROVED") {
        const res = await sellTokensService.approveSellTokenRequest({
          request_uuid: this.sellTokenRequest.uuid,
        })
        if (res && res.sell_token_request) {
          this.sellTokenRequest = res.sell_token_request
          this.needUpdate = true

        }
      } else if (this.selectedStatus === STATUS.REJECTED && this.reason !== "") {
        const res = await sellTokensService.rejectSellTokenRequest({
          request_uuid: this.sellTokenRequest.uuid,
          reason: this.reason,
        })
        if (res && res.sell_token_request) {
          this.sellTokenRequest = res.sell_token_request
          this.needUpdate = true
        }
      }
    },
  },

  computed: {
    isPending () {
      return this.sellTokenRequest && this.sellTokenRequest.status === STATUS.PENDING
    },

    uuid () {
      return this.sellTokenRequest && this.sellTokenRequest.uuid
    },

    adminRequestId () {
      return this.sellTokenRequest && this.sellTokenRequest.admin_request_id
    },

    name () {
      return this.sellTokenRequest && this.sellTokenRequest.name
    },

    email () {
      return this.sellTokenRequest && this.sellTokenRequest.email
    },

    propertyName () {
      return this.sellTokenRequest && this.sellTokenRequest.property_name
    },

    numOfTokens () {
      return this.sellTokenRequest && numberWithCommas(this.sellTokenRequest.num_of_tokens)
    },

    amount () {
      return this.sellTokenRequest && numberWithCommas(this.sellTokenRequest.amount)
    },

    receivedAmount () {
      return this.sellTokenRequest && numberWithCommas(this.sellTokenRequest.received_amount)
    },

    fee () {
      return this.sellTokenRequest && numberWithCommas(this.sellTokenRequest.fee)
    },

    feePercent () {
      return this.sellTokenRequest && numberWithCommas(this.sellTokenRequest.fee_percent) + "%"
    },

    description () {
      return this.sellTokenRequest && this.sellTokenRequest.description
    },

    failedReason () {
      return this.sellTokenRequest && this.sellTokenRequest.reason
    },
  },
}
</script>
<style>

</style>

