<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="locations.data" :columns="tableColumns" :showEditAction="true"
                        @onModalDelete="onModalDelete" @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th>
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="locations.total" align="right" v-model="locations.current_page"
                        :total-rows="locations.total" :per-page="locations.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-create-location :show="showModalCreate" :location="location" @on-close="onModalClose" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeleteLocation" type="button" class="close font-weight-bold" aria-label="Close"
                    @click="modalDelete.show=false">×</button>
            </template>
            <p>Are you sure you want to delete this location?</p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeleteConfig">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show=false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import locationService from "../../services/admin/location.service";
import ModalCreateLocation from "./modals/ModalCreateLocation.vue";

const tableColumns = ["Id", "Name"];

export default {
    components: {
        PaperTable,
        ModalCreateLocation,
    },
    data() {
        return {
            locations: {},
            tableColumns: [...tableColumns],
            filters: {
                name: '',
            },
            showModalCreate: false,
            location: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
        };
    },
    async mounted() {
        await this.getLocations(1);
    },
    watch: {
        'filters.name'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getLocations(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await locationService.getLocations(filters);
            if (result && result.data) {
                this.locations = result;
            }
        },

        async onChangePage(page) {
            await this.getLocations(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getLocations(page);
            }, 400);
        },

        showAddModal() {
            this.location = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalCreate = false;
            if (success) {
                this.getLocations(this.locations.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.location = this.locations.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },

        async onDeleteConfig() {
            if (this.modalDelete.id) {
                await locationService.delete(this.modalDelete.id);
                this.modalDelete = {
                    show: false,
                    content: '',
                    id: null,
                };
                await this.getLocations(this.locations.current_page);
            }
        }
    }
};
</script>
<style scoped>

</style>
  