<template>
    <b-tooltip v-if="shouldShow && show" variant="secondary" :target="tooltipId" triggers="hover" placement="top">
        {{ tooltipMessage }}
    </b-tooltip>
</template>
<script>
import store from "../store/store";
import {  exchange } from "../helpers/common";

export default {
    data() {
        return {
            show: true
        }
    },
    props: {
        tooltipId: {
            type: String,
            default: null,
        },
        value: {
            type: Number,
            default: 0,
        },

        showOthers: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        // Fix issue display value does not get updated
        value(newValue) {
            this.show = false
            setTimeout(() => {
                this.show = true
            }, 100)
        }
    },

    computed: {
        shouldShow() {
            const user = store.state.userProfile;
            if (user && user.preferred_currency) {
                return user.preferred_currency !== 'IDR'
            }
            return false
        },

        tooltipMessage() {
            if (this.showOthers) {
                return this.$t('account.ARE_REFERENCES_ONLY', { value: exchange(this.value) })
            }
            return this.$t('account.OTHER_CURRENCIES_ARE_REFERENCES_ONLY', { value: exchange(this.value, 0, false, 'IDR') })
        },
    },
}
</script>