import serverApi from "../utils/serverApi";

const twofaService = {
  check2FAStatus: () => serverApi({ uri: "twofa/status", method: "GET" }),

  getAuthCode: () => serverApi({ uri: "twofa/auth-code", method: "GET" }),

  enabled2FA: (data) => serverApi({ uri: "twofa/enable", method: "POST", data }),

  generateRecoveryCodes: () => serverApi({ uri: "twofa/recovery-codes", method: "PUT" }),

  getRecoveryCodes: () => serverApi({ uri: "twofa/recovery-codes", method: "GET" }),

  updateStatus: (data) => serverApi({ uri: "twofa/update-status", method: "PUT", data }),

  verify2FA: (data) => serverApi({ uri: "twofa/verify", method: "POST", data }),

  disable2FA: () => serverApi({ uri: "twofa/disable", method: "PUT" }),
};

export default twofaService;
