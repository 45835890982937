import serverApi from "../../utils/serverApi";

const propertiesService = {
  getOwnedByUserWithPaginate: (id, data) =>
    serverApi({ uri: `properties/owned-by-user/${id}/paginate`, method: "GET", data }),

  getHolders: (id, data) =>
    serverApi({ uri: `properties/holders/${id}`, method: "GET", data }),

};

export default propertiesService;
