import serverApi from '../../utils/serverApi';

const emailService = {
  getList: (data) => serverApi({uri: 'admin/email', method: 'GET', data}),

  sendEmail: (data) => serverApi({uri: 'admin/email', method: 'POST', data}),

  getRecipients: () => serverApi({uri: 'admin/email/recipients', method: 'GET'}),
};

export default emailService;
