<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-form-group label="Location Name" label-for="key-input" :state="validateState('name')">
                <b-form-input id="key-input" v-model="form.name" :state="validateState('name')" required>
                </b-form-input>
            </b-form-group>

        </form>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import locationService from "../../../services/admin/location.service";

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
        form: {
            name: {
                required,
            },
        },
    }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    emits: ['on-close'],
    data() {
        return {
            form: {
                name: '',
            },
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            if (this.location != null) {
                this.form = {
                    name: this.location.name,
                    id: this.location.id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                name: '',
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            await locationService.createOrUpdate(this.form);
            this.$nextTick(() => {
                this.resetModal();
                this.$emit('on-close', true);
            })
        }
    },

    computed: {
        title() {
            return this.location != null ? 'Update location' : 'Add new location';
        },
    },
}
</script>

<style lang="scss" scoped>

</style>