<template>
  <div class="row">
    <div class="col-12">
      <div slot="raw-content" class="table-responsive">
        <paper-table
          :data="joinWaitlist.data"
          :columns="tableColumns"
        >
          <template #theadSearch>
            <thead class="search">
            <th width="30px"></th>
            <th>
              <b-input v-model="filters.full_name"></b-input>
            </th>
            <th width="10%">
              <b-input v-model="filters.email"></b-input>
            </th>
            <th>
              <b-input v-model="filters.phone"></b-input>
            </th>

            <th></th>
            <th></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination
          v-if="joinWaitlist.total"
          align="right"
          v-model="joinWaitlist.current_page"
          :total-rows="joinWaitlist.total"
          :per-page="joinWaitlist.per_page"
          @change="onChangePage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import {PaperTable} from "../../components";
  import waitlistService from "../../services/admin/waitlist.service";

  const tableColumns = ["Id", "Full_name", "Email", "Phone", "Funds", "Interested"];

  export default {
    components: {
      PaperTable,
    },
    data() {
      return {
        joinWaitlist: {},
        tableColumns: [...tableColumns],
        filters: {
          full_name: '',
          email: '',
          phone: '',
        },
      };
    },
    async mounted() {
      await Promise.all([
        this.getJoinWaitlist(1),
      ]);
    },
    watch: {
      async "filters.full_name"() {
        this.searchTimeOut(1)
      },
      async "filters.email"() {
        this.searchTimeOut(1)
      },
      async "filters.phone"() {
        this.searchTimeOut(1)
      },
    },
    methods: {
      async getJoinWaitlist(page) {
        const filters = {
          ...this.filters,
          page,
        };
        const result = await waitlistService.getList(filters);
        if (result && result.data) {
          this.joinWaitlist = result
        }
      },
      async onChangePage(page) {
        await this.getJoinWaitlist(page)
      },
      searchTimeOut(page) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getJoinWaitlist(page);
        }, 400);
      },
    }
  };
</script>
<style scoped>
</style>
