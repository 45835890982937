<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Send Email
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="emails.data" :columns="tableColumns" :showEditAction="true"
                        @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="3%"></th>
                                <th width="30%">
                                    <b-input v-model="filters.campaign_title"></b-input>
                                </th>
                                <th width="17%">
                                    <b-input v-model="filters.alias"></b-input>
                                </th>
                                <th width="8%"></th>
                                <th width="15%">
                                    <b-form-select v-model="filters.is_testing" text-field="name" value-field="name">
                                        <template #first>
                                            <b-form-select-option :value="null">Select value</b-form-select-option>
                                        </template>
                                        <template v-for="option in options">
                                            <b-form-select-option :value="option.value">
                                                {{ option.name }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th width="10%"></th>
                                <th width="10%"></th>
                                <th width="7%"></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="emails.total" align="right" v-model="emails.current_page"
                        :total-rows="emails.total" :per-page="emails.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-send-email :show="showModalSend" :templates="templates" @on-close="onModalClose" />
        <modal-email-detail title="Email Detail" :show="showModalDetail" :email="email"
            @onClose="hideModalDetail"></modal-email-detail>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import emailService from "../../services/admin/email.service";
import emailTemplateService from "../../services/admin/emailTemplate.service";
import ModalSendEmail from "./modals/ModalSendEmail.vue";
import ModalEmailDetail from "./modals/ModalEmailDetail.vue"

const tableColumns = ["Id", "Title", "Alias", "Tag", "Is_Testing", "Sent_By", "Status"];

export default {
    components: {
        PaperTable,
        ModalSendEmail,
        ModalEmailDetail,
    },
    data() {
        return {
            emails: {},
            tableColumns: [...tableColumns],
            filters: {
                campaign_title: '',
                alias: '',
                is_testing: null,
            },
            showModalSend: false,
            email: {},
            templates: null,
            showModalDetail: false,
            options: [
                {
                    name: 'Testing',
                    value: 1,
                },
                {
                    name: 'To Users',
                    value: 0,
                }
            ],
        };
    },
    async mounted() {
        await this.getEmails(1);
    },
    watch: {
        'filters.alias'() {
            this.searchTimeOut(1);
        },
        'filters.campaign_title'() {
            this.searchTimeOut(1);
        },
        'filters.is_testing'() {
            this.searchTimeOut(1);
        }
    },
    methods: {

        async getEmails(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await emailService.getList(filters);
            if (result && result.data) {
                this.emails = result;
            }
        },

        async onChangePage(page) {
            await this.getEmails(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getEmails(page);
            }, 400);
        },

        async showAddModal() {
            if (this.templates) {
                this.showModalSend = true;
            } else {
                const res = await emailTemplateService.getAll();
                if (res && res.data) {
                    this.templates = res.data;
                    this.showModalSend = true;
                }
            }
        },

        onModalClose(success) {
            this.showModalSend = false;
            if (success) {
                this.getEmails(this.emails.current_page);
            }
        },

        onModalEdit(data) {
            if (data) {
                this.email = this.emails.data.find((e) => e.id === data);
                this.showModalDetail = true;
            }
        },

        async hideModalDetail(data) {
            this.showModalDetail = data.show
            if (data.show === false) {
                this.email = {}
            }
        },
    }
};
</script>
<style scoped>

</style>
