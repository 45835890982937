<template>
  <div class="row">
    <div class="col-12">
      <div class="pb-2">
        <b-button size="sm" id="show-btn" @click="onToggleModal({ show: true })">
          <b-icon icon="plus-lg"></b-icon>
          Add new
        </b-button>
      </div>
      <div slot="raw-content" class="table-responsive">
        <paper-table :data="properties.data" :columns="tableColumns" :show-edit-action="false" :show-delete-action="true"
          :customActionTitles="customActions" @onModalEdit="onModalEdit"
          @onModalDelete="onModalDelete" @onCustomAction="onClickedCustomAction" :tr-class-field="'status'">
          <template #theadSearch>
            <thead class="search">
              <th width="30px" />
              <th width="10%">
                <b-input v-model="filters.uuid"></b-input>
              </th>
              <th>
                <b-input v-model="filters.name"></b-input>
              </th>
              <th width="10%">
                <b-select :options="statusList" v-model="filters.status"></b-select>
              </th>
              <th>
                <b-form-select v-model="filters.spc_id" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select spc</b-form-select-option>
                  </template>
                  <template v-for="spc in spcs">
                    <b-form-select-option :value="spc.id">
                      {{ spc.company_name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th>
                <b-input v-model="filters.location"></b-input>
              </th>
              <th>
                <b-input v-model="filters.address"></b-input>
              </th>
              <th>
                <b-form-select v-model="filters.category_id" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select type</b-form-select-option>
                  </template>
                  <template v-for="category in categories">
                    <b-form-select-option :value="category.id">
                      {{ category.name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination v-if="properties.total" align="right" v-model="properties.current_page"
          :total-rows="properties.total" :per-page="properties.per_page" @change="onChangePage"
          aria-controls="my-table"></b-pagination>
      </div>
    </div>
    <modal-create title="Create property" :show="modalCreate"
      @onClose="onToggleModal"></modal-create>
    <b-modal v-model="modalDelete.show" header-bg-variant="dark" header-text-variant="light">
      <template #modal-header>
        <h5 class="modal-header-title mb-0">Delete confirm</h5>
        <button id="btn_closeModalDeleteProperty" type="button" class="close font-weight-bold" aria-label="Close"
          @click="modalDelete.show = false">×</button>
      </template>
      <p v-if="modalDelete.content">Are you sure you wanna delete <strong style="color: red">{{ modalDelete.content
      }}</strong></p>
      <template #modal-footer>
        <b-button variant="danger" size="sm" @click="onDeleteProperty">
          Delete
        </b-button>
        <b-button variant="primary" size="sm" @click="modalDelete.show = false">
          Close
        </b-button>
      </template>
    </b-modal>
    <modal-rental-distribution :show="modalRentalDistribution" :propertyId="rentalDistributionPropertyId"
      @on-close="onCloseRentalDistribution" />
    <modal-property-holders :show="modalPropertyHolders" :property="property" @on-close="modalPropertyHolders = false" />
    <modal-image-visibility :show="modalImageVisibility" :images="visibilityImages" @on-close="modalImageVisibility = false"></modal-image-visibility>
    <modal-milestone :show="modalMilestoneVisibility" :property-id="milestonePropertyId" @on-close="modalMilestoneVisibility = false"></modal-milestone>
    <modal-financial :show="modalFinancialVisibility" :property-id="financialPropertyId" @on-close="modalFinancialVisibility = false"></modal-financial>
    <modal-update-property-status title="Update property" :show="modalUpdatePropertyStatus" :propertyId="updateStatusPropertyId"
      @onClose="onCloseUpdatePropertyStatus"></modal-update-property-status>
    <modal-update-property-details title="Update property details" :show="modalUpdatePropertyDetails" :propertyId="updateDetailsPropertyId"
      @onClose="onCloseUpdatePropertyDetails"></modal-update-property-details>
    <modal-view-property-details title="View property details" :show="modalViewPropertyDetails" :propertyId="viewPropertyId"
      @onClose="onCloseViewPropertyDetails"></modal-view-property-details>
    <modal-copy-property title="Copy property" :show="modalCopyProperty" :propertyId="copyPropertyId"
      @onClose="onCloseCopyProperty"></modal-copy-property>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import ModalCreate from "./modals/ModalCreate.vue"
import ModalUpdatePropertyStatus from "./modals/ModalUpdatePropertyStatus.vue"
import ModalUpdatePropertyDetails from "./modals/ModalUpdatePropertyDetails.vue"
import ModalViewPropertyDetails from "./modals/ModalViewPropertyDetails.vue"
import ModalCopyProperty from "./modals/ModalCopyProperty.vue"
import ModalImageVisibility from "./modals/ModalImageVisibility.vue"
import ModalRentalDistribution from "@/pages/admin/modals/ModalRentalDistribution"
import ModalPropertyHolders from "@/pages/admin/modals/ModalPropertyHolders"
import ModalMilestone from "./modals/ModalMilestone.vue"
import propertiesService from "../../services/properties.service"
import spcsService from "../../services/spcs.service"
import categoryService from "../../services/categories.service"
import imageService from "../../services/admin/image.service"
import ModalFinancial from "./modals/ModalFinancial.vue"
import { userHasPermission } from "../../helpers/common"
import { USER_PERMISSIONS } from "../../constants/permissions"
import { PROPERTY_STATUSES_LIST, PROPERTY_CUSTOM_ACTIONS, PROPERTY_SHOW_MODE_STATUS } from "../../constants/constants"

const tableColumns = ["Id", "Uuid", "Name", "Status", "Spc", "Location", "Address", "Type", "Sold_Tokens", "Weight"]

export default {
  components: {
    PaperTable,
    ModalCreate,
    ModalRentalDistribution,
    ModalPropertyHolders,
    ModalImageVisibility,
    ModalMilestone,
    ModalUpdatePropertyStatus,
    ModalUpdatePropertyDetails,
    ModalViewPropertyDetails,
    ModalCopyProperty,
    ModalFinancial
  },
  data() {
    return {
      modalCreate: false,
      modalUpdatePropertyStatus: false,
      modalUpdatePropertyDetails: false,
      modalViewPropertyDetails: false,
      modalCopyProperty: false,
      modalDelete: {
        show: false,
        content: '',
        id: null,
      },
      modalRentalDistribution: false,
      propertyId: null,
      property: null,
      properties: {},
      tableColumns: [...tableColumns],
      filters: {
        uuid: '',
        name: '',
        status: '',
        spc_id: null,
        location: '',
        address: '',
        category_id: null,
      },
      statusList: PROPERTY_STATUSES_LIST,
      spcs: [],
      categories: [],
      modalPropertyHolders: false,
      modalImageVisibility: false,
      visibilityImages: [],
      modalMilestoneVisibility: false,
      milestonePropertyId: null,
      rentalDistributionPropertyId: null,
      updateStatusPropertyId: null,
      updateDetailsPropertyId: null,
      modalFinancialVisibility: false,
      financialPropertyId: null,
      viewPropertyId: null,
      copyPropertyId: null
    };
  },
  async mounted() {
    await Promise.all([
      this.getProperties(1),
      this.getSpcs(),
      this.getCategories(),
    ]);
  },
  watch: {
    async "filters.uuid"() {
      this.searchTimeOut(1)
    },
    async "filters.name"() {
      this.searchTimeOut(1)
    },
    async "filters.spc_id"() {
      this.searchTimeOut(1)
    },
    async "filters.location"() {
      this.searchTimeOut(1)
    },
    async "filters.address"() {
      this.searchTimeOut(1)
    },
    async "filters.status"() {
      this.getProperties(1)
    },
    async "filters.category_id"() {
      this.getProperties(1)
    },
  },
  computed: {
    canApprovePropertyStatus() {
      return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_STATUS_AND_TOKEN)
    },
    customActions() {
      let actions = []
      // actions = ['Rental Distribution', 'Holders', 'Images Visibility', 'Milestone', 'Financials', 'Update Status and Token', 'View', 'Modfify']

      // 'Update Status and Token'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_STATUS_AND_TOKEN)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.UPDATE_PROPERTY_STATUS_AND_TOKEN)
      }
      
      // 'Rental Distribution'
      if (userHasPermission(USER_PERMISSIONS.DISTRIBUTION.UPDATE_RENTAL_DISTRIBUTION)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.UPDATE_RENTAL_DISTRIBUTION)
      }

      // 'Holders'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_HOLDER)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_HOLDER)
      }

      // 'Images Visibility'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY_IMAGE.VIEW_PROPERTY_IMAGE)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_IMAGE)
      }

      // 'Milestone'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_MILESTONE)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_MILESTONE)
      }

      // 'Financials'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY_FINANCIAL.VIEW_ALL_PROPERTY_FINANCIALS)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.VIEW_ALL_PROPERTY_FINANCIALS)
      }

      // 'View'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY)
      }

      // 'Modify/Update'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.UPDATE_PROPERY_DETAIL)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.UPDATE_PROPERY_DETAIL)
      }

      // Copy
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.COPY_PROPERTY)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.COPY_PROPERTY)
      }
      
      return actions
    },
  },
  methods: {
    async onDeleteProperty() {
      if (this.modalDelete.id) {
        await propertiesService.delete(this.modalDelete.id);
        this.modalDelete = {
          show: false,
          content: '',
          id: null,
        };
        await this.getProperties(1)
      }
    },
    onModalDelete(data) {
      this.modalDelete = data;
    },
    onModalEdit(data) {
      if (data) {
        this.updateDetailsPropertyId = data;
        this.modalUpdatePropertyDetails = true
      }
    },
    async onClickedCustomAction(data, index) {
      if (index === PROPERTY_CUSTOM_ACTIONS.UPDATE_RENTAL_DISTRIBUTION.key) {
        this.rentalDistributionPropertyId = data;
        this.modalRentalDistribution = true
      } else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_HOLDER.key) {
        this.property = this.properties.data.find(e => e.id == data);
        this.modalPropertyHolders = true;
      } else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_IMAGE.key) {
        const res = await imageService.getPropertyImages({ property_id: data })
        if (res && res.data) {
          this.visibilityImages = res.data
          this.modalImageVisibility = true
        }
      } else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_MILESTONE.key) {
        this.milestonePropertyId = data;
        this.modalMilestoneVisibility = true
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_ALL_PROPERTY_FINANCIALS.key) {
        this.financialPropertyId = data;
        this.modalFinancialVisibility = true
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.UPDATE_PROPERTY_STATUS_AND_TOKEN.key) {
        this.updateStatusPropertyId = data;
        this.modalUpdatePropertyStatus = true;
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY.key) {
        this.viewPropertyId = data;
        this.modalViewPropertyDetails = true;
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.UPDATE_PROPERY_DETAIL.key) {
        this.updateDetailsPropertyId = data;
        this.modalUpdatePropertyDetails = true
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.COPY_PROPERTY.key) {
        this.copyPropertyId = data;
        this.modalCopyProperty = true
      }
    },
    onCloseRentalDistribution(created) {
      this.rentalDistributionPropertyId = null
      this.modalRentalDistribution = false
      if (created) {
        //do something
      }
    },
    async onToggleModal(data) {
      this.modalCreate = data.show;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.propertyId = null;
      }
    },
    async onCloseUpdatePropertyStatus(data) {
      this.modalUpdatePropertyStatus = false;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.updateStatusPropertyId = null;
      }
    },

    async onCloseUpdatePropertyDetails(data) {
      this.modalUpdatePropertyDetails = false;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.updateDetailsPropertyId = null;
      }
    },

    async onCloseViewPropertyDetails(data) {
      this.modalViewPropertyDetails = false;
      if (data.show === false) {
        this.viewPropertyId = null;
      }
    },

    async onCloseCopyProperty(data) {
      this.modalCopyProperty = false;
      if (data.show === false) {
        this.copyPropertyId = null;
      }
    },

    async getSpcs() {
      const response = await spcsService.getSpcs()
      if (response && response.data) {
        this.spcs = response.data
      }
    },
    async getCategories() {
      this.categories = await categoryService.getCategories();
    },
    async getProperties(page) {
      const filters = {
        ...this.filters,
        page,
        show: PROPERTY_SHOW_MODE_STATUS.ALL
      };
      const result = await propertiesService.getList(filters);
      if (result) {
        const { data } = result;
        data.map((property) => {
          property.type = property.category.name;
          property.bed = property.num_bed;
          property.bath = property.num_bath;
          property.spc = property.spc ? property.spc.company_name : ""
          property.location = property.location.name;
          property.address = property.metadata.address;
        });
        this.properties = result
      }
    },
    async onChangePage(page) {
      await this.getProperties(page)
    },
    searchTimeOut(page) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getProperties(page);
      }, 400);
    },
  }
};
</script>
<style scoped>
</style>
