<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <b-row class="mb-3" align-v="center" v-if="partner">
                <b-col class="font-22">
                    <p>Name: {{ partner.name }}</p>
                    <p>Email: {{ partner.email }}</p>
                </b-col>
            </b-row>
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Roles" label-for="key-roleIds">
                <multiselect id="key-roleIds" v-model="form.role_ids" :options="roles"
                    :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                    placeholder="None" label="name" track-by="id"
                     :allowEmpty="true">
                </multiselect>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
import partnerService from "../../../services/admin/partner.service";
import { Field } from "vee-validate"
import rolesService from "../../../services/admin/roles.service";

export default {
    components: {
        Field,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        partner: {
            type: Object,
            default: null,
        },
    },
    emits: ["on-close"],
    data() {
        return {
            form: {
                role_ids: [],
            },
            showModal: false,
            roles: [],
        };
    },

    async mounted() {
        await this.getRoles();
    },

    watch: {
        show(value) {
            this.showModal = value;
        },
    },

    methods: {
        customLabel({ name }) {
            return `${name} (${name})`;
        },

        async getRoles() {
            const result = await rolesService.getAll();
            if (result) {
                this.roles = result;
            }
        },

        updateModal() {
            if (this.partner != null) {
                this.form = {
                    role_ids: this.partner.roles
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                role_ids: [],
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            await this.handleSubmit();
        },

        async handleSubmit() {
            const dataToSubmit = {
                ...this.form,
                role_ids: this.form.role_ids.map((item) => item.id),
            };
            await partnerService.update(this.partner.id, dataToSubmit);
            this.$nextTick(() => {
                this.resetModal();
                this.$emit("on-close", true);
            });
        },
    },

    computed: {
        title() {
            return "Assign Role";
        },
    },
};
</script>

<style lang="scss" scoped></style>
