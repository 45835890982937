<template>
    <div class="page-loader" v-if="isLoading">
        <div class="image-container" >
            <div class="image-overlay" :style="{bottom: topValue}" ></div>
        </div>
        <h3 class="percent-counter" >{{ percent }}%</h3>
    </div>
</template>
  
<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            percent: 0,
        };
    },
    computed: {
        topValue() {
            return `${this.percent}%`;
        },
    },
    mounted() {
        this.startProgress();
    },
    methods: {
        startProgress() {
            this.percent = 0;
            const interval = setInterval(() => {
                this.percent += 1;
                if (this.percent === 100) {
                    clearInterval(interval);
                    setTimeout(() => {
                        this.$emit("loading-complete");
                    }, 50);
                }
            }, 5);
        }
    }
};
</script>
  
<style scoped>
.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.image-container {
    position: absolute;
    overflow: hidden;
    width: 200px;
    height:200px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("~@/assets/img/logo.png");
    margin: 0;
}

.image-overlay {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    opacity: 0.7;
}

.percent-counter{
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 20px;
}
</style>