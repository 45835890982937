import roles from "../constants/roles"
import AssetsOverview from "../pages/account/AssetsOverview.vue"
import { FOREIGNER, ROUTE_META_PAGE_TYPES } from "../constants/constants"

const account = [
  {
    path: "/account",
    component: () => import("../layout/account/Account.vue"),
    name: "account",
    redirect: "/account/assets-overview",
    meta: {
      authorize: [roles.User],
      pageType: ROUTE_META_PAGE_TYPES.ACCOUNT,
      footer: 'hidden'
    },
    children: [
      {
        path: "assets-overview",
        component: AssetsOverview,
        name: "assetsOverview",
      },
      {
        path: "buy-property",
        component: () => import("../pages/account/BuyProperty.vue"),
        name: "buyProperty",
      },
      {
        path: "referrals",
        component: () => import("../pages/account/Referral.vue"),
        name: "referrals",
      },
      {
        path: "my-account",
        component: () => import("../pages/account/MyAccount.vue"),
        name: "myAccount",
        redirect: "/account/my-account/basic-info",
      },
      {
        path: "my-account/basic-info",
        component: () => import("../pages/account/MyAccount.vue"),
        name: "basicInfo",
      },
      {
        path: "my-account/kyc",
        component: () => import("../pages/account/Kyc.vue"),
        name: "kYC",
      },
      {
        path: "transactions",
        component: () => import("../pages/account/Transactions.vue"),
        name: "transactions",
      },
      {
        path: "pending-tasks",
        component: () => import("../pages/account/PendingTasks.vue"),
        name: "pendingTasks",
      },
      {
        path: "rental-income-reports",
        component: () => import("../pages/account/RentalIncomeReports.vue"),
        name: "rentalIncomeReports",
      },
      {
        path: "pay",
        component: () => import("../pages/account/Pay.vue"),
        name: "pay",
      },
      {
        path: "pay/success",
        component: () => import("../pages/account/PaySuccess.vue"),
        name: "paySuccess",
      },
      {
        path: "pay/failed",
        component: () => import("../pages/account/PayFailed.vue"),
        name: "payFailed",
      },
      {
        path: "order/detail",
        component: () => import("../pages/account/OrderDetail.vue"),
        name: "orderDetail",
      },
      {
        path: "sell-request",
        component: () => import("../pages/account/SellTokenRequest.vue"),
        name: "sellTokenRequest",
      },
      {
        path: "sell-request/success",
        component: () => import("../pages/account/SellTokenRequestSuccess.vue"),
        name: "sellTokenRequestSuccess",
      },
      {
        path: "swap-token",
        component: () => import("../pages/account/SwapToken.vue"),
        name: "swapToken",
      },
      {
        path: "swap-token/success",
        component: () => import("../pages/account/SwapTokenSuccess.vue"),
        name: "swapTokenSuccess",
      },
      {
        path: "withdrawals",
        name: "withdrawals",
        redirect: "/account/withdrawals/history",
        component: () => import("../pages/account/withdrawals/Withdrawals.vue"),
        children: [
          {
            path: "history",
            component: () => import("../pages/account/withdrawals/WithdrawalHistory.vue"),
            name: "withdrawalHistory",
          },
          {
            path: "bank-account-history",
            component: () => import("../pages/account/withdrawals/BankAccountHistory.vue"),
            name: "bankAccountHistory",
          },
          {
            path: "create-withdrawal",
            component: () => import("../pages/account/withdrawals/CreateWithdrawal.vue"),
            name: "createWithdrawal",
          },
        ],
      },
      {
        path: "confirm-bank-account",
        component: () => import("../pages/account/ConfirmBankAccount.vue"),
        name: "confirmBankAccount",
      },
      {
        path: "property/:uuid",
        component: () => import("../pages/marketplace/Detail.vue"),
        name: "accountPropertyDetail",
      },
      {
        path: "security/pin",
        component: () => import("../pages/account/Pin.vue"),
        name: "pin",
      },
      {
        path: "security/password",
        component: () => import("../pages/account/Password.vue"),
        name: "password",
      },
      {
        path: "security/change-password",
        component: () => import("../pages/account/ChangePassword.vue"),
        name: "changePassword",
      },
      {
        path: "security/two-factor-auth",
        component: () => import("../pages/account/TwoFactorAuthentication.vue"),
        name: "twoFactorAuth",
      },
      {
        path: "balance-history",
        component: () => import("../pages/account/BalanceHistory.vue"),
        name: "balanceHistory",
      },
    ],
  },
]

function createNewRoute (route, routePathPrefix, routeNameSuffix) {
  // Clone the route to avoid mutating the original
  let newRoute = { ...route }
  if (newRoute.path && newRoute.path.startsWith("/account")) {
    newRoute.path = `${routePathPrefix}${newRoute.path}`
  }
  if (newRoute.redirect && newRoute.redirect.startsWith("/account")) {
    newRoute.redirect = `${routePathPrefix}${newRoute.redirect}`
  }
  if (newRoute.name) {
    newRoute.name = `${newRoute.name}${FOREIGNER.ROUTE_NAME_SUFFIX}`
  }
  if (newRoute.children) {
    newRoute.children = newRoute.children.map(route =>
      createNewRoute(route, routePathPrefix, routeNameSuffix)
    )
  }
  return newRoute
}

const accountForeigner = account.map(route =>
  createNewRoute(route, FOREIGNER.ROUTE_PATH_PREFIX, FOREIGNER.ROUTE_NAME_SUFFIX)
)

export { account, accountForeigner }
