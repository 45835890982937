<template>
    <div class="mb-3 mt-2">
          <div class="flex">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col>
                    <b-form-select v-model="rentalDeduction.deduction_type_id" :options="rentalDeductionTypes"
                      :state="validateRentalDeductions(index, 'deduction_type_id')" :disabled="isDisable"
                      text-field="deduction_type" value-field="id">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Select Deduction Type</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>Deduction type is required.</b-form-invalid-feedback>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="rentalDeduction.amount" placeholder="Deduction Amount" type="number"
                      :state="validateRentalDeductions(index, 'amount')" :disabled="isDisable"
                      class="w-full py-2 border rounded col-12" />
                    <b-form-invalid-feedback>Amount is invalid</b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="10">
                    <b-form-input v-model="rentalDeduction.note" placeholder="Deduction Note"
                      :state="validateRentalDeductions(index, 'note')" :disabled="isDisable"
                      class="w-full py-2 border rounded " />
                    <b-form-invalid-feedback>Note is required</b-form-invalid-feedback>
                  </b-col>
                  <b-col cols="1">
                    <b-button :id="`btn_removeDeduction_${index}`" type="button" class="px-3 py-2 btn-outline-main" :disabled="isDisable"
                      @click="removeDeduction(index)">×
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
</template>
  
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue , integer} from '@vuelidate/validators'

const greaterThanZero = (value) => value > 0

export default {
    props: {
        rentalDeduction: { type: Object, required: true },
        index: { type: Number, required: true },
        rentalDeductionTypes: { type: Array, required: true },
        isDisable: { type: Boolean, required: false },
    },
    setup() {
        return { v$: useVuelidate() }
    },
    methods: {
        validateRentalDeductions(index, field) {
            const { $dirty, $error } = this.v$.rentalDeduction[field]
            return $dirty ? !$error : null
        },
        removeDeduction(index) {
            this.$emit('removeDeduction', index)
        },
    },
    validations: {
        rentalDeduction: {
            deduction_type_id: {
              required, integer
            },
            amount: {
              required, minValue: greaterThanZero
            },
            note: {
              required
            },
        }
    }
}
</script>