<template>
    <div class="popup" v-if="isOpen" style="z-index: 10001;">
        <div class="popup-content d-flex flex-column">
            <div class="d-flex justify-content-end">
                <div class="btn-close" @click="close()">
                    <b-icon icon="x" style="color: gray;" scale="1.6"></b-icon>
                </div>
            </div>
            <div class="p-3 text-left flex-grow-1 d-flex flex-column">
                <p class="font-weight-bold font-18">{{ $t('TWO_FA.TURN_OFF_AUTHENTICATION_APP') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.TURN_OFF_AUTHENTICATION_APP_DESC') }}</p>
                <div class="flex-grow-1">
                </div>
                <b-button id="btn_turnOffAuthApp_Ok" class="btn-outline-main mt-5 col-12 btn-submit" variant="none" @click="turnOffAuthApp()">
                    {{ $t("TWO_FA.TURN_OFF") }}
                </b-button>
                <b-button id="btn_turnOffAuthApp_Cancel" class="btn-main mt-2 col-12 btn-submit" variant="none" @click="close()">
                    {{ $t("common.CANCEL") }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>

import twofaService from '../services/twofa.service'

export default {
    data() {
        return {
            isOpen: false,
        }
    },

    emits: ["on-success"],

    mounted() {

    },

    watch: {

    },

    methods: {
        openPopup() {
            this.isOpen = true
        },

        close(success = false) {
            this.isOpen = false
            if(success) {
                this.$emit("on-success")
            }
        },

        async turnOffAuthApp() {
            const res = await twofaService.disable2FA()
            if (res && res.message) {
                this.close(true)
            }
        },
    },

    computed: {
    },

}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    overflow: auto;

    .popup-content {
        position: relative;
        background-color: #fff;
        padding: 30px;
        border-radius: 20px;
        text-align: center;
        min-width: 30%;
        max-width: 95%;
        height: fit-content;
        margin: auto;

        @media screen and (min-width: 600px) {
            max-width: 50%;
        }

        @media screen and (min-width: 1100px) {
            max-width: 30%;
        }

        .btn-close {
            background-color: rgb(221, 221, 221);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .btn-submit {
            max-height: 40px;
        }
    }
}
</style>