import serverApi from "../utils/serverApi";

const propertiesService = {
  getList: (data) => serverApi({ uri: "properties", data, method: "GET" }),

  getFeatures: () => serverApi({ uri: "properties/features", method: "GET" }),

  getAvailableProperties: () => serverApi({ uri: "properties/available", method: "GET" }),

  getPropertiesForCalculator: () => serverApi({ uri: "properties/for-calculator", method: "GET" }),

  getByUuid: async (uuid) => {
    return await serverApi({ uri: `properties/detail/${uuid}`, method: "GET" });
  },
  getStatusCount: () =>
    serverApi({ uri: "properties/status/count", method: "GET" }),

  getById: async (id) => {
    return await serverApi({ uri: `properties/${id}`, method: "GET" });
  },

  save: async (data) => {
    if (!data.id) {
      return await serverApi({ uri: "properties", data, method: "POST" });
    } else {
      return await serverApi({
        uri: `properties/${data.id}`,
        data,
        method: "PUT",
      });
    }
  },

  delete: async (id) =>
    serverApi({ uri: `properties/${id}`, method: "DELETE" }),

  getAnnualizedReturns: async (id) => {
    return await serverApi({
      uri: `properties/${id}/annualized-returns`,
      method: "GET",
      isLoading: false,
    });
  },

  getTopOwners: async (id) => {
    return await serverApi({
      uri: `properties/${id}/top-own`,
      method: "GET",
      isLoading: true,
    });
  },

  getMilestones: async (data) => {
    return await serverApi({
      uri: `properties/milestones/get-by-property-id`,
      method: "GET",
      data,
      isLoading: false,
    });
  },

  saveStatusAndToken: async (data) => {
    return await serverApi({
      uri: `properties/update-status-and-token/${data.id}`,
      method: "PUT",
      data,
    });
  },

  saveDetails: async (data) => {
    return await serverApi({
      uri: `properties/update-details/${data.id}`,
      method: "PUT",
      data,
    });
  },

  getFinancials: async (data) => {
    return await serverApi({
      uri: `properties/financials/get-by-property-id`,
      method: "GET",
      data,
      isLoading: false,
    });
  },

  checkTokenHolders: async (id) => {
    return await serverApi({
      uri: `properties/${id}/check-token-holders`,
      method: "GET",
      isLoading: false,
    });
  },

  getPrimaryImage: async (id) => serverApi({uri: `properties/${id}/primary-image`, method: 'GET'}),
};

export default propertiesService;
