import NotFound from "../pages/NotFoundPage"
import Home from "../pages/Home.vue"
import { FOREIGNER } from "../constants/constants"

const user = [
  {
    path: "/",
    component: Home,
    name: "home",
  },
  {
    path: "/landing",
    component: () => import("../pages/Landing.vue"),
    name: "landing",
    meta: {
      header: "hidden",
      footer: "hidden",
      virtual_balance_bubble: "hidden"
    },
  },
  {
    path: "/landing-wa",
    component: () => import("../pages/LandingWa.vue"),
    name: "landingWa",
    meta: {
      header: "hidden",
      footer: "hidden",
      virtual_balance_bubble: "hidden"
    },
  },
  {
    path: "/landing-en",
    component: () => import("../pages/LandingEnglish.vue"),
    name: "landingEnglish",
    meta: {
      header: "hidden",
      footer: "hidden",
      virtual_balance_bubble: "hidden"
    },
  },
  {
    path: "/register",
    component: () => import("../pages/auth/Register.vue"),
    name: "register",
  },
  {
    path: "/login",
    component: () => import("../pages/auth/Login.vue"),
    name: "login",
  },
  {
    path: "/login-admin",
    component: () => import("../pages/admin/LoginAdmin.vue"),
    name: "loginAdmin",
  },
  {
    path: "/forgot-password",
    component: () => import("../pages/auth/PasswordForgot.vue"),
    name: "forgotPassword",
  },
  {
    path: "/reset-password",
    component: () => import("../pages/auth/PasswordReset.vue"),
    name: "resetPassword",
  },
  {
    path: "/verify-email",
    component: () => import("../pages/auth/VerifyEmail.vue"),
    name: "verifyEmail",
  },
  {
    path: "/marketplace",
    component: () => import("../pages/marketplace/FilterList.vue"),
    name: "marketplace",
  },
  {
    path: "/about-us",
    component: () => import("../pages/AboutUs.vue"),
    name: "aboutUs",
  },
  {
    path: "/property/:uuid",
    component: () => import("../pages/marketplace/Detail.vue"),
    name: "propertyDetail",
  },
  {
    path: "/property-image/:id",
    component: () => import("../pages/PropertyImage.vue"),
    name: "propertyImage",
  },
  {
    path: "/privacy-policy",
    component: () => import("../pages/PrivacyPolicy.vue"),
    name: "privacyPolicy",
  },
  {
    path: "/terms-and-conditions",
    component: () => import("../pages/TermsAndConditions.vue"),
    name: "termsAndConditions",
  },
  {
    path: "/buy-success",
    component: () => import("../pages/BuySuccess.vue"),
    name: "buySuccess",
  },
  {
    path: "/get-browser-session-id",
    component: () => import("../pages/BrowserSessionId.vue"),
    name: "browserSessionId",
  },
  {
    path: "/order-detail",
    component: () => import("../pages/account/OrderDetail.vue"),
    name: "publicOrderDetail",
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
]

const userForeigner = user.map(route => {
  let newRoute = { ...route }
  if (newRoute.path !== "/:pathMatch(.*)*") {
    newRoute.path = `${FOREIGNER.ROUTE_PATH_PREFIX}${newRoute.path}`
  }
  if (newRoute.name) {
    newRoute.name = `${newRoute.name}${FOREIGNER.ROUTE_NAME_SUFFIX}`
  }
  return newRoute
})

export { user, userForeigner }
