<!--suppress HtmlUnknownTag -->
<template>
  <b-modal v-model="showModal" id="modal-prevent-closing" :no-close-on-backdrop="true" header-bg-variant="dark" header-text-variant="light"
    @hidden="onClose(false)">
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose(false)">×
      </button>
    </template>
    <div class="container">
      <b-form class="form-content">
        <b-row class="mb-2" v-if="!isDateRange">
          <b-col cols="6">
            <label>Select a date:</label>
              <VueDatePicker v-model="form.date" model-type="yyyy-MM-dd" format="yyyy-MM-dd" style="width: 150px;" :disabled-dates="notAfterToday" />
            <b-form-input v-model="form.date" type="text" :state="validateState('date')" v-show="false" />
            <b-form-invalid-feedback>Date is required</b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="mb-2" v-if="isDateRange">
          <b-col cols="6">
            <label>Select date range:</label>
            <VueDatePicker v-model="dateRange" model-type="yyyy-MM-dd" format="yyyy-MM-dd" range @internal-model-change="onDateRangeChanged" :class="[{ 'input-valid': form.from_date && form.to_date }, { 'input-invalid': !form.from_date || !form.to_date }]" style="width: 300px;" :disabled-dates="notAfterToday" />
            <p v-if="!form.from_date || !form.to_date" class="red font-14">Date range required</p>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-form-checkbox switch class="ml-3" v-model="isDateRange" @change="isDateRangeChanged">
            Date Range
          </b-form-checkbox>
        </b-row>

        <b-row class="mb-4">
          <b-form-checkbox class="ml-3" v-model="form.check_discrepancy" check>
            Check discrepancy of Sold Tokens & User's Tokens
          </b-form-checkbox>
        </b-row>
      </b-form>
    </div>
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button size="sm" class="btn-main" variant="none" @click="onCreate">
        Create
      </b-button>
      <b-button size="sm" class="btn-outline-main" variant="none" @click="onClose(false)">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import rentalDistributionService from "@/services/admin/rentalDistribution.service"
import virtualRentalDistributionService from "@/services/admin/virtualRentalDistribution.service"
import { useVuelidate } from '@vuelidate/core'
import { requiredUnless } from '@vuelidate/validators'

export default {
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isVirtual: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      showModal: false,
      form: { check_discrepancy: true },
      dateRange: [],
      isDateRange: false,
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v$.form[name]
      return $dirty ? !$error : null
    },

    notAfterToday(date) {
      const today = new Date().setHours(0, 0, 0, 0)
      return date > today
    },

    isDateRangeChanged() {
      this.form = { check_discrepancy: this.form.check_discrepancy }
      this.dateRange = []
    },

    onDateRangeChanged(value) {
      if (value) {
        this.form.from_date = value[0] ? value[0] : null
        this.form.to_date = value[1] ? value[1] : null
      }
    },

    async onCreate() {
      this.v$.form.$touch();
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        window.scrollTo(0, 0)
      } else {
        if (this.form.date || (this.form.from_date && this.form.to_date)) {
          if (this.isVirtual) {
            await virtualRentalDistributionService.createRentalDistributionDetails(this.form)
          } else {
            await rentalDistributionService.createRentalDistributionDetails(this.form)
          }
          this.onClose(true)
        }
      }
    },

    onClose: function (changed) {
      this.form = { check_discrepancy: true }
      this.isDateRange = false
      this.$emit("on-create-close", changed)
    },
  },

  validations() {
    return {
      form: {
        date: {
          required: requiredUnless(function () {
            return (this.isDateRange)
          }),
        },
      },
    }
  },

  computed: {
    title() {
      return "Create Rental Distribution Details"
    },
  },

  watch: {
    show: function (val) {
      this.showModal = val
    },
  },
}
</script>

<style scoped>
.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: .8;
  padding-bottom: 20px !important;
}

.form-content label {
  font-weight: bold;
  font-size: 16px;
}

.input-invalid {
  border: 1px solid #dc3545;
  border-radius: 5px;
}

.input-valid {
  border: 1px solid #28a745;
  border-radius: 5px;
}
</style>
