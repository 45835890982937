import serverApi from '../../utils/serverApi';

const emailTemplateService = {
  getList: (data) => serverApi({uri: 'admin/email-template', method: 'GET', data}),

  getAll: () => serverApi({uri: 'admin/email-template/all', method: 'GET'}),

  create: (data) => serverApi({uri: 'admin/email-template', method: 'POST', data}),

  update: (id, data) => serverApi({uri: `admin/email-template/${id}`, method: 'PUT', data}),

  delete: (id) => serverApi({uri: `admin/email-template/${id}`, method: 'DELETE'}),
};

export default emailTemplateService;
