<template>
  <div>
    <p class="font-20">- Total no. of unique accounts registered: <b>{{ registeredUserCount }}</b></p>
    <p class="font-20">- Total no. of unique accounts transacted (successful purchase at least once):
      <b>{{ transactedUserCount }}</b></p>
    <p class="font-20">- Total no. of unique accounts not transacted: <b>{{ notTransactedUserCount }}</b></p>
    <p class="font-20">- Total no. of unique accounts who have successfully done KYC:
      <b>{{ completedKycUserCount }}</b></p>
    <p class="font-20">- Total no. of unique accounts who have an active bank account:
      <b>{{ haveActiveBankAccountUserCount }}</b></p>
    <p class="font-20">- Total no. of unique accounts who made at least 1 successful withdrawal:
      <b>{{ haveSuccessWithdrawalUserCount }}</b></p>
    <br><br>
    <p class="font-20">- Total no. of tokens bought:
      <br>&emsp;+ No of tokens: <b>{{ numOfBoughtTokens }}</b>
      <br>&emsp;+ No of transactions: <b>{{ numOfBoughtTokenTransactions }}</b>
      <br>&emsp;+ No of tokens by Admin: <b>{{ numOfBoughtTokensByAdmin }}</b>
      <br>&emsp;+ No of transactions by Admin: <b>{{ numOfBoughtTokenByAdminTransactions }}</b>
    </p>
    <p class="font-20">- Total no. of tokens sold:
      <br>&emsp;+ No of tokens: <b>{{ numOfSoldTokens }}</b>
      <br>&emsp;+ No of transactions: <b>{{ numOfSoldTokenTransactions }}</b>
      <br>&emsp;+ No of tokens by Admin: <b>{{ numOfSoldTokensByAdmin }}</b>
      <br>&emsp;+ No of transactions by Admin: <b>{{ numOfSoldTokenByAdminTransactions }}</b>
    </p>
    <p class="font-20">- Total no. of tokens bought net (net = bought - sold):
      <b>{{ totalTokensBoughtNet }}</b>
      <br>&emsp;+ By Users: <b>{{ tokensBoughtNet }}</b>
      <br>&emsp;+ By Admin: <b>{{ tokensBoughtByAdminNet }}</b>
    </p>
    <p class="font-20">- Total no. of checkout/transactions (total, successful=paid, failed=not paid/expired):
      <br>&emsp;+ Total transactions: <b>{{ totalPaymentCount }}</b>
      <br>&emsp;+ Successful transactions: <b>{{ successPaymentCount }}</b>
      <br>&emsp;+ Failed transactions: <b>{{ failedPaymentCount }}</b>
    </p>
    <br><br>
    <p class="font-20">- Avg. no. of transactions per transacted account:
      <b>{{ avgSuccessTransactionsPerTransactedUser }}</b></p>
    <p class="font-20">- Avg. of success transactions amount: IDR <b>{{ avgSuccessTransactionsAmount }}</b></p>
    <p class="font-20">- Avg. amount of tokens owned per account: <b>{{ avgTokensOwnedPerTransactedAccount }}</b></p>
    <br><br>
    <p class="font-20">- New account registrations today: <b>{{ todayRegisteredUserCount }}</b>
      (with <b>{{ todayRegisteredIncreasePercent }}</b>% increase compared to previous day:
      <b>{{ yesterdayRegisteredUserCount }}</b>)
    </p>
    <p class="font-20">- New account registrations this week: <b>{{ thisWeekRegisteredUserCount }}</b>
      (with <b>{{ thisWeekRegisteredIncreasePercent }}</b>% increase compared to previous week:
      <b>{{ lastWeekRegisteredUserCount }}</b>)
    </p>
    <p class="font-20">- New account registrations this month: <b>{{ thisMonthRegisteredUserCount }}</b>
      (with <b>{{ thisMonthRegisteredIncreasePercent }}</b>% increase compared to previous month:
      <b>{{ lastMonthRegisteredUserCount }}</b>)
    </p>
    <br><br>
    <p class="font-20">- No. of tokens bought today: <b>{{ todayBoughtTokens }}</b>
      (with <b>{{ todayBoughtTokensIncreasePercent }}</b>% increase compared to previous day:
      <b>{{ yesterdayBoughtTokens }}</b>)
    </p>
    <p class="font-20">- No. of tokens bought this week: <b>{{ thisWeekBoughtTokens }}</b>
      (with <b>{{ thisWeekBoughtTokensIncreasePercent }}</b>% increase compared to previous week:
      <b>{{ lastWeekBoughtTokens }}</b>)
    </p>
    <p class="font-20">- No. of tokens bought this month: <b>{{ thisMonthBoughtTokens }}</b>
      (with <b>{{ thisMonthBoughtTokensIncreasePercent }}</b>% increase compared to previous month:
      <b>{{ lastMonthBoughtTokens }}</b>)
    </p>
    <br><br>
    <p class="font-20">- Total amount paid as rental income: IDR <b>{{ totalAmountPaidAsRentalIncome }}</b></p>
    <div class="font-20">- Breakdown of successful payment channels:
      <ul>
        <li v-for="item in breakdownPaymentChannels" :key="breakdownPaymentChannels.payment_channel">
          {{ item.payment_channel || 'n/a' }} : <b>{{ item.count }}</b>
        </li>
      </ul>
    </div>

    <!--    &lt;!&ndash;Stats cards&ndash;&gt;-->
    <!--    <div class="row">-->
    <!--      <div class="col-md-6 col-xl-3" v-for="stats in statsCards" :key="stats.title">-->
    <!--        <stats-card>-->
    <!--          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">-->
    <!--            <i :class="stats.icon"></i>-->
    <!--          </div>-->
    <!--          <div class="numbers" slot="content">-->
    <!--            <p><b>{{ stats.title }}</b></p>-->
    <!--            <b>{{ stats.value }}</b>-->
    <!--          </div>-->
    <!--          <div class="stats" slot="footer">-->
    <!--            <i :class="stats.footerIcon"></i> <b>{{ stats.footerText }}</b>-->
    <!--          </div>-->
    <!--        </stats-card>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    &lt;!&ndash;Charts&ndash;&gt;-->
    <!--    <div class="row">-->

    <!--      <div class="col-12">-->
    <!--        <chart-card title="Users behavior"-->
    <!--                    sub-title="24 Hours performance"-->
    <!--                    :chart-data="usersChart.data"-->
    <!--                    :chart-options="usersChart.options">-->
    <!--          <span slot="footer">-->
    <!--            <i class="ti-reload"></i> Updated 3 minutes ago-->
    <!--          </span>-->
    <!--          <div slot="legend">-->
    <!--            <i class="fa fa-circle text-info"></i> Open-->
    <!--            <i class="fa fa-circle text-danger"></i> Click-->
    <!--            <i class="fa fa-circle text-warning"></i> Click Second Time-->
    <!--          </div>-->
    <!--        </chart-card>-->
    <!--      </div>-->

    <!--      <div class="col-md-6 col-12">-->
    <!--        <chart-card title="Email Statistics"-->
    <!--                    sub-title="Last campaign performance"-->
    <!--                    :chart-data="preferencesChart.data"-->
    <!--                    chart-type="Pie">-->
    <!--          <span slot="footer">-->
    <!--            <i class="ti-timer"></i> Campaign set 2 days ago</span>-->
    <!--          <div slot="legend">-->
    <!--            <i class="fa fa-circle text-info"></i> Open-->
    <!--            <i class="fa fa-circle text-danger"></i> Bounce-->
    <!--            <i class="fa fa-circle text-warning"></i> Unsubscribe-->
    <!--          </div>-->
    <!--        </chart-card>-->
    <!--      </div>-->

    <!--      <div class="col-md-6 col-12">-->
    <!--        <chart-card title="2015 Sales"-->
    <!--                    sub-title="All products including Taxes"-->
    <!--                    :chart-data="activityChart.data"-->
    <!--                    :chart-options="activityChart.options">-->
    <!--          <span slot="footer">-->
    <!--            <i class="ti-check"></i> Data information certified-->
    <!--          </span>-->
    <!--          <div slot="legend">-->
    <!--            <i class="fa fa-circle text-info"></i> Tesla Model S-->
    <!--            <i class="fa fa-circle text-warning"></i> BMW 5 Series-->
    <!--          </div>-->
    <!--        </chart-card>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>
</template>
<script>
// import { ChartCard, StatsCard } from "@/components/index"
// import Chartist from "chartist"
import dashboardService from "../../services/admin/dashboard.service"

export default {
  components: {
    // StatsCard,
    // ChartCard
  },
  async mounted () {
    await this.getStatisticalInfo()
  },

  methods: {
    async getStatisticalInfo () {
      this.statisticalInfo = await dashboardService.getStatisticalInfo()
    },
  },

  data () {
    return {
      statisticalInfo: null,
      // statsCards: [
      //   {
      //     type: "warning",
      //     icon: "ti-server",
      //     title: "Capacity",
      //     value: "105GB",
      //     footerText: "Updated now",
      //     footerIcon: "ti-reload"
      //   },
      //   {
      //     type: "success",
      //     icon: "ti-wallet",
      //     title: "Revenue",
      //     value: "$1,345",
      //     footerText: "Last day",
      //     footerIcon: "ti-calendar"
      //   },
      //   {
      //     type: "danger",
      //     icon: "ti-pulse",
      //     title: "Errors",
      //     value: "23",
      //     footerText: "In the last hour",
      //     footerIcon: "ti-timer"
      //   },
      //   {
      //     type: "info",
      //     icon: "ti-twitter-alt",
      //     title: "Followers",
      //     value: "+45",
      //     footerText: "Updated now",
      //     footerIcon: "ti-reload"
      //   }
      // ],
      // usersChart: {
      //   data: {
      //     labels: [
      //       "9:00AM",
      //       "12:00AM",
      //       "3:00PM",
      //       "6:00PM",
      //       "9:00PM",
      //       "12:00PM",
      //       "3:00AM",
      //       "6:00AM"
      //     ],
      //     series: [
      //       [287, 385, 490, 562, 594, 626, 698, 895, 952],
      //       [67, 152, 193, 240, 387, 435, 535, 642, 744],
      //       [23, 113, 67, 108, 190, 239, 307, 410, 410]
      //     ]
      //   },
      //   options: {
      //     low: 0,
      //     high: 1000,
      //     showArea: true,
      //     height: "245px",
      //     axisX: {
      //       showGrid: false
      //     },
      //     lineSmooth: Chartist.Interpolation.simple({
      //       divisor: 3
      //     }),
      //     showLine: true,
      //     showPoint: false
      //   }
      // },
      // activityChart: {
      //   data: {
      //     labels: [
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //       "Apr",
      //       "Mai",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //       "Dec"
      //     ],
      //     series: [
      //       [542, 543, 520, 680, 653, 753, 326, 434, 568, 610, 756, 895],
      //       [230, 293, 380, 480, 503, 553, 600, 664, 698, 710, 736, 795]
      //     ]
      //   },
      //   options: {
      //     seriesBarDistance: 10,
      //     axisX: {
      //       showGrid: false
      //     },
      //     height: "245px"
      //   }
      // },
      // preferencesChart: {
      //   data: {
      //     labels: ["62%", "32%", "6%"],
      //     series: [62, 32, 6]
      //   },
      //   options: {}
      // }
    }
  },

  computed: {
    registeredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.registered_user_count : 0
    },
    transactedUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.transacted_user_count : 0
    },
    notTransactedUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.not_transacted_user_count : 0
    },
    completedKycUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.completed_kyc_user_count : 0
    },
    haveActiveBankAccountUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.have_active_bank_account_user_count : 0
    },
    haveSuccessWithdrawalUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.have_success_withdrawal_user_count : 0
    },
    numOfBoughtTokenTransactions () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_bought_info
        ? this.statisticalInfo.tokens_bought_info.num_of_transactions : 0
    },
    numOfBoughtTokenByAdminTransactions () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_bought_by_admin_info
        ? this.statisticalInfo.tokens_bought_by_admin_info.num_of_transactions : 0
    },
    numOfBoughtTokens () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_bought_info
        ? this.statisticalInfo.tokens_bought_info.num_of_tokens : 0
    },
    numOfBoughtTokensByAdmin () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_bought_by_admin_info
        ? this.statisticalInfo.tokens_bought_by_admin_info.num_of_tokens : 0
    },
    // numOfBoughtTokenUsers () {
    //   return this.statisticalInfo != null && this.statisticalInfo.tokens_bought_info
    //     ? this.statisticalInfo.tokens_bought_info.num_of_users : 0
    // },
    numOfSoldTokenTransactions () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_sold_info
        ? this.statisticalInfo.tokens_sold_info.num_of_transactions : 0
    },
    numOfSoldTokenByAdminTransactions () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_sold_by_admin_info
        ? this.statisticalInfo.tokens_sold_by_admin_info.num_of_transactions : 0
    },
    numOfSoldTokens () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_sold_info
        ? this.statisticalInfo.tokens_sold_info.num_of_tokens || 0 : 0
    },
    numOfSoldTokensByAdmin () {
      return this.statisticalInfo != null && this.statisticalInfo.tokens_sold_by_admin_info
        ? this.statisticalInfo.tokens_sold_by_admin_info.num_of_tokens || 0 : 0
    },
    // numOfSoldTokenUsers () {
    //   return this.statisticalInfo != null && this.statisticalInfo.tokens_sold_info
    //     ? this.statisticalInfo.tokens_sold_info.num_of_users : 0
    // },
    tokensBoughtNet () {
      return this.statisticalInfo != null ? this.statisticalInfo.tokens_bought_net : 0
    },
    tokensBoughtByAdminNet () {
      return this.statisticalInfo != null ? this.statisticalInfo.tokens_bought_by_admin_net : 0
    },
    totalTokensBoughtNet () {
      return this.tokensBoughtNet + this.tokensBoughtByAdminNet
    },
    totalPaymentCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.total_payment_count : 0
    },
    successPaymentCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.success_payment_count : 0
    },
    failedPaymentCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.failed_payment_count : 0
    },
    avgSuccessTransactionsPerTransactedUser () {
      return this.statisticalInfo != null ? this.statisticalInfo.avg_success_transactions_per_transacted_user : 0
    },
    avgSuccessTransactionsAmount () {
      return this.statisticalInfo != null ? this.statisticalInfo.avg_success_transactions_amount : 0
    },
    avgTokensOwnedPerTransactedAccount () {
      return this.statisticalInfo != null ? this.statisticalInfo.avg_tokens_owned_per_transacted_account : 0
    },
    todayRegisteredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.today_registered_user_count : 0
    },
    yesterdayRegisteredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.yesterday_registered_user_count : 0
    },
    todayRegisteredIncreasePercent () {
      return this.statisticalInfo != null ? this.statisticalInfo.today_registered_increase_percent : 0
    },
    thisWeekRegisteredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_week_registered_user_count : 0
    },
    lastWeekRegisteredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.last_week_registered_user_count : 0
    },
    thisWeekRegisteredIncreasePercent () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_week_registered_increase_percent : 0
    },
    thisMonthRegisteredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_month_registered_user_count : 0
    },
    lastMonthRegisteredUserCount () {
      return this.statisticalInfo != null ? this.statisticalInfo.last_month_registered_user_count : 0
    },
    thisMonthRegisteredIncreasePercent () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_month_registered_increase_percent : 0
    },
    todayBoughtTokens () {
      return this.statisticalInfo != null ? this.statisticalInfo.today_bought_tokens : 0
    },
    yesterdayBoughtTokens () {
      return this.statisticalInfo != null ? this.statisticalInfo.yesterday_bought_tokens : 0
    },
    todayBoughtTokensIncreasePercent () {
      return this.statisticalInfo != null ? this.statisticalInfo.today_bought_tokens_increase_percent : 0
    },
    thisWeekBoughtTokens () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_week_bought_tokens : 0
    },
    lastWeekBoughtTokens () {
      return this.statisticalInfo != null ? this.statisticalInfo.last_week_bought_tokens : 0
    },
    thisWeekBoughtTokensIncreasePercent () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_week_bought_tokens_increase_percent : 0
    },
    thisMonthBoughtTokens () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_month_bought_tokens : 0
    },
    lastMonthBoughtTokens () {
      return this.statisticalInfo != null ? this.statisticalInfo.last_month_bought_tokens : 0
    },
    thisMonthBoughtTokensIncreasePercent () {
      return this.statisticalInfo != null ? this.statisticalInfo.this_month_bought_tokens_increase_percent : 0
    },
    totalAmountPaidAsRentalIncome () {
      return this.statisticalInfo != null ? this.statisticalInfo.total_amount_paid_as_rental_income : 0
    },

    breakdownPaymentChannels () {
      return this.statisticalInfo != null ? this.statisticalInfo.breakdown_payment_channels : 0
    },
  }
}
</script>
<style>
</style>
