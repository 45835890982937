<template>
    <div>
        <div v-if="isUser" @click="showNotification" class="notification mr-3 ml-1 d-flex flex-row align-items-start">
            <img :src="notificationIcon" width="36" />
        </div>
        <b-modal v-model="show" id="full-screen-modal" size="huge"
                 header-class="modal-header" footer-class="modal-footer footer-notifications" body-class="modal-body-full" scrollable>
            <template #modal-header>
                <div class="d-flex flex-row align-items-center justify-content-between" style="width:100%;">
                    <b-button id="btn_notification_Back" v-if="selectedNotification != null"
                        style="border: none; background-color: transparent; color: black"
                        @click.prevent="selectedNotification = null">
                        <b-icon icon="arrow-left" class="pr-1 pt-1" style="color: black;" scale="0.8"></b-icon>
                        {{ $t("common.BACK") }}
                    </b-button>
                    <div v-else class="ml-3"></div>
                    <h5 class="modal-header-title">{{ selectedNotification != null ? $t('common.NOTIFICATION_DETAILS') :
                        $t('common.NOTIFICATIONS') }}</h5>
                    <button id="btn_hideNotification" type="button" class="close-btn font-weight-bold mr-3" aria-label="Close"
                        @click="onHide()">×</button>
                </div>
            </template>
            <div v-if="selectedNotification == null">
                <div class="notification-item mt-2 d-flex flex-column"
                    :class="[{ 'notification-unread': !notification.read }, { 'notification-read': notification.read }]"
                    v-for="notification in notifications" :key="notification.id">
                    <div class="d-flex flex-row align-items-center notification-item-content">
                        <b-icon v-if="!notification.read" icon="circle-fill" class="status-unread" variant="danger" scale="0.7"
                            style="margin-right: 5px;"></b-icon>
                        <div class="notification-content" @click="readNotification(notification)">
                            <div class="notification-title">{{
                                notification.title
                            }}</div>
                            <div class="notification-content">{{
                                notification.message
                            }} <span
                                    v-if="notification.expires_on != null && !isExpired(notification.expires_on) && notification.status == 'UNCLAIM'">
                                    {{$t('common.CLAIM_BEFORE_EXPIRED')}}</span></div>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between mt-2 notification-item-time">
                        <div v-if="notification.expires_on != null" class="notification-time notification-time-expired-in">
                            <div v-if="notification.status == 'CLAIMED'">{{ notification.type ===
                                'VIRTUAL_RENTAL_DISTRIBUTION' ?
                                $t('common.RENTAL_INCOME_HAS_BEEN_CLAIMED') : $t('common.REFERRAL_BONUS_HAS_BEEN_CLAIMED')
                            }}
                            </div>
                            <div v-else-if="isExpired(notification.expires_on)">{{ $t('common.EXPIRED_ON',
                                { value: getFormattedTime(notification.expires_on) }) }}</div>
                            <div v-else style="color:red;">{{ $t('common.EXPIRES_ON',
                                { value: getFormattedTime(notification.expires_on) }) }}</div>
                        </div>
                        <div v-else></div>
                        <div class="notification-time">{{ getFormattedTime(notification.created_at) }}</div>
                    </div>
                </div>
            </div>
            <b-row v-else align-h="center" class="pl-2 pr-2">
                <b-col cols="12" md="12" lg="5" class="notification-detail">
                    <p class="mt-3 font-weight-bold font-18">{{ selectedNotification.title }}</p>
                    <p>{{ selectedNotification.message }}</p>
                    <div v-if="selectedNotification.attachment != null && selectedNotification.attachment.amount > 0"
                        class="notification-amount font-24 font-weight-bold mt-4">{{
                            selectedNotification.attachment.currency }}{{ notificationAmount }}</div>
                    <div v-if="selectedNotification.expires_on != null" class="notification-time text-center mt-2">
                        <div v-if="selectedNotification.status == 'CLAIMED'">{{ selectedNotification.type ===
                            'VIRTUAL_RENTAL_DISTRIBUTION' ?
                            $t('common.RENTAL_INCOME_HAS_BEEN_CLAIMED') : $t('common.REFERRAL_BONUS_HAS_BEEN_CLAIMED')
                        }}
                        </div>
                        <div v-else-if="isExpired(selectedNotification.expires_on)">{{ $t('common.EXPIRED_ON',
                            { value: getFormattedTime(selectedNotification.expires_on) }) }}</div>
                        <div v-else style="color:red;">{{ $t('common.EXPIRES_ON',
                            { value: getFormattedTime(selectedNotification.expires_on) }) }}</div>
                    </div>
                    <div class="mt-5 d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <p class="m-0 font-14 font-weight-bold">GORO Team,</p>
                            <p class="font-12">{{ getFormattedTime(selectedNotification.created_at) }}</p>
                        </div>
                        <b-button id="btn_notification_claimRentalIncome" 
                            v-if="selectedNotification != null && selectedNotification.status == 'UNCLAIM' && !isExpired(selectedNotification.expires_on)"
                            size="sm" class="btn-outline-main" variant="primary" @click="claimRentalIncome">
                            {{ $t('common.CLAIM_NOW') }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button id="btn_notification_markAllAsRead" v-if="selectedNotification == null" :disabled="unread === 0" size="sm" class="btn-outline-main bottom-btn btn-absolute"
                    variant="primary" @click="markAllAsRead()">
                    {{ $t('common.MARK_ALL_AS_READ') }}
                </b-button>
                <b-button id="btn_notification_viewDetail" v-else-if="canViewDetails" size="sm" class="btn-outline-main bottom-btn btn-absolute" variant="primary"
                    @click="navigateToScreen">
                    {{ $t('common.VIEW_DETAILS') }}
                </b-button>
                <div v-else></div>
                <b-pagination v-if="totalItems && selectedNotification == null" class="trx-pagination align-self-center" :prev-text="$t('common.PREVIOUS')" :next-text="$t('common.NEXT')" v-model="currentPage" :total-rows="totalItems" :per-page="perPage" 
                            aria-controls="my-table">
                </b-pagination>
            </template>
        </b-modal>
    </div>
</template>

<script>

import notificationsService from "../services/notifications.service"
import moment from "moment"
import { isUser } from "../constants/roles"
import { formatCurrency } from "../helpers/common"
import { gtmTrackEvent } from "../helpers/gtm"
import { GTM_EVENT_NAMES } from "../constants/gtm"
import { PAGINATION_DEFAULT } from "../constants/pagination"

export default {
    data() {
        return {
            show: false,
            notifications: [],
            selectedNotification: null,
            routeMap: {
                PAYMENT: 'transactions',
                KYC: 'kYC',
                REFERRAL: 'referrals',
                ACTIVATE_ACCOUNT: 'account',
                ACCOUNT: 'account',
                MARKETPLACE: 'marketplace',
                WITHDRAWAL: 'withdrawalHistory',
                BANK_HISTORY: 'bankAccountHistory',
                RENTAL_DISTRIBUTION: 'rentalIncomeReports',
                VIRTUAL_RENTAL_DISTRIBUTION: 'rentalIncomeReports',
                SELL_TOKEN: 'account',
            },
            perPage: PAGINATION_DEFAULT.perPage,
            currentPage: 1,
            totalItems: 0,
        };
    },
    methods: {
        async getNotifications() {
            this.selectedNotification = null
            const filters = {
                page: this.currentPage,
                per_page: this.perPage,
            }
            const res = await notificationsService.getNotifications(filters);
            this.totalItems = res && res.total ? parseInt(res.total) : 0
            this.notifications = res.data ? res.data : [];
        },

        async showNotification() {
            gtmTrackEvent({
                event: GTM_EVENT_NAMES.NOTIFICATIONS
            })
            await this.getNotifications();
            this.show = true;
        },

        async markAllAsRead() {
            const res = await notificationsService.markAllAsRead();
            if (res && res.data) {
                this.notifications = res.data;
            }
        },

        getFormattedTime(time) {
            return moment(time).format('DD/MM/YYYY HH:mm');
        },

        async navigateToScreen() {
            const name = this.routeMap[this.selectedNotification.type];
            if (name) {
                this.$router.push({ name });
                this.onHide()
            }
        },

        async readNotification(notify) {
            this.selectedNotification = notify
            if (!notify.read) {
                const notification = await notificationsService.read({ id: notify.id });
                if (notification) {
                    this.getUnreadCount();
                    this.notifications = this.notifications.map(n => {
                        if (n.id === notification.id) {
                            n.read = true;
                        }
                        return n;
                    });
                }
            }
        },

        getUnreadCount() {
            this.$store.dispatch('getUnreadNotificationCount');
        },

        onHide() {
            this.selectedNotification = null
            this.show = false
        },

        isExpired(expiresOn) {
            return moment() > moment(expiresOn)
        },

        async claimRentalIncome() {
            const res = await notificationsService.claimNotification({ id: this.selectedNotification.id })
            if (res && res.data) {
                this.selectedNotification = res.data
                this.notifications = this.notifications.map(e => {
                    if (e.id === this.selectedNotification.id) {
                        return this.selectedNotification
                    }
                    return e
                });
            }
        },
    },

    computed: {
        isUser() {
            return isUser()
        },

        unread() {
            return this.$store.getters.unread;
        },

        notificationIcon() {
            const images = require.context('@/assets/img/account/', false, /\.svg$/)
            if (this.unread > 0) {
                return images('./' + 'notification-active.svg')
            }
            return images('./' + 'notification.svg')
        },

        canViewDetails() {
            return this.routeMap[this.selectedNotification.type]
        },

        notificationAmount() {
            return formatCurrency(this.selectedNotification.attachment.amount)
        }
    },

    watch: {
        currentPage: {
            handler: async function(value) {
                await this.getNotifications()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.notification {
    cursor: pointer;
}

.notification-item {
  width: 60%;
  margin: auto;
  padding: 0 20px;
  .notification-item-content{
    position: relative;
    svg.status-unread{
        position: absolute;
        top: 35%;
        left: -20px;
    }
  }
  .notification-item-time{
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(180, 180, 180);
  }
}



@media (max-width: 766.98px) {
    .notification-item {
        width: 100%;
    }
}

.notification-content {
    cursor: pointer;
}

.notification-time {
    font-size: 12px;
    font-weight: 300;
}

.notification-time-expired-in{
}

.notification-read {
    color: rgb(160, 160, 160);
}

.notification-unread {
    color: rgb(39, 39, 39);
}

.notification-title {
    font-size: 20px;
    font-weight: 700;
}

.notification-content {
    font-size: 15px;
    font-weight: 300;
}

.notification-detail {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 20px;
}

.notification-amount {
    padding: 10px 30px 10px 30px;
    background-color: white;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    width: fit-content;
    margin: auto;
}

.close-btn {
    color: #727272;
    font-size: 25px;
    border: none;
    background-color: transparent;
}
.bottom-btn {
    margin-right: 60px;
    @media screen and (max-width: 992px) {
      margin-right: 0px !important;
    }
}
</style>
