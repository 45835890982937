const axios = require("axios")

let googleAuth = (function () {
  function installClient () {
    let apiUrl = "https://accounts.google.com/gsi/client"
    return new Promise((resolve) => {
      let script = document.createElement("script")
      script.src = apiUrl
      script.onreadystatechange = script.onload = function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            resolve()
          }, 500)
        }
      }
      document.getElementsByTagName("head")[0].appendChild(script)
    })
  }

  function Auth () {
    if (!(this instanceof Auth))
      return new Auth()
    this.tokenClient = null
    this.config = null
    this.prompt = null

    this.load = async (config, prompt) => {
      installClient().then(() => {
        this.tokenClient = window.google.accounts.oauth2.initTokenClient(config)
        this.config = config
        this.prompt = prompt
      })
    }

    //return TokenResponse (https://developers.google.com/identity/oauth2/web/reference/js-reference#TokenResponse)
    this.signIn = async (successCallback, errorCallback) => {
      return new Promise((resolve, reject) => {
        try {
          if (!this.tokenClient) {
            this.tokenClient = window.google.accounts.oauth2.initTokenClient(this.config)
          }
          // Settle this promise in the response callback for requestAccessToken()
          this.tokenClient.callback = (tokenResponse) => {
            if (tokenResponse.error !== undefined) {
              if (typeof errorCallback === "function") errorCallback(tokenResponse)
              reject(tokenResponse)
            } else {
              try {
                //console.log(resp)
                let accessToken = tokenResponse.access_token
                axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`)
                  .then(userInfoResp => {
                    let googleUserInfo = {
                      google_id: userInfoResp.data.sub,
                      google_token: accessToken,
                      email: userInfoResp.data.email,
                      name: userInfoResp.data.name
                    }
                    if (typeof successCallback === "function") successCallback(googleUserInfo)
                    resolve(googleUserInfo)
                  })
              } catch (error) {
                if (typeof errorCallback === "function") errorCallback(error)
                reject(error)
              }
            }
          }
          this.tokenClient.requestAccessToken({ prompt: this.prompt })
        } catch (err) {
          if (typeof errorCallback === "function") errorCallback(err)
          reject(err)
        }
      })
    }

    //we have 2 login cuz Google One tap UI login has features#exponential_cool_down if user clicks on 'X',
    // the One Tap will be disabled for a while. if user declines one tap login he'll see popup(signIn)
    // this.signInOneTap = async (successCallback, errorCallback) => {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       window.google.accounts.id.initialize({
    //         client_id: this.config.client_id,
    //         scope: this.config.scope,
    //         callback: handleCredentialResponse, // defined at request time
    //       })
    //
    //       // Callback response: https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
    //       function handleCredentialResponse (resp) {
    //         if (resp.error !== undefined) {
    //           if (typeof errorCallback === "function") errorCallback(resp)
    //           reject(resp)
    //         } else {
    //           //Online encode/decode: https://jwt.io/
    //           const responsePayload = VueJwtDecode.decode(resp.credential)
    //           //console.log("userId: " + responsePayload.sub)
    //           //console.log("name: " + responsePayload.name)
    //           //console.log("email: " + responsePayload.email)
    //           let googleUserInfo = {
    //             google_id: responsePayload.sub,
    //             email: responsePayload.email,
    //             name: responsePayload.name
    //           }
    //           if (typeof successCallback === "function") successCallback(googleUserInfo)
    //           resolve(googleUserInfo)
    //         }
    //       }
    //
    //       window.google.accounts.id.prompt((notification) => {
    //         if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
    //           console.log("Prompt cancelled by user")
    //           //continue with another identity provider.
    //           resolve(this.signIn())
    //         }
    //       })
    //     } catch (err) {
    //       if (typeof errorCallback === "function") errorCallback(err)
    //       reject(err)
    //     }
    //   })
    // }
  }

  return new Auth()
})()

function installGoogleAuthPlugin (Vue, options) {
  //set config
  let GoogleAuthConfig = null
  let GoogleAuthDefaultConfig = {
    scope: "profile email",
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]
  }
  let prompt = "select_account"
  if (typeof options === "object") {
    GoogleAuthConfig = Object.assign(GoogleAuthDefaultConfig, options)
    if (options.scope) GoogleAuthConfig.scope = options.scope
    if (options.prompt) prompt = options.prompt
    if (!options.client_id) {
      console.warn("client_id is required")
    }
  } else {
    console.warn("invalid option type. Object type accepted only")
  }

  //Install Vue plugin
  Vue.gAuth = googleAuth
  Object.defineProperties(Vue.config.globalProperties, {
    $gAuth: {
      get: function () {
        return Vue.gAuth
      }
    }
  })
  Vue.gAuth.load(GoogleAuthConfig, prompt)
}

export default installGoogleAuthPlugin
