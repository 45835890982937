import moment from "moment"
import browserID from "browser-id"
import currencyFormatter from "currency.js"
import store from "../store/store"
import { notify as vueNotify } from "@kyvg/vue3-notification"
import numeral from "numeral"
import i18n from "../i18n"
import { FOREIGNER } from "../constants/constants"
import { STATUS, TransactionType } from "../constants/constants"

const base_url = process.env.VUE_APP_IMG_HOST;

const notify = ({ text = "", title = "", type = "success" }) => {
  if (!["warn", "error", "success"].includes(type)) {
    type = "success";
  }

  vueNotify({
    text,
    title,
    type,
    duration: 4000,
  });
};
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const urlImage = (image, thumbnail = false) => {
  if (thumbnail) {
    if (image && image.thumbnail) {
      if (image.thumbnail.startsWith('http')) {
        return image.thumbnail
      }
      return base_url + "/" + image.thumbnail;
    }
  } else {
    if (image && image.image) {
      if (image.image.startsWith('http')) {
        return image.image
      }
      return base_url + "/" + image.image;
    }
  }
  return null;
};

const getRandomFloat = (min, max, decimals) => {
  const str = (Math.random() * (max - min) + min).toFixed(decimals);

  return parseFloat(str);
};

const helpers = {
  notify,
  getBase64,
  urlImage,
  getRandomFloat,
};

const formattedDuration = (durationMilliseconds) => {
  const duration = moment.duration(durationMilliseconds, "milliseconds");
  const h =
    duration.hours() >= 10 ? `${duration.hours()}` : `0${duration.hours()}`;
  const m =
    duration.minutes() >= 10
      ? `${duration.minutes()}`
      : `0${duration.minutes()}`;
  const s =
    duration.seconds() >= 10
      ? `${duration.seconds()}`
      : `0${duration.seconds()}`;
  return `${h}:${m}:${s}`;
};

function numberWithCommas(x, separator = ',') {
  if (x || x == 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }
  return "";
}

function imageSizeFromBase64(base64) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;
    img.onload = function () {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };
    img.onerror = function (e) {
      console.error(e);
    };
  });
}

function resizeBase64Img(base64, quantity = 0.8) {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.onload = function () {
      var iw = img.width;
      var ih = img.height;
      var maxW = iw / 2;
      var maxH = ih / 2;
      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      resolve(canvas.toDataURL("image/jpeg", quantity));
    };
    img.src = base64;
  });
}

function getDeviceUUID() {
  return browserID();
}

function exchange (input, num = 100, asterisk = false, currency = null, ceil = false) {
  const defaultCurrency = "IDR"
  const user = store.state.userProfile
  const locale = i18n.global.locale.value
  const rates = store.state.exchangeRates

  // For IDR
  const separator = locale === 'id' ? '.' : ','
  const decimal = locale === 'id' ? ',' : '.'

  if (!currency) {
    if (user && user.preferred_currency) {
      currency = user.preferred_currency
    } else if (locale && locale === FOREIGNER.LOCALE) {
      currency = FOREIGNER.CURRENCY
    } else {
      currency = defaultCurrency
    }
  }
  if (!input) {
    return `${currency}0${asterisk && currency === defaultCurrency ? "*" : ""}`
  }
  if (!rates) {
    currency = defaultCurrency
  }
  const rate = rates ? rates[currency] : null
  if (currency === defaultCurrency || !rate) {
    const precision = input % 1 === 0 ? 0: 2
    return currencyFormatter(parseFloat(input), { symbol: defaultCurrency, separator, decimal, precision }).format()
  }
  const value = rate * input
  const rounded = ceil ? Math.ceil(value * num) / num : Math.floor(value * num) / num
  if (rounded === 0) {
    return exchange(input, num * 10, asterisk, currency)
  }
  return `${currency}${rounded > 1 ? currencyFormatter(rounded, { symbol: '', separator: ',', decimal: '.' }).format() : rounded}${asterisk ? "*" : ""}`
}

function getErrorMessage(err) {
  let mess = ""
  if (err) {
    if (err.data) {
      if (typeof err.data === "object") {
        let i = 0
        for (const p in err.data) {
          if (err.data.hasOwnProperty(p)) {
            mess += `${err.data[p]} <br>`
            i++
          }
          if (i === 3) {
            break
          }
        }
      } else {
        mess = err.data
      }
    } else if (err.message) {
      mess = err.message
    } else if (err.error) {
      mess = err.error
    }
  }
  return mess
}

function formatCurrency(value) {
  const locale = i18n.global.locale.value
  if (locale !== 'id') {
    return numeral(value).format("0,0")
  }
  return numeral(value).format("0,0").replace(",", ".")
}

function formatByCurrency(value) {
  const locale = i18n.global.locale.value
  return new Intl.NumberFormat(locale).format(value);
}

function userHasPermission(permission) {
  const userPermissions = store.getters.adminPermissions;
  if (!userPermissions) {
    return false;
  }
  return userPermissions.includes(permission);
}

function isEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  return reg.test(email)
}

function appendTimestampToUrl (url) {
  const timestamp = Math.floor(new Date().getTime() / 1000)
  if (url.includes("?")) {
    return `${url}&t=${timestamp}`
  } else {
    return `${url}?t=${timestamp}`
  }
}

function getCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
  return null
}

function getTinyMCEApiKey() {
  return process.env.VUE_APP_TINYMCE_API_KEY || ""
}
function getTransactionStatus(e) {
  switch (e.type) {
    case TransactionType.ADMIN_ADD_BALANCE:
    case TransactionType.ADMIN_DEDUCT_BALANCE:
    case TransactionType.SYSTEM_ADD_BALANCE:
      return STATUS.COMPLETED
    case TransactionType.BUY_TOKEN:
      if (e.payment) {
        return e.payment.status
      }
      if (e.swap_token_transaction) {
        return e.swap_token_transaction.status
      }
      return STATUS.PENDING
    case TransactionType.ORDER_TOKEN:
      if (e.order) {
        return e.order.status
      }
      return STATUS.PENDING
    case TransactionType.SELL_TOKEN:
      if (e.sell_token_request) {
        return e.sell_token_request.status
      }
      if (e.swap_token_transaction) {
        return e.swap_token_transaction.status
      }
      return STATUS.PENDING
    case TransactionType.WITHDRAWAL:
      if (e.withdrawal) {
        return e.withdrawal.status
      }
      return STATUS.PENDING
    default:
      return STATUS.RECEIVED
  }
}

/**
 * convertImageToBase64
 *
 * @param {*} imageUrl
 * @returns
 */
async function convertImageToBase64(imageUrl) {
  const proxyUrl = ''
  const fetchUrl = proxyUrl + imageUrl

  try {
    const response = await fetch(fetchUrl)
    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        resolve(reader.result)
      };
      reader.onerror = (error) => {
        reject(error)
      }
    })
  } catch (error) {
    console.error('Error converting image to Base64:', error)
    // throw error
  }
}

function toFixedWithoutRounding(number, fixed = 2) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + fixed + '})?');
  return `${number}`.match(re)[0]
}

// Function to shuffle an array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

function payoutDateRules() {
  /**
   * Here's the mean of the value to get and show
   *
   * For the [Current Month & Year] rent distribution,
   * which will be paid by [Next 21, Month Year], you will only receive rent distribution
   * for [Total days] ([Date range of Buy Tokens starting from buy date until end of the month])
   * if you do not sell tokens in [Current Month].
   */
  const expectedPayoutDate = process.env.VUE_APP_EXPECTED_PAYOUT_DATE
  const nextPayoutDate = moment().set("date", expectedPayoutDate).add(1, "M").format("DD MMMM YYYY")
  const currentRent = moment().format("MMMM YYYY")

  // Get the receive from. This is the buy token date
  const receiveRentFromDate = moment().startOf('day')
  const receiveRentFrom = receiveRentFromDate.clone().format('DD')
  const receiveRentTo = receiveRentFromDate.clone().endOf('month').format('DD MMMM YYYY')

  // The month of the buy token date
  const currentMonth = moment().format('MMMM')

  // Total days: Date range of Buy Tokens starting from buy date until end of the month
  const totalDaysReceiveRent = moment(receiveRentTo).diff(receiveRentFromDate, 'days') + 1 // Include the start day

  // The range of date
  const receiveRentRangeDate = (totalDaysReceiveRent > 1) ? `${receiveRentFrom} - ${receiveRentTo}` : receiveRentTo

  return {
    currentRent,
    nextPayoutDate,
    totalDaysReceiveRent,
    receiveRentRangeDate,
    currentMonth
  }
}

function obfuscateBlockchainInfo(str) {
  if (!str || str.length <= 17) return str;
  const start = str.substring(0, 8);
  const end = str.substring(str.length - 9);
  return `${start}...${end}`;
}

export default helpers;
export {
  notify,
  getBase64,
  urlImage,
  getRandomFloat,
  formattedDuration,
  numberWithCommas,
  imageSizeFromBase64,
  resizeBase64Img,
  getDeviceUUID,
  exchange,
  getErrorMessage,
  formatCurrency,
  formatByCurrency,
  userHasPermission,
  isEmail,
  appendTimestampToUrl,
  getCookie,
  getTinyMCEApiKey,
  getTransactionStatus,
  convertImageToBase64,
  toFixedWithoutRounding,
  shuffleArray,
  payoutDateRules,
  obfuscateBlockchainInfo,
};
