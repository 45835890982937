
import serverApi from '../../utils/serverApi';

const categoryService = {
    getCategories: (data) => serverApi({ uri: 'admin/categories', method: 'GET', data }),

    createOrUpdate: (data) => serverApi({ uri: 'admin/categories', method: 'POST', data }),

    delete: (id) => serverApi({ uri: `admin/categories/${id}`, method: 'DELETE' }),
};

export default categoryService;
