import serverApi from "../../utils/serverApi";

const userService = {
  getList: (data) => serverApi({ uri: "admin/users", method: "GET", data }),

  unbanAccount: (data) =>
    serverApi({ uri: "admin/users/unban-account", method: "PUT", data }),

  updateCardInfo: (id, data) =>
    serverApi({ uri: `admin/users/${id}/card`, method: "PUT", data }),

  getIdCard: (id) =>
    serverApi({ uri: `admin/users/${id}/card`, method: "GET" }),

  updateWithdrawalStatus: (data) =>
    serverApi({
      uri: "admin/users/update-withdrawal-status",
      method: "PUT",
      data,
    }),

  verifySelfie: (id) =>
    serverApi({ uri: `admin/users/${id}/verify-selfie`, method: "PUT" }),

  uploadSelfie: (id, data) =>
    serverApi({ uri: `admin/users/${id}/upload-selfie`, method: "POST", data }),
};

export default userService;
