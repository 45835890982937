<template xmlns="http://www.w3.org/1999/html">
  <div class="home">
    <div class="main__content goro-new-banner" style="background-color: var(--primary-background-color)">
      <b-container class="goro-banner-container">
        <b-row>
          <b-col cols="12" xl="6" lg="6" md="8" sm="8" class="goro-content-banner">
            <div class="pb-2">
              <h1 class="font-46">{{ $t("LANDING.TITLE") }}</h1>
              <p class="font-18">{{ $t("LANDING.DESCRIPTION") }}</p>
            </div>
            <b-row style="margin-left: 1px;" class="goro-main-actions mt-3">
              <ripple-button id="btn_home_viewGoroVideo" ref="videoButton" class="mr-2" :video-id='goroVideoId'></ripple-button>
              <router-link v-if="!userProfile" :to="{ name: 'register' }">
                <b-button id="btn_home_getStartedEvent" class="btn-main goro-view-property" variant="none" @click="trackGtmEvent(gtmEvent.getStartedEvent)">
                  {{ $t("AUTH.GET_STARTED") }}
                </b-button>
              </router-link>
              <router-link v-if="userProfile" :to="{ name: 'marketplace' }">
                <b-button id="btn_home_top_buyProperty" class="btn-main goro-view-property" variant="none" @click="trackGtmEvent(gtmEvent.buyPropertiesEvent)">
                  {{ $t("ASSETS.VIEW_PROPERTIES") }}
                </b-button>
              </router-link>
            </b-row>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="4" sm="4" class="goro-banner-carousel">
            <div class="parent">
              <Vue3Marquee :clone="true" :vertical="true" :direction="'reverse'" :duration="durationMarquee" class="block1">
                <div v-for="(item, index) in carouselItems.block1" :key="index" class="item">
                  <img width="180" height="237" :src="require(`@/assets/img/banners/carousels/${item.image}`)" alt="Item" class="item-image"/>
                </div>
              </Vue3Marquee>
              <Vue3Marquee :clone="true" :vertical="true" :duration="durationMarquee" class="block2">
                <div v-for="(item, index) in carouselItems.block2" :key="index" class="item">
                  <img width="180" height="237" :src="require(`@/assets/img/banners/carousels/${item.image}`)" alt="Item" class="item-image"/>
                </div>
              </Vue3Marquee>
              <Vue3Marquee :clone="true" :vertical="true" :direction="'reverse'" :duration="durationMarquee" class="block3">
                <div v-for="(item, index) in carouselItems.block3" :key="index" class="item">
                  <img width="180" height="237" :src="require(`@/assets/img/banners/carousels/${item.image}`)" alt="Item" class="item-image"/>
                </div>
              </Vue3Marquee>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-show="false" class="pt-4 pb-4" style="background-color: var(--primary-background-darker-color)">
      <b-container>
        <div class="text-center">
          <h1 class="font-38 mt-0">{{ $t("LANDING.DOWNLOAD_OUR_APP") }}</h1>
        </div>
        <b-row class="pt-2 justify-content-center">
          <a class="mx-2 mt-2" :href="storeLink.appStore" target="_blank" @click="trackGtmEvent(gtmEvent.appStoreEvent)">
            <img width="220" height="65" src="../assets/img/store/appstore.png" alt=""/>
          </a>
          <a class="mx-2 mt-2" :href="storeLink.playStore" target="_blank" @click="trackGtmEvent(gtmEvent.playStoreEvent)">
            <img width="220" height="65" src="../assets/img/store/playstore.png" alt=""/>
          </a>
        </b-row>
      </b-container>
    </div>
    <div class="goro-light-section"
         style="background-color: var(--primary-background-darker-color); padding-top: 42px; padding-bottom: 42px">
      <b-container>
        <b-row class="statistic-card">
          <b-col cols="12" xl="3" lg="6" md="6" align="center" class="mt-3 mb-3">
            <p class="font-44 font-wide-bold">{{ registeredUsers }}</p>
            <p class="font-20">{{ $t("LANDING.REGISTERED_USERS") }}</p>
          </b-col>
          <b-col cols="12" xl="3" lg="6" md="6" align="center" class="mt-3 mb-3">
            <p class="font-44 font-wide-bold">{{ investedInProperty }}</p>
            <p class="font-20">{{ $t("LANDING.INVESTED_IN_PROPERTY") }}</p>
          </b-col>
          <b-col cols="12" xl="3" lg="6" md="6" align="center" class="mt-3 mb-3">
            <p class="font-44 font-wide-bold">{{ totalIncomePaid }}</p>
            <p class="font-20">{{ $t("LANDING.TOTAL_INCOME_PAID") }}</p>
          </b-col>
          <b-col cols="12" xl="3" lg="6" md="6" align="center" class="mt-3 mb-3">
            <p class="font-44 font-wide-bold">{{ numOfProperties }}</p>
            <p class="font-20">{{ $t("LANDING.PROPERTIES") }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center pb-4 pt-1" style="background-color: var(--primary-background-color)">
      <b-container>
        <b-row class="pb-3">
          <b-col cols="12">
            <h1 class="font-38 mt-3">{{ $t("LANDING.FEATURED_ON") }}</h1>
            <b-row align-h="center" align-v="center" class="pt-3">
              <b-col cols="1" class="horizontal-navigation d-flex justify-content-start" @click="scrollToPrev">
                <b-icon icon="chevron-left" scale="1.9"></b-icon>
              </b-col>
              <b-col cols="10">
                <carousel v-bind="horizontalSettings" ref="horizontal" :breakpoints="breakpoints" class="horizontal goro-featured-on-list"
                          :wrap-around="true" :autoplay="timeout">
                  <slide v-for="(item, index) in featuredItems" :key="index" class="list-item">
                    <a :href="item.url" target="_blank" class="item-link">
                      <img :src="item.image" alt="Item" class="item-image"/>
                    </a>
                  </slide>

                  <template #addons>
                    <!-- <navigation /> -->
                    <!-- <pagination /> -->
                  </template>
                </carousel>
              </b-col>
              <b-col cols="1" class="horizontal-navigation d-flex justify-content-end" @click="scrollToNext">
                <b-icon icon="chevron-right" scale="1.9"></b-icon>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="pt-4 pb-4 goro-light-section" style="background-color: var(--primary-background-darker-color)">
      <b-container>
        <div class="text-center">
          <h1 class="font-38 mt-0">{{ $t("LANDING.HOW_IT_WORKS") }}</h1>
          <p class="font-18 mb-3">{{ $t("LANDING.HOW_IT_WORKS_DESCRIPTION") }}</p>
        </div>
        <b-row class="pt-2">
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card">
              <img src="../assets/img/ic_browse.svg" alt=""/>
              <label>{{ $t("LANDING.BROWSE") }}</label>
              <p class="font-16">{{ $t("LANDING.BROWSE_DESCRIPTION") }}</p>
            </div>
          </b-col>
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card">
              <img src="../assets/img/ic_purchase.svg" alt=""/>
              <label>{{ $t("LANDING.PURCHASE") }}</label>
              <p>{{ $t("LANDING.PURCHASE_DESCRIPTION") }}</p>
            </div>
          </b-col>
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card">
              <img src="../assets/img/ic_earn.svg" alt=""/>
              <label>{{ $t("LANDING.EARN") }}</label>
              <p>{{ $t("LANDING.EARN_DESCRIPTION") }}</p>
            </div>
          </b-col>
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card">
              <img src="../assets/img/ic_exit.svg" alt=""/>
              <label>{{ $t("LANDING.EXIT") }}</label>
              <p>{{ $t("LANDING.EXIT_DESCRIPTION") }}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="pt-1 pb-4" style="background-color: var(--primary-background-color)">
      <b-container class="mt-0">
        <b-row align-h="center">
          <b-col class="text-center" cols="10" lg="8" xl="6">
            <h1 class="font-38">{{ $t("LANDING.FEATURED_PROPERTIES") }}</h1>
            <label class="mb-3 font-18">{{ $t("LANDING.FEATURED_PROPERTIES_DESCRIPTION") }}</label>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center pt-4 pb-1">
          <b-col cols="12" xl="4" lg="4" md="4" class="pb-4" v-for="property in propertiesFeatured" :key="property.id">
            <property-light-card :property="property"/>
          </b-col>
        </b-row>
        <!--        <div class="text-center">-->
        <!--          <router-link :to="{ name: 'marketplace' }">-->
        <!--            <b-button class="btn-outline-main pl-4 pr-4" type="submit" variant="none">-->
        <!--              {{ $t("LANDING.VIEW_ALL_PROPERTIES") }}-->
        <!--            </b-button>-->
        <!--          </router-link>-->
        <!--        </div>-->
      </b-container>
    </div>
    <div class="pt-1 pb-1 goro-light-section" style="background-color: var(--primary-background-darker-color);position: relative;" ref="calculatorComponent">
      <div ref="calculatorHiddenComponent" style="height: 0px; position: absolute; top: -80px; pointer-events: none;"></div>
      <b-container class="mt-4 mb-3">
        <h1 class="font-38 text-center mt-0 mb-0">{{ $t("LANDING.CALCULATOR_SIMULATION") }}</h1>
        <b-row align-h="center">
          <b-col cols="12" lg="6" xl="6" class="mt-3 mb-2 pr-3">
            <div class="calculator-card mt-3">
              <h5>{{ $t("LANDING.SELECT_PROPERTY") }}</h5>
              <v-select class="goro-select" v-model="propertyForCalculator" :options="propertiesForCalculator"
                        :clearable="false" label="name">
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
                <template #selected-option="option">
                  {{ option.name }}
                </template>
              </v-select>
              <div class="d-flex justify-content-center">
                <div class="segmented-control mt-4">
                  <b-button id="btn_home_oneTimeInvestment" :class="{ active: !monthlyInvestment}" variant="none" @click="monthlyInvestment = false">
                    {{ $t("LANDING.ONE_TIME") }}
                  </b-button>
                  <b-button id="btn_home_monthlyInvestment" :class="{ active: monthlyInvestment }" variant="none" @click="monthlyInvestment = true">
                    {{ $t("LANDING.MONTHLY") }}
                  </b-button>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <h5 v-if="!monthlyInvestment" class="mt-4">
                  {{ $t("LANDING.INITIAL_PURCHASE", { value: currency }) }}
                </h5>
                <h5 v-if="monthlyInvestment" class="mt-4">
                  {{ $t("LANDING.MONTHLY_PURCHASE", { value: currency }) }}
                </h5>
              </div>
              <b-form-input v-model="initialPurchase" type="text" style="height: 40px; color: var(--primary-color)"
                            :placeholder="initialPurchaseHint" :formatter="formatInitialPurchase"/>

              <h5 class="mt-4">{{ $t("LANDING.PERIOD", { value: investmentPeriod }) }}</h5>
              <Slider v-model="investmentPeriod" class="slider mt-2 mb-3"
                      :min='1' :max='10' :step='1' :lazy='false' :tooltips='false'/>

              <div class="d-flex justify-content-between" style="margin-top: -5px">
                <h5 class="mt-3">{{ $t("LANDING.EXPECTED_RENTAL_YIELD") }}</h5>
                <p class="rental-yield">{{ rentalYieldPercent }}%</p>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <b-form-radio v-model="selectedEry" value="LAST_MONTH" class="mt-0">
                    {{ $t("LANDING.LAST_MONTH") }}
                  </b-form-radio>
                  <b-form-radio v-model="selectedEry" value="AVERAGE" class="mt-2">
                    {{ $t("LANDING.AVERAGE") }}
                  </b-form-radio>
                </div>
              </div>
              <div class="d-flex justify-content-between" style="margin-top: 12px">
                <div>
                  <b-form-checkbox v-model="includeEca"
                                   style="font-weight: 400; font-size: 16px; z-index: 0; margin-top: 9px">
                    {{ $t("LANDING.INCLUDE_EXPECTED_CAPITAL_APPRECIATION") }}
                    <img class="compound-tooltip-icon" src="../assets/img/info-circle-fill.png" id="tooltip-eca" alt="">
                  </b-form-checkbox>
                  <b-tooltip variant="secondary" target="tooltip-eca" triggers="hover" placement="top">
                    {{ $t("propertyDetail.ECA_ANNUAL_TOOLTIP") }}
                  </b-tooltip>
                </div>
                <p class="rental-yield font-28">{{ ecaPercent }}%</p>
              </div>
              <b-form-checkbox v-model="compoundByReinvesting"
                               class="mt-1" style="font-weight: 400; font-size: 16px; z-index: 0">
                {{ $t("LANDING.COMPOUND_BY_AUTO_PURCHASE") }}
                <img class="compound-tooltip-icon"
                     src="../assets/img/info-circle-fill.png" id="tooltip-compound" alt="">
              </b-form-checkbox>
              <b-tooltip variant="secondary" target="tooltip-compound" triggers="hover" placement="top">
                {{ $t("LANDING.COMPOUND_BY_AUTO_PURCHASE_TOOLTIP", { value: exchangeValue(10000) }) }}
              </b-tooltip>
            </div>
            <p class="font-12 mt-3 ml-3 mr-3" v-html="$t('LANDING.CALCULATOR_DISCLAIMER')"></p>
          </b-col>
          <b-col cols="12" lg="6" xl="6" class="mt-3 mb-0 pl-3">
            <b-col class="calculator-card mt-3">
              <div class="expected-income">
                <p class="projected-text font-23 mb-3" :class="compoundByReinvesting ? 'mt-0' : 'mt-5' "
                   v-html="$t('LANDING.PROJECTED_INCOME_RETURN', { year: investmentPeriod > 1 ? $t('LANDING.YEARS', { value: investmentPeriod }) : $t('LANDING.YEAR', { value: investmentPeriod }) })">
                </p>
                <p class="mt-4 mb-4">{{ $t('LANDING.PURCHASE_TOKEN_DESCRIPTION', { purchase: this.initialPurchaseFormat, token: this.purchaseToken }) }} {{ this.purchaseToken > 1 ? $t('common.TOKENS') : $t('common.TOKEN') }}</p>
                <p class="mt-4" v-html="$t('LANDING.ESTIMATED_RENTAL_YIELD_RECEIVED', { year: investmentPeriod > 1 ? $t('LANDING.YEARS', { value: investmentPeriod }) : $t('LANDING.YEAR', { value: investmentPeriod }) })">
                </p>
                <h1 class="font-38 mt-1 mb-0">
                  {{ exchangeValue(compoundByReinvesting ? expectedIncomeByReinvestingIdr : expectedIncomeIdr) }}
                </h1>
                <h5 class="font-bold mt-0 mb-0" :class="compoundByReinvesting ? 'mb-0' :'mb-4'">
                  {{
                    compoundByReinvesting
                      ? this.getEquivalentTokens(this.expectedIncomeByReinvestingIdr)
                      : this.getEquivalentTokens(this.expectedIncomeIdr)
                  }}
                </h5>
                <div class="expected-income-divider"
                     :style="{ visibility: compoundByReinvesting ? 'visible' : 'hidden' }"/>
                <b-row class="pl-3 pr-3 mt-1" :style="{ visibility: compoundByReinvesting ? 'visible' : 'hidden' }">
                  <b-col cols="8" class="pl-0 pr-0">
                    <p class="font-16 text-left"> {{ $t("LANDING.EXPECTED_INCOME_WITH_COMPOUND") }}</p>
                  </b-col>
                  <b-col cols="4" class="pl-0 pr-1">
                    <p class="font-bolder font-16 text-right">
                      +{{ exchangeValue(expectedIncomeByReinvestingIdr - expectedIncomeIdr) }}
                    </p>
                    <p class="font-bolder font-14 text-right">
                      {{ this.getEquivalentTokens(this.expectedIncomeByReinvestingIdr - this.expectedIncomeIdr) }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <div class="total-asset-card mt-4">
              <p v-html="$t('LANDING.TOTAL_ASSETS_IN_YEAR', { year: investmentPeriod > 1 ? $t('LANDING.YEARS', { value: investmentPeriod }) : $t('LANDING.YEAR', { value: investmentPeriod }) })">
              </p>
              <p class="font-bold font-33 text-left">{{ totalAsset }}</p>
              <p class="font-bold mt-0">{{ totalAssetTokensDetail }}</p>
            </div>
            <b-button id="btn_home_calculatorInvest" class="btn-main mt-4 mb-3" style="width: 100%; height: 45px" variant="none"
                      @click="onCalculatorInvestClicked">
              {{ $t("ASSETS.VIEW_PROPERTIES") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center pb-3 pt-3" style="background-color: var(--primary-background-color)">
      <b-container class="benefit-container">
        <b-row class="align-items-center" align-h="around">
          <b-col cols="12" xl="6" lg="6" class="position-relative">
            <h1 class="font-38 text-center mt-3">{{ $t("LANDING.OUR_INVESTOR_BENEFIT") }}</h1>
            <p class="font-18 text-center">{{ $t("LANDING.HIGH_RETURNS_AND_LOW_VOLATILITY") }}</p>
            <img v-if="this.$i18n.locale !== 'id'" class="mt-4 mb-3" style="width: 110%; margin-left: -20px"
                 src="../assets/img/landing_benefit_1.png" alt="Landing Benefit 1">
            <img v-if="this.$i18n.locale === 'id'" class="mt-4 mb-3" style="width: 110%; margin-left: -20px"
                 src="../assets/img/landing_benefit_1_id.png" alt="Landing Benefit 1">
            <p class="information-caption">{{ $t("LANDING.OUR_INVESTOR_BENEFIT_CAPTION") }}</p>
          </b-col>
          <b-col cols="12" xl="6" lg="6">
            <img v-if="this.$i18n.locale !== 'id'" class="mt-2 mb-3" style="width: 105%;"
                 src="../assets/img/landing_benefit_2.png" alt="Landing Benefit 2">
            <img v-if="this.$i18n.locale === 'id'" class="mt-4 mb-3" style="width: 110%; margin-left: -20px"
                 src="../assets/img/landing_benefit_2_id.png" alt="Landing Benefit 2">
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center pb-1 pt-3 goro-light-section" style="background-color: var(--primary-background-darker-color)">
      <b-container>
        <b-row class="align-items-center" align-h="around">
          <b-col cols="12">
            <h1 class="font-38 text-center mb-0">{{ $t("LANDING.TESTIMONIALS") }}</h1>
            <b-row v-if="innerWidth >= 992">
              <b-col cols="12" xl="4" lg="4" class="mt-5">
                <div class="w-card-4-a text-left">
                  <b-row>
                    <b-col>
                      <img style="width: 105%; margin-left: -18px; margin-top: -30%" :src="testimonial_1_photo"
                           alt="Testimonial 1">
                    </b-col>
                    <b-col>
                      <h5 class="font-bolder" style="margin-left: -35px">{{ $t("LANDING.TESTIMONIAL_1_NAME") }}</h5>
                      <p style="margin-left: -35px; margin-top: -5px; font-size: 14px">{{
                          $t("LANDING.TESTIMONIAL_1_TITLE")
                        }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="review-content">
                    <p class="ml-3 mr-3 mt-1">{{ $t("LANDING.TESTIMONIAL_1_COMMENT") }}</p>
                  </b-row>
                </div>
              </b-col>
              <b-col cols="12" xl="4" lg="4" class="mt-5">
                <div class="w-card-4-a text-left">
                  <b-row>
                    <b-col>
                      <img style="width: 105%; margin-left: -18px; margin-top: -30%" :src="testimonial_2_photo"
                           alt="Testimonial 2">
                    </b-col>
                    <b-col>
                      <h5 class="font-bolder" style="margin-left: -35px">{{ $t("LANDING.TESTIMONIAL_2_NAME") }}</h5>
                      <p style="margin-left: -35px; margin-top: -5px; font-size: 14px">
                        {{ $t("LANDING.TESTIMONIAL_2_TITLE") }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="review-content">
                    <p class="ml-3 mr-3 mt-1">{{ $t("LANDING.TESTIMONIAL_2_COMMENT") }}</p>
                  </b-row>
                </div>
              </b-col>

              <b-col cols="12" xl="4" lg="4" class="mt-5">
                <div class="w-card-4-a text-left">
                  <b-row>
                    <b-col>
                      <img style="width: 105%; margin-left: -18px; margin-top: -30%" :src="testimonial_3_photo"
                           alt="Testimonial 3">
                    </b-col>
                    <b-col>
                      <h5 class="font-bolder" style="margin-left: -35px">{{ $t("LANDING.TESTIMONIAL_3_NAME") }}</h5>
                      <p style="margin-left: -35px; margin-top: -5px; font-size: 14px">
                        {{ $t("LANDING.TESTIMONIAL_3_TITLE") }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="review-content">
                    <p class="ml-3 mr-3 mt-1">{{ $t("LANDING.TESTIMONIAL_3_COMMENT") }}</p>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="innerWidth < 992">
              <b-col cols="12" xl="4" lg="4" class="mt-4">
                <div class="w-card-4-b text-left">
                  <b-row class="align-items-center">
                    <img style="width: 25%; height: 25%; margin-left: -8%;" :src="testimonial_1_photo"
                         alt="Testimonial 1">
                    <b-col>
                      <h5 class="font-bolder">{{ $t("LANDING.TESTIMONIAL_1_NAME") }}</h5>
                      <p style="margin-top: -5px">{{ $t("LANDING.TESTIMONIAL_1_TITLE") }}</p>
                      <div class="review-content">
                        <p class="mt-3 mb-2">{{ $t("LANDING.TESTIMONIAL_1_COMMENT") }}</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col cols="12" xl="4" lg="4" class="mt-4">
                <div class="w-card-4-b text-left">
                  <b-row class="align-items-center">
                    <img style="width: 25%; height: 25%; margin-left: -8%;" :src="testimonial_2_photo"
                         alt="Testimonial 2">
                    <b-col>
                      <h5 class="font-bolder">{{ $t("LANDING.TESTIMONIAL_2_NAME") }}</h5>
                      <p style="margin-top: -5px">{{ $t("LANDING.TESTIMONIAL_2_TITLE") }}</p>
                      <div class="review-content">
                        <p class="mt-3 mb-2">{{ $t("LANDING.TESTIMONIAL_2_COMMENT") }}</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col cols="12" xl="4" lg="4" class="mt-4">
                <div class="w-card-4-b text-left">
                  <b-row class="align-items-center">
                    <img style="width: 25%; height: 25%; margin-left: -8%;" :src="testimonial_3_photo"
                         alt="Testimonial 2">
                    <b-col>
                      <h5 class="font-bolder">{{ $t("LANDING.TESTIMONIAL_3_NAME") }}</h5>
                      <p style="margin-top: -5px">{{ $t("LANDING.TESTIMONIAL_3_TITLE") }}</p>
                      <div class="review-content">
                        <p class="mt-3 mb-2">{{ $t("LANDING.TESTIMONIAL_3_COMMENT") }}</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="pb-4 pt-4 pb-lg-5 pt-lg-5 goro-light-section" style="background-color: var(--primary-background-color)">
      <b-container>
        <b-row align-h="center">
          <b-col class="text-center" cols="10" lg="8" xl="8">
            <h1 class="font-38 mt-0">{{ $t("LANDING.WHY") }}</h1>
            <label class="col-10 col-lg-9 col-xl-8">{{ $t("LANDING.WHY_DESCRIPTION") }}</label>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card-3">
              <img src="../assets/img/ic_appreciation_primary.svg" alt=""/>
              <h5 class="font-bolder">{{ $t("LANDING.VALUE_APPRECIATION") }}</h5>
              <p class="font-16">{{ $t("LANDING.VALUE_APPRECIATION_DESCRIPTION") }}</p>
            </div>
          </b-col>
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card-3">
              <img src="../assets/img/ic_inflation_primary.svg" alt=""/>
              <h5 class="font-bolder">{{ $t("LANDING.HEDGE_FOR_INFLATION") }}</h5>
              <p class="font-16">{{ $t("LANDING.HEDGE_FOR_INFLATION_DESCRIPTION") }}</p>
            </div>
          </b-col>
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card-3">
              <img src="../assets/img/ic_income_primary.svg" alt=""/>
              <h5 class="font-bolder">{{ $t("LANDING.PASSIVE_INCOME") }}</h5>
              <p class="font-16">{{ $t("LANDING.PASSIVE_INCOME_DESCRIPTION") }}</p>
            </div>
          </b-col>
          <b-col cols="12" xl="3" lg="3">
            <div class="w-card-3">
              <img src="../assets/img/ic_wealth_primary.svg" alt=""/>
              <h5 class="font-bolder">{{ $t("LANDING.STOREHOLD_OF_WEALTH") }}</h5>
              <p class="font-16">{{ $t("LANDING.STOREHOLD_OF_WEALTH_DESCRIPTION") }}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <!-- <b-container v-if="!userProfile" class="text-center mt-4" ref="registrationForm">
        <b-row align-h="center">
          <Form v-if="!isVerifyOtp" class="form text-left col-lg-6" ref="observer" v-slot="{ handleSubmit, errors }">
            <b-form @submit.prevent="handleSubmit(requestOtp)">
              <h3 class="text-center mt-0 mb-0">{{ $t("LANDING.JOIN_THE_FUTURE_TODAY") }}</h3>
              <p class="font-18 text-center mb-3">{{ $t("LANDING.ONLY_TAKE_5_MINUTES") }}</p>

              <Field :model-value="form.name" @update:modelValue="form.name = $event" :name="$t('AUTH.FULL_NAME')"
                     :rules="{ required: true, min: 2 }" v-slot="context">
                <b-form-group :label="$t('AUTH.FULL_NAME')">
                  <b-form-input v-bind="context.field" placeholder="John Doe" aria-describedby="input-name-feedback"
                                :state="getValidationState(context.meta)"></b-form-input>
                  <b-form-invalid-feedback id="input-name-feedback">
                    {{ errors[$t("AUTH.FULL_NAME")] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </Field>

              <Field :model-value="form.email" @update:modelValue="form.email = $event" :name="$t('AUTH.EMAIL')"
                     :rules="{ required: true, email: true }" v-slot="context">
                <b-form-group class="font-14" :label="$t('AUTH.EMAIL')">
                  <b-form-input v-bind="context.field" placeholder="someone@example.com" type="email"
                                :state="getValidationState(context.meta)"
                                aria-describedby="input-email-feedback"></b-form-input>
                  <b-form-invalid-feedback id="input-email-feedback">
                    {{ errors[$t("AUTH.EMAIL")] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </Field>

              <Field :name="$t('AUTH.PHONE')" :rules="{}" v-slot="{ valid, errors, field }">
                <label class="font-14">{{ $t("AUTH.PHONE") }}</label>
                <vue-tel-input v-model="vuePhone.phone" @on-input="updatePhoneNumber" :validCharactersOnly="false"
                               :dropdownOptions="{ showDialCodeInSelection: true, showFlags: true }"
                               :inputOptions="{ autocomplete: false, name: 'phone', placeholder: 'Enter your phone number' }"
                               defaultCountry="ID" :preferredCountries="['ID']"></vue-tel-input>

                <label class="invalid" v-if="!vuePhone.isValid">
                  {{ errors[$t("AUTH.PHONE")] || "Invalid phone number" }}</label>
              </Field>

              <b-form-group v-show="false" class="font-14" :label="$t('REFERRAL.REFERRAL_CODE')">
                <b-form-input name="referral_code" placeholder="123456ABC" :disabled="disabledReferralInput"
                              v-model="form.referral_code"></b-form-input>
              </b-form-group>

              <Field :name="$t('AUTH.I_ACCEPT_TERMS_AND_PRIVACY')" :rules="{ required: { allowFalse: false } }"
                     v-slot="{ valid, errors, field, handleChange }" :model-value="form.accept_status"
                     @update:modelValue="form.accept_status = $event">
                <b-form-checkbox @change="handleChange" class="pt-1" name="accept_status"
                                 style="font-weight: 400; font-size: 16px; z-index: 0"
                                 :state="errors[0] ? false : (valid ? true : null)">
                  {{ $t("AUTH.I_ACCEPT_TERMS_AND_PRIVACY") }}
                </b-form-checkbox>
              </Field>

              <b-col class="text-center pt-4" align-h="center">
                <p class="font-bold mb-2" style="color: dodgerblue; font-size: 12px" v-if="isSendOtpUsingRadist">
                  {{ $t("AUTH.OTP_NOTICE") }}
                </p>
                <b-button class="btn-main pl-4 pr-4" type="submit" variant="none">
                  {{ $t("AUTH.REGISTER") }}
                </b-button>
              </b-col>
            </b-form>
          </Form>

          <b-form v-if="isVerifyOtp" class="form text-center" style="width: 510px" @submit.prevent="register">
            <p class="font-bold font-32 pb-1" style="color: var(--primary-color)">
              {{ $t("AUTH.VERIFICATION") }}
            </p>
            <p class="font-bold pl-5 pr-5" style="color: #989898; font-size: 14px">
              {{ $t("AUTH.ENTER_THE_OTP") }}
            </p>
            <p class="font-bold font-26 color-gray-6d pt-3" style="margin-bottom: -6px">
              {{ form.phone }}
            </p>
            <label class="hyperlink font-bold font-14 pb-1" @click="changePhoneNumber">
              <u>{{ $t("AUTH.CHANGE_PHONE_NUMBER") }}</u>
            </label>
            <label class="invalid font-bold pb-2"
                   :style="{ visibility: !isOtpCodeValid && observeOtp ? 'visible' : 'hidden' }">
              {{ $t("AUTH.OTP_REQUIRED") }}
            </label>
            <div style="display: flex; justify-content: center; align-items: center;">
              <v-otp-input
                ref="otpInput" v-model:value="form.otp_code" input-classes="otp-input" separator=" " :num-inputs="6"
                :should-auto-focus="true" input-type="numeric" @on-change="onOtpChange"/>
            </div>
            <b-col class="text-center pt-3" align-h="center">
              <b-row class="align-items-center" align-h="center" v-if="isSendOtpUsingRadist && otpStatus">
                <p class="font-bold font-14 color-gray-6d">{{ $t("AUTH.OTP_DELIVERY_STATUS") }}</p>
                <p class="font-bold font-14 text-white ml-2" :class="otpStatusClass">{{ otpStatusText }}</p>
              </b-row>
              <b-row class="otp-notice-background">
                <p class="font-bold font-12" v-if="isSendOtpUsingRadist">
                  {{ $t("AUTH.OTP_NOTICE") }}
                </p>
              </b-row>
              <b-button class="btn-main mt-1 pt-2 pb-2" type="submit" variant="none" style="width: 350px">
                {{ $t("AUTH.VERIFY") }}
              </b-button>
            </b-col>
            <b-row class="flex text-center pt-2 pb-2" align-h="center">
              <p class="font-bold color-gray-6d font-14">{{ $t("AUTH.OTP_DID_NOT_RECEIVE") }}</p>
              <p class="font-bold font-14 ml-1" :class="otpResendClass" @click="requestOtp(true)">
                {{ resendOtpText }}
              </p>
            </b-row>
          </b-form>
        </b-row>
      </b-container> -->
    </div>
    <div class="pb-4 pt-4 pb-lg-5 pt-lg-5 goro-light-section" style="background-color: var(--primary-background-darker-color)">
      <b-container class="text-center">
        <div class="join-goro">
          <h1 class="font-38">{{ $t("LANDING.JOIN_THE_FUTURE_TODAY") }}</h1>
          <p class="font-18">{{ $t("LANDING.ONLY_TAKE_5_MINUTES") }}</p>
          <router-link :to="{ name: 'marketplace' }">
            <b-button id="btn_home_bottom_buyProperty" class="btn-main mt-4 mb-4 pl-4 pr-4" variant="none" @click="trackGtmEvent(gtmEvent.buyPropertiesEvent)">
              {{ $t("ASSETS.VIEW_PROPERTIES") }}
            </b-button>
          </router-link>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { exchange, getCookie, getDeviceUUID, getErrorMessage, notify, urlImage, numberWithCommas } from "../helpers/common"
import propertiesService from "../services/properties.service"
import PropertyLightCard from "../components/Cards/PropertyLightCard"
import RippleButton from "../components/RippleButton.vue"
import authService from "../services/auth.service"
import { defineRule, Field, Form } from "vee-validate"
import { email, min, required } from "@vee-validate/rules"
import commonService from "../services/common.service"
import VOtpInput from "vue3-otp-input"
import "vue3-carousel/dist/carousel.css"
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel"
import store from "../store/store"
//import Slider from '@vueform/slider/dist/slider.vue2.js'
import { FOREIGNER, INDO, OTP_STATUS, OTP_TYPE, STATUS_CODE } from "../constants/constants"
import externalSites from "../constants/externalSites"
import Slider from "@vueform/slider"
import messErrors from "../constants/errors"
import { gtmTrackEvent } from "../helpers/gtm"
import { GTM_EVENT_NAMES } from "../constants/gtm"
import { Vue3Marquee } from "vue3-marquee"
import {FIREBASE_EVENTS, firebaseLogEvent} from "../helpers/firebase";

let DEFAULT_RESEND_OTP_TIME_IN_SECONDS = 120
defineRule("email", email)
defineRule("min", min)
defineRule("required", required)

export default {
  components: {
    Field,
    Form,
    defineRule,
    PropertyLightCard,
    RippleButton,
    VOtpInput,
    // VuePhoneNumberInput,
    // SimpleOtpInput,
    // VueHorizontal,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Slider,
    Vue3Marquee,
  },
  data () {
    return {
      title: "GORO | Property for All",
      innerWidth: 0,
      videoButtonWidth: 0,
      statisticConfigKey: "statistic_numbers",
      statisticNumbers: null,
      propertiesFeatured: [],
      propertiesForCalculator: [],
      propertyForCalculator: null,
      monthlyInvestment: false,
      initialPurchase: null,
      investmentPeriod: 5,
      pricePerToken: 10000,
      selectedEry: "LAST_MONTH",
      includeEca: false,
      compoundByReinvesting: false,
      durationMarquee: 35,
      featuredItems: [
        {
          image: require("../assets/img/featureds/the-big-spark.png"),
          url: "https://www.channelnewsasia.com/the-big-spark",
        },
        {
          image: require("../assets/img/featureds/dsa.png"),
          url: "https://www.dealstreetasia.com/stories/indonesia-proptech-goro-round-356958",
        },
        {
          image: require("../assets/img/featureds/tech_in_asia.png"),
          url: "https://www.techinasia.com/iterative-leads-id-proptech-firm-goros-1m-round",
        },
        {
          image: require("../assets/img/featureds/e27.png"),
          url: "https://e27.co/goro-raises-us1m-to-democratise-indonesian-property-investment-amidst-economic-challenges-20230808/",
        },
        {
          image: require("../assets/img/featureds/technode.png"),
          url: "https://technode.global/2023/08/09/indonesias-goro-raises-1m-funding-in-pre-seed-round-led-by-iterative/",
        },
        {
          image: require("../assets/img/featureds/katadata.png"),
          url: "https://katadata.co.id/desysetyowati/digital/64d319fbc66d8/iteratif-dan-veteran-properti-suntik-startup-goro-rp-15-2-miliar",
        },
        {
          image: require("../assets/img/featureds/abo.png"),
          url: "https://www.asiabusinessoutlook.com/news/property-startup-goro-secures-1-million-in-preseed-round-led-by-iterative-nwid-4442.html",
        },
        {
          image: require("../assets/img/featureds/daily_social.png"),
          url: "https://dailysocial-id.cdn.ampproject.org/c/s/dailysocial.id/amp/post/goro-peroleh-pendanaan-pra-awal-rp152-miliar-dipimpin-iterative",
        },
        {
          image: require("../assets/img/featureds/IDX_Channel.png"),
          url: "https://www.youtube.com/watch?v=Nf1NHF68wCg&t=408s",
        },
        {
          image: require("@/assets/img/featureds/backscoop.png"),
          url: "https://www.backscoop.com/newsletter-posts/indonesias-property-market-just-got-friendlier-with-goro",
        },
        {
          image: require("@/assets/img/featureds/krasia.png"),
          url: "https://kr-asia.com/unlocking-access-to-real-estate-goros-tokenization-of-rental-properties-in-indonesia",
        },
        {
          image: require("../assets/img/featureds/money_abroad.png"),
          url: "https://www.moneyabroad.co/newsletter/investing-in-bali-real-estate-robert-hoving",
        }
      ],
      carouselItems: {
        block1: [
          {
            image: 'goro-banner-2.jpg',
          },
          {
            image: 'goro-banner-3.jpg',
          },
          {
            image: 'goro-banner-4.jpg',
          },
        ],
        block2: [
          {
            image: 'goro-banner-1.jpg',
          },
          {
            image: 'goro-banner-5.jpg',
          },
          {
            image: 'goro-banner-9.jpg',
          },
        ],
        block3: [
          {
            image: 'goro-banner-6.jpg',
          },
          {
            image: 'goro-banner-7.jpg',
          },
          {
            image: 'goro-banner-8.jpg',
          },
        ],
      },
      hasPrev: false,
      hasNext: false,
      interval: null,
      timeout: 3500,
      isScrolling: false,
      form: {
        "email": this.$route.params.email,
        "name": this.$route.params.name,
        "platform": "web",
        "uuid": getDeviceUUID(),
        "cookie_fbp": getCookie("_fbp"),
      },
      referUser: "",
      vuePhone: {
        phone: "",
        isValid: true,
        props: {
          error: true,
          clearable: true,
          //fetchCountry: true, //Do not use it with defaultCountryCode
          defaultCountryCode: "ID",
          preferredCountries: ["ID"],
          translations: {
            countrySelectorLabel: this.$t("AUTH.COUNTRY_CODE"),
            phoneNumberLabel: this.$t("AUTH.PHONE_HINT"),
            example: this.$t("common.example"),
          },
          color: "#80BDFF",
          validColor: "#006666",
          errorColor: "#DC3445",
        }
      },
      isVerifyOtp: false,
      isOtpCodeValid: false,
      observeOtp: false,
      resendOtpTimer: 0,
      horizontalSettings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        300: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        500: {
          itemsToShow: 1.75,
          snapAlign: "center",
        },
        768: {
          itemsToShow: 2.25,
          snapAlign: "center",
        },
        // 900 and up
        900: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        1000: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },
        1200: {
          itemsToShow: 4,
          snapAlign: "start",
        }
      },
      getOtpStatusIntervalId: null,
      otpStatus: OTP_STATUS.QUEUED,
      storeLink: {
        playStore: externalSites.STORE.GOOGLE_PLAY,
        appStore: externalSites.STORE.APP_STORE,
      },
      gtmEvent: {
        appStoreEvent: GTM_EVENT_NAMES.APP_STORE_BUTTON,
        playStoreEvent: GTM_EVENT_NAMES.PLAY_STORE_BUTTON,
        buyPropertiesEvent: GTM_EVENT_NAMES.BUY_PROPERTIES,
        getStartedEvent: GTM_EVENT_NAMES.GET_STARTED,
      }
    }
  },
  async created () {
    await this.getReferralInviter()
  },
  async mounted () {
    this.handleWindowResize()
    this.videoButtonWidth = this.$refs.videoButton.$el.clientWidth
    window.addEventListener("resize", this.handleWindowResize)
    // observeVisibility(this.$refs.horizontal.$el, (visible) => {
    //   if (visible) {
    //     this.interval = setInterval(this.play, this.timeout)
    //   } else {
    //     clearInterval(this.interval)
    //   }
    // })
    await this.getStatisticNumbers()
    await this.getProperties()
    await this.getPropertiesForCalculator()

    if (window.location.hash === '#calculator') {
      this.scrollToCalculatorComponent()
    }
  },
  async beforeDestroy () {
    if (this.interval != null) clearInterval(this.interval)
    this.stopGetOtpStatusInterval()
    window.removeEventListener("resize", this.handleWindowResize)
  },
  methods: {
    scrollToCalculatorComponent() {
      this.$refs.calculatorHiddenComponent.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    handleWindowResize () {
      this.innerWidth = window.innerWidth
    },
    getAvatar (images) {
      if (images && images.length) {
        return urlImage(images[0])
      }
      return ""
    },
    async getStatisticNumbers () {
      try {
        const result = await commonService.getConfigDetail(this.statisticConfigKey, true)
        if (result && result.data) {
          this.statisticNumbers = JSON.parse(result.data.c_value)
        }
      } catch (ex) {}
    },
    async getProperties () {
      const result = await propertiesService.getFeatures()
      if (result) {
        this.propertiesFeatured = result
      }
    },
    async getPropertiesForCalculator () {
      const result = await propertiesService.getPropertiesForCalculator()
      if (result) {
        this.propertiesForCalculator = result
        if (this.propertiesForCalculator.length > 0) {
          this.propertyForCalculator = this.propertiesForCalculator[0]
        }
      }
    },
    onNext () {
      this.isScrolling = true
    },
    onPrev () {
      this.isScrolling = true
    },
    onScrollDebounce ({ hasNext, hasPrev, scrollWidth, width, left }) {
      this.hasPrev = hasPrev
      this.hasNext = hasNext
      // this.scrollWidth = scrollWidth
      // this.left = left
      // this.progress = left / scrollWidth
      // this.index = Math.floor(left / width)
      this.isScrolling = false
    },
    scrollToNext () {
      this.$refs.horizontal.next()
      // if (!this.isScrolling) {
      //   if (!this.hasNext && this.hasPrev) {
      //     this.$refs.horizontal.slideTo(0)
      //     return
      //   }
      //   if (this.hasNext) {
      //     this.$refs.horizontal.next()
      //   } else if (!this.hasNext && !this.hasPrev && !this.debounceId) {
      //     //some browsers got both hasNext/hasPrev == false, so we do the trick manual here
      //     this.$refs.horizontal.slideTo(1)
      //   }
      //   clearInterval(this.interval)
      //   this.interval = setInterval(this.play, this.timeout)
      // }
    },
    scrollToPrev () {
      this.$refs.horizontal.prev()
      // if (!this.isScrolling) {
      //   if (!this.hasPrev && this.hasNext) {
      //     this.$refs.horizontal.slideTo(this.featuredItems.length - 1)
      //     return
      //   }
      //   if (this.hasPrev) {
      //     this.$refs.horizontal.prev()
      //   } else if (!this.hasNext && !this.hasPrev && !this.debounceId) {
      //     //some browsers got both hasNext/hasPrev == false, so we do the trick manual here
      //     this.$refs.horizontal.slideTo(this.featuredItems.length - 1)
      //   }
      //   clearInterval(this.interval)
      //   this.interval = setInterval(this.play, this.timeout)
      // }
    },
    play () {
      if (!this.hasNext && this.hasPrev) {
        this.$refs.horizontal.slideTo(0)
        return
      }
      if (this.hasNext) {
        this.$refs.horizontal.next()
      } else if (!this.hasNext && !this.hasPrev) {
        //some browsers got both hasNext/hasPrev == false, so we do the trick manual here
        this.$refs.horizontal.slideTo(1)
      }
    },
    async onCalculatorInvestClicked () {
      if (!this.userProfile && this.$refs.registrationForm) {
        this.$refs.registrationForm.scrollIntoView({ behavior: "smooth" })
      } else if (this.propertyForCalculator != null && this.propertyForCalculator.status === "available") {
        await this.$router.push({ name: "propertyDetail", params: { uuid: this.propertyForCalculator.uuid } })
      }else {
        await this.$router.push({ name: "marketplace" })
      }
    },
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    updatePhoneNumber (number, data) {
      if (number) {
        this.vuePhone.isValid = data.valid
      }
      this.form.phone = data.number
      this.form.country_code = data.countryCallingCode
      this.form.iso_country_code = data.countryCode
    },
    async changePhoneNumber () {
      this.isVerifyOtp = false
      this.observeOtp = false
      this.resendOtpTimer = 0
      this.otpStatus = OTP_STATUS.QUEUED
      this.stopGetOtpStatusInterval()
    },
    onOtpChange (value) {
      this.isOtpCodeValid = value.toString().trim().length === 6
    },
    async requestOtp (isResend = false) {
      if (isResend === true && this.resendOtpTimer > 0) {
        return
      }
      if (this.vuePhone.isValid) {
        window.fbq("trackCustom", "Landing:RequestOtp", { is_resend: isResend })
        gtmTrackEvent({
          event: GTM_EVENT_NAMES.USER_REQUEST_OTP,
          is_resend: isResend,
        })
        try {
          await this.$recaptchaLoaded()
          const recaptchaToken = await this.$recaptcha("requestOtp")
          let data = await authService.requestOtp({
            recaptcha_token: recaptchaToken,
            preferred_locale: this.$i18n.locale === FOREIGNER.LOCALE ? "en" : this.$i18n.locale,
            source: "landing",
            ...this.form
          }, true)
          if (data && data.message) {
            this.displayOtpVerification()
          }
        } catch (ex) {
          const errorMessage = getErrorMessage(ex) || messErrors.INTERNAL;
          firebaseLogEvent(FIREBASE_EVENTS.REGISTRATION_FAILED, {
            email: this.form.email,
            phone: this.form.phone,
            method: "RequestOtp",
            error: errorMessage
          });
          if (ex && ex.statusCode === STATUS_CODE.HTTP_TOO_EARLY) {
            if (isResend === true) {
              notify({ text: this.$t("AUTH.OTP_PLEASE_WAIT"), type: "error" })
            } else {
              this.displayOtpVerification()
            }
          } else {
            notify({text: errorMessage, type: "error"})
          }
        }
      }
    },
    displayOtpVerification () {
      this.isVerifyOtp = true
      this.resendOtpTimer = DEFAULT_RESEND_OTP_TIME_IN_SECONDS
      this.stopGetOtpStatusInterval()
      this.getOtpStatusIntervalId = setInterval(this.getOtpStatus, 5000)
    },
    async getOtpStatus () {
      if (this.otpStatus !== OTP_STATUS.DELIVERED && this.otpStatus !== OTP_STATUS.ERROR) {
        let data = await authService.getOtpStatus({
          email: this.form.email,
          phone: this.form.phone,
        })
        if (data && data.status) {
          this.otpStatus = data.status
        }
      } else {
        this.stopGetOtpStatusInterval()
      }
    },
    stopGetOtpStatusInterval () {
      if (this.getOtpStatusIntervalId) {
        clearInterval(this.getOtpStatusIntervalId)
      }
    },
    async register () {
      this.observeOtp = true
      if (this.vuePhone.isValid && this.isOtpCodeValid) {
        await this.$recaptchaLoaded()
        const action = this.isRegisterWithFacebook ? "registerWithFacebook"
          : this.isRegisterWithGoogle ? "registerWithGoogle" : "register"
        const recaptchaToken = await this.$recaptcha(action)
        const formData = {
          ...this.form,
          preferred_locale: this.$i18n.locale === FOREIGNER.LOCALE ? "en" : this.$i18n.locale,
          uuid: getDeviceUUID(),
          cookie_fbp: getCookie("_fbp"),
          source: "landing",
          recaptcha_token: recaptchaToken,
        }
        let data
        let method = ""
        try {
          if (this.isRegisterWithFacebook) {
            method = "Facebook"
            window.fbq("trackCustom", "Landing:Register", {method: method})
            gtmTrackEvent({
              event: GTM_EVENT_NAMES.USER_REGISTER,
              method: method,
            })
            data = await authService.registerWithFacebook(formData)
          } else if (this.isRegisterWithGoogle) {
            method = "Google"
            window.fbq("trackCustom", "Landing:Register", {method: method})
            gtmTrackEvent({
              event: GTM_EVENT_NAMES.USER_REGISTER,
              method: method,
            })
            data = await authService.registerWithGoogle(formData)
          } else {
            method = "Manual"
            window.fbq("trackCustom", "Landing:Register", {method: method})
            gtmTrackEvent({
              event: GTM_EVENT_NAMES.USER_REGISTER,
              method: method,
            })
            data = await authService.register(formData)
          }
        } catch (e) {
          const errorMessage = getErrorMessage(e) || messErrors.INTERNAL;
          firebaseLogEvent(FIREBASE_EVENTS.REGISTRATION_FAILED, {
            email: this.form.email,
            phone: this.form.phone,
            method: method,
            error: errorMessage
          });
          throw e
        }
        //check registration result
        if (data && data.user && data.access_token) {
          await this.onRegisterSuccess(data.user, data.access_token)
        }
      }
    },
    async onRegisterSuccess (user, accessToken) {
      if (user && accessToken) {
        this.stopGetOtpStatusInterval()
        window.fbq("trackCustom", "Landing:CompleteRegistration")
        localStorage.setItem("Authorization", accessToken)
        await store.dispatch("setUserProfile", user)
        await this.$router.push(this.$route.query.redirect || { name: "assetsOverview" })
        gtmTrackEvent({
          event: GTM_EVENT_NAMES.USER_REGISTER_SUCCESS,
        })

        this.vuePhone.phone = ""
        this.form = { "platform": "web" }
        if (this.referUser != null && this.referUser !== "" && this.referralCode) {
          this.form.referral_code = this.referralCode
        }
      }
    },
    async getReferralInviter () {
      if (this.referralCode != null) {
        const response = await commonService.getReferralInviter(this.referralCode)
        if (response.name) {
          this.referUser = response.name
          this.form.referral_code = this.referralCode
          window.fbq("trackCustom", "Landing:GetReferralInviter", { success: true })
          gtmTrackEvent({
            event: GTM_EVENT_NAMES.USER_GET_REFERRAL_INVITER,
            success: true,
          })
        } else {
          window.fbq("trackCustom", "Landing:GetReferralInviter", { success: false })
          gtmTrackEvent({
            event: GTM_EVENT_NAMES.USER_GET_REFERRAL_INVITER,
            success: false,
          })
        }
      }
    },
    formatInitialPurchase (value) {
      if (value) {
        // Remove non-numeric characters
        let formattedValue = value.toString().replace(/[^0-9]/g, "")
        // Remove leading zeros
        formattedValue = formattedValue.replace(/^0+/g, "")
        let maxValue = 100000000000
        if (Number(formattedValue) > maxValue) {
          return String(maxValue).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      }
      return null
    },
    getEquivalentTokens (value) {
      let pricePerToken = this.pricePerToken
      let equivalentTokens = Math.floor(value / pricePerToken)
      return `(${equivalentTokens} ${equivalentTokens > 1 ? this.$t("PAYMENT.TOKENS") : this.$t("PAYMENT.TOKEN")})`
    },
    exchangeValue (value) {
      return exchange(value, 100, false)
    },
    trackGtmEvent(name){
      gtmTrackEvent({
        event: name,
      })
    },
    tokenLabel (value) {
      return value > 1 ? this.$t("PAYMENT.TOKENS") : this.$t("PAYMENT.TOKEN")
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    goroVideoId () {
      if (this.$i18n.locale === "id") {
        return "z2z90l2UyDg"
      } else {
        return "2uAcQKWbDPU"
      }
    },
    registeredUsers () {
      if (this.statisticNumbers) {
        if (this.$i18n.locale === FOREIGNER.LOCALE) {
          return this.statisticNumbers.registered_users.en_UK
        } else if (this.$i18n.locale === "en") {
          return this.statisticNumbers.registered_users.en
        } else {
          return this.statisticNumbers.registered_users.id
        }
      }
      return "70K+"
    },
    investedInProperty () {
      if (this.$i18n.locale === FOREIGNER.LOCALE) {
        if (this.statisticNumbers) {
          return this.statisticNumbers.invested_in_property.en_UK
        }
        return "$3M+"
      } else if (this.$i18n.locale === "en") {
        if (this.statisticNumbers) {
          return this.statisticNumbers.invested_in_property.en
        }
        return "IDR50B+"
      } else {
        if (this.statisticNumbers) {
          return this.statisticNumbers.invested_in_property.id
        }
        return "Rp.50M+"
      }
    },
    totalIncomePaid () {
      if (this.$i18n.locale === FOREIGNER.LOCALE) {
        if (this.statisticNumbers) {
          return this.statisticNumbers.total_income_paid.en_UK
        }
        return "$200K+"
      } else if (this.$i18n.locale === "en") {
        if (this.statisticNumbers) {
          return this.statisticNumbers.total_income_paid.en
        }
        return "IDR3B+"
      } else {
        if (this.statisticNumbers) {
          return this.statisticNumbers.total_income_paid.id
        }
        return "Rp.3M+"
      }
    },
    numOfProperties () {
      if (this.statisticNumbers) {
        if (this.$i18n.locale === FOREIGNER.LOCALE) {
          return this.statisticNumbers.properties.en_UK
        } else if (this.$i18n.locale === "en") {
          return this.statisticNumbers.properties.en
        } else {
          return this.statisticNumbers.properties.id
        }
      }
      return "16"
    },
    testimonial_1_photo () {
      if (this.$i18n.locale === "id") {
        return require("../assets/img/landing_testimonial_danisworo.png")
      } else {
        return require("../assets/img/landing_testimonial_florian_k.png")
      }
    },
    testimonial_2_photo () {
      if (this.$i18n.locale === "id") {
        return require("../assets/img/landing_testimonial_sofyan.png")
      } else {
        return require("../assets/img/landing_testimonial_karan_s.png")
      }
    },
    testimonial_3_photo () {
      if (this.$i18n.locale === "id") {
        return require("../assets/img/landing_testimonial_aldi.png")
      } else {
        return require("../assets/img/landing_testimonial_alvin_d.png")
      }
    },
    isSendOtpUsingRadist () {
      if (store.state.configs && store.state.configs.otp_type) {
        return store.state.configs.otp_type === OTP_TYPE.RADIST
      }
      return false
    },
    referralCode () {
      const codeParam = this.$route.query.code
      const savedReferralCode = localStorage.getItem("referral_code")
      if (codeParam && codeParam !== savedReferralCode) {
        localStorage.setItem("referral_code", codeParam)
      }
      return codeParam || savedReferralCode
    },
    disabledReferralInput () {
      return this.referUser != null && this.referUser !== ""
    },
    resendOtpText () {
      if (this.resendOtpTimer > 0) {
        return this.$t("AUTH.OTP_RESEND") + " (" + this.resendOtpTimer + "s)"
      }
      return this.$t("AUTH.OTP_RESEND")
    },
    otpStatusText () {
      if (this.otpStatus === OTP_STATUS.DELIVERED) {
        return "Sent"
      }
      return this.otpStatus
    },
    otpStatusClass () {
      return {
        "otp-status-queued": this.otpStatus === OTP_STATUS.QUEUED,
        "otp-status-sent": this.otpStatus === OTP_STATUS.DELIVERED,
        "otp-status-error": this.otpStatus === OTP_STATUS.ERROR,
      }
    },
    otpResendClass () {
      return {
        "hyperlink": this.resendOtpTimer === 0,
        "hyperlink-disabled": this.resendOtpTimer > 0,
      }
    },
    currency () {
      let currency = INDO.CURRENCY
      if (this.userProfile && this.userProfile.preferred_currency) {
        currency = this.userProfile.preferred_currency
      } else if (this.$i18n.locale === FOREIGNER.LOCALE) {
        currency = FOREIGNER.CURRENCY
      }
      return currency
    },
    initialPurchaseHint () {
      if (this.currency === INDO.CURRENCY) {
        return this.$t("LANDING.INITIAL_PURCHASE_HINT", { value: this.exchangeValue(10000) })
      } else {
        return this.$t("LANDING.INITIAL_PURCHASE_HINT", { value: this.currency })
      }
    },
    initialPurchaseValue () {
      if (this.initialPurchase) {
        let value = this.initialPurchase.replace(/[^0-9]/g, "")
        return Number(value)
      }
      return 0
    },
    initialPurchaseValueIdr () {
      if (this.currency === INDO.CURRENCY) {
        return this.initialPurchaseValue
      } else {
        const rates = this.$store.getters.exchangeRates
        if (rates && rates[this.currency]) {
          return Math.floor(this.initialPurchaseValue / rates[this.currency])
        }
      }
      return 0
    },
    totalPurchaseValueIdr () {
      if (this.monthlyInvestment) {
        let totalPurchaseValue = 0
        let months = 12
        for (let i = 0; i < this.investmentPeriod; i++) {
          for (let j = 0; j < months; j++) {
            totalPurchaseValue += this.initialPurchaseValueIdr
          }
        }
        return totalPurchaseValue
      } else {
        return this.initialPurchaseValueIdr
      }
    },
    rentalYieldPercent () {
      if (this.propertyForCalculator != null) {
        if (this.selectedEry === "LAST_MONTH") {
          return this.propertyForCalculator.last_month_rental_yield
        } else if (this.selectedEry === "AVERAGE") {
          return this.propertyForCalculator.average_rental_yield
        }
      }
      return 0
    },
    ecaPercent () {
      if (this.propertyForCalculator != null) {
        return this.propertyForCalculator.eca
      }
      return 0
    },
    expectedIncomeIdr () {
      if (this.propertyForCalculator != null) {
        if (this.monthlyInvestment) {
          let months = 12
          let monthlyRentalYieldPercent = this.rentalYieldPercent / months
          let monthlyCapitalAppreciationPercent = this.includeEca ? this.ecaPercent / months : 0
          let totalInvestmentValue = 0
          let expectedIncome = 0
          for (let i = 0; i < this.investmentPeriod; i++) {
            for (let j = 0; j < months; j++) {
              totalInvestmentValue += this.initialPurchaseValueIdr
              expectedIncome += totalInvestmentValue * (monthlyRentalYieldPercent + monthlyCapitalAppreciationPercent)/ 100
            }
          }
          return Math.floor(expectedIncome)
        } else {
          let capitalAppreciationPercent = this.includeEca ? this.ecaPercent : 0
          return Math.floor(this.initialPurchaseValueIdr
            * (this.rentalYieldPercent + capitalAppreciationPercent) / 100 * this.investmentPeriod)
        }
      }
      return 0
    },
    expectedIncomeByReinvestingIdr () {
      if (this.propertyForCalculator != null) {
        if (this.compoundByReinvesting) {
          let months = 12
          let pricePerToken = 10000
          let monthlyRentalYieldPercent = this.rentalYieldPercent / months
          let monthlyCapitalAppreciationPercent = this.includeEca ? this.ecaPercent / months : 0
          let totalInvestmentValue = 0
          let compoundIncome = 0
          for (let i = 0; i < this.investmentPeriod; i++) {
            for (let j = 0; j < months; j++) {
              if (totalInvestmentValue === 0 || this.monthlyInvestment) {
                totalInvestmentValue += this.initialPurchaseValueIdr
              }
              let amountCanReinvest = Math.floor(compoundIncome / pricePerToken) * pricePerToken
              compoundIncome += (totalInvestmentValue + amountCanReinvest)
                * (monthlyRentalYieldPercent + monthlyCapitalAppreciationPercent) / 100
            }
          }
          return Math.floor(compoundIncome)
        }
      }
      return 0
    },
    totalAsset () {
      if (this.compoundByReinvesting) {
        return this.exchangeValue(this.totalPurchaseValueIdr + this.expectedIncomeByReinvestingIdr)
      } else {
        return this.exchangeValue(this.totalPurchaseValueIdr + this.expectedIncomeIdr)
      }
    },
    totalAssetTokens () {
      if (this.compoundByReinvesting) {
        return this.getEquivalentTokens(this.totalPurchaseValueIdr + this.expectedIncomeByReinvestingIdr)
      } else {
        return this.getEquivalentTokens(this.totalPurchaseValueIdr + this.expectedIncomeIdr)
      }
    },
    purchaseToken() {
      return Math.floor(this.initialPurchaseValueIdr / this.pricePerToken)
    },
    initialPurchaseFormat() {
      return `${this.currency}${numberWithCommas(this.initialPurchase || 0)}`
    },
    totalAssetTokensDetail() {
      if (this.compoundByReinvesting) {
        let equivalentTokens = Math.floor((this.totalPurchaseValueIdr + this.expectedIncomeByReinvestingIdr) / this.pricePerToken)
        let totalPurchaseTokens = Math.floor(this.totalPurchaseValueIdr / this.pricePerToken)
        let expectedIncomeByReinvestingTokens = Math.floor(this.expectedIncomeByReinvestingIdr / this.pricePerToken)
        if (!totalPurchaseTokens || !expectedIncomeByReinvestingTokens) {
          return `(${equivalentTokens} ${this.tokenLabel(equivalentTokens)})`
        }
        return `(${totalPurchaseTokens} + ${expectedIncomeByReinvestingTokens} = ${equivalentTokens} ${this.tokenLabel(equivalentTokens)})`
      } else {
        let equivalentTokens = Math.floor((this.totalPurchaseValueIdr + this.expectedIncomeIdr) / this.pricePerToken)
        let totalPurchaseTokens = Math.floor(this.totalPurchaseValueIdr / this.pricePerToken)
        let expectedIncomeTokens = Math.floor(this.expectedIncomeIdr / this.pricePerToken)

        if (!totalPurchaseTokens || !expectedIncomeTokens) {
          return `(${equivalentTokens} ${this.tokenLabel(equivalentTokens)})`
        }
        return `(${totalPurchaseTokens} + ${expectedIncomeTokens} = ${equivalentTokens} ${this.tokenLabel(equivalentTokens)})`
      }
    },
  },
  watch: {
    resendOtpTimer (value) {
      if (value > 0) {
        setTimeout(() => { this.resendOtpTimer-- }, 1000)
      }
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { property: "og:title", content: this.title },
        { property: "og:site_name", content: this.title },
      ],
    }
  },
}

/**
 * Custom function, much easier way: https://www.npmjs.com/package/vue-observe-visibility
 *
 * @param element to track visibility
 * @param callback: function(boolean) when visibility change
 */
function observeVisibility (element, callback) {
  const observer = new IntersectionObserver((records) => {
    callback(records.find(record => record.isIntersecting))
  }, { rootMargin: "10% 0% 10% 0%", threshold: 0.5 })
  observer.observe(element)
}
</script>
<style lang="scss">
.home {
  h1 {
    font-family: "Figtree-Bold", serif;
    font-weight: 500;
    color: var(--primary-color);
  }

  p {
    color: var(--primary-color);
  }

  .main__content {
    // padding-top: 20px;
    // padding-bottom: 75px;
    // background-size: cover;
    // background-image: url('../assets/img/landing_banner_min_w_1100.png');

    // @media only screen and (max-width: 1100px) {
    //   background-image: url('../assets/img/landing_banner_max_w_1100.png');
    // }

    // @media only screen and (max-width: 800px) {
    //   background-image: url('../assets/img/landing_banner_max_w_800.png');
    // }

    // @media only screen and (max-width: 650px) {
    //   background-image: url('../assets/img/landing_banner_max_w_650.png');
    // }

    &.goro-new-banner{
      .goro-banner-container{
        margin-top: 0;
        margin-bottom: 0;
        max-width: 1400px;
        padding: 0 60px !important;
        max-height: 530px;
        @media only screen and (max-width: 650px) {
          padding: 0 15px !important;
        }
      }
      .goro-content-banner{
          padding: 6rem 0 12rem 0;
          @media only screen and (max-width: 10240px) {
            padding: 3rem 15px 6rem 15px;
          }
          @media only screen and (max-width: 650px) {
            padding: 3rem 15px 6rem 15px;
          }
          @media only screen and (max-width: 575px) {
            background-image: url('../assets/img/landing_banner_max_w_650.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
      }
      .bg-image{
        background-image: url('../assets/img/banners/new_landing_banner_max_w_1400.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media only screen and (max-width: 1024px) {
          background-image: url('../assets/img/banners/new_landing_banner_min_w_1400.png');
        }
        @media only screen and (max-width: 1000px) {
          background-image: url('../assets/img/banners/new_landing_banner_max_w_1000.png');
        }
        @media only screen and (max-width: 575px) {
          background-image: none !important;
        }
      }

      .goro-main-actions{
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          gap: 10px;
        }
        .goro-view-property{
          padding: 0.375rem 2rem;
          width: 180px;
        }
      }

      .goro-banner-carousel{
        @media only screen and (max-width: 575px) {
          display: none !important;
        }
        .parent{
          // position:relative;
          display: flex;
          justify-content: flex-end;
          height: 530px;
          overflow: hidden;

          .cols{
            display:flex;
            flex-wrap:wrap;
            width:200px;
          }

          .item{
            width:100%;
            padding: 10px;
            overflow: hidden;
            img{
              width: 100%;
              height: auto;
              border-radius: 15px;
            }
          }

          .block2{
            @media only screen and (max-width: 1000px) {
              display: none !important;
            }
          }
          .block3{
            animation-name: slidedown2;
            @media only screen and (max-width: 1024px) {
              display: none !important;
            }
          }
        }
      }
    }
  }

  .statistic-card {
    padding: 25px 50px 30px;
    margin-left: 1px;
    margin-right: 1px;
    background-color: var(--primary-background-color);
    border-radius: 48px;
  }

  .w-card {
    padding: 15px 25px 25px;
    margin-top: 10px;
    height: 90%;
    background-color: var(--primary-background-darker-color);
    border-radius: 16px;

    img {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
    }

    label {
      padding-left: 0.5rem;
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }

  .w-card-2 {
    padding: 15px 25px 30px;
    margin-top: 10px;
    height: 90%;
    background-color: var(--primary-background-darker-color);
    border-radius: 16px;

    h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 18px;
      color: var(--primary-color);
    }

    p {
      font-size: 16px;
    }
  }

  .w-card-3 {
    padding: 15px 25px 30px;
    margin-top: 10px;
    height: 90%;
    background-color: var(--primary-background-color);
    border-radius: 16px;

    img {
      width: 30px;
      height: 30px;
    }

    h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }
  }

  .w-card-4-a {
    padding: 15px 25px 50px;
    margin-top: 10px;
    height: 90%;
    background-color: var(--primary-background-darker-color);
    border-radius: 32px;

    h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 22px;
      color: var(--primary-color);
    }

    p {
      font-size: 16px;
    }
  }

  .w-card-4-b {
    padding: 10px 25px 15px;
    margin-left: 15px;
    margin-right: 15px;
    background-color: var(--primary-background-darker-color);
    border-radius: 32px;

    h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 22px;
      color: var(--primary-color);
    }

    p {
      font-size: 16px;
    }
  }

  .calculator-card {
    padding: 28px 32px 28px 32px;
    background-color: var(--primary-background-darker-color);
    border-radius: 28px;

    h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 18px;
      font-family: "Figtree-Bold", serif;
      font-weight: 300;
      color: var(--primary-color);
    }

    p {
      font-size: 18px;
    }

    .rental-yield {
      width: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 5px;
      //background-color: white;
      color: var(--primary-color);
      //border-radius: 10px;
      font-size: 38px;
      font-family: "Figtree-Bold", serif;
      font-weight: 600;
    }

    .projected-text {
      margin-left: -4px;
      margin-right: -4px;
      padding: 8px 10px;
      background-color: #afe1e1;
      border-radius: 15px;
    }

    .expected-income {
      height: 346px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .expected-income-divider {
        width: auto;
        height: 1px;
        background-color: var(--primary-lighter-color);
        margin: 55px 2px 15px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      color: white;
      border-color: var(--primary-color);
      box-shadow: 0 0 5px var(--primary-lighter-color);
      background-color: var(--primary-color);
    }

    .slider {
      --slider-bg: white;
      --slider-height: 8px;
      --slider-connect-bg: var(--primary-color);
      --slider-tooltip-bg: var(--primary-color);
      --slider-handle-width: 18px;
      --slider-handle-height: 18px;
      --slider-handle-bg: var(--primary-color);
      --slider-handle-ring-color: none;
    }

    .segmented-control {
      display: flex;
      width: 265px;
      background-color: white;
      border-radius: 50px;
    }

    .segmented-control button {
      flex: 1;
      padding: 8px 10px;
      margin-left: 1px;
      margin-right: 1px;
      cursor: pointer;
      border: none;
      border-radius: 50px;
      color: var(--primary-color);
      background-color: white;
      box-shadow: none;
    }

    .segmented-control button.active {
      color: white;
      background-color: var(--primary-color);
      font-weight: bolder;
    }
  }

  .total-asset-card {
    background-color: white;
    box-shadow: 0 8px 35px rgba(7, 55, 99, 0.16);
    border-radius: 28px;
    padding: 16px 32px;
  }

  .join-goro {
    padding: 0.6rem 5% 1rem;
    margin-top: 5rem;
    background-color: var(--primary-background-color);
    //box-shadow: 0 4px 10px var(--primary-darker-color);
    border-radius: 16px;
  }

  .form {
    background-color: white;
    box-shadow: 0 8px 35px rgba(7, 55, 99, 0.16);
    border-radius: 16px;
    margin: 10px;
    padding: 24px !important;
  }

  .invalid {
    width: 100%;
    margin-top: .25rem;
    margin-bottom: 0;
    font-size: 80%;
    color: #dc3545
  }

  //b-form-group label
  legend {
    padding-top: 5px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: var(--primary-darker-color);
  }

  //b-form-input
  input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--primary-darker-color);

    &::placeholder {
      color: #ABB5BE !important;
      opacity: 1;
    }
  }

  //b-form-invalid-feedback
  .invalid-feedback {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  .show-hide {
    width: 40px;
    background-color: rgba(0, 102, 102, 0.9);;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
    transition: .5s;

    &:hover {
      background-color: rgba(0, 102, 102, 1);;
    }
  }

  .horizontal {
    margin-left: -50px;
    margin-right: -50px;

    @media only screen and (max-width: 1200px) {
      margin-left: -42px;
      margin-right: -42px;
    }

    @media only screen and (max-width: 996px) {
      margin-left: -24px;
      margin-right: -24px;
    }

    @media only screen and (max-width: 768px) {
      margin-left: -12px;
      margin-right: -12px;
    }

    @media only screen and (max-width: 650px) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  .goro-featured-on-list{
    .list-item {
      width: auto;
      height: 118px;
      // margin-left: 8px;
      // margin-right: 8px;
      background-color: transparent;
      padding: 7px;
      &:hover {
        background-color: transparent;
        cursor: pointer;
      }

      &:focus {
        background-color: transparent;
      }

      .item-link{
        border: 2px solid #348685;
        border-radius: 20px;
        height: 100%;
        padding: 5px 14px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          border: 2px solid var(--primary-hover-color);
        }
        .item-image {
          width: auto;
          height: 100%;
          object-fit: contain;
          max-width: 100%;
        }
      }
    }
  }

  .carousel__track {
    margin-bottom: 0;
  }

  .horizontal-navigation {
    color: var(--primary-color);

    &:hover {
      cursor: pointer;
      color: var(--primary-hover-color);
    }
  }

  .compound-tooltip-icon {
    margin-bottom: 2px;
    width: 18px;
    height: 18px;
    z-index: 1;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .information-caption{
    position: absolute;
    left: 0px;
    bottom: 35px;
    width: 100%;
    @media only screen and (max-width: 768px) {
      bottom: 15px !important;
    }
  }

  /**
   * Style for light card
   */
  .goro-light-section{
    .w-card{
      background-color: var(--primary-background-color);
    }

    .calculator-card{
      background-color: var(--primary-background-color);
    }

    .w-card-2{
      background-color: var(--primary-background-color);
    }
    .w-card-4-a,
    .w-card-4-b{
      background-color: var(--primary-background-color);
    }

    .w-card-4-a{
      .review-content{
        position: relative;
        &::before{
          position: absolute;
          content: "";
          top: -13px;
          left: -4px;
          width: 50px;
          height: 50px;
          background-image: url('~@/assets/img/quote-prev.png');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: .5;
        }
        p{
          z-index: 1;
        }
      }
    }

    .w-card-4-b{
      .review-content{
        position: relative;
        &::before{
          position: absolute;
          content: "";
          top: -18px;
          left: -10px;
          width: 50px;
          height: 50px;
          background-image: url('~@/assets/img/quote-prev.png');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: .5;
        }
        p{
          position: inherit;
          z-index: 1;
        }
      }
    }

    .w-card-3{
      background-color: var(--primary-background-darker-color);
    }

    .join-goro{
      background-color: #fff;
      margin-top: 0 !important
    }
  }
}

@import "~@vueform/slider/themes/default.css";
</style>
