<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="items" :columns="tableColumns" :showEditAction="true"
                        :customActionTitles="customActions"
                        @onModalEdit="onModalEdit" @onCustomAction="onClickedCustomAction">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th></th>
                                <th>
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th>
                                    <b-input v-model="filters.email"></b-input>
                                </th>
                                <th>
                                    <b-input v-model="filters.phone"></b-input>
                                </th>
                                <th width="30px"></th>
                                <th>
                                    <b-input v-model="filters.status"></b-input>
                                </th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="users.total" align="right" v-model="users.current_page" :total-rows="users.total"
                        :per-page="users.per_page" @change="onChangePage" aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-user-info title="User Info" :show="showModal" :initUser="user" @on-close="onModalClose" />
        <modal-user-assets :show="modalUserAssets" :user="user" @on-close="modalUserAssets = false" />
        <modal-virtual-user-assets :show="modalVirtualUserAssets" :user="user" @on-close="modalVirtualUserAssets = false" />
        <modal-update-ktp-passport :show="modalUpdateKtpPassport.show" :userId="modalUpdateKtpPassport.userId"
            @on-close="onHideUpdateKtpPassportModal" />
        <modal-verify-selfie ref="modalVerifySelfie" @on-success="onVerifiedSelfie"></modal-verify-selfie>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import userService from "../../services/admin/user.service";
import ModalUserInfo from "./modals/ModalUserInfo.vue";
import ModalUserAssets from "./modals/ModalUserAssets.vue";
import ModalVirtualUserAssets from "./modals/ModalVirtualUserAssets.vue";
import ModalVerifySelfie from "./modals/ModalVerifySelfie.vue";
import ModalUpdateKtpPassport from "./modals/ModalUpdateKtpPassport.vue";
import { numberWithCommas, userHasPermission } from "../../helpers/common";
import { USER_CUSTOM_ACTIONS } from "../../constants/constants";
import { USER_PERMISSIONS } from "../../constants/permissions";

const tableColumns = ["Id", "Uuid", "Name", "Email", "Phone", "Balance", "Status", "Notification_Status"];

export default {
    components: {
        PaperTable,
        ModalUserInfo,
        ModalUserAssets,
        ModalUpdateKtpPassport,
        ModalVirtualUserAssets,
        ModalVerifySelfie,
    },
    data() {
        return {
            users: {},
            items: [],
            tableColumns: [...tableColumns],
            filters: {
                name: '',
                email: '',
                phone: '',
                status: '',
            },
            showModal: false,
            user: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
            modalUserAssets: false,
            modalUpdateKtpPassport: {
                show: false,
                userId: null,
            },
            modalVirtualUserAssets: false,
        };
    },
    async mounted() {
        await this.getUsers(1);
    },
    watch: {
        'filters.name'() {
            this.searchTimeOut(1);
        },
        'filters.email'() {
            this.searchTimeOut(1);
        },
        'filters.phone'() {
            this.searchTimeOut(1);
        },
        'filters.status'() {
            this.searchTimeOut(1);
        },
    },
    computed: {
        customActions() {
            let actions = [
                USER_CUSTOM_ACTIONS.VIEW_USER_ASSETS,
                USER_CUSTOM_ACTIONS.VIEW_USER_VIRTUAL_ASSETS,
            ]

            if (userHasPermission(USER_PERMISSIONS.USER.VIEW_KTP_PASSPORT_SELFIE)) {
                actions = [
                    ...actions,
                    USER_CUSTOM_ACTIONS.VIEW_USER_KTP_PASSPORT,
                    USER_CUSTOM_ACTIONS.VIEW_USER_VERIFY_SELFIE
                ]
            }

            return actions
        },
    },
    methods: {

        async getUsers(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await userService.getList(filters);
            if (result && result.data) {
                this.users = result;
                this.items = result.data.map(e => {
                    return {
                        'id': e.id,
                        'uuid': e.uuid,
                        'name': e.name,
                        'email': e.email,
                        'phone': e.phone,
                        'balance': `IDR${numberWithCommas(e.balance)}`,
                        'status': e.status,
                        'notification_status': this.getSubscriptionStatus(e.notification_sub_status),
                    };
                });
            }
        },

        async onChangePage(page) {
            await this.getUsers(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getUsers(page);
            }, 400);
        },

        onModalClose(v) {
            this.showModal = false;
            const refresh = v.refresh;
            if (refresh) {
                this.getUsers(this.users.current_page);
            }
        },

        onModalEdit(data) {
            if (data) {
                this.user = this.users.data.find((e) => e.id === data);
                this.showModal = true;
            }
        },

        onClickedCustomAction(data, key) {
            const user = this.users.data.find((e) => e.id === data);
            if (key === USER_CUSTOM_ACTIONS.VIEW_USER_ASSETS.key) {
                this.user = user;
                this.modalUserAssets = true;
            } else if (key === USER_CUSTOM_ACTIONS.VIEW_USER_VIRTUAL_ASSETS.key) {
                this.user = user;
                this.modalVirtualUserAssets = true
            } else if (key === USER_CUSTOM_ACTIONS.VIEW_USER_KTP_PASSPORT.key) {
                this.modalUpdateKtpPassport = {
                    show: true,
                    userId: user.id,
                }
            } else if (key === USER_CUSTOM_ACTIONS.VIEW_USER_VERIFY_SELFIE.key) {
                this.$refs.modalVerifySelfie.showModal(user)
            }
        },

        onHideUpdateKtpPassportModal() {
            this.modalUpdateKtpPassport = {
                show: false,
                userId: null,
            }
        },

        onVerifiedSelfie(user) {
            if (this.users.data) {
                this.users.data = this.users.data.map(e => {
                    if (e.id === user.id) {
                        return user
                    }
                    return e
                })
            }
        },

        getSubscriptionStatus(status) {
            if (!status || status === 'never_subscribed' || status === 'prompted') {
                return 'Never Subscribed'
            }
            if (status === 'subscribed') {
                return 'Subscribed'
            }
            return 'Unsubscribed'
        }
    }
};
</script>
<style scoped></style>
