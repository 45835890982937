<template>
  <div class="contact-us full-screen">
    <div class="wrapper wrapper-full-page section content">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-md-offset-2 text-center">
              <h2 class="title text-danger">404 {{$t("NOT_FOUND.NOT_FOUND")}}</h2>
              <h2 class="title">Oops! {{$t("NOT_FOUND.PAGE_DOES_NOT_EXIST")}}.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Not Found"
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { property: 'og:title', content: this.title },
        { property: 'og:site_name', content: this.title },
      ],
    };
  },
};
</script>
