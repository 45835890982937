import store from "../store/store"

const status = {
    Inactive: 'inactive',
    Active: 'active',
    FullyActive: 'active_fully',
    Banned: 'banned'
};

const isFullyActive = (force = false) => {
    if (force) {
        return store.state.userProfile && store.state.userProfile.status === status.FullyActive;
    }
    return store.state.userProfile && (store.state.userProfile.status === status.Active || store.state.userProfile.status === status.FullyActive);
}

const isActive = () => {
    return store.state.userProfile && store.state.userProfile.status === status.Active;
}

const isInactive = () => {
    return store.state.userProfile && store.state.userProfile.status === status.Inactive;
}

const isBanned = () => {
    return store.state.userProfile && store.state.userProfile.status === status.Banned;
}

const isLoggedIn = () => {
    return localStorage.getItem('Authorization');
}

export default status;

export {
    isFullyActive,
    isInactive,
    isBanned,
    isLoggedIn,
    isActive,
}
