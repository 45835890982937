<template>
  <div>
    <VueFileAgent v-if="!outputFileUrl" v-model:modelValue="csvFiles" v-model:rawModelValue="csvFiles"
                  :theme="'grid'" :multiple="false" :meta="true" :accept="'text/csv'" :maxSize="'8MB'" :maxFiles="1"
                  :deletable="true" :helpText="'Choose csv file or drag & drop here'" :errorText="{
                    type: 'Invalid file type. Only csv are allowed',
                    size: 'Files should not exceed 8MB in size',
                  }" @beforedelete="onBeforeDelete($event)" @select="onFilesSelected($event)" required>
    </VueFileAgent>

    <div class="d-flex flex-row justify-content-center">
      <b-button v-if="!outputFileUrl" size="xl" class="btn-main mt-4" variant="none" @click="handleSubmit">
        Submit
      </b-button>
    </div>

    <p v-if="outputFileUrl" class="text-center">
      Result: <a :href="outputFileUrlWithTimestamp" target="_blank">{{ outputFileUrl }}</a>
    </p>
    <div v-if="outputFileUrl" class="d-flex flex-row justify-content-center mt-4">
      <b-button size="xl" class="btn-outline-main mr-2" variant="none" @click="reset">
        Reset
      </b-button>
      <b-button size="xl" class="btn-main" variant="none" @click="downloadResult">
        Download Result
      </b-button>
    </div>
  </div>
</template>
<script>

import { appendTimestampToUrl, getBase64 } from "../../helpers/common"
import adminHelperService from "../../services/admin/helper.service"

export default {
  components: {},
  data () {
    return {
      csvFiles: [],
      outputFileUrl: null,
    }
  },
  async mounted () {},
  methods: {
    onBeforeDelete (csvFile) {
      const index = this.csvFiles.indexOf(csvFile)
      if (index !== -1) {
        this.csvFiles.splice(index, 1)
      }
    },
    async onFilesSelected (csvFiles) {
      if (this.csvFiles.length) {
        for (let i = 0; i < this.csvFiles.length; i++) {
          if (!this.csvFiles[i].path) {
            this.csvFiles[i].file_base_64 = await getBase64(this.csvFiles[i].file)
          }
        }
      }
    },

    async handleSubmit () {
      if (this.csvFiles.length > 0) {
        const dataToSubmit = { input_base_64: this.csvFiles[0].file_base_64 }
        const result = await adminHelperService.getTokensInfo(dataToSubmit)
        if (result && result.output_file_url) {
          this.outputFileUrl = result.output_file_url
        }
      }
    },

    async downloadResult () {
      if (this.outputFileUrl) {
        const filename = "rm_output.csv"
        const anchor = document.createElement("a")
        anchor.href = this.outputFileUrlWithTimestamp
        anchor.download = filename
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
      }
    },

    async reset () {
      this.csvFiles = []
      this.outputFileUrl = null
    }
  },
  computed: {
    outputFileUrlWithTimestamp () {
      if (this.outputFileUrl) {
        return appendTimestampToUrl(this.outputFileUrl)
      }
      return null
    }
  }
}
</script>
<style scoped>

</style>
