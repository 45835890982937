import axios from "axios"
import messErrors from "../constants/errors"
import { notify } from "../helpers/common"
import i18n from "../i18n"
import { STATUS_CODE } from "../constants/constants"

const baseURL = process.env.VUE_APP_API_ENDPOINT;
const DEFAULT_TIMEOUT = 20000;
let csrfRetries = 0;
const maxCsrfRetries = 3;
let pendingRequests = 0;

async function hideLoading() {
  if (!pendingRequests) {
    await store.dispatch("setIsLoading", false);
  }
}

let notifyTimeout;
let notifyMessage;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
  data: function (params) {
    // Filter out null or empty values
    return Object.keys(params)
      .filter(key => params[key] !== null && params[key] !== '')
      .reduce((obj, key) => {
        obj[key] = params[key]
        return obj
      }, {});
  },
  paramsSerializer: function (params) {
    // Filter out null or empty values
    return Object.keys(params)
      .filter(key => params[key] !== null && params[key] !== '')
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
      .join("&")
  },
  transformRequest: [function (data) {
    if (data) {
      // Filter out null or empty values from data
      const filteredData = Object.keys(data).reduce((obj, key) => {
        if (data[key] !== null && data[key] !== "") {
          obj[key] = data[key]
        }
        return obj
      }, {})
      return JSON.stringify(filteredData)
    }
    return data
  }],
  timeout: DEFAULT_TIMEOUT,
});
instance.interceptors.request.use(config => {
  pendingRequests++
  if (config.url === "admin/rental-distributions/create"
    || config.url === "admin/rental-distributions/update"
    || config.url === "admin/rental-distributions/info"
    || config.url === "admin/rental-distributions/details"
    || config.url === "admin/rental-distributions/create-details"
    || config.url === "admin/dashboard/get-statistical-info"
    || config.url === "admin/property-dashboard/get-statistical-info") {
    config.timeout = 600000
  } else if (config.url === "contracts/transactions/get-preview"
    || config.url === "contracts/transactions/get-preview-for-existing"
    || config.url === "contracts/transactions/create-for-existing"
    || config.url === "payments/create-payment"
    || config.url === "sell-token-requests/approve") {
    config.timeout = 50000
  } else {
    config.timeout = DEFAULT_TIMEOUT
  }
  return config
})
instance.interceptors.response.use(
  function (response) {
    pendingRequests--
    hideLoading()
    return response;
  },
  async function (error) {
    pendingRequests--
    hideLoading()
    if (error && error.response) {
      const status = error.response.status
      if (status === 419) {
        // refresh session token
        if (csrfRetries < maxCsrfRetries) {
          try {
            csrfRetries++;
            await store.dispatch("refreshSanctumCsrfCookie");
            const config = error.config;
            if (config.data) {
              config.data = JSON.parse(config.data);
            }
            return instance(config);
          } catch (e) {
            return Promise.reject(e);
          }
        } else {
          csrfRetries = 0;
          const ex = createError(error)
          ex.data = ex.data + "20230511001";
          return Promise.reject(ex)
        }
      }
      const ex = createError(error)
      return Promise.reject(ex)
    }
    return Promise.reject(error)
  }
);

const createError = (error) => {
  console.log(error)
  const ex = new Error(error?.response?.data?.error || error?.response?.data?.message || error?.message)
  ex.source = "API"
  ex.statusCode = (error.response && error.response.status) || 500
  ex.data =
    (error.response.data && (error.response.data.error
      || error.response.data.errors || error.response.data.message))
    || (error.code ? error.message + " (Code: " + error.code + ")" : error.message)
    || messErrors.INTERNAL
  ex.extraData = (error.response && error.response.data)
  return ex
}

const serverApi = async ({
                           uri,
                           method,
                           data,
                           isLoading = true,
                           isNotify = true,
                           isManualErrorHandling = false,
                         }) => {
  if (isLoading) {
    await store.dispatch("setIsLoading", true);
  }
  try {
    let params;
    let body;
    if (data != null) {
      if (method === "POST" || method === "PUT") {
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if (typeof element === "string") {
              data[key] = element.trim();
            }
          }
        }
        body = data;
      } else {
        params = data;
      }
    }
    const response = await instance({
      method: method,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("Authorization")}`,
        "X-Localization": i18n.global.locale.value,
        "X-Platform": "web"
      },
      data: body,
      url: uri,
      params,
    });
    if (isNotify && response.data && response.data.message) {
      if (!notifyMessage) {
        notifyMessage = response.data.message
        notify({ text: notifyMessage });
        notifyTimeout = setTimeout(() => {
          notifyMessage = null
        }, 2000)
      } else {
        if (notifyMessage !== response.data.message) {
          notify({ text: response.data.message });
        }
      }
    }
    return response.data;
  } catch (e) {
    if (e.statusCode === STATUS_CODE.HTTP_UPGRADE_REQUIRED) {

    } else if (e.statusCode == STATUS_CODE.HTTP_PRECONDITION_REQUIRED) {
      await store.dispatch("setRequired2FA", false);
      await store.dispatch("setRequired2FA", true);
    } else {
      if (isManualErrorHandling) {
        throw e
      } else if (e.source === "API") {
        throw e
      } else {
        const err = new Error(e.toString() || messErrors.INTERNAL)
        err.source = "API"
        throw err
      }
    }
  } finally {
    if (isLoading) {
      await store.dispatch("setIsLoading", false);
    }
  }
};

export default serverApi;
