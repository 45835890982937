<template>
  <div>
    <b-modal v-model="showModal" centered="true" modal-class="goro-general-fe-side-modal" id="id-goro-sell-sold-out-modal" @hidden="$emit('on-close')" header-class="has-icon">
        <template #modal-header>
          <div class="icon-header warning">
            <img width="210" height="210" src="@/assets/img/icons/modal_warning.png"/>
          </div>
          <h5 class="modal-header-title mb-0 font-24 font-bold red">
            {{ $t('MODALS.SELL_SOLD_OUT.HEADER') }}
          </h5>
          <!-- <button type="button" class="close font-weight-bold" aria-label="Close" @click="$emit('on-close')">
            <img width="32" height="32" src="@/assets/img/icons/close.svg"/>
          </button> -->
        </template>
        <p class="font-20">
          {{ $t('MODALS.SELL_SOLD_OUT.CONTENT') }}
        </p>
        <template #modal-footer>
            <b-button id="btn_modalSellSoldOut_Cancel" variant="primary" class="btn-main btn-modal fixed-width mr-3" @click="$emit('on-close')">
                {{ $t('MODALS.COMMON.CANCEL') }}
            </b-button>
            <b-button id="btn_modalSellSoldOut_Continue" variant="primary" class="btn-main btn-modal btn-outline fixed-width" @click="$emit('on-sell')">
                {{ $t('MODALS.COMMON.CONTINUE') }}
            </b-button>
        </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-close"],
  data () {
    return {
      showModal: false,
      collapses: {
        lockedTokens: false,
      },
    }
  },

  watch: {
    show (value) {
      this.showModal = value
    },
  },

  methods: {
    
  },

  computed: {
    
  },
}
</script>

<style lang="scss" scoped>

</style>
