<template>
    <img class="avatar" :style="{ width: `${width}px`, height: `${height}px` }" src="@/assets/img/logo_white.png" />
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 50,
        },
        height: {
            type: Number,
            default: 50,
        },
    },
}
</script>

<style lang="scss" scoped>
.avatar {
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid var(--primary-color);
    padding: 6px;
    background-color: var(--primary-color);
}
</style>