import serverApi from "../utils/serverApi"

const ordersService = {

  getOrderInfo: (body) => serverApi({ uri: "orders/get-order-info", method: "POST", data: body }),

  getOrders: (data) => serverApi({ uri: "orders", method: "GET", data }),

  approveOrder: (body) => serverApi({ uri: "orders/approve", method: "PUT", data: body }),

  rejectOrder: (body) => serverApi({ uri: "orders/reject", method: "PUT", data: body }),
}

export default ordersService
