<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" @show="updateModal" @hidden="$emit('on-close')"
        @ok="handleOk">
        <template #modal-header>
            <div>
                <h5>{{ title }}</h5>
                <p class="font-14">Enable/Disable images will be used on referral page</p>
            </div>
        </template>
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-row class="pl-3 pr-3 mt-2" align-v="center" v-for="item in imageItems">
                <img class="property-img" :src="getUrl(item)" />
                <b-form-checkbox :id="`checkbox-${item.id}`" class="ml-3" v-model="item.visible"
                    :name="`checkbox-${item.id}`" :value="true" :unchecked-value="false">
                    {{ item.visible ? 'Enabled' : 'Disabled' }}
                </b-form-checkbox>
            </b-row>

        </form>
    </b-modal>
</template>

<script>

import { urlImage } from "@/helpers/common"
import imageService from "../../../services/admin/image.service"

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        images: {
            type: Array,
            default: null,
        },
    },
    emits: ['on-close'],
    data() {
        return {
            form: [],
            imageItems: [],
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        updateModal() {
            if (this.images) {
                this.imageItems = this.images.map(e => {
                    return {
                        id: e.id,
                        image: e.image,
                        visible: e.visible_on_prefer,
                    }
                })
            }
        },

        resetModal() {
            this.form = []
            this.images = []
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            const images = this.imageItems.map(e => {
                return {
                    id: e.id,
                    visible: e.visible,
                }
            })
            const res = await imageService.updatePreferImages({ images })
            if (res) {
                this.$emit('on-close')
            }
        },

        getUrl(image) {
            return urlImage(image)
        },
    },

    computed: {
        title() {
            return 'Images Visibility';
        },
    },
}
</script>

<style lang="scss" scoped>
.property-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid rgb(204, 204, 204);
}
</style>