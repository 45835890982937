<template>
  <div class="row">
    <div class="col-12">
      <div slot="raw-content" class="table-responsive">
        <paper-table
          :data="payments.data"
          :columns="tableColumns"
          :show-edit-action="true" @onModalEdit="onModalDetail"
        >
          <template #theadSearch>
            <thead class="search">
            <th></th>
            <th></th>
            <th>
              <b-input v-model="filters.user_email"></b-input>
            </th>
            <th width="35px">
              <b-input v-model="filters.property_id"></b-input>
            </th>
            <th>
              <b-input v-model="filters.property_name"></b-input>
            </th>
            <th width="90px">
              <b-input v-model="filters.external_id"></b-input>
            </th>
            <th width="30px"></th>
            <th></th>
            <th>
              <b-input v-model="filters.status"></b-input>
            </th>
            <th></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination
          v-if="payments.total"
          align="right"
          v-model="payments.current_page"
          :total-rows="payments.total"
          :per-page="payments.per_page"
          @change="onChangePage"
          aria-controls="my-table"
        ></b-pagination>
      </div>

      <modal-payment title="Payment Detail" :show="showModalDetail" :payment="payment" @onClose="hideModalDetail"></modal-payment>
    </div>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import ModalPayment from "./modals/ModalPayment"
import paymentsService from "../../services/payments.service"

const tableColumns = ["Id", "Trx_Id", "Email", "Property_id", "Property_name", "External_id", "Num_of_tokens", "Amount", "Status"]

export default {
  components: {
    PaperTable,
    ModalPayment,
  },
  data () {
    return {
      payments: {},
      tableColumns: [...tableColumns],
      filters: {
        user_id: "",
        user_email: "",
        property_id: "",
        property_name: "",
        external_id: "",
        status: "",
      },
      showModalDetail: false,
      payment: {},
    }
  },
  async mounted () {
    await Promise.all([
      this.getPayments(1),
    ])
  },
  watch: {
    async "filters.user_id" () {
      this.searchTimeOut(1)
    },
    async "filters.user_email" () {
      this.searchTimeOut(1)
    },
    async "filters.property_id" () {
      this.searchTimeOut(1)
    },
    async "filters.property_name" () {
      this.searchTimeOut(1)
    },
    async "filters.external_id" () {
      this.searchTimeOut(1)
    },
    async "filters.status" () {
      this.searchTimeOut(1)
    },
  },
  methods: {
    async getPayments (page) {
      const filters = {
        ...this.filters,
        page,
      }
      const result = await paymentsService.getPayments(filters)
      if (result && result.data) {
        this.payments = result
      }
    },
    async onChangePage (page) {
      await this.getPayments(page)
    },
    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getPayments(page)
      }, 800)
    },

    onModalDetail (data) {
      if (data) {
        this.payment = this.payments.data.find((e) => e.id === data)
        this.showModalDetail = true
      }
    },
    async hideModalDetail (data) {
      this.showModalDetail = data.show
      if (data.show === false) {
        this.payment = {}
      }
    },
  }
}
</script>
<style scoped>
</style>
