import store from '../store/store';

const roles = {
  Admin: 'ADMIN',
  User: 'USER',
  Partner: 'PARTNER'
};

export default roles;

const isUser = () => {
  return store.state.userProfile && store.state.userProfile.role === roles.User;
}

const isAdmin = () => {
  return store.state.userProfile && store.state.userProfile.role === roles.Admin;
}

const isPartner = () => {
  return store.state.userProfile && store.state.userProfile.role === roles.Partner;
}

export {
  isUser,
  isAdmin,
  isPartner
};
