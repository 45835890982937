<template>
    <div v-if="!hideVirtualBalance" class="vb-container">
        <b-container>
            <b-row align-h="center" align-v="center" class="text-center">
                <b-col cols="12" xl="9" lg="9" class="text-center text-lg-left message-text">
                    <p class="value-text">{{ $t("VIRTUAL_BALANCE.VIRTUAL_BALANCE") }}{{
                        virtualBalance
                    }}</p>
                    <p>{{ earnMessage }}</p>
                </b-col>
                <b-col v-if="!hideViewProperty" cols="12" xl="3" lg="3" class="mt-2 mt-lg-0 text-center text-lg-right">
                    <router-link :to="{ name: 'marketplace' }">
                        <b-button id="btn_virtualBalance_ViewOutProperties" class="btn-main" type="submit" variant="none">
                            {{ $t("VIRTUAL_BALANCE.VIEW_OUR_PROPERIES") }}
                        </b-button>
                    </router-link>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>

import { getDeviceUUID, numberWithCommas } from "../helpers/common"
import guestService from "@/services/guest.service"
import store from "@/store/store"

export default {

    data() {
        return {
            transactions: [],
        }
    },

    async mounted() {

    },

    methods: {
        async getTransactions() {
            if (!this.hideVirtualBalance) {
                const res = await guestService.getTransactions(getDeviceUUID())
                if (res.data && res.data.length) {
                    this.transactions = res.data
                }
            }
        },
    },

    computed: {
        isEnableVirtualBalance () {
          if (store.state.configs && store.state.configs.enabled_virtual_balance) {
            return store.state.configs.enabled_virtual_balance
          }
          return false
        },

        initialBalance() {
            return store.getters.initialBalance
        },

        virtualBalance() {
            return ` IDR${numberWithCommas(this.initialBalance)}`;
        },

        hideVirtualBalance () {
          return store.getters.userProfile || this.initialBalance === 0
            || this.$route.name === "buySuccess" || !this.isEnableVirtualBalance
        },
        hideViewProperty() {
            return this.$route.name === 'marketplace'
        },
        earnMessage() {
            const days = this.$store.getters.configs.initial_virtual_balance_expiry_period || 0
            return this.$t("VIRTUAL_BALANCE.VIRTUAL_BALANCE_EARN", { value: days })
        }
    },
}
</script>
<style lang="scss">
.vb-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top: 2px solid var(--primary-color);
    z-index: 1000;

    .value-text {
        font-size: 23px;
        font-weight: bold;
    }

    .message-text {
        font-size: 15px;
        color: var(--primary-color);
    }
}
</style>
