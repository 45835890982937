<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" title="Send Email" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-form-group label="Template alias" label-for="key-input" :state="validateState('template_id')">
                <b-form-select id="key-alias" v-model="form.template_id" :options="templatesData" text-field="alias"
                    value-field="id" :state="validateState('template_id')">
                    <template #first>
                        <b-form-select-option :value="null">Select template</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Campaign Title" label-for="key-title" :state="validateState('campaign_title')">
                <b-form-input id="key-title" v-model="form.campaign_title" placeholder="Campaign title"
                    :state="validateState('campaign_title')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group v-for="field in fieldColumns" :label="field" :label-for="`value-${field}`"
                :state="validateState(field)">
                <b-form-input :id="`value-${field}`" v-model="form[field]" :state="validateState(field)" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="CC (optional)" label-for="key-cc">
                <b-form-input id="key-cc" v-model="form.cc" placeholder="Multiple emails separate by comma(,)">
                </b-form-input>
            </b-form-group>

            <b-form-group label="BCC (optional)" label-for="key-bcc">
                <b-form-input id="key-bcc" v-model="form.bcc" placeholder="Multiple emails separate by comma(,)">
                </b-form-input>
            </b-form-group>

            <b-form-group label="Tag (optional)" label-for="key-tag">
                <b-form-input id="key-tag" v-model="form.tag">
                </b-form-input>
            </b-form-group>

            <b-form-group label="" label-for="key-send-all">
                <b-form-checkbox id="key-send-all" :disabled="form.is_testing" v-model="form.is_all_users"
                    name="checkbox-send-all" :value="true" :unchecked-value="false">
                    Send to all users (except Admin)
                </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="!form.is_all_users" label="Recipients" label-for="key-recipients">
                <multiselect id="key-recipients" :disabled="form.is_testing" v-model="form.to" :options="recipients"
                    :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                    placeholder="Select recipients" label="email" :custom-label="customLabel" track-by="id"
                    :preselect-first="true" :allowEmpty="form.is_all_users">
                    <template v-slot:selection slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                            v-if="values && values.length && !isOpen">{{ values.length }} recipients
                            selected</span></template>
                </multiselect>
            </b-form-group>

            <b-form-group label="" label-for="key-testing">
                <b-form-checkbox id="key-testing" v-model="form.is_testing" name="checkbox-testing" :value="true"
                    :unchecked-value="false">
                    Send testing email
                </b-form-checkbox>
            </b-form-group>

            <b-form-group label="" label-for="key-schedule">
                <b-form-checkbox id="key-schedule" v-model="schedule_send" name="checkbox-testing" :value="true"
                    :unchecked-value="false">
                    Schedule sending
                </b-form-checkbox>
            </b-form-group>
            <VueDatePicker v-if="schedule_send" v-model="schedule_time" model-type="timestamp" :disabled-dates="notBeforeToday" placeholder="Select schedule time"/>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="secondary" @click="cancel()">
                Cancel
            </b-button>
            <b-button size="md" variant="primary" @click="ok()">
                Send
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { notify } from "../../../helpers/common";
import emailService from "../../../services/admin/email.service";

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        templates: {
            type: Array,
            default() {
                return []
            },
        },
    },
    validations() {
        let v = {
            form: {
                template_id: {
                    required,
                },
                campaign_title: {
                    required,
                },
            },
        };
        if (!this.form.is_all_users && !this.form.is_testing) {
            v.form.to = { required };
        }
        this.fieldColumns.forEach(e => {
            v.form[e] = { required };
        });
        return v;
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    emits: ['on-close'],
    data() {
        return {
            form: {
                template_id: null,
                campaign_title: '',
                is_testing: false,
                to: [],
                is_all_users: true,
                cc: '',
                bcc: '',
                tag: '',
                schedule_time: null,
            },
            templatesData: this.templates,
            fieldColumns: [],
            recipients: [],
            schedule_send: false,
            schedule_time: null,
            showModal: false,
        }
    },
    watch: {
        'form.template_id'(value) {
            const template = this.templatesData.find((e) => e.id === value);
            if (template) {
                this.fieldColumns = template.fields.split(',').map(e => e.trim());
            } else {
                this.fieldColumns = [];
            }
        },
        async 'form.is_all_users'(value) {
            if (!value && !this.recipients.length) {
                const res = await emailService.getRecipients();
                if (res && res.data) {
                    this.recipients = res.data;
                }
            }
        },
        'schedule_time'(value) {
            if (!value) {
                this.form.schedule_time = null;
            } else {
                this.form.schedule_time = value / 1000;
            }
        },
        'schedule_send'(value) {
            if (!value) {
                this.schedule_time = null;
            } else {
                this.schedule_time = new Date().getTime();
            }
        },
        show(value) {
            this.showModal = value
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            this.resetModal();
        },

        resetModal() {
            this.form = {
                template_id: null,
                campaign_title: '',
                is_testing: false,
                to: [],
                is_all_users: true,
                cc: '',
                bcc: '',
                tag: '',
                schedule_time: null,
            };
            this.schedule_send = false;
            this.templatesData = this.templates
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            if (this.form.is_all_users) {
                this.form.to = '';
            } else {
                this.form.to = this.form.to.reduce((a, c) => a ? `${a},${c.id}` : c.id, '');
            }
            if (!this.form.schedule_time) {
                this.form.schedule_time = new Date().getTime() / 1000;
            }
            try {
                await emailService.sendEmail(this.form);
                this.$nextTick(() => {
                    this.resetModal();
                    this.$emit('on-close', true);
                })
            } catch (err) {
                notify({
                    text: err.data,
                    type: 'error',
                });
            }
        },

        customLabel({ name, email }) {
            return `${name} (${email})`;
        },

        notBeforeToday(date) {
            const d = new Date();
            return date < new Date(d.setDate(d.getDate() - 1));
        },

        notBeforeThisTime(date) {
            // TODO: check time and date
            const d = new Date();
            const newDate = new Date(new Date().setHours(date.hours, date.minutes, date.seconds, 0));
            return newDate < new Date(new Date().setHours(d.getHours(), d.getMinutes(), d.getSeconds(), 0));
        },
    },

    computed: {
    },
}
</script>

<style lang="scss" scoped>

</style>
