<template>
    <div>
        <div class="pulse-button d-flex flex-row align-items-center justify-content-center"
            @click="startVideo">
            <b-icon icon="play-circle-fill"></b-icon>
            <p class="font-16 font-weight-bold ml-2">{{ $t('WHAT_IS_GOGO') }}</p>
        </div>
        <modal-video v-model:isOpen="videoIsOpen" :videoUrl="videoUrl" :videoId="videoId" />
    </div>
</template>

<script>
import { GTM_EVENT_NAMES } from "../constants/gtm"
import { gtmTrackEvent } from "../helpers/gtm"
import ModalVideo from "../modals/ModalVideo.vue"

export default {
    components: {
        ModalVideo,
    },
    props: {
        videoUrl: String,
        videoId: String,
    },
    data() {
        return {
            videoIsOpen: false,
        }
    },
    emits: ['on-start-video'],
    methods: {
        startVideo() {
            this.videoIsOpen = true
            this.$emit('on-start-video')
            gtmTrackEvent({
                event: GTM_EVENT_NAMES.WHAT_IS_GORO
            })
        },
    },
}
</script>

<style lang="scss">
.pulse-button {
    width: 180px;
    height: 38px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid var(--primary-color);
    cursor: pointer;
    box-shadow: 0 0 0 0 var(--primary-color);
    animation: ripple 1s infinite;

  &:hover {
    background-color: var(--primary-background-hover-color);
    border-color: var(--primary-hover-color);
    animation: ripple-hover 1s infinite;
  }
}

@keyframes ripple {
  0% {
    background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%;
    background-color: rgba(0, 142, 142, 0.4);
    background-size: 100%;
  }

  100% {
    background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%;
  }
}

@keyframes ripple-hover {
  0% {
    background: var(--primary-background-hover-color) radial-gradient(circle, transparent 1%, var(--primary-background-hover-color) 1%) center/15000%;
    background-color: rgba(0, 142, 142, 0.4);
    background-size: 100%;
  }

  100% {
    background: var(--primary-background-hover-color) radial-gradient(circle, transparent 1%, var(--primary-background-hover-color) 1%) center/15000%;
  }
}
</style>
