<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <b-button
            class="btn btn-none btn-main"
            style="padding: 5px 20px"
            size="sm"
            id="property-pick-date"
            @click="onShowModalPickDate()"
          >
            {{ $t("PARTNER.PICK_DATE") }}
          </b-button>
        </div>
        <div class="mb-3">
            <div class="cls-custom-filters-applied d-flex justify-content-start align-items-center">
            <p class="cls-applied-label">Filters: </p>
            <p v-if="!hasFiltered" class="cls-applied-label no-filtered">No filters</p>
            <div v-if="filters.from_created_at || filters.to_created_at" class="cls-applied-item cls-pick-date">
                <span v-if="filters.from_created_at">{{ filters.from_created_at }}</span>
                <span v-if="filters.to_created_at"> - {{ filters.to_created_at }}</span>
                <button class="btn-remove-filter" @click="filters.created_at=null">
                    <b-icon icon="x-lg" style="color: gray;" scale="1.6"></b-icon>
                </button>
            </div>
            </div>
        </div>
        <div slot="raw-content" class="table-responsive">
          <paper-table
            class="cls-goro-custom-table"
            :hover="true"
            :data="items"
            :columns="tableColumns"
            :showEditAction="false"
            :customActionTitles="customActions"
            @onCustomAction="onClickedCustomAction"
            @onRowClicked="onRowClicked"
          >
            <template #theadSearch>
              <thead class="search">
                <th width="10%"></th>
                <th width="10%">
                  <b-input v-model="filters.external_id"></b-input>
                </th>
                <th width="10%">
                  <b-select :options="transactionTypes" v-model="filters.type">
                  </b-select>
                </th>
                <th width="5%"></th>
                <th width="5%">
                  <b-input v-model="filters.property_name"></b-input>
                </th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="10%">
                  <b-form-select
                    v-model="filters.status"
                    text-field="name"
                    value-field="id"
                  >
                    <template #first>
                      <b-form-select-option :value="''"
                        >Select status</b-form-select-option
                      >
                    </template>
                    <template v-for="status in statuses">
                      <b-form-select-option :value="status">
                        {{ status }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="60px"></th>
              </thead>
            </template>
          </paper-table>
          <b-pagination
            v-if="transactions.total"
            align="left"
            class="mt-3"
            v-model="transactions.current_page"
            :total-rows="transactions.total"
            :per-page="transactions.per_page"
            @change="onChangePage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <modal-pick-date
      :show="showModalPickDate"
      @on-close="showModalPickDate = false"
      @on-apply="onApply"
    ></modal-pick-date>
    <modal-transaction-details :show="showModalTransactionDetails" :transaction="transaction" @on-close="onCloseTransactionDetails" />
  </div>
</template>
<script>
import moment from "moment";
import { PaperTable } from "../../components";
import transactionService from "../../services/partner/transactions.service";
import ModalTransactionDetails from "./modals/ModalTransactionDetails.vue";
import { exchange, getTransactionStatus } from "../../helpers/common";
import { INDO, TRANSACTION_CUSTOM_ACTIONS } from "../../constants/constants";
import { USER_PERMISSIONS } from "../../constants/permissions";
import { partnerHasPermission } from "../../helpers/partner";
import ModalPickDate from "./modals/ModalPickDate.vue";

const tableColumns = [
  "uuid",
  "Trx_id",
  "Trx_type",
  "Property_id",
  "Property_name",
  "Num_of_tokens",
  "Amount",
  "Status",
  "Notes",
  "Created_at",
];

export default {
  components: {
    PaperTable,
    ModalTransactionDetails,
    ModalPickDate,
  },
  data() {
    return {
      transactions: {},
      items: [],
      tableColumns: [...tableColumns],
      filters: {
        external_id: "",
        property_name: "",
        type: "",
        status: "",
        created_at: null,
        from_created_at: "",
        to_created_at: "",
      },
      showModal: false,
      user: null,
      showModalTransactionDetails: false,
      showModalPickDate: false,
      transactionTypes: this.getAllTransactionTypes(),
      statuses: [
        "PENDING",
        "PAID",
        "SETTLED",
        "EXPIRED",
        "REFUNDED",
        "APPROVED",
        "CANCELLED",
        "REJECTED",
        "COMPLETED",
        "FAILED",
      ],
    };
  },
  async mounted() {
    await this.getTransactions();
  },
  watch: {
    "filters.uuid"() {
      this.searchTimeOut();
    },
    "filters.external_id"() {
      this.searchTimeOut();
    },
    "filters.property_name"() {
      this.searchTimeOut();
    },
    "filters.type"() {
      this.searchTimeOut();
    },
    "filters.status"() {
      this.searchTimeOut();
    },
    async "filters.created_at"(value) {
      this.filters.from_created_at = null;
      this.filters.to_created_at = null;

      if (value && value[0]) {
        this.filters.from_created_at = value[0];
      }
      if (value && value[1]) {
        this.filters.to_created_at = value[1];
      }
      this.searchTimeOut();
    },
  },
  computed: {
    hasFiltered() {
      if (this.filters.created_at) {
          return true
      }
      return false
    },
    customActions() {
      let actions = [];
      actions.push({
        ...TRANSACTION_CUSTOM_ACTIONS.VIEW_TRANSACTION_DETAILS,
        label: this.$t("PARTNER.TRANSACTION.DETAIL"),
        btnClass: "btn btn-none btn-main",
      });

      return actions;
    },
  },
  methods: {
    async getTransactions(page = 1) {
      const self = this
      const filters = {
        ...this.filters,
        page,
      };
      const result = await transactionService.getTransactions(filters);
      if (result && result.data) {
        let { data } = result;
        this.transactions = result;
        this.items =
          (data &&
            data.map((e) => {
              const status = this.getStatus(e);
              return {
                id: e.id,
                uuid: e.uuid,
                property_id: (e.property && e.property.id) || "--",
                property_name: (e.property && e.property.name) || "--",
                type: e.type,
                trx_type: this.$t(`TRANSACTIONS_TYPES.${e.type}`),
                num_of_tokens: e.num_of_tokens || "--",
                amount: self.exchangeValue(e.amount + e.amount_from_balance + e.fee),
                status_value: status,
                status: this.$t(`TRANSACTIONS_STATUS.${status}`),
                notes: e.note,
                created_at: e.created_at,
                trx_id:
                  e.external_id ||
                  (e.payment && e.payment.external_id) ||
                  (e.withdrawal && e.withdrawal.external_id) ||
                  "--",
                item: e,
              };
            })) ||
          [];
      }
    },

    onShowModalPickDate() {
      this.showModalPickDate = true;
    },

    onCloseTransactionDetails() {
      this.showModalTransactionDetails = false;
      this.transaction = null;
    },

    onApply(dates) {
      this.filters.created_at = dates;
    },

    async onChangePage(page) {
      await this.getTransactions(page);
    },

    searchTimeOut(page = 1) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getTransactions(page);
      }, 400);
    },

    onClickedCustomAction(data, key) {
      const transaction = this.transactions.data.find((e) => e.id === data);
      if (key === TRANSACTION_CUSTOM_ACTIONS.VIEW_TRANSACTION_DETAILS.key) {
        this.transaction = transaction;
        this.showModalTransactionDetails = true;
      }
    },

    onRowClicked(data, item) {
      console.log(this.transactions, data)
      const transaction = this.transactions.data.find((e) => e.id === data);
      this.transaction = transaction;
      this.showModalTransactionDetails = true;
    },

    getStatus(e) {
      return getTransactionStatus(e);
    },

    exchangeValue(value) {
      return exchange(value, 100, false, INDO.CURRENCY)
    },

    getAllTransactionTypes() {
      const types = [
        "ALL",
        "BUY_TOKEN",
        "SELL_TOKEN",
        "ORDER_TOKEN",
        "REFERRAL_BONUS",
        "REFERRAL_BONUS_TOKEN",
        "WITHDRAWAL",
        "RENTAL_DISTRIBUTION",
        "BUY_TOKEN_VIRTUAL_BALANCE",
        "VIRTUAL_RENTAL_DISTRIBUTION",
      ];
      return types.map((e) => {
        return {
          text: this.$t(`TRANSACTIONS_TYPES.${e}`),
          value: e !== "ALL" ? e : "",
        };
      });
    },
  },
};
</script>
<style scoped></style>
