<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div slot="raw-content" class="table-responsive">
                    <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                        <div class="d-flex flex-row">
                           
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            Export:
                            <download-csv ref="downloadCsv" class="ml-2" :data="dataToExport" :fields="downloadFields"
                                :labels="downloadLabels" :name="csvFileName">
                                <div></div>
                            </download-csv>
                            <div class="download-btn" @click="exportCsv">csv
                                <b-icon icon="download" scale="0.8" />
                            </div>
                            <export-excel ref="downloadExcel" class="ml-2" :data="dataToExport" :fields="excelDownloadFields" :name="excelFileName">
                                <div></div>
                            </export-excel>
                            <div class="download-btn" @click="exportExcel">xls
                                <b-icon icon="download" scale="0.8" />
                            </div>
                        </div>
                    </div>
                    <paper-table :data="adminLogsData.data" :columns="tableColumns" :customActionTitles="customActions" @onCustomAction="onClickedCustomAction">
                        <template #theadSearch>
                            <thead class="search">
                                <th>
                                    <VueDatePicker class="" v-model="filters.date" model-type="dd/MM/yyyy" format="dd/MM/yyyy"
                                :range="true" :enable-time-picker="false"
                                placeholder="Search date range" />
                                </th>
                                <th>
                                    <b-input class="" v-model="filters.user_uuid" 
                                placeholder="Filter by uuid"></b-input>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="adminLogsData.total" align="right" v-model="adminLogsData.current_page"
                        :total-rows="adminLogsData.total" :per-page="perPage" @change="onChangePage"
                        aria-controls="my-table" class="mt-3"></b-pagination>
                </div>
            </div>
        </div>
        <modal-show-admin-log-changes title="Show Changes" :show="enableShowChanges" :showChangesData="showChangesData"
        @onClose="onCloseShowChanges"></modal-show-admin-log-changes>
    </div>
</template>
<script>
import moment from "moment"
import { PaperTable } from "../../components"
import adminLogsService from "../../services/admin/adminLogs.service"
import { exchange } from '../../helpers/common'
import { ADMIN_LOGS_CUSTOM_ACTIONS } from "../../constants/constants"
import ModalShowAdminLogChanges from "@/pages/admin/modals/ModalShowAdminLogChanges"
import { PAGINATION_DEFAULT } from "../../constants/pagination"

export default {
    components: {
        PaperTable,
        ModalShowAdminLogChanges,
    },
    data() {
        return {
            perPage: PAGINATION_DEFAULT.perPage,
            adminLogsData: {},
            tableColumns: this.getTableHeaderFields(),
            filters: {
                user_uuid: '',
                start: '',
                end: '',
            },
            showModal: false,
            downloadFields: [
                'created_at',
                'uuid',
                'email',
                'action_type',
                'table_name',
                'data_id',
                'value_original',
                'value_changes',
                'ip',
                'browser'
            ],
            downloadLabels: {
                created_at: 'Created At',
                uuid: 'Admin UUID',
                email: 'Admin Email',
                action_type: 'Action Type',
                table_name: 'Table Name',
                data_id: 'Data ID',
                value_original: 'Value Original',
                value_changes: 'Value Changes',
                ip: 'IP Address',
                browser: 'Browser Name',
            },
            excelDownloadFields: {
                'Created At': 'created_at',
                'Admin UUID': 'uuid',
                'Admin Email': 'email',
                'Action Type': 'action_type',
                'Table Name': 'table_name',
                'Data ID': 'data_id',
                'Value Original': 'value_original',
                'Value Changes': 'value_changes',
                'IP Address': 'ip',
                'Browser Name': 'browser',
            },
            dataToExport: [],
            showChangesData: null,
            enableShowChanges: false
        };
    },
    async mounted() {
        await this.getAdminLogsData(1);
    },
    watch: {
        'filters.user_uuid'() {
            this.searchTimeOut(1);
        },
        'filters.date'(value) {
            if (value && value.length === 2) {
                this.filters.start = value[0]
                this.filters.end = value[1]
                this.searchTimeOut(1);
            } else {
                this.filters.start = ''
                this.filters.end = ''
                this.searchTimeOut(1);
            }
            this.dataToExport = []
        },
    },
    methods: {
        async getAdminLogsData(page = 1) {
            const filters = {
                ...this.filters,
                per_page: this.perPage,
                page,
            };
            const result = await adminLogsService.getAdminLogsData(filters);
            if (result && result.data) {
                let data = result
                let items = data.data
                items = items.map((e, idx) => {
                    e.admin_uuid = e.uuid
                    e.admin_email = e.email
                    e.ip_address = e.ip
                    e.browser_name = e.browser
                    e.id = idx
                    return e
                })
                data.data = items
                this.adminLogsData = data
            }
        },

        async onChangePage(page) {
            await this.getAdminLogsData(page);
        },

        searchTimeOut() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getAdminLogsData();
            }, 400);
        },

        async exportCsv() {
            if (!this.dataToExport.length) {
                await this.getExportData()
            }
            this.$refs.downloadCsv.generate()
        },

        async exportExcel() {
            if (!this.dataToExport.length) {
                await this.getExportData()
            }
            this.$refs.downloadExcel.generate()
        },

        async getExportData() {
            const filters = {
                ...this.filters,
                page: 1,
                per_page: this.adminLogsData?.total || 1000,
            };
            const result = await adminLogsService.getAdminLogsData(filters);
            if (result && result.data) {
                this.dataToExport = result.data.map((e, idx) => {
                    e.admin_uuid = e.uuid
                    e.admin_email = e.email
                    e.ip_address = e.ip
                    e.value_original = JSON.stringify(e.value_original, null, 2)
                    e.value_changes = JSON.stringify(e.value_changes, null, 2)
                    e.browser_name = e.browser
                    e.id = idx
                    return e
                })
                await this.$nextTick()
            }
        },
        getTableHeaderFields () {
            return [
                'Created At',
                'Admin UUID',
                'Admin Email',
                'Action Type',
                'Table Name',
                'Data ID',
                'IP Address',
                'Browser Name'
            ]
        },
        async onClickedCustomAction(idx, key) {
            if (key === ADMIN_LOGS_CUSTOM_ACTIONS.SHOW_CHANGES.key) {
                this.showChangesData = this.adminLogsData.data[idx] || null
                this.enableShowChanges = true
            }
        },

        async onCloseShowChanges(data) {
            this.enableShowChanges = false
            this.showChangesData = null
        },
    },

    computed: {
        fileName() {
            const user_uuid = this.filters.user_uuid ? `_${this.filters.user_uuid}` : ''
            const date = this.filters.start && this.filters.end ? `_${this.filters.start}-${this.filters.end}` : '';
            return `export_admin_logs${user_uuid}${date}_${moment().format('YYYYMMDDHHmmss')}`;
        },

        csvFileName() {
            return `${this.fileName}.csv`;
        },

        excelFileName() {
            return `${this.fileName}.xls`;
        },
        customActions() {
            let actions = []
            actions.push(ADMIN_LOGS_CUSTOM_ACTIONS.SHOW_CHANGES)

            return actions
        },
    },
};
</script>
<style scoped>
.download-btn {
    cursor: pointer;
    color: white;
    background-color: black;
    padding: 4px 10px 4px 10px;
    border-radius: 6px;
}
</style>

