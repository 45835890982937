<template>
    <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light" @ok="handleOk" @hidden="onHidden()">
        <template #modal-header>
            <h5 class="modal-header-title">{{ title }}</h5>
            <button type="button" class="close font-weight-bold" aria-label="Close"
                @click="onClose({ show: false })">×</button>
        </template>
        <div class="container">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row align-h="center">
                    <b-col cols="6">
                        <label>Card type</label>
                        <div class="d-flex flex-row justify-content-around">
                            <b-form-radio :disabled="completed" v-model="type" name="ktp"
                                value="indonesia_ktp">Indonesia
                                KTP</b-form-radio>
                            <b-form-radio :disabled="completed" v-model="type" name="passport"
                                value="passport">Passport</b-form-radio>
                        </div>
                        <label class="mt-3">Card photo</label>
                        <VueFileAgent v-if="!completed" :model-value="images" v-model:rawModelValue="images" ref="vueFileAgent" :theme="'grid'"
                            :multiple="false" :meta="false" :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1"
                            :helpText="'Choose image file'" :errorText="{
                                type: 'Invalid file type. Only images are allowed',
                                size: 'Files should not exceed 3MB in size',
                            }" @beforedelete="onBeforeDelete($event)" @select="filesSelected($event)" required>
                        </VueFileAgent>
                        <img v-if="completed" :src="cardImageUrl" style="width:100%;" />
                        <p v-if="cardError" class="error">Please select a card to upload</p>
                        <div class="mt-3" v-if="type === 'indonesia_ktp'">
                            <b-form-group label="Id number" label-for="key-id-number"
                                :state="validateState('id_number')">
                                <b-form-input :disabled="completed" id="key-id-number" v-model="ktpForm.id_number"
                                    placeholder="Id number" :state="validateState('id_number')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Name" label-for="key-name" :state="validateState('name')">
                                <b-form-input :disabled="completed" id="key-name" v-model="ktpForm.name"
                                    placeholder="Name" :state="validateState('name')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Gender" label-for="key-gender" :state="validateState('gender')">
                                <b-form-select :disabled="completed" id="key-gender" v-model="ktpForm.gender"
                                    :options="idGenderOptions" :state="validateState('gender')"
                                    required></b-form-select>
                            </b-form-group>
                            <b-form-group label="City" label-for="key-city" :state="validateState('city')">
                                <b-form-input :disabled="completed" id="key-city" v-model="ktpForm.city"
                                    placeholder="City" :state="validateState('city')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Village" label-for="key-village" :state="validateState('village')">
                                <b-form-input :disabled="completed" id="key-village" v-model="ktpForm.village"
                                    placeholder="Village" :state="validateState('village')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="District" label-for="key-district" :state="validateState('district')">
                                <b-form-input :disabled="completed" id="key-district" v-model="ktpForm.district"
                                    placeholder="District" :state="validateState('district')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Province" label-for="key-province" :state="validateState('province')">
                                <b-form-input :disabled="completed" id="key-province" v-model="ktpForm.province"
                                    placeholder="Province" :state="validateState('province')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Address" label-for="key-address" :state="validateState('address')">
                                <b-form-input :disabled="completed" id="key-address" v-model="ktpForm.address"
                                    placeholder="Address" :state="validateState('address')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Religion" label-for="key-religion" :state="validateState('religion')">
                                <b-form-input :disabled="completed" id="key-religion" v-model="ktpForm.religion"
                                    placeholder="Religion" :state="validateState('religion')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Blood type" label-for="key-blood-type">
                                <b-form-select :disabled="completed" id="key-blood-type" v-model="ktpForm.blood_type"
                                    :options="bloodTypeOptions"></b-form-select>
                            </b-form-group>
                            <b-form-group label="Expiry date" label-for="key-expiry-date"
                                :state="validateState('expiry_date')">
                                <b-form-input :disabled="completed" id="key-expiry-date" v-model="ktpForm.expiry_date"
                                    placeholder="Expiry date" :state="validateState('expiry_date')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Occupation" label-for="key-occupation"
                                :state="validateState('occupation')">
                                <b-form-input :disabled="completed" id="key-occupation" v-model="ktpForm.occupation"
                                    placeholder="Occupation" :state="validateState('occupation')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Nationality" label-for="key-nationality"
                                :state="validateState('nationality')">
                                <b-form-input :disabled="completed" id="key-nationality" v-model="ktpForm.nationality"
                                    placeholder="Nationality" :state="validateState('nationality')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Marital status" label-for="key-marital-status"
                                :state="validateState('marital_status')">
                                <b-form-input :disabled="completed" id="key-marital-status"
                                    v-model="ktpForm.marital_status" placeholder="Marital status"
                                    :state="validateState('marital_status')" required>
                                </b-form-input>
                            </b-form-group>
                            <label class="mr-3">Birthday</label><br />
                            <VueDatePicker :disabled="completed" class="mb-2" v-model="ktpForm.birthday" format="dd-MM-yyyy" style="width: 100%;"
                            :class="[{ 'input-valid': validBirthday === true }, { 'input-normal': validBirthday === null }, { 'input-invalid': validBirthday === false }]"
                            placeholder="Birthday" @internal-model-change="onBirthdayChange" :clearable="false" />
                            <b-form-group label="Birth place" label-for="key-birth-place"
                                :state="validateState('birth_place')">
                                <b-form-input :disabled="completed" id="key-birth-place" v-model="ktpForm.birth_place"
                                    placeholder="Birth place" :state="validateState('birth_place')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="RT/RW" label-for="key-rtrw" :state="validateState('rtrw')">
                                <b-form-input :disabled="completed" id="key-rtrw" v-model="ktpForm.rtrw"
                                    placeholder="Rtrw" :state="validateState('rtrw')" required>
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="mt-3" v-else>
                            <b-form-group label="Passport number" label-for="key-passport-number"
                                :state="validateStatePassport('passport_number')">
                                <b-form-input :disabled="completed" id="key-passport-number"
                                    v-model="passportForm.passport_number" placeholder="Passport number"
                                    :state="validateStatePassport('passport_number')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Surname" label-for="key-surname"
                                :state="validateStatePassport('surname')">
                                <b-form-input :disabled="completed" id="key-surname" v-model="passportForm.surname"
                                    placeholder="Surname" :state="validateStatePassport('surname')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Given name" label-for="key-given-name"
                                :state="validateStatePassport('given_name')">
                                <b-form-input :disabled="completed" id="key-given-name"
                                    v-model="passportForm.given_name" placeholder="Given name"
                                    :state="validateStatePassport('given_name')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Full name" label-for="key-full-name"
                                :state="validateStatePassport('full_name')">
                                <b-form-input :disabled="completed" id="key-full-name"
                                    v-model="passportForm.given_name" placeholder="Full name"
                                    :state="validateStatePassport('full_name')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Gender" label-for="key-gender"
                                :state="validateStatePassport('gender')">
                                <b-form-select :disabled="completed" id="key-gender" v-model="passportForm.gender"
                                    :options="genderOptions" :state="validateStatePassport('gender')"
                                    required></b-form-select>
                            </b-form-group>
                            <label class="mr-3">Date of birth</label><br />
                            <VueDatePicker :disabled="completed" class="mb-2" v-model="passportForm.date_of_birth" format="yyyy-MM-dd" style="width: 100%;"
                                :class="[{ 'input-valid': validDateOfBirth === true }, { 'input-normal': validDateOfBirth === null }, { 'input-invalid': validDateOfBirth === false }]"
                                placeholder="Date of birth"
                                @internal-model-change="date => { if (date) { validDateOfBirth = true } }"
                                :clearable="false" />
                            <b-form-group label="Place of birth" label-for="key-place-of-birth"
                                :state="validateStatePassport('place_of_birth')">
                                <b-form-input :disabled="completed" id="key-place-of-birth"
                                    v-model="passportForm.place_of_birth" placeholder="Place of birth"
                                    :state="validateStatePassport('place_of_birth')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Country" label-for="key-country"
                                :state="validateStatePassport('country')">
                                <b-form-input :disabled="completed" id="key-country"
                                    v-model="passportForm.country" placeholder="Country"
                                    :state="validateStatePassport('country')" required>
                                </b-form-input>
                            </b-form-group>
                            <label class="mr-3">Date of issue</label><br />
                            <VueDatePicker :disabled="completed" class="mb-2" v-model="passportForm.date_of_issue" format="yyyy-MM-dd" style="width: 100%;"
                                :class="[{ 'input-valid': validDateOfIssue === true }, { 'input-normal': validDateOfIssue === null }, { 'input-invalid': validDateOfIssue === false }]"
                                placeholder="Date of issue"
                                @internal-model-change="date => { if (date) { validDateOfIssue = true } }"
                                :clearable="false" />
                            <label class="mr-3">Date of expiry</label><br />
                            <VueDatePicker :disabled="completed" class="mb-2" v-model="passportForm.date_of_expiry" format="yyyy-MM-dd" style="width: 100%;"
                                :class="[{ 'input-valid': validDateOfExpiry === true }, { 'input-normal': validDateOfExpiry === null }, { 'input-invalid': validDateOfExpiry === false }]"
                                placeholder="Date of expiry"
                                @internal-model-change="date => { if (date) { validDateOfExpiry = true } }"
                                :clearable="false" />
                            <b-form-group label="Issuing authority" label-for="key-issuing-authority"
                                :state="validateStatePassport('issuing_authority')">
                                <b-form-input :disabled="completed" id="key-issuing-authority"
                                    v-model="passportForm.issuing_authority" placeholder="Issuing authority"
                                    :state="validateStatePassport('issuing_authority')" required>
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button v-if="!completed && show" size="sm" variant="secondary" @click="onClose({ show: false })">
                Cancel
            </b-button>
            <b-button v-if="show" size="sm" variant="primary" @click="completed ? onClose({ show: false }) : ok()">
                {{ completed? "Ok": "Update" }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getBase64, urlImage, userHasPermission } from "../../../helpers/common"
import userService from "../../../services/admin/user.service"
import moment from 'moment'
import { USER_PERMISSIONS } from '../../../constants/permissions'

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
        },
    },

    validations() {
        if (this.type === 'indonesia_ktp') {
            const fields = ['id_number', 'name', 'city', 'address', 'village',
                'district', 'province', 'gender', 'religion', 'expiry_date',
                'occupation', 'nationality', 'marital_status', 'birth_place', 'rtrw']
            let v = {
                ktpForm: {},
            }
            fields.forEach(e => {
                v.ktpForm[e] = { required };
            });
            return v
        }
        const fields = ['passport_number', 'surname', 'given_name', 'full_name', 'gender',
            'place_of_birth', 'issuing_authority', 'country']
        let v = {
            passportForm: {},
        }
        fields.forEach(e => {
            v.passportForm[e] = { required };
        });
        return v
    },

    setup() {
        return {
            v$: useVuelidate()
        }
    },

    data() {
        return {
            showModal: false,
            type: 'indonesia_ktp',
            ktpForm: {
                gender: null,
                blood_type: null,
            },
            passportForm: {
                gender: null,
            },
            images: [],
            idGenderOptions: [
                {
                    value: null,
                    text: 'Select gender',
                },
                {
                    value: 'LAKI-LAKI',
                    text: 'Male',
                },
                {
                    value: 'PEREMPUAN',
                    text: 'Female'
                },
            ],
            genderOptions: [
                {
                    value: null,
                    text: 'Select gender',
                },
                {
                    value: 'Male',
                    text: 'Male',
                },
                {
                    value: 'Female',
                    text: 'Female'
                },
            ],
            bloodTypeOptions: [
                {
                    value: null,
                    text: 'Select blood type',
                },
                {
                    value: 'O',
                    text: 'O',
                },
                {
                    value: 'A',
                    text: 'A'
                },
                {
                    value: 'B',
                    text: 'B'
                },
                {
                    value: 'AB',
                    text: 'AB'
                },
            ],
            validBirthday: null,
            validDateOfBirth: null,
            validDateOfIssue: null,
            validDateOfExpiry: null,
            cardError: false,
            idCard: null,
        }
    },

    emits: ['on-close'],

    watch: {
        userId(value) {
            if (value) {
                this.getIdCard(value)
            }
        },

        show(value) {
            this.showModal = value
        },

        idCard(value) {
            if (value && Object.keys(value).length) {
                this.type = value.card_type
                const name = value.card_image ? value.card_image.split("/").pop() : ''
                const type = value.card_image ? value.card_image.split(".").pop() : ''
                const imageUrl = urlImage({
                    image: value.card_image,
                })
                const images = [
                    {
                        id: value.id,
                        name: name,
                        tempName: name,
                        url: imageUrl,
                        path: imageUrl,
                        size: 0,
                        type: `image/${type}`,
                    }
                ]
                this.images = images
                const cardData = value.card_data
                if (this.type === 'indonesia_ktp') {
                    const birthPlaceBirthday = cardData.birthPlaceBirthday ? cardData.birthPlaceBirthday.split(', ') : []
                    this.ktpForm = {
                        id_number: cardData.idNumber,
                        name: cardData.name,
                        gender: cardData.gender,
                        city: cardData.city,
                        village: cardData.village,
                        district: cardData.district,
                        province: cardData.province,
                        address: cardData.address,
                        religion: cardData.religion,
                        blood_type: cardData.bloodType,
                        expiry_date: cardData.expiryDate,
                        occupation: cardData.occupation,
                        nationality: cardData.nationality,
                        marital_status: cardData.maritalStatus,
                        rtrw: cardData.rtrw,
                        birthday: birthPlaceBirthday.length > 1 ? moment(birthPlaceBirthday[1], 'DD-MM-YYYY') : '',
                        birth_place: birthPlaceBirthday.length > 0 ? birthPlaceBirthday[0] : '',
                    }
                } else {
                    this.passportForm = {
                        passport_number: cardData.passport_number,
                        surname: cardData.surname,
                        given_name: cardData.given_name,
                        full_name: cardData.full_name,
                        gender: cardData.gender,
                        date_of_birth: cardData.date_of_birth,
                        date_of_issue: cardData.date_of_issue,
                        date_of_expiry: cardData.date_of_expiry,
                        place_of_birth: cardData.place_of_birth,
                        issuing_authority: cardData.issuing_authority,
                        country: cardData.country,
                    }
                }
            }
        }
    },

    methods: {
        async getIdCard(id) {
            const res = await userService.getIdCard(id);
            if (res) {
                this.idCard = res
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.v$.ktpForm[name];
            return $dirty ? !$error : null;
        },
        validateStatePassport(name) {
            const { $dirty, $error } = this.v$.passportForm[name];
            return $dirty ? !$error : null;
        },
        onReset() {
            this.ktpForm = {
                gender: null,
                blood_type: null,
            }
            this.passportForm = {
                gender: null,
            }
            this.images = []
            this.validBirthday = null
            this.validDateOfBirth = null
            this.validDateOfIssue = null
            this.validDateOfExpiry = null
            this.idCard = null
            this.cardError = false
            this.type = 'indonesia_ktp'
        },

        onHidden: function (v = { show: false }) {
            this.$emit("on-close", v)
            this.onReset()
        },

        onClose: function (v) {
            this.showModal = false
        },
        onBeforeDelete(fileRecord) {
            const index = this.images.indexOf(fileRecord)
            if (index !== -1) {
                this.images.splice(index, 1)
            }
        },

        onBirthdayChange(value) {
            if (value) {
                this.validBirthday = true
            }
        },

        async filesSelected(fileRecords) {
            this.cardError = false
            if (this.images.length) {
                for (let i = 0; i < this.images.length; i++) {
                    if (!this.images[i].id && !this.images[i].path) {
                        this.images[i].path = await getBase64(this.images[i].file)
                        this.images[i].updated = true
                    }
                }
            }
        },
        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },
        async handleSubmit() {
            if (!this.images.length) {
                this.cardError = true
            }
            if (this.type === 'indonesia_ktp') {
                this.v$.ktpForm.$touch();
                if (!this.ktpForm.birthday) {
                    this.validBirthday = false
                }
                const isKtpFormCorrect = await this.v$.$validate()
                if (!isKtpFormCorrect || !this.ktpForm.birthday || this.cardError) {
                    return;
                }
                const image = {
                    path: this.images[0].path,
                    name: this.images[0].file && this.images[0].file.name || this.images[0].tempName,
                    updated: this.images[0].updated,
                }
                const formData = {
                    type: this.type,
                    ...this.ktpForm,
                    card_image: image,
                    birthday: moment(this.ktpForm.birthday).format('DD-MM-YYYY'),
                }
                const res = await userService.updateCardInfo(this.userId, formData)
                if (res) {
                    this.onClose({ show: false })
                }
            } else {
                this.v$.passportForm.$touch();
                if (!this.passportForm.date_of_birth) {
                    this.validDateOfBirth = false
                }
                if (!this.passportForm.date_of_issue) {
                    this.validDateOfIssue = false
                }
                if (!this.passportForm.date_of_expiry) {
                    this.validDateOfExpiry = false
                }
                const isPassportFormCorrect = await this.v$.$validate()
                if (!isPassportFormCorrect
                    || !this.passportForm.date_of_birth
                    || !this.passportForm.date_of_issue
                    || !this.passportForm.date_of_expiry
                    || this.cardError) {
                    return;
                }
                const image = {
                    path: this.images[0].path,
                    name: this.images[0].file && this.images[0].file.name || this.images[0].tempName,
                    updated: this.images[0].updated,
                }
                const formData = {
                    type: this.type,
                    ...this.passportForm,
                    card_image: image,
                    date_of_birth: moment(this.passportForm.date_of_birth).toISOString(),
                    date_of_issue: moment(this.passportForm.date_of_issue).toISOString(),
                    date_of_expiry: moment(this.passportForm.date_of_expiry).toISOString(),
                }
                const res = await userService.updateCardInfo(this.userId, formData)
                if (res) {
                    this.onClose({ show: false })
                }
            }
        },
    },

    computed: {
        title() {
            return "KTP/Passport"
        },

        completed() {
            if (!this.hasUpdatePermission) {
                return true
            }
            return this.idCard && this.idCard.is_valid
        },

        hasUpdatePermission() {
            return userHasPermission(USER_PERMISSIONS.USER.UPDATE_KTP_PASSPORT_SELFIE)
        },

        cardImageUrl() {
            if (!this.idCard) {
                return ''
            }
            return urlImage({
                image: this.idCard.card_image,
            })
        },
    },
}
</script>

<style scoped>
:deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
}

#full-screen-modal :deep(.modal-dialog) {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
    border-radius: 0;
}

.modal-header-title {
    margin: 0;
    font-weight: bold;
}

.close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
}

.input-invalid {
    border: 1px solid #dc3545;
    border-radius: 5px;
}

.input-valid {
    border: 1px solid #28a745;
    border-radius: 5px;
}

.input-normal {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.error {
    color: red;
    font-size: 15px;
}
</style>
