import serverApi from "../../utils/serverApi"

const virtualRentalDistributionService = {
  
  createRentalDistributionDetails: (body) => serverApi({
    uri: "admin/virtual-rental-distributions/create-details",
    method: "POST",
    data: body
  }),

  getRentalDistributionDetails: (data) => serverApi({
    uri: "admin/virtual-rental-distributions/details", method: "GET", data
  }),
}

export default virtualRentalDistributionService
