
import serverApi from '../../utils/serverApi';

const imageService = {
    getPropertyImages: (data) => serverApi({ uri: 'admin/images/get-by-property-id', method: 'GET', data }),

    updatePreferImages: (data) => serverApi({ uri: 'admin/images/update-prefer', method: 'PUT', data }),
};

export default imageService;
