<template>
  <form ref="form" @submit.stop.prevent="handleSubmit">
    <b-form-group label="Registered Users:">
      <b-row>
        <b-col>
          <b-input-group prepend="ID">
            <b-form-input v-model="form.registered_users.id" :state="validateState('registered_users', 'id')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN">
            <b-form-input v-model="form.registered_users.en" :state="validateState('registered_users', 'en')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN_UK">
            <b-form-input v-model="form.registered_users.en_UK" :state="validateState('registered_users', 'en_UK')">
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="Invested in Property:" style="margin-top: 30px">
      <b-row>
        <b-col>
          <b-input-group prepend="ID">
            <b-form-input v-model="form.invested_in_property.id" :state="validateState('invested_in_property', 'id')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN">
            <b-form-input v-model="form.invested_in_property.en" :state="validateState('invested_in_property', 'en')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN_UK">
            <b-form-input v-model="form.invested_in_property.en_UK"
                          :state="validateState('invested_in_property', 'en_UK')">
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="Total Income Paid:" style="margin-top: 30px">
      <b-row>
        <b-col>
          <b-input-group prepend="ID">
            <b-form-input v-model="form.total_income_paid.id" :state="validateState('total_income_paid', 'id')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN">
            <b-form-input v-model="form.total_income_paid.en" :state="validateState('total_income_paid', 'en')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN_UK">
            <b-form-input v-model="form.total_income_paid.en_UK" :state="validateState('total_income_paid', 'en_UK')">
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="Number of Properties:" style="margin-top: 30px">
      <b-row>
        <b-col>
          <b-input-group prepend="ID">
            <b-form-input v-model="form.properties.id" :state="validateState('properties','id')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN">
            <b-form-input v-model="form.properties.en" :state="validateState('properties', 'en')">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group prepend="EN_UK">
            <b-form-input v-model="form.properties.en_UK" :state="validateState('properties', 'en_UK')">
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
    <div class="d-flex justify-content-center">
      <b-button class="btn-main mt-4 pl-5 pr-5" type="submit" variant="none">Save</b-button>
    </div>
  </form>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import commonService from "../../services/common.service"
import configService from "../../services/admin/configs.service"

export default {
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  async mounted () {
    await this.getStatisticNumbers()
  },
  data () {
    return {
      configKey: "statistic_numbers",
      id: null,
      form: {
        registered_users: {
          id: "",
          en: "",
          en_UK: ""
        },
        invested_in_property: {
          id: "",
          en: "",
          en_UK: ""
        },
        total_income_paid: {
          id: "",
          en: "",
          en_UK: ""
        },
        properties: {
          id: "",
          en: "",
          en_UK: ""
        }
      },
    }
  },
  validations () {
    return {
      form: {
        registered_users: {
          id: { required },
          en: { required },
          en_UK: { required }
        },
        invested_in_property: {
          id: { required },
          en: { required },
          en_UK: { required }
        },
        total_income_paid: {
          id: { required },
          en: { required },
          en_UK: { required }
        },
        properties: {
          id: { required },
          en: { required },
          en_UK: { required }
        },
      },
    }
  },
  methods: {
    validateState (name, language) {
      const { $dirty, $error } = this.v$.form[name][language]
      return $dirty ? !$error : null
    },
    async getStatisticNumbers () {
      const result = await commonService.getConfigDetail(this.configKey)
      if (result && result.data) {
        let config = result.data
        this.form.id = config.id
        let value = JSON.parse(config.c_value)
        this.form.registered_users.id = value.registered_users.id
        this.form.registered_users.en = value.registered_users.en
        this.form.registered_users.en_UK = value.registered_users.en_UK

        this.form.invested_in_property.id = value.invested_in_property.id
        this.form.invested_in_property.en = value.invested_in_property.en
        this.form.invested_in_property.en_UK = value.invested_in_property.en_UK

        this.form.total_income_paid.id = value.total_income_paid.id
        this.form.total_income_paid.en = value.total_income_paid.en
        this.form.total_income_paid.en_UK = value.total_income_paid.en_UK

        this.form.properties.id = value.properties.id
        this.form.properties.en = value.properties.en
        this.form.properties.en_UK = value.properties.en_UK
      }
    },
    async handleSubmit () {
      this.v$.form.$touch()
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        const result = await configService.createOrUpdate({
          id: this.form.id,
          c_key: this.configKey,
          c_value: JSON.stringify(this.form),
          description: "Should be update from \"Statistic Numbers\" menu (JSON format)"
        })
        if (result) {this.form.id = result.id}
      }
    }
  },

  computed: {
    title () {
      return "Statistic Numbers"
    },
  },
}
</script>

<style lang="scss" scoped></style>
