import serverApi from "../../utils/serverApi";

const maintenanceService = {
  get: () => serverApi({ uri: "admin/maintenance", method: "GET" }),

  update: (data) =>
    serverApi({ uri: "admin/maintenance", method: "POST", data }),
};

export default maintenanceService;
