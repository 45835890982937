<template>
  <div class="row">
    <div class="col-12">
      <div class="pb-2">
        <b-button size="sm" id="show-btn" @click="createSpc">
          <b-icon icon="plus-lg"></b-icon>
          Add new
        </b-button>
      </div>
      <div slot="raw-content" class="table-responsive">
        <paper-table
          :data="spcs.data" :columns="tableColumns" :show-action="true" :show-edit-action="true" :show-delete-action="true"
          @onModalEdit="editSpc" @onModalDelete="onModalDelete"
        >
          <template #theadSearch>
            <thead class="search">
            <th></th>
            <th>
              <b-input v-model="filters.uuid"></b-input>
            </th>
            <th>
              <b-input v-model="filters.slug"></b-input>
            </th>
            <th>
              <b-input v-model="filters.company_name"></b-input>
            </th>
            <th>
              <b-input v-model="filters.director_name"></b-input>
            </th>
            <th>
              <b-input v-model="filters.company_address"></b-input>
            </th>
            <th>
              <b-input v-model="filters.company_tax_no"></b-input>
            </th>
            <th></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination
          v-if="spcs.total"
          align="right"
          v-model="spcs.current_page"
          :total-rows="spcs.total"
          :per-page="spcs.per_page"
          @change="onChangePage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
      <modal-spc :show="showModalSpc" :spc="spc" @on-close="onModalSpcClose"/>

      <b-modal v-model="modalDelete.show" header-bg-variant="dark" header-text-variant="light">
        <template #modal-header>
          <h5 class="modal-header-title mb-0">Delete confirm</h5>
          <button id="btn_closeModelDeleteSpc" type="button" class="close font-weight-bold" aria-label="Close"
                  @click="modalDelete.show = false">×
          </button>
        </template>
        <p>Are you sure you wanna delete this <strong style="color: red">spc</strong>?</p>
        <template #modal-footer>
          <b-button variant="danger" size="sm" @click="deleteSpc">
            Delete
          </b-button>
          <b-button variant="primary" size="sm" @click="modalDelete.show = false">
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import ModalSpc from "./modals/ModalSpc"
import spcsService from "../../services/spcs.service"

const tableColumns = ["Id", "Uuid", "Slug", "Company_name", "Director_name", "Company_address", "Company_tax_no"]

export default {
  components: {
    PaperTable,
    ModalSpc,
  },
  data () {
    return {
      spcs: {},
      tableColumns: [...tableColumns],
      filters: {
        uuid: "",
        company_name: "",
        director_name: "",
        company_address: "",
        company_tax_no: "",
      },
      spc: null,
      showModalSpc: false,
      modalDelete: {
        show: false,
        content: "",
        id: null,
      },
    }
  },
  async mounted () {
    await Promise.all([
      this.getSpcs(1),
    ])
  },
  watch: {
    async "filters.uuid" () {
      this.searchTimeOut(1)
    },
    async "filters.slug" () {
      this.searchTimeOut(1)
    },
    async "filters.company_name" () {
      this.searchTimeOut(1)
    },
    async "filters.director_name" () {
      this.searchTimeOut(1)
    },
    async "filters.company_address" () {
      this.searchTimeOut(1)
    },
    async "filters.company_tax_no" () {
      this.searchTimeOut(1)
    },
  },
  methods: {
    async getSpcs (page) {
      const filters = { ...this.filters, page }
      const result = await spcsService.getSpcs(filters)
      if (result && result.data) {
        this.spcs = result
      }
    },
    async onChangePage (page) {
      await this.getSpcs(page)
    },
    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getSpcs(page)
      }, 800)
    },
    createSpc () {
      this.spc = null
      this.showModalSpc = true
    },
    editSpc (data) {
      if (data) {
        this.spc = this.spcs.data.find((e) => e.id === data)
        this.showModalSpc = true
      }
    },
    onModalSpcClose (success) {
      this.showModalSpc = false
      if (success) {
        this.getSpcs(this.spcs.current_page)
      }
    },
    onModalDelete (data) {
      this.modalDelete = data
    },
    async deleteSpc () {
      if (this.modalDelete.id) {
        await spcsService.deleteSpc(this.modalDelete.id)
        this.modalDelete = {
          show: false,
          content: "",
          id: null,
        }
        await this.getSpcs(1)
      }
    },
  }
}
</script>
<style scoped>
</style>
