<template>
  <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
    @hidden="$emit('on-close')" @ok="handleOk">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Deduction Type" label-for="key-input" :state="validateState('deduction_type')">
        <b-form-input id="key-input" v-model="form.deduction_type" :state="validateState('deduction_type')" required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Description" label-for="key-input" :state="validateState('description')">
        <b-form-textarea id="key-input" v-model="form.description" :state="validateState('description')"
          placeholder='{"en": "description", "id": "deskripsi"}' rows="5" required>
        </b-form-textarea>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import rentalDistributionService from "../../../services/admin/rentalDistribution.service"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rentalDeductionType: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return {
      form: {
        deduction_type: {
          required,
        },
        description: {
          required,
        },
      },
    }
  },
  emits: ["on-close"],
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      form: {
        deduction_type: "",
        description: "",
      },
      showModal: false,
    }
  },

  watch: {
    show(value) {
      this.showModal = value
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v$.form[name]
      return $dirty ? !$error : null
    },

    updateModal() {
      if (this.rentalDeductionType != null) {
        this.form = {
          id: this.rentalDeductionType.id,
          deduction_type: this.rentalDeductionType.deduction_type,
          description: this.rentalDeductionType.description,
        }
      } else {
        this.resetModal()
      }
    },

    resetModal() {
      this.form = {
        id: "",
        deduction_type: "",
        description: "",
      }
    },

    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      await this.handleSubmit()
    },

    async handleSubmit() {
      this.v$.form.$touch();
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return;
      }
      if (this.rentalDeductionType != null) {
        await rentalDistributionService.updateRentalDeductionType(this.form)
      } else {
        await rentalDistributionService.createRentalDeductionType(this.form)
      }
      this.$nextTick(() => {
        this.resetModal()
        this.$emit("on-close", true)
      })
    }
  },

  computed: {
    title() {
      return this.rentalDeductionType != null ? "Update deduction type" : "Add new deduction type"
    },
  },
}
</script>

<style lang="scss" scoped></style>
