<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
    @hidden="$emit('on-close')" @ok="handleOk">
        <b-form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Name" label-for="name-input" :state="validateState('name')">
                <b-form-input id="name-input" v-model="form.name" :state="validateState('name')" required>
                </b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="email-input" :state="validateState('email')">
                <b-form-input id="email-input" v-model="form.email" :state="validateState('email')" type="email" required>
                </b-form-input>
            </b-form-group>
            <b-form-group label="Password" label-for="password-input" :state="validateState('password')">
                <b-form-input id="password-input" v-model="form.password" :state="validateState('password')" type="password" autocomplete="on"
                    required >
                </b-form-input>
                <b-form-invalid-feedback>Password is a required field and must be at least 8 characters long.
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Roles" label-for="key-roleIds">
                <multiselect id="key-roleIds" v-model="form.role_ids" :options="roles" :multiple="true"
                    :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="None" label="name"
                    track-by="id" :allowEmpty="true">
                </multiselect>
            </b-form-group>
        </b-form>
    </b-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required,minLength, email} from '@vuelidate/validators'
import rolesService from "../../../services/admin/roles.service";
import adminUsersService from "../../../services/admin/adminUsers.service";

export default {
    components: {
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        admin: {
            type: Object,
            default: null,
        },
    },
    validations() {
       return { form: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(8),
            },
        },
    }
    },
    emits: ["on-close"],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {
                name: "",
                email: "",
                password: "",
                role_ids: [],
            },
            showModal: false,
            roles: [],
        };
    },

    async mounted() {
        await this.getRoles();
    },

    watch: {
        show(value) {
            this.showModal = value;
        },
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null
        },

        async getRoles() {
            const result = await rolesService.getAll();
            if (result) {
                this.roles = result;
            }
        },

        async updateModal() {
            if (this.admin != null) {
                this.form = {
                    name: this.admin.name,
                    email: this.admin.email,
                    password: this.admin.password,
                    role_ids: this.admin.roles.map((role) => role.id),
                    id: this.admin.id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                name: "",
                email: "",
                password: "",
                role_ids: [],
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            await this.handleSubmit();
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }

            const dataToSubmit = {
                ...this.form,
                role_ids: this.form.role_ids.map((item) => item.id),
            };

            if (this.admin != null) {
                await adminUsersService.update(this.form.id, dataToSubmit);
            } else {
                await adminUsersService.create(dataToSubmit);
            }

            this.$nextTick(() => {
                this.resetModal();
                this.$emit("on-close", true);
            });
        },
    },

    computed: {
        title() {
            return this.role != null ? "Update admin" : "Add new admin";
        },
    },
};
</script>

<style lang="scss" scoped></style>
