<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="referrals.data" :columns="tableColumns" :showCopyAction="true" @onCopy="onCopy">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th>
                                    <b-input v-model="filters.code"></b-input>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="referrals.total" align="right" v-model="referrals.current_page"
                        :total-rows="referrals.total" :per-page="referrals.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <b-modal v-model="showModalCreate" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Generate Referral Code</h5>
                <button id="btn_closeShowModalCreateReferral" type="button" class="close font-weight-bold" aria-label="Close"
                    @click="showModalCreate=false">×</button>
            </template>
            <p>Are you sure you want to generate a new referral code?
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onGenerate">
                    Generate
                </b-button>
                <b-button variant="primary" size="sm" @click="showModalCreate=false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import referralService from "../../services/admin/referral.service";
import { notify } from "../../helpers/common";

const tableColumns = ["Id", "Code", "Used_Count", "Max_Invites"];

export default {
    components: {
        PaperTable,
    },
    data() {
        return {
            referrals: {},
            tableColumns: [...tableColumns],
            filters: {
                code: '',
            },
            showModalCreate: false,
        };
    },
    async mounted() {
        await this.getReferrals(1);
    },
    watch: {
        'filters.code'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getReferrals(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await referralService.getList(filters);
            if (result && result.data) {
                this.referrals = result;
            }
        },

        async onChangePage(page) {
            await this.getReferrals(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getReferrals(page);
            }, 400);
        },

        showAddModal() {
            this.showModalCreate = true;
        },

        async onGenerate() {
            this.showModalCreate = false;
            const result = await referralService.create();
            if (result && result.data) {
                await this.getReferrals(this.referrals.current_page);
            }
        },

        onCopy(data) {
            const link = `${window.location.origin}/invite/${data.code}`;
            navigator.clipboard.writeText(link);
            notify({ text: this.$t('common.COPIED') });
        },
    }
};
</script>
<style scoped>

</style>
