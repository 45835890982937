<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="adminUsers.data" :columns="tableColumns" :showEditAction="true"
                        @onModalEdit="onModalEdit" >
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th width="100px">
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th width="10%">
                                    <b-input v-model="filters.email"></b-input>
                                </th>
                                <th>
                                    <multiselect id="key-recipients" v-model="filters.role_ids" :options="roles"
                                        :multiple="true" :close-on-select="false" :clear-on-select="true"
                                        :preserve-search="true" placeholder="Select roles" label="name" track-by="id"
                                        :allowEmpty="true" selectLabel="select" deselectLabel="remove">
                                    </multiselect>
                                </th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="adminUsers.total" align="right" v-model="adminUsers.current_page"
                        :total-rows="adminUsers.total" :per-page="adminUsers.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-update-admin-role :show="showModalEdit" :adminUser="adminUser" @on-close="onModalClose" />
        <modal-create-admin :show="showModalCreate" @on-close="onModalClose" />
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import adminUsersService from "../../services/admin/adminUsers.service";
import rolesService from "../../services/admin/roles.service";
import ModalCreateAdmin from "./modals/ModalCreateAdmin.vue";
import ModalUpdateAdminRole from "./modals/ModalUpdateAdminRole.vue";

const tableColumns = ["Id", "Name", "Email", "Roles", "2FA", "Status"];

export default {
    components: {
        PaperTable,
        ModalCreateAdmin,
        ModalUpdateAdminRole,
    },
    data() {
        return {
            adminUsers: {},
            tableColumns: [...tableColumns],
            filters: {
                name: '',
                email: '',
                role_ids: null,
            },
            showModalEdit: false,
            showModalCreate: false,
            adminUser: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
            roles: [],
        };
    },
    async mounted() {
        await this.getAdmins(1);
        await this.getRoles();
    },
    watch: {
        'filters.name'() {
            this.searchTimeOut(1);
        },
        'filters.email'() {
            this.searchTimeOut(1);
        },
        'filters.role_ids'() {
            this.getAdmins(1);
        },
    },
    methods: {

        async getAdmins(page) {
            const filters = {
                ...this.filters,
                role_ids: this.filters.role_ids ? this.filters.role_ids.map((e) => e.id) : null,
                page,
            };
            const result = await adminUsersService.getList(filters);
            if (result && result.data) {
                this.adminUsers = result;
            }
        },

        async getRoles() {
            this.roles = await rolesService.getAll();
        },

        async onChangePage(page) {
            await this.getAdmins(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getAdmins(page);
            }, 400);
        },

        showAddModal() {
            this.adminUser = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalEdit = false;
            this.showModalCreate = false;
            if (success) {
                this.getAdmins(this.adminUsers.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.adminUser = this.adminUsers.data.find((e) => e.id === data);
                this.showModalEdit = true;
            }
        },
    }
};
</script>
<style scoped></style>
