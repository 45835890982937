import DashboardPartner from "../layout/partner/DashboardPartner.vue"
import Properties from "../pages/partner/Properties.vue"
import Users from "../pages/partner/Users"
import Transactions from "../pages/partner/Transactions"
import roles from "../constants/roles"

const partner = [
  {
    path: "/partner/login",
    component: () => import("../pages/partner/LoginPartner.vue"),
    name: "loginPartner",
    meta: {
      header: "hidden",
      footer: "hidden",
      virtual_balance_bubble: "hidden"
    },
  },
  {
    path: "/partner",
    component: DashboardPartner,
    redirect: "/partner/properties",
    name: "partner",
    meta: {
      authorize: [roles.Partner],
      header: "hidden",
      footer: "hidden",
      virtual_balance_bubble: "hidden"
    },
    children: [
      {
        path: "properties",
        name: "partnerProperties",
        component: Properties
      },
      {
        path: "users",
        name: "partnerUsers",
        component: Users
      },
      {
        path: "transactions",
        name: "partnerTransactions",
        component: Transactions
      },
    ]
  }
]

export default partner
