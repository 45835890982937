<template>
  <b-modal v-model="show" id="full-screen-modal" size="huge" no-close-on-backdrop no-close-on-esc
           content-class="modal-content-full" body-class="modal-body-full"
           :title="$t('PAYMENT.TOKEN_TRANSACTION_AGREEMENT')" :hide-footer="propertyUuid == null">

    <iframe :src="contractUrl" class="responsive-iframe"></iframe>

    <template #modal-footer>
      <div class="text-center">
        <b-form-checkbox v-model="agreeToContract" class="goro-checkbox mt-0 mb-2 mr-3"
                         style="font-weight: 400; font-size: 16px; z-index: 0">
          <span>
            {{ $t("PAYMENT.I_HAVE_READ_AND_AGREE") }}
            <u class="token-transaction">{{ $t("PAYMENT.TOKEN_TRANSACTION_AGREEMENT") }}</u>
            {{ $t("PAYMENT.I_AGREE_TO_BE_BOUND") }}
          </span>
        </b-form-checkbox>
        <b-button id="btn_createContractForExisting_Submit" class="btn-main mt-1 mb-0 text-center" variant="none"
                  :disabled="!agreeToContract" @click="createContractForExisting">
          {{ $t("common.SUBMIT") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import contractsService from "../services/contracts.service"

export default {
  components: {},
  data () {
    return {
      show: false,
      contractUrl: null,
      propertyUuid: null,
      agreeToContract: false,
    }
  },

  methods: {
    showModal (contractUrl, propertyUuid = null) {
      this.contractUrl = contractUrl
      this.propertyUuid = propertyUuid
      this.agreeToContract = false
      this.show = true
    },

    async createContractForExisting () {
      if (this.propertyUuid) {
        const response = await contractsService.createContractForExisting({
          property_uuid: this.propertyUuid
        })
        if (response && response.data) {
          store.dispatch("getPendingTaskCount")
          this.onAgreedToContract({ propertyUuid: this.propertyUuid, contractPath: response.data.contract_path })
        }
      }
    },

    onAgreedToContract: function (data) {
      this.$emit("on-agreed-to-contract", data)
      this.show = false
    },
  },
}
</script>
<!--suppress CssUnusedSymbol -->
<style scoped>

.modal-class {
  border-radius: 8px;
}

.responsive-iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.token-transaction {
  color: #00B7B6;
}
</style>
