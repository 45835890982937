<template>
  <b-modal
    v-model="showModal"
    size="lg"
    modal-class="cls-goro-custom-modal"
    header-class="modal-header no-border"
    footer-class="modal-footer"
    @hidden="onHidden()"
    hide-footer
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div style="width: 30px"></div>
        <h5 class="font-20 font-weight-bold header-title mb-0">
          {{ $t("PARTNER.USER.ASSETS_LIST") }}
        </h5>
        <button class="btn-close" @click="onClose()">
          <b-icon icon="x-lg" style="color: gray" scale="1.6"></b-icon>
        </button>
      </div>
    </template>
    <template #default="{ hide }">
      <div class="container">
        <b-row class="mb-3 cls-owned-sumary" align-v="center" v-if="user">
          <b-col cols="12" class="font-22">
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Id</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ user.id }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Name</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ user.name }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Email</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ user.email }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Phone</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ user.phone }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Total Assets</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ totalAssets }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Total Tokens</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ totalTokens }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="pl-0">
                <p class="font-16">Joined</p>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <p class="font-16 font-weight-bold">{{ userJoinedAt }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div v-if="ownedUsers" slot="raw-content" class="table-responsive">
          <paper-table
            :data="assets"
            :columns="tableColumns"
            class="cls-goro-custom-table"
            :borderless="true"
          >
            <template #theadSearch>
              <thead class="search">
                <th width="80px">
                  <b-input v-model="filters.id"></b-input>
                </th>
                <th width="30%">
                  <b-input v-model="filters.name"></b-input>
                </th>
                <th width="20%">
                  <b-input v-model="filters.address"></b-input>
                </th>
                <th width="20%">
                  <b-form-select
                    v-model="filters.category_id"
                    text-field="name"
                    value-field="id"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >Select type</b-form-select-option
                      >
                    </template>
                    <template v-for="category in categories">
                      <b-form-select-option :value="category.id">
                        {{ category.name }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </th>
                <th width="15%"></th>
              </thead>
            </template>
          </paper-table>
          <b-pagination
            v-if="ownedUsers.total"
            align="left"
            class="mt-3"
            v-model="ownedUsers.current_page"
            :total-rows="ownedUsers.total"
            :per-page="ownedUsers.per_page"
            @change="onChangePage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { PaperTable } from "../../../components";
import propertiesService from "../../../services/partner/properties.service";
import { formatCurrency, urlImage } from "../../../helpers/common";
import moment from "moment";
import { PAGINATION_DEFAULT } from "../../../constants/pagination";
import categoryService from "../../../services/categories.service";

export default {
  components: {
    PaperTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      perPage: PAGINATION_DEFAULT.perPage,
      showModal: false,
      assets: [],
      ownedUsers: null,
      totalTokens: 0,
      totalAssets: 0,
      tableColumns: ["Id", "Name", "Address", "Type", "Tokens"],
      categories: [],
      filters: {
        id: "",
        name: "",
        address: "",
        category_id: "",
      },
    };
  },

  emits: ["on-close"],

  async mounted() {
    await Promise.all([this.getCategories()]);
  },

  watch: {
    async user(value) {
      if (value) {
        this.assets = [];
        this.getAssets(value.id);
      }
    },
    show(value) {
      this.showModal = value;
    },
    async "filters.id"() {
      await this.searchTimeOut();
    },
    async "filters.name"() {
      await this.searchTimeOut();
    },
    async "filters.address"() {
      await this.searchTimeOut();
    },
    async "filters.category_id"() {
      await this.searchTimeOut();
    },
    async "filters.token"() {
      await this.searchTimeOut();
    },
  },

  methods: {
    async getCategories() {
      this.categories = await categoryService.getCategories();
    },

    async getAssets(id, page = 1) {
      const filters = {
        ...this.filters,
        per_page: this.perPage,
        page,
      };
      const res = await propertiesService.getOwnedByUserWithPaginate(
        id,
        filters
      );
      if (res) {
        const { data } = res;
        data.map((item) => {
          item.type = item.category.name;
          item.bed = item.num_bed;
          item.bath = item.num_bath;
          item.address = item.metadata.address;
          item.tokens = `${item.owning_tokens} tokens`;
          this.totalTokens += parseInt(item.owning_tokens);
        });
        this.assets = data;
        this.ownedUsers = res;
        this.totalAssets = res.total || 0;
        this.totalTokens = res.total_tokens || 0
      }
    },

    async onChangePage(page) {
      await this.getAssets(this.user.id, page);
    },

    searchTimeOut(page = 1) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getAssets(this.user.id, page);
      }, 400);
    },

    onHidden: function () {
        this.$emit("on-close");
    },

    onClose: function () {
        this.showModal = false
    },

    getImageUrl(image) {
      return urlImage(image);
    },

    getPercent(tokens) {
      return (
        Math.round(((tokens * 100) / this.property.total_tokens) * 10) / 10
      );
    },

    async openPropertyDetails(property) {
      const route = this.$router.resolve({
        name: "propertyDetail",
        params: { uuid: property.uuid },
      });
      window.open(route.href, "_blank");
    },

    getFormattedCurrency(value) {
      return formatCurrency(value);
    },
  },

  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },

    userJoinedAt() {
      return moment(this.user.created_at).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped lang="scss">
.cls-owned-sumary{
    p{
        color: #3F3F3F;
        line-height: 16.8px;
    }
    .row{
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 5px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
:deep() #full-screen-modal {
  padding: 0 !important;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  margin: 0;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
  border-radius: 0;
}

.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: 0.8;
  padding-bottom: 20px !important;
  /*!* opacity: .5; */
}

.item {
  background-color: white;
  padding: 20px;
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
