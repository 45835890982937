import serverApi from "../../utils/serverApi"

const configsService = {
  getList: (data) => serverApi({uri: 'admin/configs', method: 'GET', data}),

  createOrUpdate: (data) => serverApi({uri: 'admin/configs', method: 'POST', data}),

  delete: (id) => serverApi({uri: `admin/configs/${id}`, method: 'DELETE'}),
};

export default configsService;
