import serverApi from "../utils/serverApi"

const sellTokensService = {
  getSellTokenRequests: (data) => serverApi({ uri: "sell-token-requests", method: "GET", data }),

  approveSellTokenRequest: (body) => serverApi({ uri: "sell-token-requests/approve", method: "PUT", data: body }),

  rejectSellTokenRequest: (body) => serverApi({ uri: "sell-token-requests/reject", method: "PUT", data: body }),

  getSellTokenRequestHistory: () => serverApi({ uri: "sell-token-requests/history", method: "GET" }),

  createSellTokenRequest: (body) => serverApi({ uri: "sell-token-requests/create", method: "POST", data: body }),

  getSellTokenRequest: (body) => serverApi({ uri: "sell-token-requests/get", method: "POST", data: body }),

  cancelSellTokenRequest: (body) => serverApi({ uri: "sell-token-requests/cancel", method: "PUT", data: body }),
}

export default sellTokensService
