import serverApi from "../utils/serverApi"

const commonService = {
  getCountries: () => serverApi({ uri: 'countries', method: 'GET', isLoading: false }),

  getConfigs: () => serverApi({ uri: 'get-configs', method: 'GET', isLoading: false }),

  getConfigDetail: (cKey, isManualErrorHandling = false) => serverApi({
    uri: `get-config-detail/${cKey}`,
    method: "GET",
    isManualErrorHandling: isManualErrorHandling
  }),

  getReferralInviter: (code) => serverApi({ uri: 'get-referral-inviter', method: 'GET', data: { code: code } }),

  checkTransactionAccess: (code) => serverApi({ uri: 'check-transaction-access', method: 'PUT', data: { code: code } }),

  getAvailableBanks: () =>  serverApi({ uri: 'get-available-banks', method: 'GET', isLoading: false }),

  getBanners: (data) =>  serverApi({ uri: 'get-banners', method: 'GET', isLoading: false, data }),

  getExchangeRates: () =>  serverApi({ uri: 'exchange-rates', method: 'GET', isLoading: false }),

  getSanctumCsrfCookie: () =>  serverApi({ uri: `csrf-cookie`, method: 'GET', isLoading: false }),
};

export default commonService;
