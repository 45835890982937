<template>
  <div v-bind:class="{ 'nav-open': $sidebar.showSidebar }" class="cls-goro-sidebar-admin">
    <div class="wrapper">
      <side-bar>
        <template v-slot:links>
          <sidebar-link v-if="canViewLink('view-property')" :to="{name: 'partnerProperties'}" name="Properties" icon="ti-home"/>
          <sidebar-link v-if="canViewLink('view-user')" :to="{name: 'partnerUsers'}" name="Users" icon="ti-user"/>
          <sidebar-link v-if="canViewLink('view-user')" :to="{name: 'partnerTransactions'}" name="Transactions" icon="ti-briefcase"/>
        </template>
        <mobile-menu>
          <li class="divider"></li>
        </mobile-menu>
      </side-bar>
      <div class="main-panel menu-content">
        <top-navbar></top-navbar>

        <dashboard-content @click="toggleSidebar">

        </dashboard-content>

        <content-footer></content-footer>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue"
import ContentFooter from "./ContentFooter.vue"
import DashboardContent from "./Content.vue"
import MobileMenu from "./MobileMenu"
import { partnerHasPermission } from "../../helpers/partner";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    canViewLink(permissions) {
      return partnerHasPermission(permissions);
    },
  },
};
</script>

<style lang="scss">
.cls-goro-sidebar-admin{
  .menu-content {
    margin-top: -90px;
  }
}
</style>
