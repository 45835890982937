<template>
  <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
           @hidden="$emit('on-close')" @ok="handleOk">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Version" label-for="key-input" :state="validateState('version')">
        <b-form-input id="key-input" v-model="form.version" :state="validateState('version')"
                      type="number" step="0.1" required>
        </b-form-input>
      </b-form-group>

      <b-form-group v-if="form.contract_preview_path" label="Contract Preview Path"
                    label-for="key-input" :state="validateState('contract_preview_path')">
        <b-input-group>
          <b-form-input id="key-input" v-model="form.contract_preview_path" :disabled="true"
                        :state="validateState('contract_preview_path')"></b-form-input>
          <b-input-group-append>
            <button size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    style="border-radius: 0" @click="removeCurrentContractPreviewPath()">
              x
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="!form.contract_preview_path"
                    label="Contract Preview File (PDF file only)"
                    label-for="key-input" :state="validateState('contract_preview_file')">
        <b-form-file v-model="form.contract_preview_file" max-files="1" :state="validateState('contract_preview_file')">
        </b-form-file>
      </b-form-group>

      <b-form-group v-if="form.contract_path" label="Contract Path"
                    label-for="key-input" :state="validateState('contract_path')">
        <b-input-group>
          <b-form-input id="key-input" v-model="form.contract_path" :disabled="true"
                        :state="validateState('contract_path')"></b-form-input>
          <b-input-group-append>
            <button size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    style="border-radius: 0" @click="removeCurrentContractPath()">
              x
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="!form.contract_path"
                    label="Contract File (DOCX file only)"
                    label-for="key-input" :state="validateState('contract_file')">
        <b-form-file v-model="form.contract_file" max-files="1" :state="validateState('contract_file')">
        </b-form-file>
      </b-form-group>

      <b-form-group v-if="form.annex_a_path" label="Annex A Path"
                    label-for="key-input" :state="validateState('annex_a_path')">
        <b-input-group>
          <b-form-input id="key-input" v-model="form.annex_a_path" :disabled="true"
                        :state="validateState('annex_a_path')"></b-form-input>
          <b-input-group-append>
            <button size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    style="border-radius: 0" @click="removeCurrentAnnexAPath()">
              x
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="!form.annex_a_path"
                    label="Annex A File (DOCX file only)"
                    label-for="key-input" :state="validateState('annex_a_file')">
        <b-form-file v-model="form.annex_a_file" max-files="1" :state="validateState('annex_a_file')">
        </b-form-file>
      </b-form-group>

      <b-form-group v-if="form.annex_b_path" label="Annex B Path"
                    label-for="key-input" :state="validateState('annex_b_path')">
        <b-input-group>
          <b-form-input id="key-input" v-model="form.annex_b_path" :disabled="true"
                        :state="validateState('annex_b_path')"></b-form-input>
          <b-input-group-append>
            <button size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    style="border-radius: 0" @click="removeCurrentAnnexBPath()">
              x
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="!form.annex_b_path"
                    label="Annex B File (DOCX file only)"
                    label-for="key-input" :state="validateState('annex_b_file')">
        <b-form-file v-model="form.annex_b_file" max-files="1" :state="validateState('annex_b_file')">
        </b-form-file>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import { decimal, required, requiredIf } from "@vuelidate/validators"
import { getBase64 } from "../../../helpers/common"
import contractService from "../../../services/contracts.service"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contractTemplate: {
      type: Object,
      default: null,
    },
  },
  emits: ["on-close"],
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      form: {
        version: {
          required, decimal
        },
        contract_preview_path: {
          required: requiredIf(function () {
            return this.form.contract_preview_file === null || this.form.contract_preview_file === ""
          }),
        },
        contract_preview_file: {
          required: requiredIf(function () {
            return this.form.contract_preview_path === null || this.form.contract_preview_path === ""
          }),
        },
        contract_path: {
          required: requiredIf(function () {
            return this.form.contract_file === null || this.form.contract_file === ""
          }),
        },
        contract_file: {
          required: requiredIf(function () {
            return this.form.contract_path === null || this.form.contract_path === ""
          }),
        },
        annex_a_path: {
          required: requiredIf(function () {
            return this.form.annex_a_file === null || this.form.annex_a_file === ""
          }),
        },
        annex_a_file: {
          required: requiredIf(function () {
            return this.form.annex_a_path === null || this.form.annex_a_path === ""
          }),
        },
        annex_b_path: {
          required: requiredIf(function () {
            return this.form.annex_b_file === null || this.form.annex_b_file === ""
          }),
        },
        annex_b_file: {
          required: requiredIf(function () {
            return this.form.annex_b_path === null || this.form.annex_b_path === ""
          }),
        },
      },
    }
  },
  data () {
    return {
      form: {
        version: "",
        contract_preview_path: "",
        contract_preview_file: null,
        contract_path: "",
        contract_file: null,
        annex_a_path: "",
        annex_a_file: null,
        annex_b_path: "",
        annex_b_file: null,
      },
      showModal: false,
    }
  },

  watch: {
    show (value) {
      this.showModal = value
    }
  },

  methods: {
    validateState (name) {
      const { $dirty, $error } = this.v$.form[name]
      return $dirty ? !$error : null
    },
    removeCurrentContractPreviewPath () {
      this.form.contract_preview_path = ""
    },
    removeCurrentContractPath () {
      this.form.contract_path = ""
    },
    removeCurrentAnnexAPath () {
      this.form.annex_a_path = ""
    },
    removeCurrentAnnexBPath () {
      this.form.annex_b_path = ""
    },
    updateModal () {
      if (this.contractTemplate != null) {
        this.form = {
          id: this.contractTemplate.id,
          uuid: this.contractTemplate.uuid,
          version: this.contractTemplate.version,
          contract_preview_path: this.contractTemplate.contract_preview_path,
          contract_preview_file: null,
          contract_path: this.contractTemplate.contract_path,
          contract_file: null,
          annex_a_path: this.contractTemplate.annex_a_path,
          annex_a_file: null,
          annex_b_path: this.contractTemplate.annex_b_path,
          annex_b_file: null,
        }
      } else {
        this.resetModal()
      }
    },
    resetModal () {
      this.form = {
        id: "",
        uuid: "",
        version: "",
        contract_preview_path: "",
        contract_preview_file: null,
        contract_path: "",
        contract_file: null,
        annex_a_path: "",
        annex_a_file: null,
        annex_b_path: "",
        annex_b_file: null,
      }
    },
    handleOk (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit () {
      this.v$.form.$touch()
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }
      if (this.form.contract_preview_file) {
        this.form.contract_preview_file_base_64 = await getBase64(this.form.contract_preview_file)
      }
      if (this.form.contract_file) {
        this.form.contract_file_base_64 = await getBase64(this.form.contract_file)
      }
      if (this.form.annex_a_file) {
        this.form.annex_a_file_base_64 = await getBase64(this.form.annex_a_file)
      }
      if (this.form.annex_b_file) {
        this.form.annex_b_file_base_64 = await getBase64(this.form.annex_b_file)
      }
      if (this.contractTemplate != null) {
        await contractService.updateContractTemplate(this.form)
      } else {
        await contractService.createContractTemplate(this.form)
      }
      await this.$nextTick(() => {
        this.resetModal()
        this.$emit("on-close", true)
      })
    }
  },

  computed: {
    title () {
      return this.contractTemplate != null ? "Update contract" : "Create Contract"
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
