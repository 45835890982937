<!--suppress HtmlUnknownTag -->
<template>
  <b-modal v-model="showModal" id="full-screen-modal" size="huge" :no-close-on-backdrop="true" header-bg-variant="dark" header-text-variant="light"
    @hidden="onClose(false)">
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose(false)">×
      </button>
    </template>
    <div class="container">
      <b-form class="form-content">
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Property Id:</label>
            <b-form-input v-model.trim="property.id" type="text" placeholder="Property id" :state="validateProperty('id')"
              :disabled="true">
            </b-form-input>
            <b-form-invalid-feedback>Property Id is required</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <label>Property Name:</label>
            <b-form-input v-model.trim="property.name" type="text" placeholder="Property name"
              :state="validateProperty('id')" :disabled="true">
            </b-form-input>
            <b-form-invalid-feedback>Property Name is invalid</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Month & Year:</label>
            <VueDatePicker v-model="selectedMonth" placeHolder="MM-YYYY" month-picker format="MM-yyyy" :min-date="min" :max-date="max" v-show="!isDisable" :disabled="isDisable"  />
            <b-form-input v-model.trim="form.date" type="text" :state="validateForm('date')" v-show="isDisable"
              :disabled="isDisable" />
            <b-form-invalid-feedback>Month & Year is required</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <label>Rental Income: </label>
            <label v-if="rentalDistributionInfo.num_of_days">({{ rentalDistributionInfo.num_of_days }} days)</label>
            <label class="text-danger pl-1" v-if="showNumOfDaysWarning">
              (Warning: Amount for {{ rentalDistributionInfo.num_of_days }} days only. Not the whole month)
            </label>
            <b-input-group>
              <b-form-input v-model.trim="form.rental_income" type="number" placeholder="Rental Income"
                :state="validateForm('rental_income')" :disabled="isDisable">
              </b-form-input>
              <b-input-group-append>
                <b-input-group-text class="font-weight-bold">IDR</b-input-group-text>
              </b-input-group-append>
              <b-button v-if="!isDisable" size="sm" class="btn-main ml-2" variant="none" @click="addDeduction">
                Add Deduction
              </b-button>
              <b-form-invalid-feedback>Rental Income is required</b-form-invalid-feedback>
            </b-input-group>
          </b-col>
        </b-row>
        <label v-if="form.rental_deductions && form.rental_deductions.length > 0" class="mb-0">Rental deductions: </label>
        <label v-if="rentalDistributionInfo.num_of_days">({{ rentalDistributionInfo.num_of_days }} days)</label>
        <label class="text-danger pl-1" v-if="showNumOfDaysWarning">
          (Warning: Amount for {{ rentalDistributionInfo.num_of_days }} days only. Not the whole month)
        </label>
        <RentalDeductionInput v-for="(rentalDeduction, index) in form.rental_deductions" :key="index" :rentalDeduction="rentalDeduction" :index="index" @removeDeduction="removeDeduction" :isDisable="isDisable" :rentalDeductionTypes="rentalDeductionTypes" />
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Number of Investors (for selected month & year):</label>
            <b-form-input v-model.trim="rentalDistributionInfo.num_of_investors" type="number" :disabled="true"
              :state="validateDistributionInfo('num_of_investors')" placeholder="Number of investors">
            </b-form-input>
            <b-form-invalid-feedback>Number of investors is invalid</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <label>Total Tokens:</label>
            <b-form-input v-model.trim="property.total_tokens" type="number" placeholder="Total tokens"
              :state="validateProperty('total_tokens')" :disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Tokens need to distribute (for selected month & year):</label>
            <b-form-input v-model.trim="rentalDistributionInfo.num_of_tokens_to_distribute"
              :state="validateDistributionInfo('num_of_tokens_to_distribute')" type="number"
              placeholder="Tokens need to distribute" :disabled="true">
            </b-form-input>
            <b-form-invalid-feedback>Tokens need to distributes is invalid</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <label>Income Per Token:</label>
                <b-input-group>
                  <b-form-input v-model.trim="incomePerToken" type="number" :state="validate('incomePerToken')"
                    placeholder="Income per token" :disabled="true">
                  </b-form-input>
                  <b-input-group-append>
                    <b-input-group-text class="font-weight-bold">IDR</b-input-group-text>
                  </b-input-group-append>
                  <b-form-invalid-feedback>Income per token is invalid</b-form-invalid-feedback>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <b-row>
              <b-col>
                <label>Income Per Token Per Day:</label>
                <label v-if="rentalDistributionInfo.num_of_days">({{ rentalDistributionInfo.num_of_days }} days)</label>
                <b-input-group>
                  <b-form-input v-model.trim="incomePerTokenPerDay" type="number"
                    :state="validate('incomePerTokenPerDay')" placeholder="Income Per Token Per Day" :disabled="true">
                  </b-form-input>
                  <b-input-group-append>
                    <b-input-group-text class="font-weight-bold">IDR</b-input-group-text>
                  </b-input-group-append>
                  <b-form-invalid-feedback>Income per token per day is invalid</b-form-invalid-feedback>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <label>Total distribution:</label>
            <b-input-group>
              <b-form-input v-model.trim="totalDistribution" type="text" :state="validate('totalDistribution')"
                placeholder="Total distributions" :disabled="true">
              </b-form-input>
              <b-input-group-append>
                <b-input-group-text class="font-weight-bold">IDR</b-input-group-text>
              </b-input-group-append>
              <b-form-invalid-feedback>Total distribution is invalid</b-form-invalid-feedback>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Number of Virtual Investors (for selected month & year):</label>
            <b-form-input v-model.trim="rentalDistributionInfo.num_of_virtual_investors" type="number" :disabled="true"
              placeholder="Number of virtual investors">
            </b-form-input>
            <b-form-invalid-feedback>Number of investors is invalid</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <label>Virtual tokens need to distribute (for selected month & year):</label>
            <b-form-input v-model.trim="rentalDistributionInfo.num_of_virtual_tokens_to_distribute" type="number"
              placeholder="Virtual tokens need to distribute" :disabled="true">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Total virtual distribution:</label>
            <b-input-group>
              <b-form-input v-model.trim="totalVirtualDistribution" type="text" placeholder="Total virtual distributions"
                :disabled="true">
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Description (optional):</label>
            <b-form-input v-model.trim="form.description" type="text" placeholder="Description" :disabled="isDisable">
            </b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Status:</label>
            <b-form-select v-model.trim="form.status" :options="statuses" :state="validateForm('status')"
              :disabled="isApproved">
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Notes (optional):</label>
            <b-form-textarea v-model.trim="form.note" type="text" placeholder="Notes" :disabled="isDisable" rows="3"
              max-rows="6">
            </b-form-textarea>
          </b-col>
          <b-col cols="6">
            <label>Notes ID Locale (optional):</label>
            <b-form-textarea v-model.trim="form.note_id_locale" type="text" placeholder="Notes" :disabled="isDisable"
              rows="3" max-rows="6">
            </b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
          </b-col>
          <b-col v-if="isRejected" cols="6">
            <label>Reason for rejection:</label>
            <b-form-input v-model.trim="form.reason" type="text" placeholder="Reason">
            </b-form-input>
          </b-col>
        </b-row>
        <p v-if="rentalDistributionInfo.error" class="text-center red">{{ rentalDistributionInfo.error }}</p>
      </b-form>
    </div>
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button v-if="rentalDistribution === null" size="sm" class="btn-main" variant="none" @click="onCreate">
        Create
      </b-button>
      <b-button v-if="rentalDistribution !== null && !isApproved" size="sm" class="btn-main" variant="none"
        @click="onUpdate">
        Update
      </b-button>
      <b-button size="sm" class="btn-outline-main" variant="none" @click="onClose(false)">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment"
import { STATUS } from "@/constants/constants"
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import rentalDistributionService from "@/services/admin/rentalDistribution.service"
import propertiesService from "@/services/properties.service"
import { notify } from "@/helpers/common"
import RentalDeductionInput from "../../../components/RentalDeductionInput.vue"

const greaterThanZero = (value) => value > 0

export default {
  components: {
    RentalDeductionInput,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    propertyId: {
      type: Number,
      default: null,
    },
    rentalDistribution: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      showModal: false,
      property: {},
      rentalDistributionInfo: {},
      rentalDeductionTypes: [],
      form: this.rentalDistribution != null
        ? { rental_deductions: [], ...this.rentalDistribution }
        : { rental_deductions: [], status: STATUS.PENDING },
      selectedMonth: this.rentalDistribution != null ? moment(this.rentalDistribution.date, "MM-YYYY") : null,
      monthLabels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    }
  },

  methods: {
    addDeduction() {
      this.form.rental_deductions.push({
        deduction_type_id: "",
        amount: "",
        note: "",
      })
    },

    removeDeduction(index) {
      this.form.rental_deductions.splice(index, 1)
    },

    onClose: function (hasChanged) {
      this.v$.$reset()
      this.rentalDistributionInfo = {}
      this.rentalDeductionTypes = []
      this.selectedMonth = null
      this.form = { rental_deductions: [], status: STATUS.PENDING }
      this.$emit("on-close", hasChanged)
    },

    validate(name) {
      const { $dirty, $error } = this.v$[name]
      return $dirty ? !$error : null
    },

    validateProperty(name) {
      const { $dirty, $error } = this.v$.property[name]
      return $dirty ? !$error : null
    },

    validateForm(name) {
      const { $dirty, $error } = this.v$.form[name]
      return $dirty ? !$error : null
    },

    validateDistributionInfo(name) {
      const { $dirty, $error } = this.v$.rentalDistributionInfo[name]
      return $dirty ? !$error : null
    },

    isDataValid: async function () {
      this.v$.property.$touch()
      this.v$.form.$touch()
      this.v$.rentalDistributionInfo.$touch()
      this.v$.incomePerToken.$touch()
      this.v$.incomePerTokenPerDay.$touch()
      this.v$.totalDistribution.$touch()
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        window.scrollTo(0, 0)
        return false
      }
      return true
    },

    createRequestData: function (isUpdate = false) {
      return {
        request_id: isUpdate ? this.rentalDistribution.id : null,
        property_id: this.property.id,
        num_of_days: this.rentalDistributionInfo.num_of_days,
        num_of_investors: this.rentalDistributionInfo.num_of_investors,
        total_distribution: Math.floor(this.totalDistribution),
        num_of_virtual_investors: this.rentalDistributionInfo.num_of_virtual_investors,
        total_virtual_distribution: Math.floor(this.totalVirtualDistribution),
        ...this.form
      }
    },

    async onCreate() {
      const isFormCorrect = await this.isDataValid()
      if (isFormCorrect) {
        const response = await rentalDistributionService.createRentalDistribution(this.createRequestData(false))
        if (response && response.data) {
          this.onClose(true)
        }
      }
    },

    async onUpdate() {
      const isFormCorrect = await this.isDataValid()
      if (isFormCorrect) {
        const response = await rentalDistributionService.updateRentalDistribution(this.createRequestData(true))
        if (response && response.data) {
          //this.rentalDistribution = response.data
          this.onClose(true)
        }
      }
    },
  },

  computed: {
    title() {
      return this.rentalDistribution != null ? "Update Rental Distribution" : "Create Rental Distribution"
    },

    isDisable() {
      return this.rentalDistribution && this.rentalDistribution.status !== STATUS.REJECTED
    },

    isApproved() {
      return this.rentalDistribution && this.rentalDistribution.status === STATUS.APPROVED
    },

    isRejected() {
      return this.form && this.form.status === STATUS.REJECTED
    },
    showNumOfDaysWarning () {
      if (this.form && this.form.date && this.rentalDistributionInfo) {
        return moment(this.form.date, "YYYY-MM-DD").daysInMonth() !== this.rentalDistributionInfo.num_of_days
      }

      return false
    },
    min() {
      return this.property != null
        ? moment(this.property.first_live_on, "YYYY-MM-DD").startOf("month").toDate() : null
    },
    max() {
      return moment().subtract(1, "months").toDate()
    },

    statuses() {
      if (this.rentalDistribution != null) {
        if (this.rentalDistribution.status === STATUS.REJECTED) {
          return [STATUS.PENDING, STATUS.REJECTED]
        } else {
          return [STATUS.PENDING, STATUS.APPROVED, STATUS.REJECTED]
        }
      } else {
        return [STATUS.PENDING]
      }
    },

    totalDeduction() {
      let totalDeduction = 0
      this.form.rental_deductions.forEach((rentalDeduction) => {
        if (rentalDeduction.deduction_type_id && rentalDeduction.amount && rentalDeduction.note) {
          totalDeduction = totalDeduction + parseInt(rentalDeduction.amount)
        }
      })
      return totalDeduction
    },

    incomePerToken() {
      return this.property != null && this.form.rental_income
        ? (this.form.rental_income - this.totalDeduction) / this.property.total_tokens : "0"
    },

    incomePerTokenPerDay() {
      return this.property != null && this.form.rental_income
        && this.rentalDistributionInfo && this.rentalDistributionInfo.num_of_days
        ? (this.form.rental_income - this.totalDeduction) / this.property.total_tokens / this.rentalDistributionInfo.num_of_days : "0"
    },

    totalDistribution() {
      return this.rentalDistributionInfo && this.selectedMonth
        && this.rentalDistributionInfo.num_of_tokens_to_distribute && this.incomePerTokenPerDay
        ? this.rentalDistributionInfo.num_of_tokens_to_distribute * this.incomePerTokenPerDay : "0"
    },

    totalVirtualDistribution() {
      return this.rentalDistributionInfo && this.selectedMonth
        && this.rentalDistributionInfo.num_of_virtual_tokens_to_distribute && this.incomePerTokenPerDay
        ? this.rentalDistributionInfo.num_of_virtual_tokens_to_distribute * this.incomePerTokenPerDay : "0"
    }
  },

  validations() {
    return {
      property: {
        id: {
          required,
        },
        name: {
          required,
        },
        total_tokens: {
          required, minValue: greaterThanZero
        },
      },
      form: {
        date: {
          required,
        },
        rental_income: {
          required, minValue: greaterThanZero
        },
        status: {
          required,
        },
        rental_deductions: {
          required
        }
      },
      rentalDistributionInfo: {
        num_of_investors: {
          required, minValue: 0
          //required, minValue: greaterThanZero
        },
        num_of_tokens_to_distribute: {
          required, minValue: 0
          // required, minValue: greaterThanZero
        },
      },
      incomePerToken: {
        required, minValue: greaterThanZero
      },
      incomePerTokenPerDay: {
        required, minValue: greaterThanZero
      },
      totalDistribution: {
        required, minValue: 0
        //required, minValue: greaterThanZero
      },
    }
  },

  watch: {
    show(value) {
      this.showModal = value
    },
    async propertyId(value) {
      if (value) {
        this.property = await propertiesService.getById(value)
        this.rentalDeductionTypes = await rentalDistributionService.getRentalDeductionTypes({ all: true })
      }
    },

    async rentalDistribution(value) {
      if (value) {
        this.form = { rental_deductions: [], ...value }
        this.property = await propertiesService.getById(value.property_id)
        this.rentalDeductionTypes = await rentalDistributionService.getRentalDeductionTypes({ all: true })
        this.form.rental_deductions = await rentalDistributionService.getRentalDeductions({ rental_distribution_id: value.id })
        this.selectedMonth = moment(value.date, "MM-YYYY")
      }
    },

    async selectedMonth(value) {
      if (value) {
        this.form.date = moment(value).format("YYYY-MM-DD")
        try {
          this.rentalDistributionInfo = await rentalDistributionService.getRentalDistributionInfo({
            property_id: this.property.id,
            date: this.form.date,
          }, true)
        } catch (e) {
          this.rentalDistributionInfo = { error: e.data || e.message }
          notify({ text: e.data || e.message, type: "error" })
        }
      } else {
        this.form.date = null
        this.rentalDistributionInfo = {}
      }
    },
  }
}
</script>

<style scoped>
:deep() #full-screen-modal {
  padding: 0 !important;
  background: #f4f3ef;
}
 :deep() #full-screen-modal .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  margin: 0;
  background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: #f4f3ef;
}

.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: .8;
  padding-bottom: 20px !important;
}

.form-content label {
  font-weight: bold;
  font-size: 16px;
}
</style>
