import serverApi from "../../utils/serverApi";

const adminUsersService = {
  getList: (data) => serverApi({ uri: "admin/admins", method: "GET", data }),

  getAdminPermissions: (data) => serverApi({ uri: "admin/admins/admin-permissions", method: "GET", data }),

  update: (id, data) => serverApi({ uri: `admin/admins/${id}`, method: "PUT", data }),

  create: (data) => serverApi({ uri: "admin/admins", method: "POST", data }),
}

export default adminUsersService;
