<template>
  <div v-if="isShowWhatsAppWidget" class="whatsapp-button">
    <a :href=getWaSupportByLang() class="whatsapp-button__link" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 48 48" fill="none">
        <path fill="#fff"
              d="M40.8 23.567c0 9.04-7.384 16.367-16.495 16.367-2.892 0-5.609-.739-7.973-2.036L7.2 40.8l2.977-8.782a16.194 16.194 0 0 1-2.367-8.45c0-9.04 7.385-16.368 16.495-16.368 9.112 0 16.495 7.328 16.495 16.367ZM24.305 9.807c-7.647 0-13.867 6.173-13.867 13.76 0 3.011.981 5.8 2.641 8.068l-1.732 5.11 5.329-1.693a13.86 13.86 0 0 0 7.63 2.276c7.646 0 13.867-6.172 13.867-13.76S31.953 9.807 24.305 9.807Zm8.33 17.53c-.102-.167-.371-.268-.775-.468-.405-.2-2.393-1.172-2.763-1.305-.37-.134-.641-.201-.91.2-.27.402-1.044 1.305-1.28 1.573-.237.268-.472.302-.877.1-.404-.2-1.706-.624-3.25-1.99-1.203-1.063-2.014-2.376-2.25-2.778-.236-.401-.025-.618.177-.818.182-.18.405-.468.607-.703.203-.234.27-.4.404-.669.135-.268.068-.502-.034-.703-.1-.2-.91-2.175-1.247-2.978-.337-.803-.673-.67-.91-.67-.235 0-.505-.033-.774-.033-.27 0-.708.1-1.079.502-.37.402-1.414 1.372-1.414 3.346s1.448 3.882 1.65 4.15c.203.267 2.797 4.45 6.907 6.056 4.111 1.605 4.111 1.07 4.852 1.002.741-.066 2.392-.97 2.73-1.906.336-.938.336-1.74.236-1.908Z"></path>
      </svg>
    </a>
  </div>
</template>
<script>

import externalSites from "../constants/externalSites"

export default {
  data () {
    return {
      whatsappSupports: externalSites.WHATSAPP_SUPPORTS,
    }
  },

  methods: {
    getWaSupportByLang () {
      if (this.whatsappSupports[this.$i18n.locale]) {
        return this.whatsappSupports[this.$i18n.locale]
      }
      return this.whatsappSupports.id
    },
  },

  computed: {
    isShowWhatsAppWidget () {
      if (window.location.pathname.startsWith('/partner')) {
        return false
      }
      
      return !window.location.pathname.includes("landing")
        && !window.location.pathname.includes("admin")
    },
  },
}
</script>
<style lang="scss">
.whatsapp-button {
  display: block;
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  z-index: 5;
  cursor: pointer;
  background-color: #57d163;
  border-radius: 10px;
  animation: ripple-effect 1.2s infinite ease-out;
}

.whatsapp-button__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@keyframes ripple-effect {
  0% {
    box-shadow: 0 0 0 0 rgba(87, 209, 99, 0.5),
    0 0 0 2px rgba(87, 209, 99, 0),
    0 0 0 5px rgba(87, 209, 99, 0);
  }
  33.33% {
    box-shadow: 0 0 0 3px rgba(87, 209, 99, 0),
    0 0 0 3px rgba(87, 209, 99, 0.30),
    0 0 0 6px rgba(87, 209, 99, 0);
  }

  66.66% {
    box-shadow: 0 0 0 3px rgba(87, 209, 99, 0),
    0 0 0 4px rgba(87, 209, 99, 0),
    0 0 0 7px rgba(87, 209, 99, 0.2);
  }

  100% {
    box-shadow: 0 0 0 3px rgba(87, 209, 99, 0),
    0 0 0 5px rgba(87, 209, 99, 0),
    0 0 0 8px rgba(87, 209, 99, 0);
  }
}
</style>
