import serverApi from "../utils/serverApi";

const guestService = {
  getInitialBalance: (uuid) =>
    serverApi({
      uri: "guest/initial-balance",
      method: "GET",
      data: { uuid },
      isLoading: false,
    }),

  buyTokens: (body) =>
    serverApi({
      uri: "guest/buy-tokens",
      method: "POST",
      data: body,
    }),

  getTransactions: (uuid) =>
    serverApi({
      uri: "guest/transactions",
      method: "GET",
      data: { uuid },
      isLoading: false,
    }),

  getTransaction: (transId) =>
    serverApi({
      uri: `guest/transactions/${transId}`,
      method: "GET",
    }),
};

export default guestService;
